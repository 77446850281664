import * as React from "react";

import {
  GroupingState,
  IntegratedGrouping,
  IntegratedSorting,
  SortingState,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  GroupingPanel,
  Table,
  TableGroupRow,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import ComponentCell from "./ComponentCell";

export default class ComponentsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { rows, columns } = this.state;
    const tableColumnExtensions = [
      //company name
      { columnName: "name" },
      { columnName: "tradeVolume" },
      { columnName: "size" },
      { columnName: "unit" },
      { columnName: "distributor_name" },
      { columnName: "category_Name" },
      { columnName: "bay" },
      { columnName: "hylde" },
      { columnName: "actions", align: "center" },
    ];
    const defaultColumnWidths = [
      //company name
      { columnName: "name", width: 285 },
      { columnName: "tradeVolume", width: 200 },
      { columnName: "unitSuffix", width: 100 },
      { columnName: "unit_name", width: 100 },
      { columnName: "distributor_name", width: 130 },
      { columnName: "category_Name", width: 100 },
      { columnName: "bay", width: 100 },
      { columnName: "hylde", width: 100 },
      { columnName: "actions", width: 250 },
    ];
    if (this.props.myData.length > 0) {
      return (
        <Grid rows={this.props.myData} columns={this.props.dataColumn}>
          <SortingState
          /*  defaultSorting={[
              { columnName: "category_Name", direction: "desc" }
            ]} */
          />
          <GroupingState
            grouping={this.props.grouping}
            defaultExpandedGroups={this.props.defaultExpandedGroups}
          />
          <IntegratedGrouping />
          <IntegratedSorting />

          <DragDropProvider />
          <SearchState />
          <IntegratedFiltering />

          <Table
            columnExtensions={tableColumnExtensions}
            cellComponent={ComponentCell}
          />
          {/* <TableColumnResizing defaultColumnWidths={defaultColumnWidths} /> */}
          <TableHeaderRow showSortingControls={true} />

          <TableGroupRow />

          <Toolbar />
          <SearchPanel />
          <GroupingPanel showSortingControls={true} />
        </Grid>
      );
    } else {
      return <div>Loading</div>;
    }
  }
}
