import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import { Button } from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import Info from "@material-ui/icons/InfoSharp";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import apiFacade from "../../../auth/apiFacade";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { Row, Col, Card, CardTitle, CardBody, Alert } from "reactstrap";

const CustomTableCellStyles = {
  style1: {
    //color: "#3787C6",
    fontSize: 12,
    align: "left",
    paddingLeft: 10,
  },
  style2: {
    //color: "#3787C6",
    fontSize: 12,
    align: "left",
    paddingRight: 0,
  },
};

const ComponentCell = (props) => {
  const [openSaved, setOpenSaved] = useState(false);
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [savedTitle, setsavedTitle] = useState("");
  const [linkColor, setLinkColor] = useState("#ffffff");
  const [changeDisName, setChangeDisName] = useState("");
  const [changeReolNr, setChangeReolNr] = useState("");
  const [changeCategoryId, setChangeCategoryId] = useState("");
  const [changeUnitId, setChangeUnitId] = useState("");
  const [changeContainerId, setChangeContainerId] = useState("");
  const [componentValues, setComponentValues] = useState({
    componentId: "",
    categoryId: "",
    distributor_id: "",
    name: "",
    size: "",
    unitId: "",
    container_id: "",
    unitSuffix: "",
    bay: 0,
    shelf: "",
    tradeVolume: "",
    minPerBartender: 0,
  });

  const deleteCom = () => {
    setsavedTitle("  Deleted");

    apiFacade.deleteData(
      "https://pakkemodelapi.azurewebsites.net/api/components/" +
        props.row.componentId
    );
    setOpenSaved(true);

    window.setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const changeOpenSaved = () => {
    setOpenSaved(!openSaved);
  };

  const changeDistributorName = (event) => {
    if (event.target.value != 0) {
      setChangeDisName(event.target.value);
      setComponentValues({
        ...componentValues,
        distributor_id: event.target.value,
      });
    }
  };

  const changeReolNumber = (event) => {
    if (event.target.value != 0) {
      setChangeReolNr(event.target.value);
      setComponentValues({
        ...componentValues,
        bay: event.target.value,
      });
    }
  };

  const changeCategory = (event) => {
    if (event.target.value != 0) {
      setChangeCategoryId(event.target.value);
      setComponentValues({
        ...componentValues,
        categoryId: event.target.value,
      });
    }
  };
  const changeUnit = (event) => {
    if (event.target.value != 0) {
      setChangeUnitId(event.target.value);
      setComponentValues({
        ...componentValues,
        unitId: event.target.value,
      });
    }
  };

  const changeContainer = (event) => {
    if (event.target.value != 0) {
      setChangeContainerId(event.target.value);
      setComponentValues({
        ...componentValues,
        container_id: event.target.value,
      });
    }
  };

  const toggle = () => setModal(!modal);
  const editToggle = () => setEditModal(!editModal);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  const colWithtwo = (value1, value2, name1, name2) => {
    return (
      <Row>
        <Col xs="6">
          {" "}
          <TextField
            style={{ fontWeight: "bold" }}
            id="standard-name"
            disabled
            label={name1}
            value={value1}
          />
        </Col>
        <Col xs="6">
          <TextField
            style={{ fontWeight: "bold" }}
            id="standard-id"
            disabled
            label={name2}
            value={value2}
          />
        </Col>
      </Row>
    );
  };

  const colWithtContainer = (value1) => {
    if (props.row.container_id != 0) {
      let containerInfo = "";
      for (let u = 0; u < props.row.containers.length; u++) {
        if (props.row.container_id == props.row.containers[u].id) {
          containerInfo =
            props.row.containers[u].name +
            " " +
            props.row.containers[u].size +
            " " +
            props.row.containers[u].suffix;
        }
      }
      return (
        <Row>
          <Col xs="12">
            <TextField
              style={{ fontWeight: "bold" }}
              id="standard-id"
              disabled
              label="container"
              value={containerInfo}
            />
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col xs="12">
            <TextField
              style={{ fontWeight: "bold" }}
              id="standard-id"
              disabled
              label="container"
              value="ikke tilvalgt"
            />
          </Col>
        </Row>
      );
    }
  };
  const used_in = () => {
    let used_in = [];
    if (props.row.used_In.length > 0) {
      for (let index = 0; index < props.row.used_In.length; index++) {
        used_in.push(
          <Row>
            <Col xs="8">
              <TextField
                style={{ fontWeight: "bold",  width: "400px" }}
                id="standard-id"
                disabled
                label="Product Name"
                labelWidth={60}
                value={props.row.used_In[index].product_name}
              />
            </Col>
            <Col xs="4">
              <TextField
                style={{ fontWeight: "bold" }}
                id="standard-id"
                disabled
                label="Amount"
                value={props.row.used_In[index].amount}
              />
            </Col>
          </Row>
        );
      }
      return used_in;
    }
  };
  const prices = () => {
    let priceRows = [];
    if (props.row.priceList.length > 0) {
      for (let index = 0; index < props.row.priceList.length; index++) {
        priceRows.push(
          <Row>
            <Col xs="3">
              <TextField
                style={{ fontWeight: "bold" }}
                id="standard-id"
                disabled
                label="Name"
                value={props.row.priceList[index].distributor_name}
              />
            </Col>
            <Col xs="2">
              <TextField
                style={{ fontWeight: "bold" }}
                id="standard-id"
                disabled
                label="Price"
                value={props.row.priceList[index].price}
              />
            </Col>
            <Col xs="5">
              <TextField
                style={{
                  fontWeight: "bold",
                  width: "100%",
                  backgroundColor: linkColor,
                  cursor: "pointer",
                }}
                id={"standard-link" + props.row.priceList[index].link}
                disabled
                label="Link, Click on the text to copy the link to the clipboard "
                value={props.row.priceList[index].link}
                onClick={() =>
                  copyFunction(
                    props.row.priceList[index].link,
                    "standard-link" + props.row.priceList[index].link
                  )
                }
              />
            </Col>
            <Col xs="2">
              <TextField
                style={{ fontWeight: "bold" }}
                id="standard-id"
                disabled
                label="SKU"
                value={props.row.priceList[index].sku}
              />
            </Col>
          </Row>
        );
      }
      return priceRows;
    }
  };

  const inputValueprintById = (id, where) => {
    let getValue = document.getElementById(id).value;
    if (getValue != "" || where === "Distributor") {
      if (where === "Name") {
        setComponentValues({ ...componentValues, name: getValue });
      }
      if (where === "shelf") {
        setComponentValues({ ...componentValues, shelf: getValue });
      }
      if (where === "minPerBartender") {
        setComponentValues({ ...componentValues, minPerBartender: getValue });
      }
      if (where === "tradeVolume") {
        setComponentValues({ ...componentValues, tradeVolume: getValue });
      }
      if (where === "Size") {
        setComponentValues({ ...componentValues, size: getValue });
      }
      if (where === "UnitSuffix") {
        setComponentValues({ ...componentValues, unitSuffix: getValue });
      }
    } else {
      setComponentValues({
        componentId: props.row.componentId,
        categoryId: props.row.categoryId,
        distributor_id: props.row.distributor_id,
        name: props.row.name,
        size: props.row.size,
        unitId: props.row.unitId,
        unitSuffix: props.row.usuffix,
        bay: props.row.bay,
        shelf: props.row.shelf,
        tradeVolume: props.row.tradeVolume,
        minPerBartender: props.row.minPerBartender,
      });
    }
  };

  const setAllComponentsValue = () => {
    setComponentValues({
      componentId: props.row.componentId,
      categoryId: props.row.categoryId,
      distributor_id: props.row.distributor_id,
      name: props.row.name,
      size: props.row.size,
      unitId: props.row.unitId,
      unitSuffix: props.row.usuffix,
      bay: props.row.bay,
      shelf: props.row.shelf,
      tradeVolume: props.row.tradeVolume,
      minPerBartender: props.row.minPerBartender,
      container_id: props.row.container_id,
    });
  };
  const saveChanges = () => {
    setsavedTitle("  Saved");

    apiFacade
      .putData(
        "https://pakkemodelapi.azurewebsites.net/api/components/putcomponent",
        componentValues
      )
      .then((response) => {
        setOpenSaved(true);
      })
      .catch((err) => console.error("Error", err));

    // window.setTimeout(() => {
    //   editToggle();
    //   changeOpenSaved();
    //   window.location.reload();
    // }, 2000);
  };
  const editColWithtwoNameAndDis = (value1, value2, name1, name2, id1, id2) => {
    let distributors = [];
    for (let d = 0; d < props.row.distributors.length; d++) {
      distributors.push(
        <MenuItem
          id={"dis" + props.row.distributors[d].id}
          value={props.row.distributors[d].id}
        >
          {props.row.distributors[d].name}
        </MenuItem>
      );
    }
    return (
      <Row>
        <Col xs="6">
          <form noValidate autoComplete="off">
            <TextField
              id="name"
              label={value1}
              onBlur={() => {
                inputValueprintById("name", "Name");
              }}
            />
          </form>
        </Col>
        <Col xs="6">
          <form noValidate autoComplete="off">
            <InputLabel id="demo-simple-select-label">Distributor</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              displayEmpty
              value={changeDisName}
              onChange={changeDistributorName}
              style={{ width: "100%" }}
            >
              <MenuItem id="disdefault" value="">
                <em>{value2}</em>
              </MenuItem>
              {distributors}
            </Select>
          </form>
        </Col>
      </Row>
    );
  };
  const editColWithtwoTradeAndCategory = (
    value1,
    value2,
    name1,
    name2,
    id1,
    id2
  ) => {
    let categories = [];
    for (let c = 0; c < props.row.categories.length; c++) {
      categories.push(
        <MenuItem
          id={"cate" + props.row.categories[c].id}
          value={props.row.categories[c].id}
        >
          {props.row.categories[c].name}
        </MenuItem>
      );
    }
    return (
      <Row>
        <Col xs="6">
          <form noValidate autoComplete="off">
            <TextField
              id="tradeV"
              label={"Trade Volume"}
              defaultValue={value1}
              onBlur={() => {
                inputValueprintById("tradeV", "tradeVolume");
              }}
            />
          </form>
        </Col>
        <Col xs="6">
          <form noValidate autoComplete="off">
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              displayEmpty
              value={changeCategoryId}
              onChange={changeCategory}
              style={{ width: "100%" }}
            >
              <MenuItem id="categorydefault" value="">
                <em>{value2}</em>
              </MenuItem>
              {categories}
            </Select>
          </form>
        </Col>
      </Row>
    );
  };
  const editColWithtwoSizeUnitSuffix = (value1, value2, value3) => {
    let units = [];
    for (let u = 0; u < props.row.units.length; u++) {
      units.push(
        <MenuItem
          id={"unit" + props.row.units[u].id}
          value={props.row.units[u].id}
        >
          {props.row.units[u].name}
        </MenuItem>
      );
    }
    return (
      <Row>
        <Col xs="3">
          <form noValidate autoComplete="off">
            <TextField
              id="sizeId"
              label={"Size"}
              defaultValue={value1}
              onBlur={() => {
                inputValueprintById("sizeId", "Size");
              }}
            />
          </form>
        </Col>
        <Col xs="3">
          <form noValidate autoComplete="off">
            <TextField
              id="uSuffix"
              label={"Unit Suffix"}
              defaultValue={value2}
              onBlur={() => {
                inputValueprintById("uSuffix", "UnitSuffix");
              }}
            />
          </form>
        </Col>
        <Col xs="6">
          <form noValidate autoComplete="off">
            <InputLabel id="demo-simple-select-label">Unit</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              displayEmpty
              value={changeUnitId}
              onChange={changeUnit}
              style={{ width: "100%" }}
            >
              <MenuItem id="categorydefault" value="">
                <em>{value3}</em>
              </MenuItem>
              {units}
            </Select>
          </form>
        </Col>
      </Row>
    );
  };
  const editColWithtwoReolAndshelf = (
    value1,
    value2,
    name1,
    name2,
    id1,
    id2
  ) => {
    let reolnrList = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
    ];
    let reolnrWithMenu = [];

    for (let r = 0; r < reolnrList.length; r++) {
      reolnrWithMenu.push(
        <MenuItem value={reolnrList[r]}>{reolnrList[r]}</MenuItem>
      );
    }
    return (
      <Row>
        <Col xs="6">
          <form noValidate autoComplete="off">
            <InputLabel id="demo-simple-select-label">Reol</InputLabel>
            <Select
              labelId="select-reol-label"
              id="select-reol"
              displayEmpty
              value={changeReolNr}
              onChange={changeReolNumber}
              style={{ width: "100%" }}
            >
              <MenuItem value="">
                <em>{value1}</em>
              </MenuItem>
              {reolnrWithMenu}
            </Select>
          </form>
        </Col>
        <Col xs="6">
          <form noValidate autoComplete="off">
            <TextField
              id="shelf"
              label={"shelf"}
              defaultValue={value2}
              onBlur={() => {
                inputValueprintById("shelf", "shelf");
              }}
            />
          </form>
        </Col>
      </Row>
    );
  };
  const editColWithtwoMapbComp = (value1, value2, name1, name2, id1, id2) => {
    return (
      <Row>
        <Col xs="6">
          <form noValidate autoComplete="off">
            <TextField
              id="mapb"
              label="Min Per Bartender"
              defaultValue={value1}
              onBlur={() => {
                inputValueprintById("mapb", "minPerBartender");
              }}
            />
          </form>
        </Col>
        <Col xs="6">
          <form noValidate autoComplete="off">
            <TextField
              style={{ fontWeight: "bold" }}
              id="standard-name"
              disabled
              label={name2}
              value={value2}
            />
          </form>
        </Col>
      </Row>
    );
  };

  const editColWithContainer = (value1) => {
    let container = [];
    let containerDefault = "Ikke tilvalgt";

    for (let u = 0; u < props.row.containers.length; u++) {
      if (props.row.container_id != 0) {
        if (props.row.container_id == props.row.containers[u].id) {
          containerDefault =
            props.row.containers[u].name +
            " " +
            props.row.containers[u].size +
            " " +
            props.row.containers[u].suffix;
        }
      }
      container.push(
        <MenuItem
          id={"Container" + props.row.containers[u].id}
          value={props.row.containers[u].id}
        >
          {props.row.containers[u].name +
            " " +
            props.row.containers[u].size +
            " " +
            props.row.containers[u].suffix}
        </MenuItem>
      );
    }
    return (
      <Row>
        <Col xs="12">
          <form noValidate autoComplete="off">
            <InputLabel id="demo-simple-select-label">Container</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              displayEmpty
              value={changeContainerId}
              onChange={changeContainer}
              style={{ width: "100%" }}
            >
              <MenuItem id="categorydefault" value="">
                <em>{containerDefault}</em>
              </MenuItem>
              {container}
            </Select>
          </form>
        </Col>
      </Row>
    );
  };

  if (props.column.name == "name") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.name}
      </TableCell>
    );
  }
  if (props.column.name == "tradeVolume") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.tradeVolume}
      </TableCell>
    );
  }
  if (props.column.name == "unit_name") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.unit_name}
      </TableCell>
    );
  }

  if (props.column.name == "unitSuffix") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.unitSuffix}
      </TableCell>
    );
  }
  if (props.column.name == "distributor_name") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.distributor_name}
      </TableCell>
    );
  }

  if (props.column.name == "bay") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.bay}
      </TableCell>
    );
  }

  if (props.column.name == "shelf") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.shelf}
      </TableCell>
    );
  }

  if (props.column.name == "actions") {
    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="lg"
          style={{ maxWidth: "1600px", width: "80%" }}
        >
          <ModalHeader toggle={toggle}>View {props.row.name}</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="4">
                <Card>
                  <CardBody>
                    <CardTitle>Component Details</CardTitle>
                    {colWithtwo(
                      props.row.name,
                      props.row.distributor_name,
                      "Name",
                      "Distributor"
                    )}
                    {colWithtwo(
                      props.row.unitSuffix,
                      props.row.unit_name,
                      "Size & Unit",
                      "Unit"
                    )}
                    {colWithtwo(
                      props.row.tradeVolume,
                      props.row.category_Name,
                      "Trade Volume",
                      "Category"
                    )}
                    {colWithtwo(
                      props.row.minPerBartender,
                      props.row.componentId,
                      "Minimal amount per bartender",
                      "Component Id"
                    )}
                    {colWithtwo(
                      props.row.bay,
                      props.row.shelf,
                      "Reol",
                      "shelf"
                    )}
                    {colWithtContainer()}
                  </CardBody>
                </Card>
              </Col>
              <Col xs="8">
                <Card>
                  <CardBody>
                    <CardTitle>Prices</CardTitle>
                    {prices()}
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle>Used in</CardTitle>
                    {used_in()}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {" "}
            <Button
              color="danger"
              onClick={toggle}
              style={{ marginLeft: "90%" }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={deleteModal} toggle={deleteToggle}>
          <Alert
            color="danger"
            isOpen={openSaved}
            style={{
              marginTop: "10px",

              textAlign: "center",
              fontSize: "20px",
              width: "240px",
              marginLeft: "23%",
            }}
          >
            <NotificationsActiveIcon />
            {savedTitle}
          </Alert>
          <ModalHeader toggle={deleteToggle}>
            Deleting {props.row.name}
          </ModalHeader>
          <ModalBody>
            {" "}
            Are you sure that you want to delete the selected item:
            <div style={{ fontWeight: "bold" }}>{props.row.name}</div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                deleteCom();
              }}
            >
              Delete
            </Button>{" "}
            <Button color="danger" onClick={deleteToggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={editModal}
          size="lg"
          style={{ maxWidth: "1600px", width: "80%" }}
        >
          <Alert
            isOpen={openSaved}
            style={{
              marginTop: "10px",

              textAlign: "center",
              fontSize: "20px",
              width: "250px",
              marginLeft: "40%",
            }}
          >
            <NotificationsActiveIcon />
            {savedTitle}
          </Alert>
          <ModalHeader toggle={editToggle}>Edit {props.row.name}</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="4">
                <Card>
                  <CardBody>
                    <CardTitle>Edit Component Details</CardTitle>

                    {editColWithtwoNameAndDis(
                      props.row.name,
                      props.row.distributor_name,
                      "Name",
                      "Distributor",
                      "ingen id",
                      props.row.distributor_id
                    )}
                    {editColWithtwoSizeUnitSuffix(
                      props.row.size,
                      props.row.usuffix,
                      props.row.unit_name
                    )}

                    {editColWithtwoTradeAndCategory(
                      props.row.tradeVolume,
                      props.row.category_Name,
                      "Trade Volume",
                      "Category"
                    )}

                    {editColWithtwoMapbComp(
                      props.row.minPerBartender,
                      props.row.componentId,
                      "Minimal amount per bartender",
                      "Component Id"
                    )}

                    {editColWithtwoReolAndshelf(props.row.bay, props.row.shelf)}
                    {editColWithContainer(props.row.container_name)}
                  </CardBody>
                </Card>
              </Col>
              <Col xs="8">
                <Card>
                  <CardBody>
                    <CardTitle>Edit Prices</CardTitle>
                    {prices()}
                  </CardBody>
                </Card>
                {/* <Card>
                  <CardBody>
                    <CardTitle>Used in</CardTitle>
                    {used_in()}
                  </CardBody>
                </Card> */}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={() => {
                saveChanges();
              }}
            >
              Save
            </Button>{" "}
            <Button color="danger" onClick={editToggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <TableCell style={CustomTableCellStyles.style2}>
          <IconButton
            color="primary"
            aria-label="Edit"
            onClick={() => {
              editToggle();
              setAllComponentsValue();
            }}
          >
            <EditTwoToneIcon />
          </IconButton>

          <IconButton color="primary" aria-label="View more" onClick={toggle}>
            <Info />
          </IconButton>

          <IconButton
            color="secondary"
            aria-label="Delete"
            onClick={deleteToggle}
          >
            <DeleteForeverTwoToneIcon />
          </IconButton>
        </TableCell>
      </div>
    );
  }

  return <TableCell>No Data</TableCell>;
};

export default ComponentCell;

function copyFunction(text, id) {
  document.getElementById(id).style.color = "#59ff2b";
  document.getElementById(id).value = "Copied Link";
  setTimeout(function () {
    document.getElementById(id).style.color = "#9E9E9E";
    document.getElementById(id).value = text;
  }, 600);
  //document.getElementById("standard-link1");
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  //alert("Copied the text: " + copyText.value);
  //setLinkColor("#ffffff");
  document.body.removeChild(dummy);
}
