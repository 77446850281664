import types from "../actionTypes";

const initialState = {
  getPackingList: [],
};

const packinglistReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.packinglist.getPackingList:
      return {
        ...state,
        getPackingList: action.payload,
      };

    default:
      return state;
  }
};

export default packinglistReducer;
