import React, { Component } from "react";
import apiFacade from "../../../../auth/apiFacade";
import BarLoader from "react-spinners/BarLoader";
import jsPDF from "jspdf";
import hexToRgb from "pretty-easy-hex-to-rgb";
import { fstat } from "fs";
import PackinglistFunc from "./PackinglistFunc";
import {
  Row,
  Button,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import PanelHeader from "../../../../components/PanelHeader/PanelHeader.js";
import CardCategory from "../../../../components/CardElements/CardCategory.jsx"
import PrintMenu from "./../../PrintMenu/PrintMenu";
var packingStyle = {
  textColor: {
    color: "#3787C6"
  },
  backbtn: {
    position: "absolute",
    top: "10px"
  },
  printMenu: {
    position: "absolute",
    top: "10px",
    left: "100px"
  },
  fixedElement: {
    position: "fixed",
    width: 250,
    height: 80
  }
};

export default class Packinglistprint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packingList: [],
      samplingInfo: []
    };
  }
  componentDidMount() {
    apiFacade.getData("/samplings/" + this.props.match.params.id).then(data => {
      this.setState({ samplingInfo: data });
      if (this.state.samplingInfo != null) {
        apiFacade
          .getData("/samplings/getpackinglist/" + data.item_Id)
          .then(packingData => {
            this.setState({ packingList: packingData });
          });
      }
    });
  }
  render() {
    return (
      <div>
        <PanelHeader size="sm" />

        <div className="content">
          <Row>
            <Col md={1}></Col>
            <Col md={8} className="mr-auto">
              <Card className="card-chart ">
                <CardHeader>
                  <CardCategory>Print</CardCategory>
                </CardHeader>
                <CardBody>
                  <PackinglistFunc
                    myData={this.state.samplingInfo}
                    packinglist={this.state.packingList}
                    loading={false}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="" style={packingStyle.fixedElement}>
                <CardBody>
                  <Button
                    style={packingStyle.backbtn}
                    color="danger"
                    onClick={() => {
                      this.props.history.push(
                        `/samplings/${this.props.match.params.id}`
                      );
                    }}
                  >
                    Back
                  </Button>
                  <PrintMenu
                    useStyle={packingStyle.printMenu}
                    history={this.props.history}
                    match={this.props.match}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
