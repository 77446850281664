import React, { useState, useEffect } from "react";
import JobPackerTable from "./JobPackerTable";
import { useSelector } from "react-redux";

import JobPackerCell from "./JobPackerCell.js";

export default function JobPacker(props) {
  const { packerApi } = useSelector((state) => state.pakker);
  const getRowId = (row) => {
    return row.id;
  };
  const [columns, setColumns] = useState([
    { name: "firstName", title: "Name" },
    { name: "lastName", title: "lName" },
    { name: "actions", title: "Actions" },
  ]);
  const [headerExtension] = useState([
    { columnName: "firstName", width: "auto" },
    { columnName: "lastName", width: "auto" },
    { columnName: "actions", width: "auto" },
  ]);
  const [tableColumnExtensions] = useState([
    { columnName: "firstName", width: "55%" },
    { columnName: "lastName", width: "1%" },
    { columnName: "actions", width: "auto" },
  ]);

  return (
    <div>
      <JobPackerTable rows={packerApi} columns={columns} headerExtension={headerExtension} tableColumnExtensions={tableColumnExtensions} JobPackerCell={JobPackerCell} getRowId={getRowId}/>
    </div>
  );
}
