import { TableCell } from "@material-ui/core";
import React from "react";

const CocktailMenuHeaderCell = (props) => {
  // if (props.column.name === "status") {
  //   return <TableCell width="125px">Status</TableCell>;
  // }
  if (props.column.name === "name") {
    return <TableCell>Name</TableCell>;
  }
  if (props.column.name === "actions") {
    return <TableCell align="center">Actions</TableCell>;
  }
  if (props.column.name === "action") {
    return <TableCell>Action</TableCell>;
  }
  return <TableCell>No data</TableCell>;
};
export default CocktailMenuHeaderCell;
