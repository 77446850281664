import React, { useEffect, useState } from "react";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import apiFacade from "../../auth/apiFacade";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Input,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import TextField from "@material-ui/core/TextField";
import { updateJobManagementMsg } from "./UpdateJobManagementMsg";
import JobManagementTable from "./JobManagementTable/JobManagementTable";
import JobManagementCellLeft from "./JobManagementTable/JobManagementCellLeft";
import Moment from "react-moment";
import RangePicker from "react-range-picker";
import JobAssignment from "./JobAssignment/JobAssignment";
import JobPacker from "./JobPacker/JobPacker";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import types from "../../redux/actionTypes";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Inputmui from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/core/styles";

var JobManagementStyle = {
  cellStyling: {
    padding: "0px 10px 0px 0px",
  },
  dropdownmenustyle: {
    color: "#d16557",
    width: "100%",
    zIndex: "1001",
    right: "end",
  },
  customRange: {
    position: "absolute",
    right: "530px",
    top: "20px",
    boxShadow: "1px 10px 10px 1px #ccc",
  },
  stuck2: {
    position: "fixed",
    marginTop: "0%",
    marginRight: "30%",
    zIndex: "1000",
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function JobManagement(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { jwa } = useSelector((state) => state.jobManagement);
  const { tempJwa } = useSelector((state) => state.jobManagement);
  const { jobList } = useSelector((state) => state.jobManagement);
  const { packerBool } = useSelector((state) => state.pakker);
  const { errorArr } = useSelector((state) => state.pakker);
  const { errorBool } = useSelector((state) => state.pakker);
  const [searchInput, setSearchInput] = useState();
  const [myTable, setMyTable] = useState([]);
  const [tempInput, setTempInput] = useState();
  const [jobsWithAssignment, setJobsWithAssignment] = useState([]);
  const [tempMyTable, setTempMyTable] = useState([]);
  const [error, setError] = useState();
  const [isItVirtual, setIsItVirtual] = useState(false);
  const [loadingAPI, setLoadingAPI] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [jobListOfIds, setJobListOfIds] = useState("");
  const [selection, setSelection] = useState([]);
  const [assignment, setAssignment] = useState([]);
  const [packers, setPackers] = useState([]);
  const [packersAll, setPackersAll] = useState([]);
  const [loadingFg, setLoadingFg] = useState(true);
  const [arr, setArr] = useState([]);
  const [myBool, setMyBool] = useState(false);
  const [refreshSaveBTN, setRefreshSaveBTN] = useState(false);
  const [amountOfAssign, setAmountOfAssign] = useState([]);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [saveAlert, setSaveAlert] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [errorTemp, setErrorTemp] = useState(errorArr);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [savedTitle, setsavedTitle] = useState("");
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState([]);
  const [start, setStart] = useState(
    new Date().toJSON().slice(0, 10).replace(/-/g, "/")
  );
  const [datePickerHandler, setDatePickerHandler] = useState(false);
  const [errorStuck, setErrorStuck] = useState({
    position: "fixed",
    marginTop: "0%",
    marginLeft: "-15%",
    zIndex: "1000",
  });
  const [columnsLeft] = useState([
    { name: "jobId", title: "Job" },
    { name: "type", title: "Job type" },
    { name: "persons", title: "Pax" },
    { name: "date", title: "Date" },
    { name: "region", title: "Region" },
  ]);

  useEffect(() => {
    apiFacade.getData("/regions").then((data) => {
      setRegions(data);
    });
    apiFacade.getData("/departments").then((data) => {
      setDepartments(data);
      dispatch({
        type: types.jobManagement.departments,
        payload: data,
      });
    });
  }, []);

  useEffect(() => {
    if (selection.length > 0) {
      let arrSelection = [];
      selection.forEach((jobId) => {
        const checkTempJwa = tempJwa.find((x) => x.jobId === jobId);
        if (checkTempJwa === undefined) {
          arrSelection.push(jobId);
        } else {
          arr.push(checkTempJwa);
        }
      });
      dispatch({
        type: types.jobManagement.emptyJwa,
        payload: [],
      });
      dispatch({
        type: types.jobManagement.emptyTempJwa,
        payload: [],
      });
      if (arrSelection.length > 0) {
        apiFacade
          .getData("/JobManagement/getjobmanagement/" + arrSelection.join())
          .then((data) => {
            data.forEach((ele) => {
              arr.push(ele);
            });
            setJobsWithAssignment(arr);
            dispatch({
              type: types.jobManagement.jwa,
              payload: arr,
            });
            dispatch({
              type: types.jobManagement.tempJwa,
              payload: arr,
            });
            setArr([]);
          });
      } else {
        setJobsWithAssignment(arr);
        dispatch({
          type: types.jobManagement.jwa,
          payload: arr,
        });
        dispatch({
          type: types.jobManagement.tempJwa,
          payload: arr,
        });
        setArr([]);
      }
    }
  }, [selection]);

  useEffect(() => {
    let lastN = true;
    if (lastName.length > 0) {
      lastN = false;
    }
    let firstN = true;
    if (firstName.length > 0) {
      firstN = false;
    }
    setTimeout(() => {
      if (errorArr.length > 1) {
        setDisableSaveBtn(true);
      } else if (errorArr.length === 1) {
        if (!lastN && !firstN && userName.length > 0 && password.length >= 6) {
          setDisableSaveBtn(false);
        } else {
          setDisableSaveBtn(true);
        }
      }
      setErrorTemp(errorArr);
    }, 500);
  }, [errorBool]);

  useEffect(() => {
    if (selection.length > 0) {
      let arrSelection = [];
      selection.forEach((jobId) => {
        const checkTempJwa = tempJwa.find((x) => x.jobId === jobId);
        if (checkTempJwa === undefined) {
          arrSelection.push(jobId);
        } else {
          arr.push(checkTempJwa);
        }
      });
      dispatch({
        type: types.jobManagement.emptyJwa,
        payload: [],
      });
      dispatch({
        type: types.jobManagement.emptyTempJwa,
        payload: [],
      });
      if (arrSelection.length > 0) {
        apiFacade
          .getData("/JobManagement/getjobmanagement/" + arrSelection.join())
          .then((data) => {
            data.forEach((ele) => {
              arr.push(ele);
            });
            setJobsWithAssignment(arr);
            dispatch({
              type: types.jobManagement.jwa,
              payload: arr,
            });
            dispatch({
              type: types.jobManagement.tempJwa,
              payload: arr,
            });
            setArr([]);
          });
      } else {
        setJobsWithAssignment(arr);
        dispatch({
          type: types.jobManagement.jwa,
          payload: arr,
        });
        dispatch({
          type: types.jobManagement.tempJwa,
          payload: arr,
        });
        setArr([]);
      }
    }
  }, [packerBool]);

  const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);

  useEffect(() => {
    let unmount = false;
    apiFacade.getData("/jobmanagement/getpakkers").then((data) => {
      if (!equals(packers, data)) {
        if (!unmount) {
          apiFacade.getData("/jobmanagement/getpakkers").then((data1) => {
            setPackersAll(data1);
          });
          setPackers(data);
          dispatch({
            type: types.pakker.packerApi,
            payload: data,
          });
        }
      }
    });
    return () => {
      unmount = true;
    };
  }, [packerBool]);

  useEffect(() => {
    if (loadingAPI) {
      apiFacade.getData("/jobmanagement/getjobassignment").then((data) => {
        setAssignment(data);
        setLoadingFg(false);
      });
      apiFacade
        .getData("/bookings/getbookingjm?regions=" + selectedRegions.toString())
        .then((data) => {
          if (data === undefined) {
            setError(1);
          } else {
            setMyTable(data);
            setTempMyTable(data);
            setLoadingAPI(false);
            setLoadingTable(false);
          }
        })
        .catch(() => {
          setError(4);
          setTimeout(() => {
            setError(0);
          }, 60000);
        });
    }
  }, [selectedRegions]);

  useEffect(() => {
    if (assignment.length > 0) {
      assignment.forEach((element) => {
        let aIndex = amountOfAssign.findIndex(
          (a) => a.assignmentId === element.assignmentId
        );
        if (aIndex === -1) {
          amountOfAssign.push({
            assignmentId: element.assignmentId,
            dateTimeBool: true,
            packerBool: true,
          });
        }
      });
    }
  }, [assignment]);

  useEffect(() => {
    if (myBool && selection.length > 0) {
      let newJob = {
        jobId: selection[selection.length - 1],
        disabled: true,
        assignment: amountOfAssign,
      };
      dispatch({
        type: types.jobManagement.jobList,
        payload: {
          jobListId: selection[selection.length - 1],
          newJob: newJob,
          updated: false,
        },
      });
    }
    setMyBool(true);
  }, [selection]);

  const enddate = (newday) => {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var myNewdate = [year, month, day].join("/");

    var date = new Date(myNewdate);
    var next_date = new Date(date.setDate(date.getDate() + newday));
    var month_ = "" + (next_date.getMonth() + 1);
    var day_ = "" + next_date.getDate();
    var year_ = next_date.getFullYear();

    if (month_.length < 2) month_ = "0" + month_;
    if (day_.length < 2) day_ = "0" + day_;

    var myNewdate_ = [year_, month_, day_].join("/");
    return myNewdate_;
  };

  const [end, setEnd] = useState(enddate(7));

  const newBookingsbydate = (startdate, nday) => {
    setStart(startdate);
    setEnd(enddate(nday));
    apiFacade
      .getData(
        "/bookings/getbookingjm?start=" + startdate + "&end=" + enddate(nday)
      )
      .then((data) => {
        if (data === undefined) {
          setError(1);
          setLoadingTable(true);
        } else {
          setMyTable(data);
          setTempMyTable(data);
          setLoadingAPI(false);
          setLoadingTable(false);
        }
      })
      .catch(() => {
        setError(4);
        setTimeout(() => {
          setError(0);
        }, 60000);
      });
  };

  const updateTable = (e) => {
    document.getElementById("searchBar").value = "";
    setSelection([]);
    dispatch({
      type: types.jobManagement.emptyJwa,
      payload: [],
    });
    dispatch({
      type: types.jobManagement.emptyTempJwa,
      payload: [],
    });

    if (e.currentTarget.id === "14days") {
      // 14 days api call
      newBookingsbydate(startDate(0), 14);
      setEnd(enddate(14));
    }
    if (e.currentTarget.id === "7days") {
      // 7 days api call
      newBookingsbydate(startDate(0), 7);
      setEnd(enddate(7));
    }
    if (e.currentTarget.id === "tomorrow") {
      // 7 days api call
      newBookingsbydate(startDate(-1), 1);
      setStart(startDate(-1));
      setEnd(enddate(1));
    }
    if (e.currentTarget.id === "yesterday") {
      // 7 days api call
      newBookingsbydate(startDate(1), -1);
      setStart(startDate(1));
      setEnd(enddate(-1));
    }
    if (e.currentTarget.id === "today") {
      // 7 days api call
      newBookingsbydate(startDate(0), 0);
      setStart(startDate(0));
      setEnd(enddate(0));
    }
    if (e.currentTarget.id === "last7days") {
      // 7 days api call
      newBookingsbydate(startDate(7), 0);
      setStart(startDate(7));
      setEnd(enddate(0));
    }
    if (e.currentTarget.id === "last14days") {
      // 7 days api call
      newBookingsbydate(startDate(14), 0);
      setStart(startDate(14));
      setEnd(enddate(0));
    }
  };

  const startDate = (newday) => {
    var new_date = new Date();
    var day = String(new_date.getDate());
    var month = String(new_date.getMonth() + 1);
    var myNewdate = String(new_date.getFullYear() + "/" + month + "/" + day);
    var date = new Date(myNewdate);
    var next_date = new Date(date.setDate(date.getDate() - newday));
    var month_ = "" + (next_date.getMonth() + 1);
    var day_ = "" + next_date.getDate();
    var year_ = next_date.getFullYear();

    if (month_.length < 2) month_ = "0" + month_;
    if (day_.length < 2) day_ = "0" + day_;

    var myNewdate_ = [year_, month_, day_].join("/");
    return myNewdate_;
  };

  const handleSearchBar = (event) => {
    if (searchInput != null) {
      setMyTable([]);
      setSelection([]);
      dispatch({
        type: types.jobManagement.emptyJwa,
        payload: [],
      });
      dispatch({
        type: types.jobManagement.emptyTempJwa,
        payload: [],
      });
      setTempInput(searchInput);
      apiFacade
        .getData("/bookings/getbookingjmbyid/" + searchInput)
        .then((data) => {
          if (data === undefined) {
            setError(2);
          } else {
            setMyTable(data);
            setLoadingTable(false);
          }
        })
        .catch(() => {
          setError(2);
          setTimeout(() => {
            setError(0);
            setMyTable(tempMyTable);
            document.getElementById("searchBar").value = "";
            setButtonDisabled(true);
          }, 4000);
        });
    }
    event.preventDefault();
  };

  const handleChangeSearchBar = (event) => {
    setSearchInput(event.target.value);
    if (error === 2 && event.target.value !== searchInput) {
      setError(0);
    } else if (event.target.value.length > 2 && event.target.value.length < 6) {
      setButtonDisabled(false);
    } else if (event.target.value === "" && tempMyTable.length > 0) {
      setMyTable(tempMyTable);
      setSelection([]);
      setError(0);
      setButtonDisabled(true);
    } else if (event.target.value === "" && isItVirtual === true) {
      setError(0);
      setIsItVirtual(false);
      setButtonDisabled(true);
    } else if (event.target.value.length < 3 || event.target.value.length > 5) {
      setButtonDisabled(true);
    }
  };

  const returnErrorMsg = (error) => {
    if (error === 3) {
      return (
        <Alert style={JobManagementStyle.stuck2} severity="error">
          <AlertTitle>
            This booking: {tempInput} is a Virtuel booking, do you want to be
            redirected?
            <Button
              style={{ marginLeft: "5%", backgroundColor: "#d16557" }}
              size="sm"
              onClick={() => {
                props.history.push("/bookingoverview/vbookings/" + tempInput);
              }}
            >
              Yes redirect me
            </Button>
          </AlertTitle>
        </Alert>
      );
    } else {
      return updateJobManagementMsg(
        jobListOfIds,
        error,
        searchInput,
        errorStuck
      );
    }
  };

  const openDateRange = () => {
    setDatePickerHandler(true);
  };

  const customRange = () => {
    if (datePickerHandler) {
      return (
        <div>
          <RangePicker
            placeholderText={"Select date"}
            onDateSelected={onDateChanges}
            onClose={onDateChanges}
          />
        </div>
      );
    }
  };

  const onDateChanges = (date, date2) => {
    document.getElementById("searchBar").value = "";
    if (date && date2) {
      var startMonth = date.getMonth() + 1;
      var endMonth = date2.getMonth() + 1;
      var start = null;
      start = date.getFullYear() + "-" + startMonth + "-" + date.getDate();
      var end = null;
      end = date2.getFullYear() + "-" + endMonth + "-" + date2.getDate();
    }
    if (start != null && end != null) {
      setStart(start);
      setEnd(end);
      apiFacade
        .getData("/bookings/getbookingjm?start=" + start + "&end=" + end)
        .then((data) => {
          if (data === undefined) {
            setError(1);
            setLoadingTable(true);
          } else {
            setSelection([]);
            dispatch({
              type: types.jobManagement.emptyJwa,
              payload: [],
            });
            dispatch({
              type: types.jobManagement.emptyTempJwa,
              payload: [],
            });
            setMyTable(data);
            setTempMyTable(data);
            setLoadingAPI(false);
            setLoadingTable(false);
          }
        })
        .catch(() => {
          setError(4);
          setTimeout(() => {
            setError(0);
          }, 60000);
        });

      setDatePickerHandler(false);
    }
  };

  const menuItems = (departments) => {
    let mappedDep = departments.map((data, i) => {
      return (
        <MenuItem key={i} value={data.id}>
          {data.department_}
        </MenuItem>
      );
    });
    return mappedDep;
  };

  const createPacker = () => {
    return (
      <div>
        <Row>
          <Col xs={12} md={4}>
            {" "}
            <TextField
              style={{ fontWeight: "bold" }}
              id="create-firstName"
              label={"First name"}
              type={"text"}
              value={firstName}
              onChange={changeFirstName}
            />
          </Col>
          <Col xs={12} md={4}>
            <TextField
              style={{ fontWeight: "bold" }}
              id="create-lastName"
              label={"Last name"}
              type={"text"}
              value={lastName}
              onChange={changeLastName}
            />
          </Col>
          <Col xs={12} md={4}>
            <TextField
              style={{ fontWeight: "bold" }}
              id="create-userName"
              label={"Username"}
              value={userName}
              onChange={changeUserName}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <TextField
              style={{ fontWeight: "bold" }}
              id="create-password"
              label={"Password"}
              type={"password"}
              value={password}
              onChange={changePassword}
            />
          </Col>
          <Col xs={12} md={4}>
            <TextField
              style={{ fontWeight: "bold" }}
              id="create-repeatpassword"
              label={"Repeat password"}
              type={"password"}
              value={repeatPassword}
              onChange={changeRepeatPassword}
            />
          </Col>
          <Col xs={12} md={4}>
            <FormControl style={{ width: "72%" }}>
              <InputLabel id="department-label">Departments</InputLabel>
              <Select
                labelId="select-label"
                id="department-select"
                value={departmentId}
                onChange={(e) => {
                  setDepartmentId(e.target.value);
                }}
              >
                {menuItems(departments)}
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>{alertErrMsg()}</Col>
        </Row>
      </div>
    );
  };

  const alertErrMsg = () => {
    if (errorTemp.length > 0) {
      let mappedMsg = errorTemp.map((data, i) => {
        if (i > 0) {
          return (
            <Alert
              key={i}
              color="error"
              style={{ marginTop: "20px", color: "red" }}
            >
              {data.msg}
            </Alert>
          );
        }
      });
      return mappedMsg;
    }
  };

  const changeFirstName = (event) => {
    const result = event.target.value.replace(/[^a-å]/gi, "");
    setFirstName(result);
    setTimeout(() => {
      dispatch({
        type: types.pakker.errorBool,
        payload: !errorBool,
      });
    }, 500);
  };
  const changeLastName = (event) => {
    const result = event.target.value.replace(/[^a-å]/gi, "");
    setLastName(result);
    setTimeout(() => {
      dispatch({
        type: types.pakker.errorBool,
        payload: !errorBool,
      });
    }, 500);
  };
  const changeUserName = (event) => {
    const result = event.target.value.replace(/[^a-å]/gi, "");
    setUserName(result);
    if (document.getElementById("create-userName").value.length >= 5) {
      errorHandlingRemove(2);
      apiFacade
        .getData(
          "/Pakkers/checkusername/" +
            document.getElementById("create-userName").value
        )
        .then((data) => {
          if (!data) {
            errorHandlingRemove(3);
          } else {
            errorHandlingAdd(3, "The username is already in use.");
            errorHandlingRemove(2);
          }
        });
    } else {
      errorHandlingRemove(3);
      errorHandlingAdd(
        2,
        "The username needs to be at least 5 characters long."
      );
    }
  };
  const changePassword = (event) => {
    checkPasswords();
    setPassword(event.target.value);
  };
  const changeRepeatPassword = (event) => {
    checkPasswords();
    setRepeatPassword(event.target.value);
  };
  const checkPasswords = () => {
    if (
      document.getElementById("create-repeatpassword").value.length !== 0 ||
      document.getElementById("create-password").value.length !== 0
    ) {
      if (
        document.getElementById("create-repeatpassword").value ===
          document.getElementById("create-password").value &&
        document.getElementById("create-password").value.length >= 6
      ) {
        errorHandlingRemove(1);
      } else {
        errorHandlingAdd(
          1,
          "The password needs to be at least 6 characters long & needs to match each other"
        );
      }
    } else {
      errorHandlingRemove(1);
    }
  };

  const errorHandlingRemove = (errorId) => {
    dispatch({
      type: types.pakker.errorArrRemove,
      payload: errorId,
    });
    setTimeout(() => {
      dispatch({
        type: types.pakker.errorBool,
        payload: !errorBool,
      });
    }, 500);
  };

  const errorHandlingAdd = (errorId, errMsg) => {
    let pl = { id: errorId, msg: errMsg };
    dispatch({
      type: types.pakker.errorArr,
      payload: pl,
    });
    setTimeout(() => {
      dispatch({
        type: types.pakker.errorBool,
        payload: !errorBool,
      });
    }, 500);
  };

  const saveAlertMSG = () => {
    if (saveAlert) {
      setTimeout(() => {
        setSaveAlert(false);
      }, 10000);
      return <Alert color="success">The Data has been saved</Alert>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const saveInfo = (jobId) => {
    let job = jwa.find((j) => j.jobId === parseInt(jobId));
    apiFacade
      .putData("https://pakkemodelapi.azurewebsites.net/api/jobmanagement", job)
      .then(() => {
        setRefreshSaveBTN(!refreshSaveBTN);
        dispatch({
          type: types.pakker.packerBool,
          payload: !packerBool,
        });
        setSaveAlert(true);
        apiFacade.getData("/jobmanagement/getpakkers").then((data) => {
          setPackers(data);
        });
        setTimeout(() => {
          setSaveAlert(false);
        }, 5000);
      });
    dispatch({
      type: types.jobManagement.resetJobList,
      payload: {
        resetJobId: parseInt(jobId),
      },
    });
  };

  const saveChanges = () => {
    setsavedTitle("  Saved");
    let pakker = {
      firstName: firstName,
      lastName: lastName,
      userName: userName,
      password: password,
      status: true,
      aId: "0000000-000000-0000-b500028-000000",
      departmentId: departmentId,
    };
    apiFacade
      .postData2("https://pakkemodelapi.azurewebsites.net/api/pakkers", pakker)
      .then((data) => {
        setSaveAlert(true);
        setDisableSaveBtn(true);
        setTimeout(() => {
          editToggle();
          setSaveAlert(false);
        }, 3000);
        setTimeout(() => {
          dispatch({
            type: types.pakker.packerBool,
            payload: !packerBool,
          });
        }, 4000);
      })
      .catch((err) => console.error("Error", err));
  };

  const saveButton = (jobId) => {
    let job = jobList.find((j) => j.jobId === jobId);
    return (
      <Button
        id={jobId}
        disabled={job.disabled}
        color="success"
        size="sm"
        style={{ width: "100%" }}
        onClick={(e) => {
          saveInfo(e.target.id);
        }}
      >
        Save
      </Button>
    );
  };

  const editToggle = () => {
    setEditModal(!editModal);
  };

  const selectedJobs = () => {
    if (selection.length > 0) {
      if(jwa.length > 0){
        var ms = jwa.map((data) => {
          let job = jobList.find((j) => j.jobId === data.jobId);
          return (
            <div key={data.jobId}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <Row>
                    <Col xs={12} md={9}>
                      Assign packer(s) to job {data.jobId}
                    </Col>
                    <Col xs={12} md={3}>
                      {saveButton(data.jobId)}
                    </Col>
                  </Row>
                </FormLabel>
                <JobAssignment
                  jwa={jwa}
                  jobsWithAssignment={jobsWithAssignment}
                  assignment={assignment}
                  loadingFg={loadingFg}
                  jobId={data.jobId}
                  jobManagements={data.jobManagements}
                  packers={packersAll}
                  saveInfo={saveInfo}
                  job={job}
                />
                <hr
                  style={{
                    border: "2px solid #9B8878",
                    width: "100%",
                    backgroundColor: "#9B8878",
                  }}
                />
              </FormControl>
            </div>
          );
        });
        return ms;
      }
    }
    return <div></div>;
  };

  const handleChangeRegion = (event) => {
    setSelectedRegions(event.target.value);
    setLoadingAPI(true);
  };

  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12} md={6}>
            <Card style={{ minHeight: "130px" }}>
              <CardBody>
                <Form onSubmit={handleSearchBar}>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Input
                        onChange={handleChangeSearchBar}
                        id="searchBar"
                        name="sb"
                        placeholder="Booking id"
                        type="number"
                        min="0"
                        max="99999"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12}>
                    <Button
                      style={{
                        width: "100%",
                        backgroundColor: "#d16557",
                      }}
                      disabled={buttonDisabled}
                      type="submit"
                    >
                      Search
                    </Button>
                  </Col>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card style={{ minHeight: "130px" }}>
              <CardBody>
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    style={{ width: "100%", backgroundColor: "#d16557" }}
                  >
                    <Moment format="YYYY/MM/DD">{start}</Moment> &nbsp;
                    <Moment /* add={{ days: 7 }} */ format="YYYY/MM/DD">
                      {end}
                    </Moment>
                  </DropdownToggle>
                  <DropdownMenu style={JobManagementStyle.dropdownmenustyle}>
                    <DropdownItem
                      style={{ color: "#d16557" }}
                      onClick={updateTable}
                      id="14days"
                    >
                      Next 14 Days
                    </DropdownItem>
                    <DropdownItem
                      style={{ color: "#d16557" }}
                      onClick={updateTable}
                      id="7days"
                    >
                      Next 7 Days
                    </DropdownItem>
                    <DropdownItem
                      style={{ color: "#d16557" }}
                      onClick={updateTable}
                      id="tomorrow"
                    >
                      Tomorrow
                    </DropdownItem>
                    <DropdownItem
                      style={{ color: "#d16557" }}
                      onClick={updateTable}
                      id="today"
                    >
                      Today
                    </DropdownItem>
                    <DropdownItem
                      style={{ color: "#d16557" }}
                      onClick={updateTable}
                      id="yesterday"
                    >
                      Yesterday
                    </DropdownItem>
                    <DropdownItem
                      style={{ color: "#d16557" }}
                      onClick={updateTable}
                      id="last7days"
                    >
                      Last 7 days
                    </DropdownItem>
                    <DropdownItem
                      style={{ color: "#d16557" }}
                      onClick={updateTable}
                      id="last14days"
                    >
                      Last 14 days
                    </DropdownItem>
                    <DropdownItem
                      style={{ color: "#d16557" }}
                      onClick={openDateRange}
                    >
                      Custom Range
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {customRange()}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={5}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <Row>
                    <Col xs={12} md={6}>
                      Jobs
                    </Col>
                    <Col xs={12} md={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-mutiple-chip-label">
                          Regions
                        </InputLabel>
                        <Select
                          labelId="demo-mutiple-chip"
                          id="demo-mutiple"
                          multiple
                          value={selectedRegions}
                          onChange={handleChangeRegion}
                          input={<Inputmui id="select-res" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  className={classes.chip}
                                />
                              ))}
                            </div>
                          )}
                        >
                          {regions.map((region) => (
                            <MenuItem key={region.region} value={region.region}>
                              {region.region}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      {returnErrorMsg(error)}
                    </Col>
                  </Row>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <JobManagementTable
                  selection={selection}
                  setSelection={setSelection}
                  loadingTable={loadingTable}
                  rows={myTable}
                  columns={columnsLeft}
                  cell={JobManagementCellLeft}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Assignment {saveAlertMSG()}</CardTitle>
              </CardHeader>
              <CardBody>{selectedJobs()}</CardBody>
            </Card>
          </Col>
          <Col xs={12} md={3}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <Row>
                    <Col xs={12} md={6}>
                      Packer
                    </Col>
                    <Col xs={12} md={6}>
                      <Fab
                        style={{ backgroundColor: "#d16557", color: "#FFFFFF" }}
                        aria-label="add"
                        onClick={() => {
                          editToggle();
                        }}
                      >
                        <AddIcon style={{ fontSize: 40 }} />
                      </Fab>
                      <Modal
                        isOpen={editModal}
                        size="lg"
                        style={{ maxWidth: "1200px", width: "60%" }}
                      >
                        <ModalHeader toggle={editToggle}>
                          Create a new packer
                          {saveAlertMSG()}
                        </ModalHeader>
                        <ModalBody>
                          <Row>
                            <Col xs={12}>
                              <Card>
                                <CardBody>
                                  <CardTitle>New Packer Details</CardTitle>
                                  {createPacker()}
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            color="success"
                            disabled={disableSaveBtn}
                            onClick={() => {
                              saveChanges();
                            }}
                          >
                            Save
                          </Button>{" "}
                          <Button
                            color="danger"
                            onClick={() => {
                              editToggle();
                            }}
                          >
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </Col>
                  </Row>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <JobPacker updatePackers={packers} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
