import { TableCell } from "@material-ui/core";
import React from "react";
import Moment from "react-moment";

const JobManagementCellLeft = (props) => {
  if (props.column.name === "jobId") {
    return (
      <TableCell style={{ height: 100 }}>
        <a
          href={
            "https://podio.com/fridaygroup/intranet/apps/bookinger/items/" +
            props.row.jobId
          }
        >
          {props.row.jobId}
        </a>
      </TableCell>
    );
  }
  if (props.column.name === "type") {
    if (props.row.type === "Fri bar arrangement") {
      return (
        <TableCell style={{ color: "#8FDE6B" }}>{props.row.type}</TableCell>
      );
    } else if (props.row.type === "Aftalt antal cocktails") {
      return (
        <TableCell style={{ color: "#ffd652" }}>{props.row.type}</TableCell>
      );
    } else if (props.row.type === "Cocktailkursus") {
      return (
        <TableCell style={{ color: "#7dc7ff" }}>{props.row.type}</TableCell>
      );
    } else if (props.row.type === "Graffiticocktails") {
      return (
        <TableCell style={{ color: "#ff7373" }}>{props.row.type}</TableCell>
      );
    } else if (props.row.type === "Cocktailkursus + fri bar") {
      return (
        <TableCell style={{ color: "#faf2c4" }}>{props.row.type}</TableCell>
      );
    } else if (props.row.type === "Total arrangement") {
      return (
        <TableCell style={{ color: "#e3d6ff" }}>{props.row.type}</TableCell>
      );
    } else if (props.row.type === "Bartenderudlejning") {
      return (
        <TableCell style={{ color: "#bdf0ed" }}>{props.row.type}</TableCell>
      );
    } else if (props.row.type === "Materiale udlejning") {
      return (
        <TableCell style={{ color: "#cffcc7" }}>{props.row.type}</TableCell>
      );
    } else {
      return <TableCell>{props.row.type}</TableCell>;
    }
  }
  if (props.column.name === "persons") {
    return <TableCell>{props.row.persons}</TableCell>;
  }
  if (props.column.name === "date") {
    return (
      <TableCell>
        <div>
          <Moment date={props.row.date} format={"dddd"} />
        </div>
        <Moment date={props.row.date} format={"MMM Do"} />
      </TableCell>
    );
  }
  if (props.column.name === "region") {
    return <TableCell>{props.row.region}</TableCell>;
  }
  return <TableCell>No data</TableCell>;
};
export default JobManagementCellLeft;
