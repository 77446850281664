import React, { useState } from "react";
import {
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  SelectionState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
  TableSelection,
} from "@devexpress/dx-react-grid-material-ui";

const getRowId = (row) => {
  return row.jobId;
};

export default function JobManagementTable(props) {
  const [tableColumnExtensions] = useState([
    { columnName: "jobId", width: "16%" },
    { columnName: "type", width: "auto" },
    { columnName: "persons", width: "13%" },
    { columnName: "date", width: "21%" },
    { columnName: "region", width: "19%" },
  ]);
  const [headerExtension] = useState([
    { columnName: "jobId", width: "auto" },
    { columnName: "type", width: "auto" },
    { columnName: "persons", width: "auto" },
    { columnName: "date", width: "auto" },
    { columnName: "region", width: "auto" },
  ]);

  if (!props.loadingTable) {
    return (
      <React.Fragment>
        <Grid rows={props.rows} columns={props.columns} getRowId={getRowId}>
          <SelectionState
            selection={props.selection}
            onSelectionChange={props.setSelection}
          />
          <SortingState
            defaultSorting={[{ columnName: props.sortIt, direction: "asc" }]}
          />
          <IntegratedSorting />
          <SearchState />
          <IntegratedFiltering />
          <Table
            columnExtensions={tableColumnExtensions}
            cellComponent={props.cell}
          />
          <TableHeaderRow
            columnExtensions={headerExtension}
            showSortingControls={true}
          />
          <TableSelection
            selectByRowClick
            highlightRow
            showSelectionColumn={false}
          />
          <Toolbar />
          <SearchPanel />
        </Grid>
      </React.Fragment>
    );
  } else {
    return <div></div>;
  }
}
