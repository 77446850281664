import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { GroupingState, IntegratedGrouping } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow
} from "@devexpress/dx-react-grid-material-ui";

export default function PackingTable(props) {
  const [id, setId] = useState(props.id);
  const [columns] = useState([
    { name: "name", title: "Name" },
    { name: "amount", title: "Quantity" },
    { name: "category_name", title: "Category" },
    { name: "unit", title: "Unit" },
    { name: "reol", title: "Reol Nr." },
    { name: "hylde", title: "Hylde" }
  ]);
  const [rows, setRows] = useState(props.rows);

  return (
    <Paper>
      <Grid rows={rows} columns={columns}>
        <GroupingState
          grouping={[{ columnName: "category_name" }]}
          defaultExpandedGroups={[
            "Spiritus",
            "Juice & púre",
            "Sirup",
            "Sodavand, mixer",
            "Friske produkter",
            "Barudstyr",
            "Specialvarer",
            "Glas",
            "Isterninger",
            "Menukort",
            "Kursus speciel",
            "Egenproduktion",
            "Kolonial",
            "Plastikglas"
          ]}
        />
        <IntegratedGrouping />
        <Table />
        <TableHeaderRow />
        <TableGroupRow />
      </Grid>
    </Paper>
  );
}
