import WaresView from "views/Wares/WaresView.js";
import CocktailMenuview from "views/CocktailMenu/CocktailMenuview.js";
import Bookingsview from "views/Bookings/Bookingsview.js";
import SpecsView from "views/Specs/SpecsView.js";
import Glassesview from "views/Glass/Glassesview.js";
import Samplingssview from "views/Samplings/Samplingsview.js";
import Containersview from "views/Container/Containersview.js";
import Settingsview from "views/Settings/Settingsview.js";
import Components_sview from "views/Components_/Components_sview.js";
import JobManagementView from "views/JobManagement/JobManagementView.js";

const routes = [
  {
    path: "/bookingoverview",
    name: "Bookings",
    icon: "now-ui-icons files_single-copy-04",
    component: Bookingsview,
  },
  {
    path: "/jobmanagement",
    name: "Job Management",
    icon: "now-ui-icons design_vector",
    component: JobManagementView,
  },
  {
    path: "/digitalpackinglist",
    name: "Digital Packing List",
    icon: "now-ui-icons shopping_delivery-fast",
    component: WaresView,
  },
  {
    path: "/cocktailmenus",
    name: "Cocktail menus",
    icon: "now-ui-icons education_paper",
    component: CocktailMenuview,
  },
  {
    path: "/components",
    name: "Components",
    icon: "now-ui-icons design-2_ruler-pencil",
    component: Components_sview,
  },
  {
    path: "/glasses",
    name: "Glasses",
    icon: "now-ui-icons objects_diamond",
    component: Glassesview,
  },
  {
    path: "/containers",
    name: "Containers",
    icon: "now-ui-icons shopping_box",
    component: Containersview,
  },
  {
    path: "/specs",
    name: "Specs",
    icon: "now-ui-icons text_align-center",
    component: SpecsView,
  },
  {
    path: "/samplings",
    name: "Samplings",
    icon: "now-ui-icons business_chart-pie-36",
    component: Samplingssview,
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "now-ui-icons ui-1_settings-gear-63",
    component: Settingsview,
  },
];

export default routes;
