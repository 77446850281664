import { TableCell } from "@material-ui/core";
import React from "react";
import { IconButton } from "@material-ui/core";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import apiFacade from "../../../auth/apiFacade";
import types from "../../../redux/actionTypes";

const CocktailsCell = (props) => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.CocktailsMenu);
  const { updateCocktails } = useSelector((state) => state.CocktailsMenu);
  const { isLoading } = useSelector((state) => state.CocktailsMenu);

  const deleteCocktails = (cocktailId) => {
    let cocktail = {
      menuId: id,
      productId: cocktailId,
    };

    apiFacade.deleteDataWithObj("/MenuCocktails/", cocktail).then((data) => {
      dispatch({
        type: types.CocktailsMenu.updateCocktails,
        payload: !updateCocktails,
      });
    });
  };

  if (props.column.name === "name") {
    if (isLoading) {
      return (
        <TableCell>
          <span style={{ color: "grey" }}>{props.row.name}</span>
        </TableCell>
      );
    } else {
      return (
        <TableCell>
          <span>{props.row.name}</span>
        </TableCell>
      );
    }
  }
  if (props.column.name === "action") {
    return (
      <TableCell>
        <IconButton
          disabled={isLoading}
          onClick={() => {
            deleteCocktails(props.row.id);
          }}
          color="secondary"
        >
          <DeleteForeverTwoToneIcon />
        </IconButton>
      </TableCell>
    );
  }

  return <TableCell>No data</TableCell>;
};
export default CocktailsCell;
