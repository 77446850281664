import React, { useState, useEffect } from "react";
import apiFacade from "../../../../auth/apiFacade";
import BarLoader from "react-spinners/BarLoader";
import jsPDF from "jspdf";
import hexToRgb from "pretty-easy-hex-to-rgb";
import { ScreenSize } from "helper/ScreenSize";

const TotalComponenets = ({
  listOfId,
  refreshApi,
  isloading,
  startDate,
  endDate,
}) => {
  //const [myListOfIds, setMyListOfIds] = useState([]);
  const [myData, setMyData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(isloading);
  const [pdfWidth, setPdfWidth] = useState(700);
  const [pdfHeight, setPdfHeight] = useState(900);

  useEffect(() => {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      setPdfWidth(300);
      setPdfHeight(350);
    } else if (screenSize === 2) {
      setPdfWidth(675);
      setPdfHeight(800);
    }

    setIsLoading(isloading);

    //setMyListOfIds(listOfId)
    if (refreshApi) {
      const sortedData = [];
      let totalContainerQty = 0;
      apiFacade
        .getData("/packinglists/getlistofpackingVCK/" + listOfId.join())
        .then((newData) => {
          //apiFacade.getData("/packinglists/getlistofpackingVCK/6518,6317,6412,6423,6433,6335").then(newData => {
          setMyData(newData);
        });
      apiFacade
        .getData("/packinglists/getlistofpackingVCK/" + listOfId.join())
        .then((data) => {
          //apiFacade.getData("/packinglists/getlistofpackingVCK/6002,6001,6000,5989,5988,5985,5983,5979,5976,5974,5969,5967,5964,5958,5955,5947,5941,5938,5937,5930,5926,5925,5924,5921,5895,5881,5873,5861,5828").then(data => {

          data.forEach((element) => {
            element.vCKItemtotals.forEach((comp) => {
              let objIndex = sortedData.findIndex(
                (obj) => obj.componentId === comp.componentId
              );
              if (objIndex !== -1) {
                sortedData[objIndex].amount =
                  sortedData[objIndex].amount + comp.amount;
                sortedData[objIndex].totalamountcl =
                  sortedData[objIndex].totalamountcl + comp.totalamountcl;
                sortedData[objIndex].containerQty =
                  sortedData[objIndex].containerQty + comp.containerQty;
                if (comp.containerName === "PET") {
                  totalContainerQty = totalContainerQty + comp.containerQty;
                }
              } else {
                sortedData.push(comp);
                if (comp.containerName === "PET") {
                  totalContainerQty = totalContainerQty + comp.containerQty;
                }
              }
            });
          });
          if (sortedData.length > 0) {
            let packs = null;
            packs = sortedData.find(function (post, index) {
              if (post.componentId === 451) {
                return post.amount;
              }
            });
            if (packs != null) {
              sortedData.push({
                componentId: 9999,
                amount: totalContainerQty,
                prkasse: totalContainerQty / packs.amount,
                componentName: "Total Container",
                unit: "PET flasker",
                containerQty: "",
              });
            }
          }
          setFilteredData(sortedData);
          setIsLoading(false);
          //setMyListOfIds(data)
        })
        .finally();
    }
  }, [refreshApi]);

  var headRows = [
    { title: "Component Name", dataKey: "componentName" },
    { title: "Amount", dataKey: "amount" },
    { title: "", dataKey: "empty" },
    { title: "Container", dataKey: "containerName" },
    { title: "cl", dataKey: "unitcl" },
    { title: "Total Amount cl", dataKey: "totalamountcl" },
  ];

  var headRowswithyes = [
    { title: "Component Name", dataKey: "componentName" },
    { title: "Amount", dataKey: "amount" },
    { title: " ", dataKey: "empty" },
    { title: "Container", dataKey: "containerName" },
    { title: "cl", dataKey: "unitcl" },
    { title: "Total Amount cl", dataKey: "totalamountcl" },
  ];

  if (myData.length > 0 && filteredData.length > 0 && isLoading != true) {
    let packs = null;
    packs = filteredData.find(function (post, index) {
      if (post.componentId === 451) {
        return post.amount;
      }
    });
    if (packs != null) {
      let newStartdate = "";
      let newEnddate = "";
      if (startDate.includes("/")) {
        newStartdate = startDate.split("/");
      } else {
        newStartdate = startDate.split("-");
      }
      if (endDate.includes("/")) {
        newEnddate = endDate.split("/");
      } else {
        newEnddate = endDate.split("-");
      }

      var doc = new jsPDF("p", "pt", "a4", true);
      doc.setFontSize(18);
      doc.setFont("times");
      doc.setFontType("italic");
      doc.text("Total Liste VCK", 14, 40);
      doc.setFontSize("15");
      doc.text(
        14,
        60,
        "Date " +
          `${
            newStartdate[2] +
            "/" +
            newStartdate[1] +
            "/" +
            newStartdate[0] +
            " - " +
            newEnddate[2] +
            "/" +
            newEnddate[1] +
            "/" +
            newEnddate[0]
          }`
      );
      doc.text("Pax Total: ", 454, 30);
      doc.text("Bartenders: ", 454, 50);
      doc.text("Packs: " + packs.amount, 454, 70);
      //table for total
      doc.autoTable({
        columns: headRows,
        body: filteredData,
        startY: 80,
        rowPageBreak: "auto",
        margin: { horizontal: 10 },
        styles: { overflow: "linebreak" },
        bodyStyles: { valign: "top" },
        columnStyles: { email: { cellWidth: "wrap" } },
        // columnStyles: { 0: { cellWidth: 1200 } },

        styles: {
          lineColor: [44, 62, 80],
          lineWidth: 1,
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
          //fillColor: hexToRgb(myColor)
        },
        columnStyles: {
          componentName: { cellWidth: 180 },
          amount: { cellWidth: 70 },
          //empty: { cellWidth: 70 },
          containerName: { cellWidth: 105 },
          unitcl: { cellWidth: 50 },
          totalamountcl: { cellWidth: 100 },
        },
        headStyles: { fillColor: hexToRgb("#36688d") }, // Red  textColor: hexToRgb("#000000")
        //pageBreak: 'avoid',
      });

      myData.forEach((element, i) => {
        //each product
        if ((i) => 0) {
          doc.setFontSize(18);
          doc.setFont("times");
          doc.setFontType("italic");
          doc.text(element.productName, 14, doc.lastAutoTable.finalY + 40);

          doc.autoTable({
            columns: headRowswithyes,
            body: element.vCKItemtotals,
            startY: doc.lastAutoTable.finalY + 45,
            rowPageBreak: "auto",
            margin: { horizontal: 10 },
            styles: { overflow: "linebreak" },
            bodyStyles: { valign: "top" },
            // columnStyles: {
            //   email: { cellWidth: "wrap" },
            //   amount:{ cellWidth: 1000}
            // },
            // columnStyles: { 0: { cellWidth: 1200 ,} },

            styles: {
              lineColor: [44, 62, 80],
              lineWidth: 1,
              cellWidth: "wrap",
              rowPageBreak: "auto",
              halign: "justify",
              //fillColor: hexToRgb(myColor)
            },
            columnStyles: {
              componentName: { cellWidth: 180 },
              amount: { cellWidth: 70 },
              //empty: { cellWidth: 70 },
              containerName: { cellWidth: 105 },
              unitcl: { cellWidth: 50 },
              totalamountcl: { cellWidth: 100 },
            },
            headStyles: { fillColor: hexToRgb(element.productColor) }, // Red  textColor: hexToRgb("#000000")
            //pageBreak: 'avoid',
          });
        }
      });

      const uri = doc.output("datauristring");
      return (
        <iframe
          title="totalCframe"
          frameBorder="0"
          width={pdfWidth}
          height={pdfHeight}
          src={uri}
        />
      );
    }
  }
  if (refreshApi) {
    return (
      <div>
        <div className="sweet-loading" style={{ marginLeft: 300 }}>
          <BarLoader
            height={5}
            width={200}
            sizeUnit={"px"}
            size={500}
            color={"#4C8FBF"}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ marginLeft: 450 }}>
          <h5>Press View</h5>
        </div>
      </div>
    );
  }
};
export default TotalComponenets;
