import React, { useState } from 'react'
import FuncComponent from './FuncComponent';

export default function Previewer() {
  const [columns, setColumns] = useState([
    { name: "A", title: "A", dataKey: "A" },
    { name: "B", title: "B", dataKey: "B" },
    { name: "C", title: "C", dataKey: "C" },
  ]);
  const [rows, setRows] = useState([
    { A: "A", B: "B", C: "C" },
    { A: "A", B: "B", C: "C" },
  ]);

  return (
    <div>
      <FuncComponent isLoading={false} columns={columns} rows={rows} />
    </div>
  );
}
