import { TableCell } from "@material-ui/core";
import React, { useState } from "react";
import Moment from "react-moment";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import types from "../../../redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import apiFacade from "../../../auth/apiFacade.js";

const AssignmentsCell = (props) => {
  const dispatch = useDispatch();
  const { assignmentBool } = useSelector((state) => state.assignment);
  const [editModal, setEditModal] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));

  const editToggle = () => {
    setEditModal(!editModal);
  };

  const putAssignmentStatus = () => {
    //let jSon = JSON.parse(risika);
    var jobPakker = {
      pakker_Id: user.packer_Id,
      management_Id: props.row.managementId,
      assignmentStatus_Id: 5,
    };
    apiFacade.putDataWithJson(
      "https://pakkemodelapi.azurewebsites.net/api/JobManagement/updateassignmentstatusid",
      jobPakker
    );
  };

  if (props.column.name === "jobId") {
    return <TableCell style={{ height: 100 }}>{props.row.jobId}</TableCell>;
  }
  if (props.column.name === "assignmentName") {
    return <TableCell>{props.row.assignmentName}</TableCell>;
  }
  if (props.column.name === "packingDate") {
    return (
      <TableCell>
        <div>
          <Moment date={props.row.packingDate} format={"dddd MMM Do"} />
        </div>
      </TableCell>
    );
  }
  if (props.column.name === "startJob") {
    return (
      <TableCell>
        <IconButton
          style={{ color: "#227235" }}
          onClick={() => {
            editToggle();
          }}
        >
          <PlayCircleFilledWhiteIcon fontSize="large" />
        </IconButton>
        <Modal
          isOpen={editModal}
          toggle={editToggle}
          size="lg"
          style={{ width: "50%", marginTop: "15%" }}
        >
          <ModalHeader style={{ alignSelf: "center" }}>
            Start {props.row.assignmentName.toLowerCase()} ?
          </ModalHeader>
          <ModalFooter>
            <Button
              size="lg"
              style={{ width: "40%" }}
              color="success"
              onClick={() => {
                putAssignmentStatus();
                dispatch({
                  type: types.assignment.managementId,
                  payload: props.row.managementId,
                });
                dispatch({
                  type: types.assignment.assignmentId,
                  payload: props.row.assignmentId,
                });
                dispatch({
                  type: types.assignment.assignmentName,
                  payload: props.row.assignmentName,
                });
                dispatch({
                  type: types.assignment.assignmentBool,
                  payload: !assignmentBool,
                });
                dispatch({
                  type: types.assignment.jobId,
                  payload: props.row.jobId,
                });
                let assignment = {
                  managementId: props.row.managementId,
                  assignmentId: props.row.assignmentId,
                  assignmentName: props.row.assignmentName,
                  jobId: props.row.jobId,
                };
                localStorage.setItem("assignment", JSON.stringify(assignment));
              }}
            >
              Yes
            </Button>{" "}
            <Button
              size="lg"
              style={{ width: "40%" }}
              color="danger"
              onClick={() => {
                editToggle();
              }}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      </TableCell>
    );
  }
  return <TableCell>No data</TableCell>;
};
export default AssignmentsCell;
