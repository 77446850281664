import React, { useState } from "react";
import {
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  GroupingState,
  IntegratedGrouping,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  Toolbar,
  SearchPanel,
  GroupingPanel,
} from "@devexpress/dx-react-grid-material-ui";

const getRowId = (row) => {
  return row.componentId;
};

export default function JobAssignmentTable(props) {
  const [grouping] = useState([{ columnName: "category" }]);
  const [defaultExpandedGroups] = useState([
    "Spiritus",
    "Juice & púre",
    "Sodavand, mixer",
    "Egenproduktion",
    "Friske produkter",
    "Sirup",
    "Specialvarer",
    "Glas",
    "Kursus speciel",
    "Isterninger",
    "Barudstyr",
    "Kolonial",
    "Mappen",
    "Plastikglas",
    "Vin",
    "PlastikglasWhiteLabel",
    "VCK prodiktion",
  ]);
  const [tableColumnExtensions] = useState([
    { columnName: "name", width: "23%" },
    { columnName: "amount", width: "14%" },
    { columnName: "unit", width: "23%" },
    { columnName: "category", width: "8%" },
    { columnName: "status", width: "34%" },
  ]);
  const [headerExtension] = useState([
    { columnName: "name", width: "auto" },
    { columnName: "amount", width: "auto" },
    { columnName: "unit", width: "auto" },
    { columnName: "category", width: "auto" },
    { columnName: "status", width: "auto" },
  ]);

  if (!props.loadingTable) {
    return (
      <React.Fragment>
        <Grid rows={props.rows} columns={props.columns} getRowId={getRowId}>
          <SortingState
            defaultSorting={[{ columnName: props.sortIt, direction: "asc" }]}
          />
          <GroupingState
            grouping={grouping}
            defaultExpandedGroups={defaultExpandedGroups}
          />
          <IntegratedGrouping />
          <IntegratedSorting />
          <SearchState />
          <IntegratedFiltering />
          <Table
            columnExtensions={tableColumnExtensions}
            cellComponent={props.cell}
          />
          <TableHeaderRow
            columnExtensions={headerExtension}
            showSortingControls={true}
          />
          <TableGroupRow />
          <Toolbar />
          <SearchPanel />
          <GroupingPanel showSortingControls={true} />
        </Grid>
      </React.Fragment>
    );
  } else {
    return <div></div>;
  }
}
