import React, { Component } from "react";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { Alert, AlertTitle } from "@material-ui/lab";
import Moment from "react-moment";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
} from "reactstrap";
import apiFacade from "../../auth/apiFacade";
import BookingsTable from "./BookingsTable/BookingsTable";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Print from "@material-ui/icons/Print";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
import "../../assets/css/checkboks.css";
import RangePicker from "react-range-picker";
import * as updateBookingMsg from "./UpdateBookingMsg";
import { connect } from "react-redux";
import Fab from "@material-ui/core/Fab";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";
import { ScreenSize } from "../../helper/ScreenSize";
import { Redirect } from "react-router-dom";

var bookingStyle = {
  cellStyling: {
    padding: "0px 10px 0px 0px",
  },
  dropdownitemstyle: {
    background: "red",
  },
  searchBarstyle: {
    boxShadow: "10px 10px 5px #b3c6d3",
    width: "275px",
    position: "absolute",
    right: "68%",
    top: "10px",
  },
  searchInputstyle: {
    position: "absolute",
    top: "10px",
    left: "10px",
    width: "125px",
  },
  dropdownmenustyle: {
    color: "#d16557",
    width: "100%",
    zIndex: "1001",
    right: "end",
  },
  cardchartMyFix: {
    position: "absolute",
    right: "220px",
    top: "12px",
  },
  cardchartMyFixEdit: {
    position: "absolute",
    right: "360px",
    top: "10px",
  },
  cardchartMyFixBack: {
    position: "fixed",
    bottom: "55px",
    right: "70px",
  },
  radioWhite: {
    border: "10px solid #90DDD0",
  },
  radioPink: {
    border: "10px solid #EF959D",
  },
  radioRed: {
    border: "10px solid #90DDD0",
  },
  customRange: {
    position: "absolute",
    right: "530px",
    top: "20px",
    boxShadow: "1px 10px 10px 1px #ccc",
  },
  stuck: {
    position: "fixed",
    marginLeft: "69%",
    zIndex: "1000",
  },
  stuck2: {
    position: "fixed",
    marginTop: "0%",
    marginRight: "30%",
    zIndex: "1000",
  },
  stuck3: {
    position: "absolute",
    right: "275px",
    top: "65px",
    zIndex: "1000",
  },
  modalSize: {
    width: "1397px",
    height: "802px",
  },
};
class Bookings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onN: false,
      printing: false,
      sjælland: false,
      jylland: false,
      fyn: false,
      udland: false,
      sendt: false,
      klar: false,
      pakket: false,
      igang: false,
      printet: false,
      ikkePrintet: false,
      loaded: false,
      unloaded: false,
      waitingOnFruitVegs: false,
      selectAllProcess: false,
      selectAllRegions: false,
      listOfId: [],
      listOfIdtoUpdate: [],
      selectedOption: null,
      bookings: [],
      menus: [],
      mytable: [],
      isLoading: true,
      start: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
      end: this.enddate(7),
      newdate: 7,
      pageSizes: [5, 10, 15],
      modalDemo: false,
      datePickerHandler: false,
      defaultExpandedGroups: ["Jylland", "Fyn", "Sjælland", "Udland"],
      updateBookingById: false,
      updateBookingByDate: false,
      searchInput: null,
      tempMyTable: [],
      error: 0,
      isItVirtual: false,
      tempInput: null,
      buttonDisabled: true,
      updateStuck: {
        position: "absolute",
        marginLeft: "80%",
        zIndex: "1000",
        marginTop: "2%",
      },
      errorStuck: {
        position: "fixed",
        marginTop: "0%",
        marginLeft: "-15%",
        zIndex: "1000",
      },
      columnsBooking: [
        { name: "id", title: "Id" },
        { name: "companyName", title: "Company Name" },
        { name: "date", title: "Date" },
        { name: "type", title: "Type" },
        { name: "persons", title: "Persons" },
        { name: "duration", title: "Duration" },
        { name: "process", title: "Process" },
        { name: "region", title: "Region" },
        { name: "view", title: "Actions" },
      ],
    };
  }

  componentDidMount() {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      this.setState({
        updateStuck: {
          position: "absolute",
          marginLeft: "55%",
          zIndex: "1000",
          marginTop: "8%",
        },
      });
      this.setState({
        errorStuck: {
          position: "fixed",
          marginTop: "0%",
          marginLeft: "0%",
          zIndex: "1000",
        },
      });
      this.setState({
        columnsBooking: [
          { name: "id", title: "Id" },
          { name: "region", title: "Region" },
          { name: "view", title: "Actions" },
          ,
        ],
      });
    } else if (screenSize === 2) {
      this.setState({
        updateStuck: {
          position: "absolute",
          marginLeft: "75%",
          zIndex: "1000",
          marginTop: "5%",
        },
      });
      this.setState({
        columnsBooking: [
          { name: "id", title: "Id" },
          { name: "date", title: "Date" },
          { name: "type", title: "Type" },
          { name: "process", title: "Process" },
          { name: "region", title: "Region" },
          { name: "view", title: "Actions" },
          ,
        ],
      });
    }

    // this.props.toggleTodo("this.props.todoId");
    //f.eks på date --> api/bookings?start=2019-03-20&end=2019-03-23
    apiFacade.getData("/bookings").then((data) => {
      this.setState({ bookings: data });
      if (data != null) {
        data.map((data) => {
          console.log(data.process);
          if (
            data.type != "Den virtuelle ginrejse" &&
            data.type != "Virtuelt cocktailkursus" &&
            data.type != "Den virtuelle ølsmagning"
          ) {
            this.state.mytable.push({
              id: data.id,
              companyName: data.companyName,
              date: data.date,
              type: data.type,
              bartenders: data.bartenders,
              barModules: data.barModules,
              duration: data.duration,
              region: data.region,
              persons: data.persons,
              isLoading: false,
              dateEnd: data.dateEnd,
              barbacks: data.barbacks,
              waiters: data.waiters,
              industrialDishwasher: data.industrialDishwasher,
              process: data.process,
            });
          }
        });
        this.setState({ isLoading: false });
        this.setState({ tempMyTable: this.state.mytable });
      }
    });
  }

  updateTable = (e) => {
    document.getElementById("searchBar").value = "";

    if (e.currentTarget.id === "14days") {
      // 14 days api call
      this.newBookingsbydate(this.startDate(0), 14);

      this.setState({ end: this.enddate(14) });
    }

    if (e.currentTarget.id === "7days") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(0), 7);
      this.state.end = this.enddate(7);
    }
    if (e.currentTarget.id === "tomorrow") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(-1), 1);
      this.state.start = this.startDate(-1);
      this.state.end = this.enddate(1);
    }
    if (e.currentTarget.id === "yesterday") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(1), -1);
      this.state.start = this.startDate(1);
      this.state.end = this.enddate(-1);
    }

    if (e.currentTarget.id === "today") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(0), 0);
      this.state.start = 0;
      this.state.start = this.startDate(0);
      this.state.end = 0;
      this.state.end = this.enddate(0);
    }
    if (e.currentTarget.id === "last7days") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(7), 0);
      this.state.start = this.startDate(7);
      this.state.end = 0;
      this.state.end = this.enddate(0);
    }
    if (e.currentTarget.id === "last14days") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(14), 0);
      this.state.start = this.startDate(14);
      this.state.end = 0;
      this.state.end = this.enddate(0);
    }
  };
  startDate = (newday) => {
    var new_date = new Date();
    var day = String(new_date.getDate());
    var month = String(new_date.getMonth() + 1);
    var myNewdate = String(new_date.getFullYear() + "/" + month + "/" + day);
    var date = new Date(myNewdate);
    var next_date = new Date(date.setDate(date.getDate() - newday));
    var month_ = "" + (next_date.getMonth() + 1);
    var day_ = "" + next_date.getDate();
    var year_ = next_date.getFullYear();

    if (month_.length < 2) month_ = "0" + month_;
    if (day_.length < 2) day_ = "0" + day_;

    var myNewdate_ = [year_, month_, day_].join("/");
    return myNewdate_;
  };

  enddate = (newday) => {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var myNewdate = [year, month, day].join("/");

    var date = new Date(myNewdate);
    var next_date = new Date(date.setDate(date.getDate() + newday));
    var month_ = "" + (next_date.getMonth() + 1);
    var day_ = "" + next_date.getDate();
    var year_ = next_date.getFullYear();

    if (month_.length < 2) month_ = "0" + month_;
    if (day_.length < 2) day_ = "0" + day_;

    var myNewdate_ = [year_, month_, day_].join("/");
    return myNewdate_;
  };

  newBookingsbydate = (startdate, nday) => {
    //bookings?start=2019-03-30&end=2019-04-03
    this.state.mytable = [];
    this.state.menus = [];
    this.state.bookings = [];
    this.setState({ start: startdate });
    this.setState({ end: this.enddate(nday) });
    apiFacade
      .getData("/bookings?start=" + startdate + "&end=" + this.enddate(nday))
      .then((data) => {
        data.map((data) => {
          if (
            data.type !== "Den virtuelle ginrejse" &&
            data.type !== "Virtuelt cocktailkursus" &&
            data.type !== "Den virtuelle ølsmagning"
          ) {
            this.state.mytable.push({
              id: data.id,
              companyName: data.companyName,
              date: data.date,
              type: data.type,
              bartenders: data.bartenders,
              barModules: data.barModules,
              duration: data.duration,
              region: data.region,
              persons: data.persons,
              process: data.process,
              isLoading: false,
              dateEnd: data.dateEnd,
            });
          }
        });

        this.setState({ isLoading: false });
      });
  };

  // bookings'
  // /bookings/rowData.id
  handleClick2 = (event) => {
    this.setState({ anchorE2: event.currentTarget });
  };

  handleClose2 = () => {
    this.setState({ anchorE2: null });
  };

  handleClickDate = (event) => {
    this.setState({ anchorE1: event.currentTarget });
  };

  handleCloseDate = () => {
    this.setState({ anchorE1: null });
  };

  //adgang til edit print menu, baseret på oid fra azure user
  editprintmenu = () => {
    if (
      apiFacade.oid == "51db0d9a-5a9a-4644-b47f-71c3ad07e5b3" ||
      apiFacade.oid == "a754573a-7733-4ad8-b707-ef626d898d34" ||
      apiFacade.oid === "20c437e4-a2dc-4916-8ffe-78e25badc009" ||
      apiFacade.oid === "31183aa9-7e72-4233-9a92-308e158e37f6" ||
      apiFacade.oid === "196e4a83-285d-4e96-86c2-d5ccbc195f76" ||
      apiFacade.oid === "753576b8-abf1-4554-b8e6-263ecc544cf1" ||
      apiFacade.oid === "d924945f-73ca-4605-9c6b-4b927d5a8392" ||
      apiFacade.oid === "d8021541-5857-4045-adfc-cba691113edd" ||
      apiFacade.oid === "34bc5d2c-6a3a-4462-be4b-109622e7b4ff" ||
      apiFacade.oid === "ef2331e0-89e7-4564-998b-3873100afc4d" ||
      apiFacade.oid === "a1f7c4e7-3597-42d3-b461-0d3592ab9c8a" ||
      apiFacade.oid === "f2d8dbf3-e2e8-4aa5-8e77-d3c8a99ccb5f"
    ) {
      return (
        <div
          className="card-chartMyFix"
          style={bookingStyle.cardchartMyFixEdit}
        >
          <Button
            style={{}}
            color="info"
            onClick={() => {
              this.props.history.push(`/bookingoverview/bookings/edit`);
            }}
          >
            {" "}
            Edit Packing List
          </Button>
        </div>
      );
    } else {
      return; //console.log("doesnt work");
    }
  };

  toggleModalDemo = () => {
    this.setState({
      modalDemo: !this.state.modalDemo,
    });
  };
  handleOptionChange = (changeEvent) => {
    this.setState({
      selectedOption: changeEvent.target.value,
    });
  };
  handleChangeFyn = (fyn) => {
    this.setState({ fyn: fyn.state.value });
  };
  handleChangeSjælland = (sjælland) => {
    this.setState({ sjælland: sjælland.state.value });
  };
  handleChangeJylland = (jylland) => {
    this.setState({ jylland: jylland.state.value });
  };
  handleChangeUdland = (udland) => {
    this.setState({ udland: udland.state.value });
  };
  handleChangeSelectAllRegions = (selectAllRegions) => {
    if (selectAllRegions.state.value === true) {
      document.getElementsByClassName("sjallandon")[0].click();
      document.getElementsByClassName("jyllandon")[0].click();
      document.getElementsByClassName("fynon")[0].click();
      document.getElementsByClassName("udlandon")[0].click();
    }
    if (selectAllRegions.state.value === false) {
      document.getElementsByClassName("sjallandoff")[0].click();
      document.getElementsByClassName("jyllandoff")[0].click();
      document.getElementsByClassName("fynoff")[0].click();
      document.getElementsByClassName("udlandoff")[0].click();
    }
    this.setState({
      selectAllRegions: selectAllRegions.state.value,
      sjælland: selectAllRegions.state.value,
      jylland: selectAllRegions.state.value,
      fyn: selectAllRegions.state.value,
      udland: selectAllRegions.state.value,
    });
  };

  handleChangePakket = (pakket) => {
    this.setState({ pakket: pakket.state.value });
  };
  handleChangeIgang = (igang) => {
    this.setState({ igang: igang.state.value });
  };
  handleChangePrintet = (printet) => {
    this.setState({ printet: printet.state.value });
  };
  handleChangeIkkePrintet = (ikkePrintet) => {
    this.setState({ ikkePrintet: ikkePrintet.state.value });
  };
  handleChangeLoaded = (loaded) => {
    this.setState({ loaded: loaded.state.value });
  };
  handleChangeUnLoaded = (unloaded) => {
    this.setState({ unloaded: unloaded.state.value });
  };
  handleChangeWaitingOnFruitVegs = (waitingOnFruitVegs) => {
    this.setState({ waitingOnFruitVegs: waitingOnFruitVegs.state.value });
  };
  handleChangeSeletcAllProcess = (selectAllProcess) => {
    if (selectAllProcess.state.value === true) {
      document.getElementsByClassName("igangon")[0].click();
      document.getElementsByClassName("printeton")[0].click();
      document.getElementsByClassName("pakketon")[0].click();
      document.getElementsByClassName("ikkeprinteton")[0].click();
      document.getElementsByClassName("loadedon")[0].click();
      document.getElementsByClassName("unloadedon")[0].click();
      document.getElementsByClassName("waitingOnFruitVegs")[0].click();
    }
    if (selectAllProcess.state.value === false) {
      document.getElementsByClassName("igangoff")[0].click();
      document.getElementsByClassName("printetoff")[0].click();
      document.getElementsByClassName("pakketoff")[0].click();
      document.getElementsByClassName("ikkeprintetoff")[0].click();
      document.getElementsByClassName("loadedoff")[0].click();
      document.getElementsByClassName("unloadedoff")[0].click();
      document.getElementsByClassName("waitingOnFruitVegs")[0].click();
    }
    this.setState({
      selectAllProcess: selectAllProcess.state.value,
      igang: selectAllProcess.state.value,
      pakket: selectAllProcess.state.value,
      printet: selectAllProcess.state.value,
      ikkePrintet: selectAllProcess.state.value,
      loaded: selectAllProcess.state.value,
      unloaded: selectAllProcess.state.value,
      waitingOnFruitVegs: selectAllProcess.state.value,
    });
  };

  handleChangeingenDagValgt = (ingenDagValgt) => {
    this.setState({ ingenDagValgt: ingenDagValgt.state.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(e);
    //console.log("You have selected:", this.state.selectedOption);
    //console.log("sjælland: " + this.state.sjælland);
    //console.log("jylland: " + this.state.jylland);
    //console.log("fyn: " + this.state.fyn);
    //console.log("ikke printet: " + this.state.ikkePrintet);
    //console.log("printet " + this.state.printet);
    //console.log("pakket " + this.state.pakket);
    //console.log("igang " + this.state.igang);

    if (e) {
      this.setState({
        printing: true,
      });
    }
  };

  printOrBooking = (columnsBooking, grouping) => {
    if (this.state.printing) {
      this.state.listOfId = [];
      //this.state.mytable[i].process
      if (this.state.sjælland === true) {
        //Pakket
        if (this.state.pakket === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Sjælland" &&
              this.state.mytable[i].process === "Packed"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Igang
        if (this.state.igang === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Sjælland" &&
              this.state.mytable[i].process === "In Packing"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Printet
        if (this.state.printet === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Sjælland" &&
              this.state.mytable[i].process === "Printed"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Loaded
        if (this.state.loaded === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Sjælland" &&
              this.state.mytable[i].process === "Loaded"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Unloaded
        if (this.state.unloaded === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Sjælland" &&
              this.state.mytable[i].process === "Unloaded"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Waiting on Fruit & Vegs.
        if (this.state.waitingOnFruitVegs === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Sjælland" &&
              this.state.mytable[i].process === "Waiting on Fruit & Vegs."
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }

        //Ikke Printet
        if (this.state.ikkePrintet === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              (this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Not started") ||
              (this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === null)
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
      }
      // herfra jylland
      if (this.state.jylland === true) {
        //Pakket
        if (this.state.pakket === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Jylland" &&
              this.state.mytable[i].process === "Packed"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Igang
        if (this.state.igang === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Jylland" &&
              this.state.mytable[i].process === "In Packing"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //printet
        if (this.state.printet === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Jylland" &&
              this.state.mytable[i].process === "Printed"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Loaded
        if (this.state.loaded === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Jylland" &&
              this.state.mytable[i].process === "Loaded"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Unloaded
        if (this.state.unloaded === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Jylland" &&
              this.state.mytable[i].process === "Unloaded"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Waiting on Fruit & Vegs.
        if (this.state.waitingOnFruitVegs === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Jylland" &&
              this.state.mytable[i].process === "Waiting on Fruit & Vegs."
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //ikke printet
        if (this.state.ikkePrintet === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              (this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Not started") ||
              (this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === null)
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
      }
      //herfra fyn
      if (this.state.fyn === true) {
        //Pakket
        if (this.state.pakket === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Fyn" &&
              this.state.mytable[i].process === "Packed"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Igang
        if (this.state.igang === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Fyn" &&
              this.state.mytable[i].process === "In Packing"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Printet
        if (this.state.printet === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Fyn" &&
              this.state.mytable[i].process === "Printed"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Loaded
        if (this.state.loaded === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Fyn" &&
              this.state.mytable[i].process === "Loaded"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Unloaded
        if (this.state.unloaded === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Fyn" &&
              this.state.mytable[i].process === "Unloaded"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Waiting on Fruit & Vegs.
        if (this.state.waitingOnFruitVegs === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Fyn" &&
              this.state.mytable[i].process === "Waiting on Fruit & Vegs."
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //ikke Printet
        if (this.state.ikkePrintet === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              (this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Not started") ||
              (this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === null)
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
      }
      //herfra udland
      if (this.state.udland === true) {
        //Pakket
        if (this.state.pakket === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Udland" &&
              this.state.mytable[i].process === "Packed"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Igang
        if (this.state.igang === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Udland" &&
              this.state.mytable[i].process === "In Packing"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Printet
        if (this.state.printet === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Udland" &&
              this.state.mytable[i].process === "Printed"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Loaded
        if (this.state.loaded === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Udland" &&
              this.state.mytable[i].process === "Loaded"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Unloaded
        if (this.state.unloaded === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Udland" &&
              this.state.mytable[i].process === "Unloaded"
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Waiting on Fruit & Vegs.
        if (this.state.waitingOnFruitVegs === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              this.state.mytable[i].region === "Udland" &&
              this.state.mytable[i].process === "Waiting on Fruit & Vegs."
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
        //Ikke Printet
        if (this.state.ikkePrintet === true) {
          for (let i = 0; i < this.state.mytable.length; i++) {
            if (
              (this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Not started") ||
              (this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === null)
            ) {
              this.state.listOfId.push(this.state.mytable[i].id);
            }
          }
        }
      }
      // END

      if (this.state.listOfId) {
        this.props.history.push(`/bookingoverview/bookings/printall`, {
          startDate: this.state.start,
          endDate: this.state.end,
          bookings: this.state.listOfId,
          type: this.state.selectedOption,
          allbookings: this.state.mytable,
          sjælland: this.state.sjælland,
          fyn: this.state.fyn,
          jylland: this.state.jylland,
          udland: this.state.udland,
          pakket: this.state.pakket,
          igang: this.state.igang,
          printet: this.state.printet,
          ikkePrintet: this.state.ikkePrintet,
          waitingOnFruitVegs: this.state.waitingOnFruitVegs,
          loaded: this.state.loaded,
          unloaded: this.state.unloaded,
          selectedOption: this.state.selectedOption,
          selectAllProcess: this.state.selectAllProcess,
          selectAllRegions: this.state.selectAllRegions,
          selectAllWeekDays: this.state.selectAllWeekDays,
        });
        // return (
        //   <Redirect
        //     to={{
        //       pathname: "/bookingoverview/bookings/printall",
        //       state: { startDate: this.state.start },
        //       state: { endDate: this.state.end },
        //       state: { bookings: this.state.listOfId },
        //       state: { type: this.state.selectedOption },
        //       state: { allbookings: this.state.mytable },
        //       state: { sjælland: this.state.sjælland },
        //       state: { fyn: this.state.fyn },
        //       state: { jylland: this.state.jylland },
        //       state: { udland: this.state.udland },
        //       state: { pakket: this.state.pakket },
        //       state: { igang: this.state.igang },
        //       state: { printet: this.state.printet },
        //       state: { ikkePrintet: this.state.ikkePrintet },
        //       state: { loaded: this.state.loaded },
        //       state: { unloaded: this.state.unloaded },
        //       state: { selectedOption: this.state.selectedOption },
        //       state: { selectAllProcess: this.state.selectAllProcess },
        //       state: { selectAllRegions: this.state.selectAllRegions },
        //       state: { selectAllWeekDays: this.state.selectAllWeekDays },
        //     }}
        //   />
        // );
      }
    } else {
      return (
        <BookingsTable
          dataColumn={columnsBooking}
          myData={this.state.mytable}
          grouping={grouping}
          defaultExpandedGroups={this.state.defaultExpandedGroups}
        />
      );
    }
  };

  viewnow = () => {
    if (this.state.selectedOption != null) {
      if (
        this.state.sjælland !== false ||
        this.state.fyn !== false ||
        this.state.jylland !== false ||
        this.state.udland !== false
      ) {
        if (
          this.state.printet !== false ||
          this.state.ikkePrintet !== false ||
          this.state.igang !== false ||
          this.state.pakket !== false ||
          this.state.waitingOnFruitVegs !== false ||
          this.state.unloaded !== false ||
          this.state.loaded !== false
        ) {
          return (
            <Button
              type="submit"
              style={{ marginLeft: "auto", backgroundColor: "#1BEB11" }}
              onClick={this.toggleModalDemo}
            >
              View
            </Button>
          );
        }
      }
    }

    if (
      this.state.sjælland === false ||
      this.state.fyn === false ||
      this.state.jylland === false ||
      this.state.udland === false ||
      this.state.sendt === false ||
      this.state.klar === false ||
      this.state.printet === false ||
      this.state.ikkePrintet === false ||
      this.state.igang === false ||
      this.state.pakket === false ||
      this.state.mandag === false ||
      this.state.tirsdag === false ||
      this.state.onsdag === false ||
      this.state.torsdag === false ||
      this.state.fredag === false ||
      this.state.lordag === false ||
      this.state.sondag === false ||
      this.state.waitingOnFruitVegs === false ||
      this.state.ingenDagValgt === false ||
      this.state.selectedOption === null
    ) {
      return (
        <div style={{ fontSize: 14 }}>
          <p className="italic">
            {" "}
            Choose one or more region, Proccess, Pakke dag & printing type to
            view
          </p>
        </div>
      );
    }
  };

  openDateRange = () => {
    this.setState({ datePickerHandler: true });
  };

  closeDateRange = () => {
    this.setState({ datePickerHandler: false });
  };

  customRange = () => {
    if (this.state.datePickerHandler) {
      return (
        <div>
          <RangePicker
            placeholderText={"Select date"}
            onDateSelected={this.onDateChanges}
            onClose={this.onDateChanges}
          />
        </div>
      );
    }
  };
  onDateChanges = (date, date2) => {
    document.getElementById("searchBar").value = "";
    if (date && date2) {
      var startMonth = date.getMonth() + 1;
      var endMonth = date2.getMonth() + 1;
      var start = null;
      start = date.getFullYear() + "-" + startMonth + "-" + date.getDate();
      var end = null;
      end = date2.getFullYear() + "-" + endMonth + "-" + date2.getDate();
    }
    if (start != null && end != null) {
      this.setState({ start: start, end: end });
      let myArr = new Array();

      apiFacade
        .getData("/bookings?start=" + start + "&end=" + end)
        .then((data) => {
          data.map((data) => {
            if (
              data.type !== "Den virtuelle ginrejse" &&
              data.type !== "Virtuelt cocktailkursus" &&
              data.type !== "Den virtuelle ølsmagning"
            ) {
              myArr.push({
                id: data.id,
                companyName: data.companyName,
                date: data.date,
                type: data.type,
                bartenders: data.bartenders,
                barModules: data.barModules,
                duration: data.duration,
                region: data.region,
                persons: data.persons,
                process: data.process,
                pakkedag: data.pakkeDag,
                isLoading: false,
                dateEnd: data.dateEnd,
              });
            }
          });

          this.setState({ isLoading: false });
          return myArr;
        })
        .then((myArr) => {
          this.setState({ mytable: myArr });
        });
      this.setState({ datePickerHandler: false });
    }
  };

  refreshBtn = () => {
    if (this.state.start != null) {
      return window.location.replace(
        "https://pakkemodelapi.azurewebsites.net/api/updatebookings/UpdateRange?start=" +
          this.state.start +
          "&end=" +
          this.state.end +
          "&redirect=true"
      );
    } else {
      return window.location.replace(
        "https://pakkemodelapi.azurewebsites.net/api/updatebookings/UpdateRange?start=" +
          this.startDate(0) +
          "&end=" +
          this.state.end +
          "&redirect=true"
      );
    }
  };

  handleSearchBar = (event) => {
    if (this.state.searchInput != null) {
      this.setState({ mytable: [] });
      this.setState({ tempInput: this.state.searchInput });
      apiFacade
        .getData("/bookings/getbookingbyid/" + this.state.searchInput)
        .then((data) => {
          this.setState({ bookings: data });
          if (data != null) {
            if (
              data.type != "Den virtuelle ginrejse" &&
              data.type != "Virtuelt cocktailkursus" &&
              data.type != "Den virtuelle ølsmagning"
            ) {
              this.setState({ error: 0 });
              this.state.mytable.push({
                id: data.id,
                companyName: data.companyName,
                date: data.date,
                type: data.type,
                bartenders: data.bartenders,
                barModules: data.barModules,
                duration: data.duration,
                region: data.region,
                persons: data.persons,
                isLoading: false,
                dateEnd: data.dateEnd,
                barbacks: data.barbacks,
                waiters: data.waiters,
                industrialDishwasher: data.industrialDishwasher,
                process: data.process,
              });
            } else {
              this.setState({ error: 3 });
              this.setState({ isItVirtual: true });
            }
            this.setState({ isLoading: false });
          }
        })
        .catch(() => {
          this.setState({ error: 2 });
          setTimeout(() => {
            this.setState({ error: 0 });
            this.setState({ mytable: this.state.tempMyTable });
            document.getElementById("searchBar").value = "";
            this.setState({ buttonDisabled: true });
          }, 4000);
        });
    }
    event.preventDefault();
  };

  handleChangeSearchBar = (event) => {
    this.setState({ searchInput: event.target.value });
    if (event.target.value.length > 2 && event.target.value.length < 6) {
      this.setState({ buttonDisabled: false });
    } else if (event.target.value === "" && this.state.tempMyTable.length > 0) {
      this.setState({ mytable: this.state.tempMyTable });
      this.setState({ error: 0 });
      this.setState({ buttonDisabled: true });
    } else if (event.target.value === "" && this.state.isItVirtual === true) {
      this.setState({ error: 0 });
      this.setState({ isItVirtual: false });
      this.setState({ buttonDisabled: true });
    } else if (event.target.value.length < 3 || event.target.value.length > 5) {
      this.setState({ buttonDisabled: true });
    }
  };

  returnBookingMsg = (error) => {
    if (error === 3) {
      return (
        <Alert style={bookingStyle.stuck2} severity="error">
          <AlertTitle>
            This booking: {this.state.tempInput} is a Virtuel booking, do you
            want to be redirected?
            <Button
              style={{ marginLeft: "5%", backgroundColor: "#d16557" }}
              size="sm"
              onClick={() => {
                this.props.history.push(
                  "/bookingoverview/vbookings/" + this.state.tempInput
                );
              }}
            >
              Yes redirect me
            </Button>
          </AlertTitle>
        </Alert>
      );
    } else {
      return updateBookingMsg.updateBookingMsg(
        this.props.booking.updateBookingById,
        this.props.booking.bookingId,
        this.state.updateBookingByDate,
        this.state.start,
        this.state.end,
        this.state.error,
        this.state.searchInput,
        this.state.errorStuck
      );
    }
  };

  render() {
    //let value = document.getElementById("option1").value;
    const columns = [
      {
        title: "Id",
        render: (rowData) => {
          return (
            <div style={bookingStyle.cellStyling}>
              <a
                href={
                  "https://podio.com/fridaygroup/intranet/apps/bookinger/items/" +
                  rowData.id
                }
              >
                {rowData.id}
              </a>
            </div>
          );
        },
        /*    field: 'id',
                   type: 'numeric',
                   headerStyle: bookingStyle.cellStyling */
      },
      {
        title: "Company Name",
        field: "companyName",
      },
      {
        title: "Date",
        render: (rowData) => {
          return (
            <div>
              <Moment format="YYYY/MM/DD HH:mm">{rowData.date} </Moment>
            </div>
          );
        },
      },
      {
        title: "Type",
        field: "type",
        cellStyle: bookingStyle.cellStyling,
      },
      {
        title: "Persons",
        field: "persons",
        cellStyle: bookingStyle.cellStyling,
      },
      {
        title: "Bartenders",
        field: "bartenders",
        cellStyle: bookingStyle.cellStyling,
      },
      {
        title: "BarModules",
        field: "barModules",
        headerStyle: bookingStyle.cellStyling,
        cellStyle: bookingStyle.cellStyling,
      },
      {
        title: "Menu",
        field: "menuNames",
        headerStyle: bookingStyle.cellStyling,
        cellStyle: bookingStyle.cellStyling,
      },
      {
        title: "Duration",
        render: (rowData) => {
          return <div>{rowData.duration} Hours</div>;
        },
        cellStyle: bookingStyle.cellStyling,
      },
      {
        title: "Region",
        field: "region",
        defaultGroupOrder: 1,
        defaultGroupSort: "desc",
      },
    ];

    // const columnsBooking = [
    //   { name: "id", title: "Id" },
    //   { name: "companyName", title: "Company Name" },
    //   { name: "date", title: "Date" },
    //   { name: "type", title: "Type" },
    //   { name: "persons", title: "Persons" },
    //   { name: "duration", title: "Duration" },
    //   { name: "process", title: "Process" },
    //   { name: "region", title: "Region" },
    //   { name: "view", title: "Actions" },
    // ];
    const myActions = [
      {
        icon: "info",
        tooltip: "Show Booking Info",
        onClick: (event, rowData) => {
          this.props.history.push("/bookingoverview/bookings/" + rowData.id);
        },
      },
    ];
    const grouping = [{ columnName: "region" }];
    const tableColumnExtensions = [{ columnName: "name", width: 800 }];
    const { anchorE1, anchorE2 } = this.state;
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12} md={4}>
                  <Card style={{ minHeight: "130px" }}>
                    <CardBody>
                      <Form onSubmit={this.handleSearchBar}>
                        <Col xs={12} md={12}>
                          <FormGroup>
                            <Input
                              onChange={this.handleChangeSearchBar}
                              id="searchBar"
                              name="sb"
                              placeholder="Booking id"
                              type="number"
                              min="0"
                              max="99999"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={12}>
                          <Button
                            style={{
                              width: "100%",
                              backgroundColor: "#d16557",
                            }}
                            disabled={this.state.buttonDisabled}
                            type="submit"
                          >
                            Search
                          </Button>
                        </Col>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={4}>
                  <Card style={{ minHeight: "130px" }}>
                    <CardBody>
                      <Button
                        style={{
                          width: "100%",
                          marginTop: "5%",
                          backgroundColor: "#d16557",
                        }}
                        size="md"
                        onClick={this.toggleModalDemo}
                      >
                        <Print /> &nbsp; Print All
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={4}>
                  <Card style={{ minHeight: "130px" }}>
                    <CardBody>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          caret
                          style={{ width: "100%", backgroundColor: "#d16557" }}
                        >
                          <Moment format="YYYY/MM/DD">
                            {this.state.start}
                          </Moment>{" "}
                          &nbsp;
                          <Moment /* add={{ days: 7 }} */ format="YYYY/MM/DD">
                            {this.state.end}
                          </Moment>
                        </DropdownToggle>
                        <DropdownMenu style={bookingStyle.dropdownmenustyle}>
                          <DropdownItem
                            style={{ color: "#d16557" }}
                            onClick={this.updateTable}
                            id="14days"
                          >
                            Next 14 Days
                          </DropdownItem>
                          <DropdownItem
                            style={{ color: "#d16557" }}
                            onClick={this.updateTable}
                            id="7days"
                          >
                            Next 7 Days
                          </DropdownItem>
                          <DropdownItem
                            style={{ color: "#d16557" }}
                            onClick={this.updateTable}
                            id="tomorrow"
                          >
                            Tomorrow
                          </DropdownItem>
                          <DropdownItem
                            style={{ color: "#d16557" }}
                            onClick={this.updateTable}
                            id="today"
                          >
                            Today
                          </DropdownItem>
                          <DropdownItem
                            style={{ color: "#d16557" }}
                            onClick={this.updateTable}
                            id="yesterday"
                          >
                            Yesterday
                          </DropdownItem>
                          <DropdownItem
                            style={{ color: "#d16557" }}
                            onClick={this.updateTable}
                            id="last7days"
                          >
                            Last 7 days
                          </DropdownItem>
                          <DropdownItem
                            style={{ color: "#d16557" }}
                            onClick={this.updateTable}
                            id="last14days"
                          >
                            Last 14 days
                          </DropdownItem>
                          <DropdownItem
                            style={{ color: "#d16557" }}
                            onClick={this.openDateRange}
                          >
                            Custom Range
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {this.customRange()}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={this.state.updateStuck}>
            <Fab
              disabled={this.state.updateBookingByDate}
              style={{
                backgroundColor: "#d16557",
                color: "#FFFFFF",
              }}
              aria-label="add"
              onClick={() => {
                apiFacade
                  .getData(
                    "/Updatebookings/UpdateBookingsByDate/?start=" +
                      this.state.start +
                      "&end=" +
                      this.state.end
                  )
                  .then((data) => {
                    if (data === "OK") {
                      //console.log(data + " _______________O::K___");
                      this.setState({
                        updateBookingByDate: true,
                      });
                      setTimeout(() => {
                        this.setState({
                          updateBookingByDate: false,
                        });
                      }, 8000);
                    }
                  });
              }}
            >
              <UpdateRoundedIcon style={{ fontSize: 40 }} />
            </Fab>
            <div style={{ marginLeft: "-10%" }}>Update All</div>
          </div>
          <Row>
            <Col xs="auto" md={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h4">
                    <Row>
                      <Col xs={12} md={6}>
                        Bookings
                      </Col>
                      <Col xs={12} md={6}>
                        {this.returnBookingMsg(this.state.error)}
                      </Col>
                      <Col></Col>
                    </Row>
                  </CardTitle>
                  <Modal
                    isOpen={this.state.modalDemo}
                    toggle={this.toggleModalDemo}
                    size="lg"
                    style={{ maxWidth: "800px", width: "100%" }}
                  >
                    <form name="printall" onSubmit={this.handleSubmit}>
                      <ModalHeader
                        className="justify-content-center"
                        toggle={this.toggleModalDemo}
                      >
                        Print All
                      </ModalHeader>
                      <ModalBody>
                        <Row>
                          <Col xs={12} md={2}>
                            <p>Regions</p>
                            <p
                              className="category"
                              style={{ color: "#4c7093", fontSize: "12px" }}
                            >
                              Select All
                            </p>
                            <Switch
                              onText={<i className="now-ui-icons ui-1_check" />}
                              onColor="custom"
                              offText={
                                <i className="now-ui-icons ui-1_simple-remove" />
                              }
                              checked={this.state.selectAllRegions}
                              onChange={this.handleChangeSelectAllRegions}
                              defaultValue={this.state.selectAllRegions}
                              id="1"
                            />
                            <p className="category">Sjælland</p>
                            <Switch
                              onText={<i className="sjallandoff" />}
                              onColor="custom"
                              offText={<i className="sjallandon" />}
                              checked={this.state.sjælland}
                              onChange={this.handleChangeSjælland}
                              defaultValue={this.state.sjælland}
                              id="1"
                            />
                            <p className="category">Jylland</p>
                            <Switch
                              onText={<i className="jyllandoff" />}
                              onColor="custom"
                              offText={<i className="jyllandon" />}
                              checked={this.state.jylland}
                              onChange={this.handleChangeJylland}
                              defaultValue={this.state.jylland}
                              id="2"
                            />
                            <p className="category">Fyn</p>
                            <Switch
                              onText={<i className="fynoff" />}
                              onColor="custom"
                              offText={<i className="fynon" />}
                              checked={this.state.fyn}
                              onChange={this.handleChangeFyn}
                              defaultValue={this.state.fyn}
                              id="3"
                            />
                            <p className="category">Udland</p>
                            <Switch
                              onText={<i className="udlandoff" />}
                              onColor="custom"
                              offText={<i className="udlandon" />}
                              checked={this.state.udland}
                              onChange={this.handleChangeUdland}
                              defaultValue={this.state.udland}
                              id="4"
                            />
                          </Col>
                          <Col xs={12} md={2}>
                            <p>Process</p>
                            <p
                              className="category"
                              style={{ color: "#4c7093", fontSize: "12px" }}
                            >
                              Select All
                            </p>
                            <Switch
                              onText={<i className="now-ui-icons ui-1_check" />}
                              onColor="custom"
                              offText={
                                <i className="now-ui-icons ui-1_simple-remove" />
                              }
                              checked={this.state.selectAllProcess}
                              onChange={this.handleChangeSeletcAllProcess}
                              defaultValue={this.state.selectAllProcess}
                              id="1"
                            />
                            <p
                              className="category"
                              style={{ color: "#e2b659", fontSize: "12px" }}
                            >
                              Packed
                            </p>
                            <Switch
                              onText={<i className="pakketoff" />}
                              onColor="custom"
                              //animate={false}
                              offText={<i className="pakketon" />}
                              checked={this.state.pakket}
                              onChange={this.handleChangePakket}
                              defaultValue={this.state.pakket}
                              id="Packed"
                            />
                            <p
                              className="category"
                              style={{ color: "#ff80a8", fontSize: "12px" }}
                            >
                              In Packing
                            </p>
                            <Switch
                              onText={<i className="igangoff" />}
                              onColor="custom"
                              offText={<i className="igangon" />}
                              checked={this.state.igang}
                              onChange={this.handleChangeIgang}
                              defaultValue={this.state.igang}
                              id="igang"
                            />
                            <p
                              className="category"
                              style={{ color: "#ff0000", fontSize: "12px" }}
                            >
                              Printed
                            </p>
                            <Switch
                              onText={<i className="printetoff" />}
                              onColor="custom"
                              offText={<i className="printeton" />}
                              checked={this.state.printet}
                              onChange={this.handleChangePrintet}
                              defaultValue={this.state.printet}
                              id="printet"
                            />
                            <p
                              className="category"
                              style={{ fontSize: "12px" }}
                            >
                              Not started
                            </p>
                            <Switch
                              onText={<i className="loadedoff" />}
                              onColor="custom"
                              offText={<i className="loadedon" />}
                              checked={this.state.loaded}
                              onChange={this.handleChangeLoaded}
                              defaultValue={this.state.loaded}
                              id="loaded"
                            />
                            <p
                              className="category"
                              style={{ fontSize: "12px" }}
                            >
                              Loaded
                            </p>
                            <Switch
                              onText={<i className="unloadedoff" />}
                              onColor="custom"
                              offText={<i className="unloadedon" />}
                              checked={this.state.unloaded}
                              onChange={this.handleChangeUnLoaded}
                              defaultValue={this.state.unloaded}
                              id="unloaded"
                            />
                            <p
                              className="category"
                              style={{ fontSize: "12px" }}
                            >
                              Unloaded
                            </p>
                            <Switch
                              onText={<i className="ikkeprintetoff" />}
                              onColor="custom"
                              offText={<i className="ikkeprinteton" />}
                              checked={this.state.ikkePrintet}
                              onChange={this.handleChangeIkkePrintet}
                              defaultValue={this.state.ikkePrintet}
                              id="ikkeprintet"
                            />{" "}
                            <p
                              className="category"
                              style={{ fontSize: "12px" }}
                            >
                              Waiting On Fruit & Vegs.
                            </p>
                            <Switch
                              onText={<i className="waitingOnFruitVegs" />}
                              onColor="custom"
                              offText={<i className="waitingOnFruitVegs" />}
                              checked={this.state.waitingOnFruitVegs}
                              onChange={this.handleChangeWaitingOnFruitVegs}
                              defaultValue={this.state.waitingOnFruitVegs}
                              id="waitingOnFruitVegs"
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <p>Printing type</p>
                            <FormGroup check className="form-check-radio">
                              <p className="category">
                                {" "}
                                only one type at the time
                              </p>
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="1"
                                  id="option1"
                                  name="radio"
                                  type="radio"
                                  checked={this.state.selectedOption === "1"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Packing List</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="2"
                                  id="2"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "2"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Front Pages</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="11"
                                  id="11"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "11"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Total list</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="4"
                                  id="4"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "4"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">SpecSheet</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="7"
                                  id="7"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "7"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Returliste</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="danger" onClick={this.toggleModalDemo}>
                          Close
                        </Button>
                        {this.viewnow()}
                      </ModalFooter>
                    </form>
                  </Modal>
                  {/* alle skal ikke kunne edit print menu */}
                  {this.editprintmenu()}
                </CardHeader>
                <CardBody>
                  {/* <div style={bookingStyle.stuck}>
                    <IconButton color="primary" onClick={this.refreshBtn}>
                      <SyncRoundedIcon style={{ fontSize: 40 }} />
                    </IconButton>
                  </div> */}
                  {this.printOrBooking(this.state.columnsBooking, grouping)}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { booking } = state;
  return { booking: booking };
}
// function mapDispatchToProps(dispatch) {
//   return {
//     updateBookingByDate: bindActionCreators(updateBookingByDate, dispatch),
//   };
// }

export default connect(mapStateToProps)(Bookings);
//export default connect(mapStateToProps, mapDispatchToProps)(Bookings);

{
  /*  <BookingsTable rows={this.state.mytable} columns={columnsDemo} /> */
}
{
  /* <Demo dataColumn={columnsBooking} myData={this.state.mytable} grouping={grouping} defaultExpandedGroups={this.state.defaultExpandedGroups} />
   */
}
