import * as React from "react";

import {
  GroupingState,
  IntegratedGrouping,
  IntegratedSorting,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  GroupingPanel,
  Table,
  TableGroupRow,
  TableHeaderRow,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import BookingCell from "./BookingCell";

export default class BookingsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const tableColumnExtensions = [
      { columnName: "companyName" },
      { columnName: "id" },
      { columnName: "menuNames" },
      { columnName: "type" },
      { columnName: "persons" },
      { columnName: "date" },
      { columnName: "bartenders" },
      { columnName: "duration" },
      { columnName: "process" },
    ];
    if (this.props.myData) {
      return (
        <Grid key={this.props.myData} rows={this.props.myData} columns={this.props.dataColumn}>
          <SortingState
            defaultSorting={[{ columnName: "region", direction: "desc" }]}
          />
          <GroupingState 
            grouping={this.props.grouping}
            defaultExpandedGroups={this.props.defaultExpandedGroups}
          />
          <IntegratedGrouping />
          <IntegratedSorting />

          <DragDropProvider />
          <Table
            columnExtensions={tableColumnExtensions}
            cellComponent={BookingCell}
          />
          <TableHeaderRow showSortingControls={true} />
          <TableGroupRow />
          <Toolbar />
          <GroupingPanel showSortingControls={true}  />
        </Grid>
      );
    } else {
      return <div>Loading</div>;
    }
  }
}
