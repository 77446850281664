import React from "react";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import { persistor } from "../redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { CustomNavigationClient } from "../utils/NavigationClient";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import { useSelector } from "react-redux";
import routes from "routes.js";
import packerRoutes from "packerRoutes.js";

function App({ pca }) {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);
  const { loginPacker } = useSelector((state) => state.pakker);

  let user = JSON.parse(localStorage.getItem("user"));

  const isSpecs = () => {
    if (window.location.pathname === "/specs") {
      return (
        <Switch>
          <Route
            path="/specs"
            render={(props) => {
              return <AuthLayout {...props} />;
            }}
          />
          <Redirect to="/specs" />
        </Switch>
      );
    } else if (user !== null) {
      return (
        <MsalProvider instance={pca}>
          <PersistGate loading={<div>loading</div>} persistor={persistor}>
            <Switch>
              <Route
                path="/"
                render={(props) => {
                  return (
                    <AdminLayout
                      {...props}
                      loginPacker={loginPacker}
                      routes={packerRoutes}
                      landingPage={"/assignments"}
                    />
                  );
                }}
              />
              <Redirect to="/" />
            </Switch>
          </PersistGate>
        </MsalProvider>
      );
    } else {
      return (
        <Switch>
          <Route
            path="/login"
            render={(props) => {
              return <AuthLayout {...props} />;
            }}
          />
          <Redirect to="/login" />
        </Switch>
      );
    }
  };

  return (
    <MsalProvider instance={pca}>
      <PersistGate loading={<div>loading</div>} persistor={persistor}>
        <AuthenticatedTemplate>
          <Switch>
            <Route
              path="/"
              render={(props) => {
                return (
                  <AdminLayout
                    {...props}
                    routes={routes}
                    landingPage={"/bookingoverview"}
                  />
                );
              }}
            />
            <Redirect to="/" />
          </Switch>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>{isSpecs()}</UnauthenticatedTemplate>
      </PersistGate>
    </MsalProvider>
  );
}

export default App;
