import React, { Component } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import PanelHeader from "../../../../components/PanelHeader/PanelHeader.js";
import CardCategory from "../../../../components/CardElements/CardCategory.jsx"
import Button from "../../../../components/CustomButton/CustomButton.jsx"

import Switch from "react-bootstrap-switch";
import apiFacade from "../../../../auth/apiFacade";
import "../../../../assets/css/checkboks.css";
import PrintAllFunc from "./PrintAllFunc";
import PackinglistAll from "./PackinglistAll";
import ReturlistAll from "./ReturlistAll";
import FrontpageAll from "./FrontpageAll";
import Weekplan from "./Weekplan";
import TotalComponenets from "./TotalComponenets";

const printOption = ({
  listOfId,
  sjælland,
  jylland,
  fyn,
  selectedOption,
  refreshApi,
  componentDtos,
  sortingList,
  startDate,
  endDate,
  listOfsamplings
}) => {
  if (
    selectedOption == 1 &&
    (fyn != false) | (jylland != false) | (sjælland != false)
  ) {
    return (
      <div>
        <h3>Packing list</h3>
        <PackinglistAll
          listOfId={listOfId}
          refreshApi={refreshApi}
          componentDtos={componentDtos}
          sortingList={sortingList}
          listOfsamplings={listOfsamplings}
        />
      </div>
    );
  }
  if (
    selectedOption == 2 &&
    (fyn != false) | (jylland != false) | (sjælland != false)
  ) {
    return (
      <div>
        <h3>Frontpage</h3>
        <FrontpageAll listOfId={listOfId} refreshApi={refreshApi} />
      </div>
    );
  }
  if (
    selectedOption == 3 &&
    (fyn != false) | (jylland != false) | (sjælland != false)
  ) {
    return (
      <div>
        <h3>Returlist</h3>
        <ReturlistAll
          listOfId={listOfId}
          refreshApi={refreshApi}
          componentDtos={componentDtos}
          sortingList={sortingList}
          listOfsamplings={listOfsamplings}
        />
      </div>
    );
  }
  if (
    selectedOption == 5 &&
    (fyn != false) | (jylland != false) | (sjælland != false)
  ) {
    return (
      <div>
        <h3>Weekplan</h3>
        <Weekplan
          listOfId={listOfId}
          refreshApi={refreshApi}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    );
  }
  if (
    selectedOption == 6 &&
    (fyn != false) | (jylland != false) | (sjælland != false)
  ) {
    return (
      <div>
        <h3>Total</h3>
        <TotalComponenets
          listOfId={listOfId}
          refreshApi={refreshApi}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    );
  }
  if (fyn == false && jylland == false && sjælland == false) {
  }
};

export default class PrintAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      printing: false,
      sjælland: this.props.location.state.sjælland,
      jylland: this.props.location.state.jylland,
      fyn: this.props.location.state.fyn,
      mytable: this.props.location.state.allsamlings,
      selectedOption: this.props.location.state.selectedOption,
      modalDemo: false,
      bookings: [],
      isLoading: true,
      listOfId: this.props.location.state.samplings,
      refreshApi: true,
      sortingList: [],
      componentDtos: [],
      startDate: this.props.location.state.startDate,
      endDate: this.props.location.state.endDate,
      listOfsamplings: this.props.location.state.allsamlings
    };
  }

  printType = () => {
    this.setState({ refreshApi: true });

    this.state.listOfId.length = 0;

    if (this.state.sjælland === true) {
      for (let i = 0; i < this.state.mytable.length; i++) {
        if (this.state.mytable[i].region === "Sjælland") {
          this.state.listOfId.push(this.state.mytable[i].Id);
        }
      }
    }

    if (this.state.jylland === true) {
      for (let i = 0; i < this.state.mytable.length; i++) {
        if (this.state.mytable[i].region === "Jylland") {
          this.state.listOfId.push(this.state.mytable[i].Id);
        }
      }
    }

    if (this.state.fyn === true) {
      for (let i = 0; i < this.state.mytable.length; i++) {
        if (this.state.mytable[i].region === "Fyn") {
          this.state.listOfId.push(this.state.mytable[i].Id);
        }
      }
    }

    this.setState({ printing: true });

  };

  handleOptionChange = changeEvent => {
    this.setState({
      selectedOption: changeEvent.target.value,
      refreshApi: false
    });
  };
  handleChangeFyn = fyn => {
    this.setState({ fyn: fyn.state.value, refreshApi: false });
  };
  handleChangeSjælland = sjælland => {
    this.setState({ sjælland: sjælland.state.value, refreshApi: false });
  };
  handleChangeJylland = jylland => {
    this.setState({ jylland: jylland.state.value, refreshApi: false });
  };
  handleSubmit = e => {
    e.preventDefault();
    // console.log(e);
    // console.log("You have selected:", this.state.selectedOption);
    // console.log("sjælland: " + this.state.sjælland);
    // console.log("jylland: " + this.state.jylland);
    // console.log("fyn: " + this.state.fyn);
  };
  viewnow = () => {
    if (this.state.selectedOption != null) {
      if (
        this.state.sjælland != false ||
        this.state.fyn != false ||
        this.state.jylland != false
      ) {
        return (
          <Button
            color="success"
            type="submit"
            style={{ marginLeft: "auto" }}
            onClick={this.printType}
          >
            View
          </Button>
        );
      }
    }

    if (
      this.state.sjælland == false ||
      this.state.fyn == false ||
      this.state.jylland == false ||
      this.state.selectedOption == null
    ) {
      return (
        <div style={{ fontSize: 14 }}>
          <p className="italic">
            {" "}
            Choose one or more region & printing type to view
          </p>
        </div>
      );
    }
  };

  render() {
    if (this.state.isLoading == false) {
    }
    return (
      <div>
        <PanelHeader size="sm" />

        <div className="content">
          <Row>
            <Col md={9} className="mr-auto">
              <Card className="card-chart ">
                <CardHeader>
                  <CardCategory>Print all</CardCategory>
                </CardHeader>
                <CardBody> {printOption(this.state)}</CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="card-chart ">
                <CardHeader>
                  <CardCategory>Menu</CardCategory>
                </CardHeader>
                <CardBody>
                  <form name="printall" onSubmit={this.handleSubmit}>
                    <Row>
                      <Col xs={12} md={4}>
                        <p>Regions</p>
                        <p className="category">Sjælland</p>
                        <Switch
                          onText={<i className="now-ui-icons ui-1_check" />}
                          onColor="custom"
                          offText={
                            <i className="now-ui-icons ui-1_simple-remove" />
                          }
                          checked={this.state.sjælland}
                          onChange={this.handleChangeSjælland}
                          defaultValue={this.state.sjælland}
                          id="1"
                        />
                        <p className="category">Jylland</p>
                        <Switch
                          onText={<i className="now-ui-icons ui-1_check" />}
                          onColor="custom"
                          offText={
                            <i className="now-ui-icons ui-1_simple-remove" />
                          }
                          checked={this.state.jylland}
                          onChange={this.handleChangeJylland}
                          defaultValue={this.state.jylland}
                          id="2"
                        />
                        <p className="category">Fyn</p>
                        <Switch
                          onText={<i className="now-ui-icons ui-1_check" />}
                          onColor="custom"
                          offText={
                            <i className="now-ui-icons ui-1_simple-remove" />
                          }
                          checked={this.state.fyn}
                          onChange={this.handleChangeFyn}
                          defaultValue={this.state.fyn}
                          id="3"
                        />
                      </Col>
                      <Col xs={12} md={8}>
                        <p>Printing type</p>

                        <FormGroup check className="form-check-radio">
                          <p className="category"> only one type at the time</p>
                          <Label className="mycontainer">
                            <Input
                              className="checkmark"
                              defaultValue="1"
                              id="option1"
                              name="radio"
                              type="radio"
                              checked={this.state.selectedOption === "1"}
                              onChange={this.handleOptionChange}
                            />
                            <p>Packing List</p> <span className="checkmark" />
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio">
                          <Label className="mycontainer">
                            <Input
                              className="checkmark"
                              defaultValue="2"
                              id="2"
                              name="exampleRadios"
                              type="radio"
                              checked={this.state.selectedOption === "2"}
                              onChange={this.handleOptionChange}
                            />
                            <p>Front Pages</p> <span className="checkmark" />
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio">
                          <Label className="mycontainer">
                            <Input
                              className="checkmark"
                              defaultValue="3"
                              id="3"
                              name="exampleRadios"
                              type="radio"
                              checked={this.state.selectedOption === "3"}
                              onChange={this.handleOptionChange}
                            />
                            <p>Returlist</p> <span className="checkmark" />
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio">
                          <Label className="mycontainer">
                            <Input
                              className="checkmark"
                              defaultValue="5"
                              id="5"
                              name="exampleRadios"
                              type="radio"
                              checked={this.state.selectedOption === "5"}
                              onChange={this.handleOptionChange}
                            />
                            <p>Weekplan</p> <span className="checkmark" />
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio">
                          <Label className="mycontainer">
                            <Input
                              className="checkmark"
                              defaultValue="6"
                              id="6"
                              name="exampleRadios"
                              type="radio"
                              checked={this.state.selectedOption === "6"}
                              onChange={this.handleOptionChange}
                            />
                            <p>Total</p> <span className="checkmark" />
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div>
                      "Press on View to update if regions has been changed"
                    </div>
                    <div style={{ display: "flex" }}>{this.viewnow()}</div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
