import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { msalConfig } from "./authConfig";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStroopwafel } from "@fortawesome/free-solid-svg-icons";
import HttpsRedirect from "react-https-redirect";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v=1.5.0";
import "assets/css/demo.css";
import App from "./views/App";

const container = document.getElementById('root');

const root = createRoot(container);

const hist = createBrowserHistory();

library.add(faStroopwafel);

export const msalInstance = new PublicClientApplication(msalConfig);

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

root.render(
  <HttpsRedirect>
    <Provider store={store}>
      <PersistGate loading={<div>loading</div>} persistor={persistor}>
        <BrowserRouter history={hist}>
          <App pca={msalInstance} />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </HttpsRedirect>
);

{
  /* <Switch>
  <Route
    path="/admin"
    render={(props) => {
      return <AdminLayout {...props} />;
    }}
  />
  <Route
    path="/auth"
    render={(props) => {
      return <AuthLayout {...props} />;
    }}
  />
  <Redirect to="/auth" />
</Switch>; */
}

// function Display() {
//   console.log("DISPLAY!!!!!!!!!!!!!!!!!!!!!!!");
//   const isAuthenticated = useIsAuthenticated();
//   if (isAuthenticated) {
//     console.log("admin");
//     return (
//       <Switch>
//         <Route
//           path="/dashboard"
//           render={(props) => {
//             return <AdminLayout {...props} />;
//           }}
//         />
//         <Redirect to="/dashboard" />
//       </Switch>
//     );
//   } else {
//     console.log("auth");
//     return (
//       <Switch>
//         <Route
//           path="/login-page"
//           render={(props) => {
//             return <AdminLayout {...props} />;
//           }}
//         />
//         <Redirect to="/login-page" />
//       </Switch>

//       // <Switch>
//       //   <Route
//       //     path="/"
//       //     render={(props) => {
//       //       return <AuthLayout {...props} />;
//       //     }}
//       //   />
//       //   {/* <Redirect to="/auth" /> */}
//       // </Switch>
//     );
//   }
// }
