import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Bookings from "./Bookings";
import Booking from "./Booking";
import apiFacade from "../../auth/apiFacade";
import Previewer from "./Previewer";
import Packinglistprint from "./Print/Packinglistprint";
import Packinglistprintvck from "./Print/Packinglistvck";
import Frontpage from "./Print/Frontpage";
import Specsheet from "./Print/Specsheet";
import SpecsheetAll from "./Print/PrintAll/SpecsheetAll";
import Printall from "./Print/PrintAll/Printall";
import EditMenu from "./Editmenu/EditMenu";
import EditPackingA from "./EditPacking/EditPackingA";
import Returliste from "./Print/Returliste";
import Menu from "./Print/Menukort";
import Vckbookings from "../VckBookings/Vckbookings";
import Vbooking from "../VckBookings/Vbooking";
import Bookingoverview from "./BookingOverview";
import Printallvck from "./Print/PrintAll/Printallvck";

export default class Bookingsview extends Component {
  editprintmenu = () => {
    if (
      apiFacade.oid === "51db0d9a-5a9a-4644-b47f-71c3ad07e5b3" ||
      apiFacade.oid === "a754573a-7733-4ad8-b707-ef626d898d34" ||
      apiFacade.oid === "20c437e4-a2dc-4916-8ffe-78e25badc009" ||
      apiFacade.oid === "31183aa9-7e72-4233-9a92-308e158e37f6" ||
      apiFacade.oid === "196e4a83-285d-4e96-86c2-d5ccbc195f76" ||
      apiFacade.oid === "753576b8-abf1-4554-b8e6-263ecc544cf1" ||
      apiFacade.oid === "dba61e47-a124-45dd-be07-9c5a5da7d0ae" ||
      apiFacade.oid === "d8021541-5857-4045-adfc-cba691113edd" ||
      apiFacade.oid === "34bc5d2c-6a3a-4462-be4b-109622e7b4ff" ||
      apiFacade.oid === "ef2331e0-89e7-4564-998b-3873100afc4d" ||
      apiFacade.oid === "a1f7c4e7-3597-42d3-b461-0d3592ab9c8a" ||
      apiFacade.oid === "f2d8dbf3-e2e8-4aa5-8e77-d3c8a99ccb5f"
    ) {
      return (
        //<Route path='/bookings/editpacking' render={(props) => <Editpacking {...props} />} />
        <Route
          path="/bookingoverview/bookings/edit"
          render={(props) => <EditPackingA {...props} />}
        />
      );
    } else {
      return (
        //<Route path='/bookings/editpacking' render={(props) => <Bookings {...props} />} />
        <Route
          path="/bookingoverview/bookings/edit"
          render={(props) => <Bookings {...props} />}
        />
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            exact
            path="/bookingoverview"
            render={(props) => <Bookingoverview {...props} />}
          />
          <Route
            exact
            path="/bookingoverview/vbookings"
            render={(props) => <Vckbookings {...props} />}
          />
          <Route
            exact
            path="/bookingoverview/bookings"
            render={(props) => <Bookings {...props} />}
          />
          <Route
            path="/bookingoverview/vbookings/vprintall"
            render={(props) => <Printallvck {...props} />}
          />
          <Route
            path="/bookingoverview/bookings/printall"
            render={(props) => <Printall {...props} />}
          />
          {this.editprintmenu()}
          <Route
            path="/bookingoverview/bookings/:id/previewer"
            render={(props) => <Previewer {...props} />}
          />
          <Route
            path="/bookingoverview/bookings/:id/packinglistprint"
            render={(props) => <Packinglistprint {...props} />}
          />
          <Route
            path="/bookingoverview/bookings/:id/packinglistvck"
            render={(props) => <Packinglistprintvck {...props} />}
          />
          <Route
            path="/bookingoverview/bookings/:id/returliste"
            render={(props) => <Returliste {...props} />}
          />
          <Route
            path="/bookingoverview/bookings/:id/frontpage"
            render={(props) => <Frontpage {...props} />}
          />
          <Route
            path="/bookingoverview/vbookings/:id/frontpage"
            render={(props) => <Frontpage {...props} />}
          />
          <Route
            path="/bookingoverview/bookings/:id/specsheet"
            render={(props) => <Specsheet {...props} />}
          />
          <Route
            path="/bookingoverview/bookings/:id/editmenu"
            render={(props) => <EditMenu {...props} />}
          />
          <Route
            path="/bookingoverview/bookings/:id/menu"
            render={(props) => <Menu {...props} />}
          />
          <Route
            path="/bookingoverview/bookings/:id"
            render={(props) => <Booking {...props} />}
          />
          <Route
            path="/bookingoverview/vbookings/:id"
            render={(props) => <Vbooking {...props} />}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
