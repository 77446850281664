import React, { Component } from "react";
import { Row, Col, Card, CardHeader, CardBody, Button, CardTitle } from "reactstrap";
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import { AppBar, Typography } from "@material-ui/core/";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PackingTable from "./PackingTable";
import apiFacade from "../../../auth/apiFacade";
import { PacmanLoader } from "react-spinners";

var bookingStyle = {
  cellStyling: {
    padding: "0px 10px 0px 0px"
  },
  titleStyling: {
    padding: "0px 10px 0px 0px",
    fontWeight: "bold"
  },
  dropdownitemstyle: {
    background: "red"
  },
  textColor: {
    color: "#3787C6"
  },
  headerStyle: {
    fontWeight: "bold",
    fontSize: "20px"
  },
  mytitle: {
    fontSize: 14
  },
  cardchartMyFixEdit: {
    position: "absolute",
    right: "140px",
    top: "0px"
  },
  btnstyle: {
    color: "#3787C6"
  },
  stuck: {
    position: "fixed",
    bottom: "50px"
  }
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <div p={3}>{children}</div>
    </Typography>
  );
}

class EditPackingA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //infoSpiritus: "",
      value: 0,
      //spiritus category id 1
      spiritus: [],
      // juice & pure category id 2
      juicePure: [],
      //sodavand, mixer category id 3
      sodavandMixer: [],
      //egenprodukter id 4
      egenproduktion: [],
      //firske produkter category id 5
      friskeProukter: [],
      //sirup category id 6
      sirup: [],
      //specialvarer category id 7
      specialvarer: [],
      // glas category id 8
      glas: [],
      // kursus speciel category id 9
      kursusSpeciel: [],
      //isterninger category id 11
      isterninger: [],
      //barudstyr category id 12
      barudstyr: [],
      // kolonial category id 14
      kolonial: [],
      //menukort category id 15
      menuKort: [],
      //plastikglas category id 16
      plastikglas: [],
      //colorId
      spiritusColor: "",
      juicePureColor: "",
      sodavandMixerColor: "",
      egenproduktionColor: "",
      friskeProukterColor: "",
      sirupColor: "",
      specialvarerColor: "",
      glasColor: "",
      kursusSpecielColor: "",
      isterningerColor: "",
      barudstyrColor: "",
      kolonialColor: "",
      menuKortColor: "",
      plastikglasColor: ""
    };
  }

  componentDidMount() {
    apiFacade.getData("/ComponentCategories").then(data => {
      data.map(s => {
        if (s.id === 1) {
          this.setState({ spiritusColor: s.colorId });
        }
        if (s.id === 2) {
          this.setState({ juicePureColor: s.colorId });
        }
        if (s.id === 3) {
          this.setState({ sodavandMixerColor: s.colorId });
        }
        if (s.id === 4) {
          this.setState({ egenproduktionColor: s.colorId });
        }
        if (s.id === 5) {
          this.setState({ friskeProukterColor: s.colorId });
        }
        if (s.id === 6) {
          this.setState({ sirupColor: s.colorId });
        }
        if (s.id === 7) {
          this.setState({ specialvarerColor: s.colorId });
        }
        if (s.id === 8) {
          this.setState({ glasColor: s.colorId });
        }
        if (s.id === 9) {
          this.setState({ kursusSpecielColor: s.colorId });
        }
        if (s.id === 11) {
          this.setState({ isterningerColor: s.colorId });
        }
        if (s.id === 12) {
          this.setState({ barudstyrColor: s.colorId });
        }
        if (s.id === 14) {
          this.setState({ kolonialColor: s.colorId });
        }
        if (s.id === 15) {
          this.setState({ menuKortColor: s.colorId });
        }
        if (s.id === 16) {
          this.setState({ plastikglasColor: s.colorId });
        }
      });
    });
    apiFacade.getData("/newsortings").then(data => {
      data.newSortingList.map(s => {
        if (s.categoryId === 1) {
          this.setState({ spiritus: s.componentDtos });
        }
        if (s.categoryId === 2) {
          this.setState({ juicePure: s.componentDtos });
        }
        if (s.categoryId === 3) {
          this.setState({ sodavandMixer: s.componentDtos });
        }
        if (s.categoryId === 4) {
          this.setState({ egenprodukter: s.componentDtos });
        }
        if (s.categoryId === 5) {
          this.setState({ friskeProukter: s.componentDtos });
        }
        if (s.categoryId === 6) {
          this.setState({ sirup: s.componentDtos });
        }
        if (s.categoryId === 7) {
          this.setState({ specialvarer: s.componentDtos });
        }
        if (s.categoryId === 8) {
          this.setState({ glas: s.componentDtos });
        }
        if (s.categoryId === 9) {
          this.setState({ kursusSpeciel: s.componentDtos });
        }
        if (s.categoryId === 11) {
          this.setState({ isterninger: s.componentDtos });
        }
        if (s.categoryId === 12) {
          this.setState({ barudstyr: s.componentDtos });
        }
        if (s.categoryId === 14) {
          this.setState({ kolonial: s.componentDtos });
        }
        if (s.categoryId === 15) {
          this.setState({ menuKort: s.componentDtos });
        }
        if (s.categoryId === 16) {
          this.setState({ plastikglas: s.componentDtos });
        }
      });
    });
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    if (this.state.spiritus.length > 0 && this.state.plastikglas.length > 0) {
      return (
        <div>
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col xs="auto" md={12}>
                <Card className="card-chart" style={{ border: "none" }}>
                  <CardHeader>
                    <CardTitle>Edit Packing List</CardTitle>
                    <AppBar
                      position="static"
                      style={{
                        backgroundColor: "#36688D",
                        borderRadius: "20px"
                      }}
                    >
                      <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        <Tab label="Spiritus" {...a11yProps(0)} />
                        <Tab label="Barudstyr" {...a11yProps(1)} />
                        <Tab label="Juice & Púre" {...a11yProps(2)} />
                        <Tab label="Sodavand, mixer" {...a11yProps(3)} />
                        <Tab label="Friske Produkter" {...a11yProps(4)} />
                        <Tab label="Special varer" {...a11yProps(5)} />
                        <Tab label="Kolonial" {...a11yProps(6)} />
                        <Tab label="Sirup" {...a11yProps(7)} />
                        <Tab label="Egen Produkter" {...a11yProps(8)} />
                        <Tab label="Glas" {...a11yProps(9)} />
                        <Tab label="Plastikglas" {...a11yProps(10)} />
                        <Tab label="Isterninger" {...a11yProps(11)} />
                        <Tab label="Menukort" {...a11yProps(12)} />
                        <Tab label="Kursus speciel" {...a11yProps(13)} />
                      </Tabs>
                    </AppBar>
                  </CardHeader>
                  <CardBody>
                    <TabPanel value={this.state.value} index={0}>
                      <PackingTable
                        categoryName={"Spiritus"}
                        newData={this.state.spiritus}
                        colorId={this.state.spiritusColor}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                      <PackingTable
                        categoryName={"Barudstyr"}
                        newData={this.state.barudstyr}
                        colorId={this.state.barudstyrColor}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={2}>
                      <PackingTable
                        categoryName={"Juice & Púre"}
                        newData={this.state.juicePure}
                        colorId={this.state.juicePureColor}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={3}>
                      <PackingTable
                        categoryName={"Sodavand, mixer"}
                        newData={this.state.sodavandMixer}
                        colorId={this.state.sodavandMixerColor}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={4}>
                      <PackingTable
                        categoryName={"Friske Produkter"}
                        newData={this.state.friskeProukter}
                        colorId={this.state.friskeProukterColor}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={5}>
                      <PackingTable
                        categoryName={"special varer"}
                        newData={this.state.specialvarer}
                        colorId={this.state.specialvarerColor}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={6}>
                      <PackingTable
                        categoryName={"kolonial"}
                        newData={this.state.kolonial}
                        colorId={this.state.kolonialColor}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={7}>
                      <PackingTable
                        categoryName={"Sirup"}
                        newData={this.state.sirup}
                        colorId={this.state.sirupColor}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={8}>
                      <PackingTable
                        categoryName={"egenprodukter"}
                        newData={this.state.egenprodukter}
                        colorId={this.state.egenproduktionColor}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={9}>
                      <PackingTable
                        categoryName={"Glas "}
                        newData={this.state.glas}
                        colorId={this.state.glasColor}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={10}>
                      <PackingTable
                        categoryName={"Plastikglas"}
                        newData={this.state.plastikglas}
                        colorId={this.state.plastikglasColor}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={11}>
                      <PackingTable
                        categoryName={"Isterninger"}
                        newData={this.state.isterninger}
                        colorId={this.state.isterningerColor}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={12}>
                      <PackingTable
                        categoryName={"Menu kort"}
                        newData={this.state.menuKort}
                        colorId={this.state.menuKortColor}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={13}>
                      <PackingTable
                        categoryName={"kursus Speciel"}
                        newData={this.state.kursusSpeciel}
                        colorId={this.state.kursusSpecielColor}
                      />
                    </TabPanel>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md={{ size: 2, offset: 10 }}>
                <Button
                  color="danger"
                  round
                  style={bookingStyle.stuck}
                  onClick={() => {
                    this.props.history.push(`/bookings`);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: 300, marginBottom: 400 }}>
          <div className="sweet-loading" style={{ marginLeft: 600 }}>
            <h5>Loading Edit List</h5>
            <PacmanLoader sizeUnit={"px"} size={50} color={"#d16557"} />
          </div>
        </div>
      );
    }
  }
}

export default EditPackingA;
