import React, { Component } from 'react'
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import ViewTable from './ViewTable'
import {
    Row,
    Col,
    Button,
} from "reactstrap";
import apiFacade from '../../../auth/apiFacade'
var bookingStyle = {
    cellStyling: {
        padding: "0px 10px 0px 0px"
    },
    dropdownitemstyle: {
        background: "red"
    },
    textColor: {
        color: "#3787C6"
    },
    cardchartMyFix: {
        position: 'absolute',
        right: '220px',
        top: '12px',
    },
    cardchartMyFixEdit: {
        position: 'absolute',
        right: '330px',
        top: '0px',
    },
    cardchartMyFixBack: {
        position: "fixed",
        bottom: "55px",
        right: "70px"

    },
    radioWhite: {
        border: "10px solid #90DDD0",
    },
    radioPink: {
        border: "10px solid #EF959D",
    },
    radioRed: {
        border: "10px solid #90DDD0",
    },
    stuck: {
        position: "fixed",
        bottom: "50px",

    }

};



class EditMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listOfCocktails: [],
            listOfDrinks: [],
            isloading: true
        }
    }
    componentDidMount() {
        apiFacade.getData("/EditMenus/getmenus/" + this.props.match.params.id).then(data => {

            data.map((d) => {
                if (d.cocktailOrNot != 1) {
                    this.state.listOfCocktails.push(d)
                    this.setState({
                        isloading: false,

                    })
                }
                if (d.cocktailOrNot == 1) {
                    this.state.listOfDrinks.push(d)
                    this.setState({
                        isloading: false,

                    })
                }
            })
        })
    }


    render() {
        if (this.state.isloading == false) {
        }
        if (this.state.listOfCocktails.length > 0) {
            return (
                <div>
                    <PanelHeader size="sm" />

                    <div className="content">
                        <Row>
                            <Col xs={12} md={{ size: 10, offset: 1 }} className="mr-auto">

                                {/* <ViewTable title={"Edit Cocktails"} /> */}


                            </Col>
                        </Row>
                        <div style={{ marginTop: '10px' }}>
                            <Row>
                                <Col sm="12"  >

                                    <ViewTable title={"Add drinks"} isItCocktail={true} bookingId={this.props.match.params.id} data={this.state.listOfCocktails} />
                                    <ViewTable title={"Add drinks"} isItCocktail={false} bookingId={this.props.match.params.id} data={this.state.listOfDrinks} />
                                </Col>

                                <Col md={{ size: 1, offset: 11 }}  >


                                    <Button color="danger" round style={bookingStyle.stuck}
                                        onClick={() => {
                                            this.props.history.push(
                                                "/bookings/" + this.props.match.params.id
                                            );
                                        }}
                                    >Back</Button>


                                </Col>

                            </Row>
                        </div>

                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <h3>loading</h3>
                </div>
            )
        }
    }
}
export default EditMenu