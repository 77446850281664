import React from "react";
import { Switch, Route } from "react-router-dom";
import CocktailMenu from "./CocktailMenu";

export default function CocktailMenuview() {
  return (
    <div>
      <React.Fragment>
        <Switch>
          <Route
            exact
            path="/cocktailmenus"
            render={(props) => <CocktailMenu {...props} />}
          />
        </Switch>
      </React.Fragment>
    </div>
  );
}
