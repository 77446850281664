import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Button,
  CardBody,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import CCLogo from "assets/img/CC_logo_Primary_red.png";
import CCBackground from "assets/img/CC_logo.jpg";
import { useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "../../authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ScreenSize } from "helper/ScreenSize";
import Alert from "@material-ui/lab/Alert";
import apiFacade from "../../auth/apiFacade.js";
import { useDispatch, useSelector } from "react-redux";
import types from "../../redux/actionTypes";

function handleLogin(instance) {
  //instance.loginRedirect(loginRequest);
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

function LoginPage() {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const [firstnameFocus, setfirstnameFocus] = React.useState(false);
  const [lastnameFocus, setlastnameFocus] = React.useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [sizeWidth, setSizeWidth] = useState("65%");
  const [password, setPassword] = useState("65%");
  const [username, setUsername] = useState("65%");
  const [errMsg, setErrMsg] = useState(false);

  useEffect(() => {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      setSizeWidth("83%");
    } else if (screenSize === 2) {
      setSizeWidth("83%");
    }
  }, []);

  React.useEffect(() => {
    document.body.classList.add("login-page");
    return function cleanup() {
      document.body.classList.remove("login-page");
    };
  }, []);

  const alertErr = () => {
    if (errMsg) {
      return (
        <DialogTitle id="alert-dialog-title">
          <Alert severity="error">Wrong username or password</Alert>
        </DialogTitle>
      );
    }
  };

  const handlePackerLogin = () => {
    let pakker = {
      userName: username,
      password: password,
    };
    apiFacade
      .putDataWithJson(
        "https://pakkemodelapi.azurewebsites.net/api/pakkers/login",
        pakker
      )
      .then((data) => {
        if (data.status === 404) {
          setErrMsg(true);
          setTimeout(() => {
            setErrMsg(false);
          }, 5000);
        } else {
          dispatch({
            type: types.pakker.loginPacker,
            payload: {
              packer_Id: data.id,
              firstName: data.firstName,
              lastName: data.lastName,
              userName: data.userName,
            },
          });
          let user = {
            packer_Id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            userName: data.userName,
          };
          localStorage.setItem("user", JSON.stringify(user));
          loginToggle();
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const loginToggle = () => {
    setLoginModal(!loginModal);
  };

  return (
    <>
      <div className="content">
        <div className="login-page">
          <Container>
            <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
              <Form>
                <Card className="card-login card-plain">
                  <CardHeader>
                    <div className="logo-container">
                      <img src={CCLogo} alt="CCLogo" />
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Button
                      onClick={() =>
                        //myMsal.handleRedirectPromise().then(handleResponse)
                        handleLogin(instance)
                      }
                      block
                      size="lg"
                      className="mb-3 btn-round"
                      style={{ backgroundColor: "#E74A3F" }}
                    >
                      Login as an administrator
                    </Button>
                  </CardBody>
                  <CardFooter>
                    <Button
                      onClick={() => {
                        //myMsal.handleRedirectPromise().then(handleResponse)
                        loginToggle();
                      }}
                      block
                      size="lg"
                      className="mb-3 btn-round"
                      style={{ backgroundColor: "#E74A3F" }}
                    >
                      Login as a packer
                    </Button>
                  </CardFooter>
                  <Row>
                    <Col xs={12}>
                      <Dialog
                        open={loginModal}
                        onClose={loginToggle}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                      >
                        {alertErr()}
                        <DialogContent style={{ backgroundColor: "rgb(231 201 159 / 59%)" }}>
                          <Row>
                            <Col>
                              <InputGroup
                                className={
                                  "no-border form-control-lg " +
                                  (firstnameFocus ? "input-group-focus" : "")
                                }
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText
                                    style={{ backgroundColor: "white" }}
                                  >
                                    <i className="now-ui-icons users_circle-08" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  style={{ backgroundColor: "white" }}
                                  type="text"
                                  placeholder="Username"
                                  onChange={(e) => setUsername(e.target.value)}
                                  onFocus={(e) => setfirstnameFocus(true)}
                                  onBlur={(e) => setfirstnameFocus(false)}
                                />
                              </InputGroup>
                            </Col>
                            <Col>
                              <InputGroup
                                className={
                                  "no-border form-control-lg " +
                                  (lastnameFocus ? "input-group-focus" : "")
                                }
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText
                                    style={{ backgroundColor: "white" }}
                                  >
                                    <i className="now-ui-icons text_caps-small" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  style={{ backgroundColor: "white" }}
                                  type="password"
                                  placeholder="Password"
                                  onChange={(e) => setPassword(e.target.value)}
                                  onFocus={(e) => setlastnameFocus(true)}
                                  onBlur={(e) => setlastnameFocus(false)}
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        </DialogContent>
                        <DialogActions style={{ backgroundColor: "rgb(231 201 159 / 59%)" }}>
                          <Button
                            onClick={() => {
                              //myMsal.handleRedirectPromise().then(handleResponse)
                              handlePackerLogin();
                            }}
                            block
                            size="lg"
                            className="mb-3 btn-round"
                            style={{
                              backgroundColor: "#E74A3F",
                              width: sizeWidth,
                              marginInline: "auto",
                              padding: "4%",
                            }}
                          >
                            Login
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Col>
                  </Row>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + CCBackground + ")" }}
      />
    </>
  );
}

export default LoginPage;
