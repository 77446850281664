import React, { Component } from "react";
import apiFacade from "../../auth/apiFacade";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import "@devexpress/dx-react-grid";
import BookingData from "./BookingData";
import BarLoader from "react-spinners/BarLoader";
import "../../assets/css/BookingStyle.css";
import Paper from "@material-ui/core/Paper";
import { AppBar, Typography } from "@material-ui/core/";
import Tabs from "@material-ui/core/Tabs";
import { ScreenSize } from "../../helper/ScreenSize";
import Tab from "@material-ui/core/Tab";
import {
  Row,
  Button,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
// react plugin used to create charts

var bookingStyle = {
  cellStyling: {
    padding: "0px 10px 0px 0px",
  },
  titleStyling: {
    padding: "0px 10px 0px 0px",
    fontWeight: "bold",
  },
  dropdownitemstyle: {
    background: "red",
  },
  headerStyle: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  mytitle: {
    fontSize: 14,
  },
  cardchartMyFixEdit: {
    position: "absolute",
    right: "170px",
    top: "10px",
  },
  btnstyle: {
    color: "#3787C6",
  },
  stuck: {
    position: "fixed",
    bottom: "50px",
    zIndex: "1001",
  },
  bold: {
    fontWeight: "bold",
  },
  paperShadow: {
    boxShadow: "1px 1px 5px 6px #ccc",
  },
};

const getChildRows = (row, rootRows) => {
  const childRows = rootRows.filter(
    (r) => r.parentId === (row ? row.newId : null)
  );
  return childRows.length ? childRows : null;
};

export default class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Details: [],
      packingList: [],
      rawProducts: [],
      products: [],
      bookings: [],
      menus: [],
      value: 0,
      mytable: [],
      isLoading: true,
      anchorEl: null,
      mytest: [],
      jobType: [],
      defaultExpandedRowIds: [0, 1],
      defaultExpandedGroups: [
        "Cocktail",
        "Drinks",
        "Staff & Materials",
        "Beer",
        "Wine",
        "WelcomeDrink",
      ],
      defaultExpandedGroupsPacking: [
        "Spiritus",
        "Juice & púre",
        "Sirup",
        "Sodavand, mixer",
        "Friske produkter",
        "Barudstyr",
        "Specialvarer",
        "Glas",
        "Isterninger",
        "Menukort",
        "Kursus speciel",
        "Egenproduktion",
        "Kolonial",
        "Plastikglas",
      ],
      tableColumnExtensions: [{ columnName: "name", width: 800 }],
      cardTitleTag: "h4",
    };
  }

  componentDidMount() {
    this.getdata(this.props.match.params.id);

    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      this.setState({
        tableColumnExtensions: [{ columnName: "name", width: 390 }],
      });
      this.setState({ cardTitleTag: "h5" });
    } else if (screenSize === 2) {
      this.setState({
        tableColumnExtensions: [{ columnName: "name", width: 390 }],
      });
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  task(booking) {
    if (booking.tasks != null) {
      let ary = [];
      for (let index = 0; index < booking.tasks.length; index++) {
        ary.push(
          <div>
            <span style={bookingStyle.bold}>Opg:</span>{" "}
            {booking.tasks[index].text + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0"}
            <span style={bookingStyle.bold}>Oprettet af:</span>{" "}
            {booking.tasks[index].responsible.name}
          </div>
        );
      }
      return (
        <div>
          <Row style={{ marginTop: "5px", marginLeft: "5px" }}>
            <Col xs={1}>
              <span style={bookingStyle.bold}>Opgaver:{""}</span>
            </Col>
            <Col xs={10}>{ary}</Col>
          </Row>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  getdata(id) {
    if (id) {
      apiFacade.getData("/bookings/getpackinglist/" + id).then((data) => {
        const myNewData = data.map((packing, i) => {
          return {
            newId: packing.component.id,
            name: packing.componentName,
            amount: packing.amount,
            category: packing.component.category.name,
            parentId: null,
          };
        });
        this.setState({ packingList: myNewData });

        apiFacade.getData("/bookings/" + id).then((data3) => {
          this.setState({ bookings: data3 });

          apiFacade.getData("/menus").then((data2) => {
            this.setState({ menus: data2 });

            this.state.menus.map((data2) => {
              if (data3.menu === data2.id && data3.id === id) {
                this.state.Details.push({
                  title: "Company name",
                  value: data3.companyName,
                });
                this.state.Details.push({
                  title: "Menu",
                  value: data2.name,
                });
                this.state.Details.push({
                  title: "Date",
                  value: data3.date,
                });
                this.state.Details.push({
                  title: "Type",
                  value: data3.type,
                });
                this.state.jobType.push({
                  value: data3.type,
                });
                this.state.Details.push({
                  title: "Bartenders",
                  value: data3.bartenders,
                });
                this.state.Details.push({
                  title: "Bar Modules",
                  value: data3.barModules,
                });
                this.state.Details.push({
                  title: "Duration",
                  value: data3.duration,
                });
                this.state.Details.push({
                  title: "Region",
                  value: data3.region,
                });
                this.state.Details.push({
                  title: "Persons",
                  value: data3.persons,
                });

                this.state.Details.push({
                  title: "Barbacks",
                  value: data3.barbacks,
                });
                this.state.Details.push({
                  title: "Waiters",
                  value: data3.waiters,
                });
                this.state.Details.push({
                  title: "IndustrialDishwasher",
                  value: data3.industrialDishwasher,
                });
              }
              this.setState({ isLoading: false });
            });
          });
          //her
        });
      });
    }

    if (this.state.packingList) {
      apiFacade.getData("/bookings/getproducts/" + id).then((data4) => {
        this.setState({ rawProducts: data4 });
        this.state.rawProducts.map((rawdata) => {
          this.state.products.push({
            newId: rawdata.productId,
            name: rawdata.product.name,
            parentId: null,
            category: rawdata.categoryNavigation.name,
            amount: rawdata.amount,
            weight: rawdata.product.scaleingFactor,
          });

          rawdata.product.productComponents.map((s) => {
            const total =
              (s.amount * rawdata.amount).toString() +
              " " +
              s.component.unitSuffix;
            this.state.products.push({
              parentId: s.productId,
              name: s.component.name,
              category: rawdata.categoryNavigation.name,
              customName: rawdata.customName,
              unit: s.amount.toString() + " " + s.component.unitSuffix,
              total: total,
              packingName: rawdata.product.name,
            });
          });
        });
        this.state.packingList.map((pklist) => {
          this.state.products.map((products) => {
            if (products.name === pklist.name) {
              this.state.packingList.push({
                name: products.packingName,
                parentId: pklist.newId,
                category: pklist.category,
              });
            }
          });
        });
      });
    }
  }
  titles() {
    const listofitems = this.state.Details.map((t, i) => (
      <tr key={i} className="trs">
        <td className="tds">{t.title}</td>
        <td className="tds">{t.value}</td>
      </tr>
    ));
    return listofitems;
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  withOrWithOutMenu = () => {
    if (this.state.isLoading !== true && this.state.jobType.length > 0) {
      if (this.state.jobType[0].value !== "Cocktailkursus") {
        return (
          <DropdownItem
            onClick={() => {
              this.props.history.push(
                `/bookings/${this.props.match.params.id}/menu`
              );
            }}
          >
            Menu
          </DropdownItem>
        );
      }
    }
  };

  withOrWithOutEditMenu = () => {
    if (this.state.isLoading !== true && this.state.jobType.length > 0) {
      if (this.state.jobType[0].value !== "Cocktailkursus") {
        return (
          <Button
            color="info"
            onClick={() => {
              this.props.history.push(
                `/bookings/${this.props.match.params.id}/editmenu`
              );
            }}
          >
            Edit Menu
          </Button>
        );
      }
    }
  };

  stringToHTML(str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, "text/html");
    return doc.body;
  }

  render() {
    if (this.state.bookings.length != 0) {
      let Yderligere_step2 = "";
      if (this.state.bookings.additionalInfo != null) {
        const Yderligere_step1 = this.stringToHTML(
          this.state.bookings.additionalInfo
        );

        Yderligere_step2 = Yderligere_step1.innerText;
      }

      const tidStart_step1 = this.state.bookings.date;
      const tidStart_step2 = tidStart_step1.split("T");
      const tidSlut_step1 = this.state.bookings.dateEnd;
      const tidSlut_step2 = tidSlut_step1.split("T");
      let opvaskemaskine = "Nej";
      if (this.state.bookings.industrialDishwasher) {
        opvaskemaskine = "Ja";
      }

      const bookingTitle = (
        <p>
          Booking:{" "}
          <a
            href={
              "https://podio.com/fridaygroup/intranet/apps/bookinger/items/" +
              this.props.match.params.id
            }
          >
            {this.props.match.params.id}
          </a>
        </p>
      );
      // const columns = [
      //   {
      //     field: "title",
      //     cellStyle: bookingStyle.titleStyling,
      //     headerStyle: bookingStyle.headerStyle
      //   },

      //   {
      //     field: "value",
      //     cellStyle: bookingStyle.cellStyling,
      //     headerStyle: bookingStyle.headerStyle
      //   }
      // ];
      const columnsPacking = [
        {
          name: "name",
          title: "Name",
          // getCellValue: row => (row.product ? row.product.name : undefined)
        },
        {
          name: "amount",
          title: "Qty",
        },
        {
          name: "category",
          title: "Category",
          //getCellValue: row => (row.category ? row.categoryNavigation.name : "juice")
        },
      ];

      const columnsP = [
          {
            name: "name",
            title: "Name",
            // getCellValue: row => (row.product ? row.product.name : undefined)
          },
          {
            name: "weight",
            title: "Weight",
          },
          {
            name: "amount",
            title: "Qty",
          },
          {
            name: "category",
            title: "Category",
            //getCellValue: row => (row.category ? row.categoryNavigation.name : "juice")
          },
          /*  {
       name: 'unit',
       title: 'Unit',
      // getCellValue: row => (row.product ? row.product.name : undefined)
 
     }, {
       name: 'total',
      title: 'Total'
      //getCellValue: row => <div>test</div>
 
     }, */
        ],
        grouping = [{ columnName: "category" }];
      // tableColumnExtensions = [{ columnName: "name", width: 800 }];
      return (
        <div>
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag={this.state.cardTitleTag}>
                    Booking Info{" "}
                    <a
                      href={
                        "https://podio.com/fridaygroup/intranet/apps/bookinger/items/" +
                        this.props.match.params.id
                      }
                    >
                      {" "}
                      {this.props.match.params.id}
                    </a>
                  </CardTitle>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      style={{ backgroundColor: "#d16557" }}
                    >
                      Print
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={() => {
                          this.props.history.push(
                            `/bookingoverview/bookings/${this.props.match.params.id}/packinglistprint`
                          );
                        }}
                      >
                        Packing List
                      </DropdownItem>
                      {/* <DropdownItem
                        onClick={() => {
                          this.props.history.push(
                            `/bookingoverview/bookings/${this.props.match.params.id}/packinglistvck`
                          );
                        }}
                      >
                        Packing List VCK
                      </DropdownItem> */}
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={() => {
                          this.props.history.push(
                            `/bookingoverview/bookings/${this.props.match.params.id}/frontpage`
                          );
                        }}
                      >
                        Front page
                      </DropdownItem>
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={() => {
                          this.props.history.push(
                            `/bookingoverview/bookings/${this.props.match.params.id}/specsheet`
                          );
                        }}
                      >
                        Specsheet
                      </DropdownItem>
                      {this.withOrWithOutMenu()}
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={() => {
                          this.props.history.push(
                            `/bookingoverview/bookings/${this.props.match.params.id}/returliste`
                          );
                        }}
                      >
                        Returliste
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  <Paper style={bookingStyle.paperShadow}>
                    <Row style={{ marginLeft: "5px" }}>
                      <Col xs={5} style={{ marginTop: "10px" }}>
                        <div>
                          <span style={bookingStyle.bold}>Firma:</span>{" "}
                          {this.state.bookings.companyName}
                        </div>
                        <div>
                          <span style={bookingStyle.bold}>Kontakt Person:</span>{" "}
                          {this.state.bookings.contactPerson}
                        </div>
                        <div>
                          <span style={bookingStyle.bold}>Address:</span>{" "}
                          {this.state.bookings.address}
                        </div>
                        <div>
                          <span style={bookingStyle.bold}>Job type: </span>{" "}
                          {this.state.bookings.type}
                        </div>
                        <div>
                          <span style={bookingStyle.bold}>
                            Antal personer:{" "}
                          </span>{" "}
                          {this.state.bookings.persons}
                        </div>
                        <div>
                          <span style={bookingStyle.bold}>Start: </span>{" "}
                          {tidStart_step2[1]}
                        </div>
                        <div>
                          <span style={bookingStyle.bold}>Bartenders: </span>{" "}
                          {this.state.bookings.bartenders}
                        </div>
                      </Col>
                      <Col xs={7} style={{ marginTop: "10px" }}>
                        {" "}
                        <div>
                          <span style={bookingStyle.bold}>Møde sted: </span>{" "}
                          {this.state.bookings.meetingPlace}
                        </div>
                        <div>
                          <span style={bookingStyle.bold}>Tlf: </span>{" "}
                          {this.state.bookings.phone}
                        </div>
                        <div>
                          <span style={bookingStyle.bold}>Region: </span>{" "}
                          {this.state.bookings.region}
                        </div>
                        <div>
                          <span style={bookingStyle.bold}>
                            Industriel opvaskemaskine:{" "}
                          </span>{" "}
                          {opvaskemaskine}
                        </div>
                        <div>
                          <span style={bookingStyle.bold}>Barelementer: </span>{" "}
                          {this.state.bookings.barModules}
                        </div>
                        <div>
                          <span style={bookingStyle.bold}>Slut: </span>{" "}
                          {tidSlut_step2[1]}
                        </div>
                        <div>
                          <span style={bookingStyle.bold}>
                            Yderligere oplysninger:{" "}
                          </span>{" "}
                          {Yderligere_step2}
                        </div>
                      </Col>
                    </Row>
                    {this.task(this.state.bookings)}
                  </Paper>
                </CardBody>
              </Card>
            </Row>
            <Row>
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col xs="12" /* md={{ size: 4, offset: 4 }} */>
                      <AppBar
                        position="static"
                        style={{
                          backgroundColor: "#d16557",
                          borderRadius: "5px",
                        }}
                      >
                        <Tabs
                          value={this.state.value}
                          onChange={this.handleChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: "#ffffff",
                            },
                          }}
                        >
                          <Tab label="Packing List" {...a11yProps(0)} />
                          <Tab label="Products" {...a11yProps(1)} />
                        </Tabs>
                      </AppBar>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <TabPanel value={this.state.value} index={0}>
                    {
                      // <PackingTable
                      //   id={samplingInfo.category_Id}
                      //   rows={this.state.packinglist}
                      // />
                    }
                    <BookingData
                      loaded={!this.state.isLoading}
                      products={this.state.products}
                      grouping={grouping}
                      columnExtensions={this.state.tableColumnExtensions}
                      columnTitle={columnsP}
                      defaultExpandedGroups={this.state.defaultExpandedGroups}
                      getChildRows={getChildRows}
                      customHeight={390}
                    />
                  </TabPanel>
                  <TabPanel value={this.state.value} index={1}>
                    <BookingData
                      loaded={!this.state.isLoading}
                      products={this.state.packingList}
                      grouping={grouping}
                      columnExtensions={this.state.tableColumnExtensions}
                      columnTitle={columnsPacking}
                      defaultExpandedGroups={
                        this.state.defaultExpandedGroupsPacking
                      }
                      getChildRows={getChildRows}
                      customHeight={715}
                    />
                  </TabPanel>
                </CardBody>
              </Card>
            </Row>
            <Col sm="12" md={{ size: 2, offset: 10 }}>
              <Button
                color="danger"
                style={bookingStyle.stuck}
                onClick={() => {
                  this.props.history.push(`/bookingoverview/bookings`);
                }}
              >
                Back
              </Button>
            </Col>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            className="sweet-loading"
            style={{ marginLeft: 400, marginTop: 440 }}
          >
            <BarLoader
              height={15}
              width={300}
              sizeUnit={"px"}
              size={500}
              color={"#4C8FBF"}
            />
          </div>
        </div>
      );
    }
  }
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <div p={3}>{children}</div>
    </Typography>
  );
}
