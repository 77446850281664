import React, { useState, useEffect } from "react";
import apiFacade from "../../../../auth/apiFacade";
import PresComp from "./PresComp";
import BarLoader from "react-spinners/BarLoader";
import jsPDF from "jspdf";
import "jspdf-autotable";
import hexToRgb from "pretty-easy-hex-to-rgb";
import { ScreenSize } from "helper/ScreenSize";

const SpecsheetAll = ({ listOfId, refreshApi, loading, navne }) => {
  const [myListOfIds, setMyListOfIds] = useState([]);
  const [myData, setMyData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [components, setMyComponents] = useState([]);
  const [pdfWidth, setPdfWidth] = useState(700);
  const [pdfHeight, setPdfHeight] = useState(900);

  useEffect(() => {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      setPdfWidth(300);
      setPdfHeight(350);
    } else if (screenSize === 2) {
      setPdfWidth(675);
      setPdfHeight(800);
    }
    setMyListOfIds(listOfId);
    if (refreshApi) {
      apiFacade
        .getData("/Products/specsheetjoblist/" + listOfId.join())
        .then((data) => {
          setMyData(data);
        })
        .finally(() => setLoaded(true));
    }
  }, [refreshApi]);

  if (myData.length > 0) {
    var cocktailHeadRow = [
      { header: "Name", dataKey: "componentName" },
      { header: "Amount", dataKey: "amountOfSuffixes" },
    ];

    let pageNr = myData.length - 1;
    var doc = new jsPDF("p", "pt");

    myData.map((data, i) => {
      let loop = Math.ceil(data.specDtoList.length / 4);
      let yourArray = data.specDtoList;
      let halfWayThough = Math.floor(yourArray.length / 2);
      let arraySecondHalf = yourArray.slice(0, halfWayThough);
      let arrayFirstHalf = yourArray.slice(halfWayThough, yourArray.length);
      let leftLength = 0;
      let leftLengthMin = 1;
      let leftLengthMax = 2;
      let leftLengthIfMin = 0;
      let leftLengthIfMax = 3;
      let rightLength = 0;
      let rightLengthMin = 1;
      let rightLengthMax = 2;
      let rightLengthIfMin = 0;
      let rightLengthIfMax = 3;

      for (let b = 0; b < loop; b++) {
        doc.setFontSize(18);
        doc.text(`${data.bookingid}`, 20, 20);
        doc.setFontSize(14);
        doc.setFontType("normal");
        doc.text(`${data.region}`, 500, 20);
        doc.setFontSize(28);
        doc.setFontType("normal");

        let leftTable = 0;
        let rightTable = 0;

        // left table row start  -------------------------------------------------------------------1-------------------------------------------------------start

        if (arrayFirstHalf.length === leftLengthMin) {
          leftLength = leftLengthMin;
        } else if (arrayFirstHalf.length >= leftLengthMax) {
          leftLength = leftLengthMax;
        }
        if (leftLength > leftLengthIfMin || leftLength < leftLengthIfMax) {
          for (
            let leftIndex = leftLengthMin - 1;
            leftIndex < leftLength;
            leftIndex++
          ) {
            doc.setFontSize(22);
            doc.text(
              `${arrayFirstHalf[leftIndex].productName}`,
              40,
              50 + leftTable
            );
            doc.setFontStyle("bold");
            doc.setFontSize(12);
            doc.text(`Glass: `, 40, 70 + leftTable);
            doc.setFontStyle("normal");
            doc.text(
              `${arrayFirstHalf[leftIndex].glassName}`,
              90,
              70 + leftTable
            );
            doc.setFontStyle("bold");
            doc.text(`Ice:`, 40, 85 + leftTable);
            doc.setFontStyle("normal");
            doc.text(`${arrayFirstHalf[leftIndex].ice}`, 90, 85 + leftTable);
            doc.setFontStyle("bold");
            doc.text(`Method:`, 40, 100 + leftTable);
            doc.setFontStyle("normal");

            let spaceBetweenLeft = 0;

            if (arrayFirstHalf[leftIndex].method !== null) {
              let methodLeft = doc.splitTextToSize(
                arrayFirstHalf[leftIndex].method,
                200
              );

              doc.text(90, 100 + leftTable, methodLeft);

              if (methodLeft.length === 4) {
                spaceBetweenLeft = 25;
              } else if (methodLeft.length === 5) {
                spaceBetweenLeft = 50;
              } else if (methodLeft.length === 6) {
                spaceBetweenLeft = 75;
              }
            }

            doc.autoTable({
                columns: cocktailHeadRow,
                body: arrayFirstHalf[leftIndex].productComponentDto,
                startY: 130 + spaceBetweenLeft + leftTable,
                tableWidth: 230,
                theme: "grid",
                styles: {
                  lineColor: [44, 62, 80],
                  lineWidth: 1,
                  overflow: "linebreak",
                },
                columnStyles: { title: { cellWidth: 120 } },
                headStyles: { fillColor: hexToRgb("#595959") },
              }
            );

            leftTable = 375;
          }
        }
        leftLengthMin += 2;
        leftLengthMax += 2;
        leftLengthIfMin += 2;
        leftLengthIfMax += 2;
        //-------------------------------left----------------------------------------1---------------------------------------------------------------------end

        //------------------------------right----------------------------------------1--------------------------------------------------------------------start
        if (arraySecondHalf.length === rightLengthMin) {
          rightLength = rightLengthMin;
        } else if (arraySecondHalf.length >= rightLengthMax) {
          rightLength = rightLengthMax;
        }
        if (rightLength > rightLengthIfMin || rightLength < rightLengthIfMax) {
          for (
            let rightIndex = rightLengthMin - 1;
            rightIndex < rightLength;
            rightIndex++
          ) {
            doc.setFontSize(22);
            doc.text(
              `${arraySecondHalf[rightIndex].productName}`,
              340,
              50 + rightTable
            );
            doc.setFontStyle("bold");
            doc.setFontSize(12);
            doc.text(`Glass: `, 340, 70 + rightTable);
            doc.setFontStyle("normal");
            doc.text(
              `${arraySecondHalf[rightIndex].glassName}`,
              390,
              70 + rightTable
            );
            doc.setFontStyle("bold");
            doc.text(`Ice:`, 340, 85 + rightTable);
            doc.setFontStyle("normal");
            doc.text(
              `${arraySecondHalf[rightIndex].ice}`,
              390,
              85 + rightTable
            );
            doc.setFontStyle("bold");
            doc.text(`Method:`, 340, 100 + rightTable);
            doc.setFontStyle("normal");

            let spaceBetweenRight = 0;

            if (arraySecondHalf[rightIndex].method !== null) {
              let methodRight = doc.splitTextToSize(
                arraySecondHalf[rightIndex].method,
                200
              );

              doc.text(390, 100 + rightTable, methodRight);

              if (methodRight.length === 4) {
                spaceBetweenRight = 35;
              } else if (methodRight.length === 5) {
                spaceBetweenRight = 70;
              } else if (methodRight.length === 6) {
                spaceBetweenRight = 105;
              }
            }

            doc.autoTable({
                columns: cocktailHeadRow,
                body: arraySecondHalf[rightIndex].productComponentDto,
                startY: 130 + spaceBetweenRight + rightTable,
                tableWidth: 230,
                margin: { horizontal: 340 },
                styles: { overflow: "linebreak" },
                theme: "grid",
                styles: {
                  lineColor: [44, 62, 80],
                  lineWidth: 1,
                },
                columnStyles: {
                  componentName: { cellWidth: 180 },
                  amountOfSuffixes: { cellWidth: 50 },
                },
                headStyles: { fillColor: hexToRgb("#595959") },
              }
            );
            rightTable = 375;
            //-------------------end-------------------------right-----------------------------------------------1--------------------------------------------------end
          }
        }
        rightLengthMin += 2;
        rightLengthMax += 2;
        rightLengthIfMin += 2;
        rightLengthIfMax += 2;
        if (b < loop - 1) {
          doc.addPage();
        }
      }
      if (i < myData.length - 1) {
        doc.addPage();
      }
    });

    const uri = doc.output("datauristring");
    return <iframe frameBorder="0" width={pdfWidth} height={pdfHeight} src={uri} />;
  }
  if (refreshApi) {
    return (
      <div>
        <div className="sweet-loading" style={{ marginLeft: 300 }}>
          <BarLoader
            height={5}
            width={200}
            sizeUnit={"px"}
            size={500}
            color={"#4C8FBF"}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ marginLeft: 450 }}>
          <h5>Press View</h5>
        </div>
      </div>
    );
  }
};

export default SpecsheetAll;
