import React from "react";
import "@devexpress/dx-react-grid";
import BarLoader from "react-spinners/BarLoader";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableTreeColumn,
  TableGroupRow,
} from "@devexpress/dx-react-grid-material-ui";
import {
  TreeDataState,
  CustomTreeData,
  GroupingState,
  IntegratedGrouping,
} from "@devexpress/dx-react-grid";

const BookingData = (props) => {
  if (props.loaded) {
    return (
      <Grid rows={props.products} columns={props.columnTitle}>
        <GroupingState
          grouping={props.grouping}
          defaultExpandedGroups={props.defaultExpandedGroups}
        />
        <TreeDataState defaultExpandedRowIds={props.defaultExpandedRowIds} />
        <CustomTreeData getChildRows={props.getChildRows} />

        <IntegratedGrouping />
        <Table columnExtensions={props.columnExtensions} />

        <TableHeaderRow />
        <TableGroupRow />
        <TableTreeColumn for="name" />
      </Grid>
    );
  } else {
    return (
      <div>
        <div
          className="sweet-loading"
          style={{ marginLeft: 400, marginTop: 440 }}
        >
          <BarLoader
            height={15}
            width={300}
            sizeUnit={"px"}
            size={500}
            color={"#4C8FBF"}
          />
        </div>
      </div>
    );
  }
};

BookingData.propTypes = {};

export default BookingData;
