import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { NavLink } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Info from "@material-ui/icons/InfoSharp";
import { withStyles } from "@material-ui/core/styles";
import Moment from "react-moment";
import apiFacade from "../../../auth/apiFacade";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";
import { useDispatch } from "react-redux";
import types from "../../../redux/actionTypes";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: "none",
  },
});

const CustomTableCellStyles = {
  style1: {
    //color: "#3787C6",
    fontSize: 12,
    align: "left",
    paddingLeft: 10,
  },
  style2: {
    color: "#e2b659",
    fontSize: 12,
    align: "left",
    paddingLeft: 10,
  },
  style3: {
    color: "#7ddf3f",
    fontSize: 12,
    align: "left",
    paddingLeft: 10,
  },
  style4: {
    color: "#32f0b2",
    fontSize: 12,
    align: "left",
    paddingLeft: 10,
  },
  style5: {
    color: "#ff80a8",
    fontSize: 12,
    align: "left",
    paddingLeft: 10,
  },
  style6: {
    color: "#ff0000",
    fontSize: 12,
    align: "left",
    paddingLeft: 10,
  },
};

const BookingCell = (props) => {
  const dispatch = useDispatch();
  const { classes } = props;
  if (props.column.name === "id") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        <a
          href={
            "https://podio.com/fridaygroup/intranet/apps/bookinger/items/" +
            props.row.id
          }
        >
          {props.row.id}
        </a>
      </TableCell>
    );
  }
  if (props.column.name === "date") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        <Moment format="YYYY/MM/DD HH:mm">{props.row.date}</Moment> -{" "}
        <Moment format="HH:mm">{props.row.dateEnd}</Moment>
      </TableCell>
    );
  }
  if (props.column.name === "companyName") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        {props.row.companyName}
      </TableCell>
    );
  }
  if (props.column.name === "type") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        {props.row.type}
      </TableCell>
    );
  }
  if (props.column.name === "menuNames") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        {props.row.menuNames}
      </TableCell>
    );
  }
  if (props.column.name === "persons") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        {props.row.persons}
      </TableCell>
    );
  }
  if (props.column.name === "bartenders") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        {props.row.bartenders}
      </TableCell>
    );
  }
  if (props.column.name === "process") {
    if (props.row.process === "Sendt") {
      return (
        <TableCell style={CustomTableCellStyles.style3}>
          {props.row.process}
        </TableCell>
      );
    }
    if (props.row.process === "Klar") {
      return (
        <TableCell style={CustomTableCellStyles.style4}>
          {props.row.process}
        </TableCell>
      );
    }
    if (props.row.process === "Pakket") {
      return (
        <TableCell style={CustomTableCellStyles.style2}>
          {props.row.process}
        </TableCell>
      );
    }
    if (props.row.process === "Igang") {
      return (
        <TableCell style={CustomTableCellStyles.style5}>
          {props.row.process}
        </TableCell>
      );
    }
    if (props.row.process === "Printet") {
      return (
        <TableCell style={CustomTableCellStyles.style6}>
          {props.row.process}
        </TableCell>
      );
    }
    if (props.row.process === null) {
      return (
        <TableCell style={CustomTableCellStyles.style1}>Ikke printet</TableCell>
      );
    }
  }
  if (props.column.name === "pakkeDag") {
    if (props.row.pakkedag != null) {
      return (
        <TableCell style={CustomTableCellStyles.style1}>
          {props.row.pakkedag}
        </TableCell>
      );
    } else {
      return (
        <TableCell style={CustomTableCellStyles.style1}>Ikke valgt</TableCell>
      );
    }
  }
  if (props.column.name === "duration") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        <div>{props.row.duration} Hours</div>
      </TableCell>
    );
  }
  if (props.column.name === "view") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        <NavLink to={"/bookingoverview/vbookings/" + props.row.id}>
          <IconButton
            className={classes.button}
            style={{ color: "#d16557" }}
            aria-label="View more"
          >
            <Info />
          </IconButton>
        </NavLink>
        <IconButton
          className={classes.button}
          style={{ color: "#d16557" }}
          aria-label="Update"
          onClick={() => {
            apiFacade
              .getData("/Virtuellebookings/UpdateBookingById/" + props.row.id)
              .then((data) => {
                if (data === "OK") {
                  dispatch({
                    type: types.vckBooking.updateBookingById,
                    payload: true,
                  });
                  dispatch({
                    type: types.vckBooking.bookingId,
                    payload: props.row.id,
                  });
                  setTimeout(() => {
                    dispatch({
                      type: types.vckBooking.updateBookingById,
                      payload: false,
                    });
                  }, 5000);
                }
              });
          }}
        >
          <UpdateRoundedIcon />
        </IconButton>
      </TableCell>
    );
  }
  return <TableCell>No Data</TableCell>;
};

export default withStyles(styles)(BookingCell);
