import types from "../actionTypes";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const initialState = {
  isAuthenticated: false,
  profile: {},
};

// const persistConfig = {
//   key: 'primary',
//   storage: storage,
//    whitelist: ["auth"]
// };

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.authentication.setProfile:
      return {
        ...state,
        profile: { ...action.payload },
      };
    case types.authentication.login:
      return {
        ...state,
        isAuthenticated: action.payload,
      };

    case types.authentication.logout:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
