import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Label,
  Input,
  Alert,
  Button,
} from "reactstrap";
import PanelHeader from "../../../../components/PanelHeader/PanelHeader";
import Switch from "react-bootstrap-switch";
import apiFacade from "../../../../auth/apiFacade";
import "../../../../assets/css/checkboks.css";
import PrintAllFunc from "./PrintAllFunc";
import PackinglistAll from "./PackinglistAll";
import SpecsheetAll from "./SpecsheetAll";
import FrontpageAll from "./FrontpageAll";
import Weekplan from "./Weekplan";
import TotalComponenets from "./TotalComponenets";
import Returliste from "./Returliste";
import PackinglistVCK from "./PackinglistVCK";
import Totalist from "./Totallist";
import { ScreenSize } from "helper/ScreenSize";

const errmsg = () => {
  return (
    <div>
      <Row>
        <Col xs={12} md={4}></Col>
        <Col xs={12} md={5}>
          <Alert color="danger">No Data, Try again with different search</Alert>
        </Col>
      </Row>
    </div>
  );
};
const printOption = ({
  bookings,
  listOfId,
  sjælland,
  jylland,
  fyn,
  udland,
  selectedOption,
  refreshApi,
  componentDtos,
  sortingList,
  startDate,
  endDate,
}) => {
  if (listOfId.length > 0) {
    if (
      selectedOption == 1 &&
      (fyn !== false) |
        (jylland !== false) |
        (sjælland !== false) |
        (udland !== false)
    ) {
      return (
        <div>
          <h3>Packing list</h3>
          <PackinglistAll
            listOfId={listOfId}
            refreshApi={refreshApi}
            componentDtos={componentDtos}
            sortingList={sortingList}
          />
        </div>
      );
    }
    if (
      selectedOption == 2 &&
      (fyn !== false) |
        (jylland !== false) |
        (sjælland !== false) |
        (udland !== false)
    ) {
      return (
        <div>
          <h3>Frontpage</h3>
          <FrontpageAll listOfId={listOfId} refreshApi={refreshApi} />
        </div>
      );
    }
    if (
      selectedOption == 3 &&
      (fyn !== false) |
        (jylland !== false) |
        (sjælland !== false) |
        (udland !== false)
    ) {
      return (
        <div>
          <h3>Menus</h3>
        </div>
      );
    }
    if (
      selectedOption == 4 &&
      (fyn !== false) |
        (jylland !== false) |
        (sjælland !== false) |
        (udland !== false)
    ) {
      return (
        <div>
          <h3>Specsheets</h3>
          <SpecsheetAll listOfId={listOfId} refreshApi={refreshApi} />
        </div>
      );
    }
    if (
      selectedOption == 5 &&
      (fyn !== false) |
        (jylland !== false) |
        (sjælland !== false) |
        (udland !== false)
    ) {
      return (
        <div>
          <h3>Weekplan</h3>
          <Weekplan listOfId={listOfId} refreshApi={refreshApi} />
        </div>
      );
    }
    if (
      selectedOption == 6 &&
      (fyn !== false) |
        (jylland !== false) |
        (sjælland !== false) |
        (udland !== false)
    ) {
      return (
        <div>
          <h3>Total VCK</h3>
          <TotalComponenets
            listOfId={listOfId}
            refreshApi={refreshApi}
            isloading={true}
          />
        </div>
      );
    }
    if (
      selectedOption == 7 &&
      (fyn !== false) |
        (jylland !== false) |
        (sjælland !== false) |
        (udland !== false)
    ) {
      return (
        <div>
          <h3>Returliste</h3>
          <Returliste
            listOfId={listOfId}
            refreshApi={refreshApi}
            componentDtos={componentDtos}
            sortingList={sortingList}
          />
        </div>
      );
    }
    if (
      selectedOption == 8 &&
      (fyn !== false) |
        (jylland !== false) |
        (sjælland !== false) |
        (udland !== false)
    ) {
      return (
        <div>
          <h3>Packing list VCK</h3>
          <PackinglistVCK
            listOfId={listOfId}
            refreshApi={refreshApi}
            componentDtos={componentDtos}
            sortingList={sortingList}
            isloading={true}
          />
        </div>
      );
    }
    if (
      selectedOption == 11 &&
      (fyn !== false) |
        (jylland !== false) |
        (sjælland !== false) |
        (udland !== false)
    ) {
      return (
        <div>
          <h3>Total list</h3>
          <Totalist
            listOfId={listOfId}
            refreshApi={refreshApi}
            componentDtos={componentDtos}
            sortingList={sortingList}
            isloading={true}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      );
    }
  } else {
    return errmsg();
  }
};

export default class Printall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      printing: false,
      sjælland: this.props.location.state.sjælland,
      jylland: this.props.location.state.jylland,
      fyn: this.props.location.state.fyn,
      udland: this.props.location.state.udland,
      mytable: this.props.location.state.allbookings,
      selectedOption: this.props.location.state.selectedOption,
      sendt: this.props.location.state.sendt,
      klar: this.props.location.state.klar,
      pakket: this.props.location.state.pakket,
      igang: this.props.location.state.igang,
      printet: this.props.location.state.printet,
      ikkePrintet: this.props.location.state.ikkePrintet,
      waitingOnFruitVegs: this.props.location.state.waitingOnFruitVegs,
      loaded: this.props.location.state.loaded,
      unloaded: this.props.location.state.unloaded,
      mandag: this.props.location.state.mandag,
      tirsdag: this.props.location.state.tirsdag,
      onsdag: this.props.location.state.onsdag,
      torsdag: this.props.location.state.torsdag,
      fredag: this.props.location.state.fredag,
      lordag: this.props.location.state.lordag,
      sondag: this.props.location.state.sondag,
      ingenDagValgt: this.props.location.state.ingenDagValgt,
      selectAllProcess: this.props.location.state.selectAllProcess,
      selectAllRegions: this.props.location.state.selectAllRegions,
      selectAllWeekDays: this.props.location.state.selectAllWeekDays,
      modalDemo: false,
      bookings: [],
      isLoading: true,
      listOfId: this.props.location.state.bookings,
      refreshApi: true,
      sortingList: [],
      componentDtos: [],
      startDate: this.props.location.state.startDate,
      endDate: this.props.location.state.endDate,
      colSize1: 8,
      colSize2: 4,
    };
  }

  componentDidMount() {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1 || screenSize === 2) {
      this.setState({ colSize1: 12 });
      this.setState({ colSize2: 12 });
    }
    apiFacade.getData("/newsortings").then((sortingData) => {
      this.setState({ sortingList: sortingData.newSortingList });

      this.state.sortingList.map((sorting) => {
        sorting.componentDtos.map((dtos) => {
          this.state.componentDtos.push(dtos);
        });
      });
    });
  }

  selectedIds = async () => {
    this.state.listOfId.length = 0;
    if (this.state.sjælland === true) {
      //Pakket
      if (this.state.pakket === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Sjælland" &&
            this.state.mytable[i].process === "Packed"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Igang
      if (this.state.igang === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Sjælland" &&
            this.state.mytable[i].process === "In Packing"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Printet
      if (this.state.printet === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Sjælland" &&
            this.state.mytable[i].process === "Printed"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Ikke Printet
      if (this.state.ikkePrintet === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            (this.state.mytable[i].region === "Sjælland" &&
              this.state.mytable[i].process === "Not started") ||
            (this.state.mytable[i].region === "Sjælland" &&
              this.state.mytable[i].process === null)
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Loaded
      if (this.state.loaded === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Sjælland" &&
            this.state.mytable[i].process === "Loaded"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Unloaded
      if (this.state.unloaded === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Sjælland" &&
            this.state.mytable[i].process === "Unloaded"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
    }

    // herfra jylland
    if (this.state.jylland === true) {
      //Pakket
      if (this.state.pakket === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Jylland" &&
            this.state.mytable[i].process === "Packed"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Igang
      if (this.state.igang === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Jylland" &&
            this.state.mytable[i].process === "In Packing"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //printet
      if (this.state.printet === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Jylland" &&
            this.state.mytable[i].process === "Printed"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //ikke printet
      if (this.state.ikkePrintet === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            (this.state.mytable[i].region === "Jylland" &&
              this.state.mytable[i].process === "Not started") ||
            (this.state.mytable[i].region === "Jylland" &&
              this.state.mytable[i].process === null)
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Loaded
      if (this.state.loaded === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Jylland" &&
            this.state.mytable[i].process === "Loaded"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Unloaded
      if (this.state.unloaded === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Jylland" &&
            this.state.mytable[i].process === "Unloaded"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
    }
    //herfra fyn
    if (this.state.fyn === true) {
      //Pakket
      if (this.state.pakket === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Fyn" &&
            this.state.mytable[i].process === "Packed"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Igang
      if (this.state.igang === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Fyn" &&
            this.state.mytable[i].process === "In Packing"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Printet
      if (this.state.printet === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Fyn" &&
            this.state.mytable[i].process === "Printed"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //ikke Printet
      if (this.state.ikkePrintet === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            (this.state.mytable[i].region === "Fyn" &&
              this.state.mytable[i].process === "Not started") ||
            (this.state.mytable[i].region === "Fyn" &&
              this.state.mytable[i].process === null)
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Loaded
      if (this.state.loaded === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Fyn" &&
            this.state.mytable[i].process === "Loaded"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Unloaded
      if (this.state.unloaded === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Fyn" &&
            this.state.mytable[i].process === "Unloaded"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
    }
    //herfra udland
    if (this.state.udland === true) {
      //Pakket
      if (this.state.pakket === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Udland" &&
            this.state.mytable[i].process === "Packed"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Igang
      if (this.state.igang === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Udland" &&
            this.state.mytable[i].process === "In Packing"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Printet
      if (this.state.printet === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Udland" &&
            this.state.mytable[i].process === "Printed"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Ikke Printet
      if (this.state.ikkePrintet === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            (this.state.mytable[i].region === "Udland" &&
              this.state.mytable[i].process === "Not started") ||
            (this.state.mytable[i].region === "Udland" &&
              this.state.mytable[i].process === null)
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Loaded
      if (this.state.loaded === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Udland" &&
            this.state.mytable[i].process === "Loaded"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
      //Unloaded
      if (this.state.unloaded === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (
            this.state.mytable[i].region === "Udland" &&
            this.state.mytable[i].process === "Unloaded"
          ) {
            this.state.listOfId.push(this.state.mytable[i].id);
          }
        }
      }
    }
    // END
    return this.state.listOfId;
  };

  refreshData = async (rfd) => {
    this.setState({ refreshApi: rfd });
    this.setState({ printing: true });
  };
  Step1 = async () => {};
  Step2 = async () => {};

  printType = async () => {
    let listofid = await this.selectedIds();

    if (listofid.length > 0) {
      await this.refreshData(true);
    } else {
      await this.refreshData(false);
    }
  };

  componentDidUpdate() {}

  handleOptionChange = (changeEvent) => {
    this.setState({
      selectedOption: changeEvent.target.value,
      refreshApi: false,
    });
  };
  handleChangeFyn = (fyn) => {
    this.setState({ fyn: fyn.state.value, refreshApi: false });
  };
  handleChangeSjælland = (sjælland) => {
    this.setState({ sjælland: sjælland.state.value, refreshApi: false });
  };
  handleChangeJylland = (jylland) => {
    this.setState({ jylland: jylland.state.value, refreshApi: false });
  };
  handleChangeUdland = (udland) => {
    this.setState({ udland: udland.state.value, refreshApi: false });
  };
  handleChangeSelectAllRegions = (selectAllRegions) => {
    if (selectAllRegions.state.value === true) {
      document.getElementsByClassName("sjallandon")[0].click();
      document.getElementsByClassName("jyllandon")[0].click();
      document.getElementsByClassName("fynon")[0].click();
      document.getElementsByClassName("udlandon")[0].click();
    }
    if (selectAllRegions.state.value === false) {
      document.getElementsByClassName("sjallandoff")[0].click();
      document.getElementsByClassName("jyllandoff")[0].click();
      document.getElementsByClassName("fynoff")[0].click();
      document.getElementsByClassName("udlandoff")[0].click();
    }
    this.setState({
      selectAllRegions: selectAllRegions.state.value,
      sjælland: selectAllRegions.state.value,
      jylland: selectAllRegions.state.value,
      fyn: selectAllRegions.state.value,
      udland: selectAllRegions.state.value,
    });
  };

  handleChangeSendt = (sendt) => {
    this.setState({ sendt: sendt.state.value, refreshApi: false });
  };
  handleChangeKlar = (klar) => {
    this.setState({ klar: klar.state.value, refreshApi: false });
  };
  handleChangePakket = (pakket) => {
    this.setState({ pakket: pakket.state.value, refreshApi: false });
  };
  handleChangeIgang = (igang) => {
    this.setState({ igang: igang.state.value, refreshApi: false });
  };
  handleChangePrintet = (printet) => {
    this.setState({ printet: printet.state.value, refreshApi: false });
  };
  handleChangeIkkePrintet = (ikkePrintet) => {
    this.setState({ ikkePrintet: ikkePrintet.state.value, refreshApi: false });
  };
  waitingOnFruitVegs;
  handleChangeIkkePrintet = (waitingOnFruitVegs) => {
    this.setState({
      waitingOnFruitVegs: waitingOnFruitVegs.state.value,
      refreshApi: false,
    });
  };
  handleChangeLoaded = (loaded) => {
    this.setState({ loaded: loaded.state.value, refreshApi: false });
  };
  handleChangeUnloaded = (unloaded) => {
    this.setState({ unloaded: unloaded.state.value, refreshApi: false });
  };
  handleChangeSeletcAllProcess = (selectAllProcess) => {
    if (selectAllProcess.state.value === true) {
      document.getElementsByClassName("igangon")[0].click();
      document.getElementsByClassName("printeton")[0].click();
      document.getElementsByClassName("pakketon")[0].click();
      document.getElementsByClassName("ikkeprinteton")[0].click();
      document.getElementsByClassName("loadedon")[0].click();
      document.getElementsByClassName("unloadedon")[0].click();
    }
    if (selectAllProcess.state.value === false) {
      document.getElementsByClassName("igangoff")[0].click();
      document.getElementsByClassName("printetoff")[0].click();
      document.getElementsByClassName("pakketoff")[0].click();
      document.getElementsByClassName("ikkeprintetoff")[0].click();
      document.getElementsByClassName("loadedoff")[0].click();
      document.getElementsByClassName("unloadedoff")[0].click();
    }
    this.setState({
      selectAllProcess: selectAllProcess.state.value,

      igang: selectAllProcess.state.value,
      pakket: selectAllProcess.state.value,
      printet: selectAllProcess.state.value,
      ikkePrintet: selectAllProcess.state.value,
      loaded: selectAllProcess.state.value,
      unloaded: selectAllProcess.state.value,
      refreshApi: false,
    });
  };
  handleChangeMandag = (mandag) => {
    this.setState({ mandag: mandag.state.value, refreshApi: false });
  };
  handleChangeTirsdag = (tirsdag) => {
    this.setState({ tirsdag: tirsdag.state.value, refreshApi: false });
  };
  handleChangeOnsdag = (onsdag) => {
    this.setState({ onsdag: onsdag.state.value, refreshApi: false });
  };
  handleChangeTorsdag = (torsdag) => {
    this.setState({ torsdag: torsdag.state.value, refreshApi: false });
  };
  handleChangeFredag = (fredag) => {
    this.setState({ fredag: fredag.state.value, refreshApi: false });
  };
  handleChangeLordag = (lordag) => {
    this.setState({ lordag: lordag.state.value, refreshApi: false });
  };
  handleChangeSondag = (sondag) => {
    this.setState({ sondag: sondag.state.value, refreshApi: false });
  };

  handleChangeingenDagValgt = (ingenDagValgt) => {
    this.setState({
      ingenDagValgt: ingenDagValgt.state.ingenDagValgt,
      refreshApi: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(e);
    //console.log("You have selected:", this.state.selectedOption);
    //console.log("sjælland: " + this.state.sjælland);
    //console.log("jylland: " + this.state.jylland);
    //console.log("fyn: " + this.state.fyn);
    //console.log("sendt: " + this.state.sendt);
    //console.log("Klar: " + this.state.klar);
  };
  viewnow = () => {
    if (this.state.selectedOption != null) {
      if (
        this.state.sjælland != false ||
        this.state.fyn != false ||
        this.state.jylland != false ||
        this.state.udland != false
      ) {
        if (
          this.state.printet != false ||
          this.state.ikkePrintet != false ||
          this.state.igang != false ||
          this.state.pakket != false
        ) {
          return (
            <Button
              type="submit"
              style={{ marginLeft: "auto", backgroundColor: "#1BEB11" }}
              onClick={this.printType}
            >
              View
            </Button>
          );
        }
      }
    }

    if (
      this.state.sjælland === false ||
      this.state.fyn === false ||
      this.state.jylland === false ||
      this.state.udland === false ||
      this.state.sendt === false ||
      this.state.klar === false ||
      this.state.printet === false ||
      this.state.ikkePrintet === false ||
      this.state.igang === false ||
      this.state.pakket === false ||
      this.state.mandag === false ||
      this.state.tirsdag === false ||
      this.state.onsdag === false ||
      this.state.torsdag === false ||
      this.state.fredag === false ||
      this.state.lordag === false ||
      this.state.sondag === false ||
      this.state.ingenDagValgt === false ||
      this.state.selectedOption === null
    ) {
      return (
        <div style={{ fontSize: 14 }}>
          <p className="italic">
            {" "}
            Choose one or more region, Proccess, Pakke dag & printing type to
            view
          </p>
        </div>
      );
    }
  };

  render() {
    const { colSize2, colSize1 } = this.state;
    if (this.state.isLoading == false) {
    }

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md={colSize1}>
              <Card>
                <CardHeader>
                  <CardTitle>Print all</CardTitle>
                </CardHeader>
                <CardBody>{printOption(this.state)}</CardBody>
              </Card>
            </Col>
            <Col md={colSize2}>
              <Card>
                <CardHeader>
                  <CardTitle>Menu</CardTitle>
                </CardHeader>
                <CardBody>
                  <form name="printall" onSubmit={this.handleSubmit}>
                    <Row>
                      <Col xs={6} md={4}>
                        <p>Regions</p>
                        <p
                          className="category"
                          style={{ color: "#4c7093", fontSize: "12px" }}
                        >
                          Select All
                        </p>
                        <Switch
                          onText={<i className="now-ui-icons ui-1_check" />}
                          onColor="custom"
                          offText={
                            <i className="now-ui-icons ui-1_simple-remove" />
                          }
                          checked={this.state.selectAllRegions}
                          onChange={this.handleChangeSelectAllRegions}
                          defaultValue={this.state.selectAllRegions}
                          id="1"
                        />
                        <p className="category">Sjælland</p>
                        <Switch
                          onText={<i className="sjallandoff" />}
                          onColor="custom"
                          offText={<i className="sjallandon" />}
                          checked={this.state.sjælland}
                          onChange={this.handleChangeSjælland}
                          defaultValue={this.state.sjælland}
                          id="1"
                        />
                        <p className="category">Jylland</p>
                        <Switch
                          onText={<i className="jyllandoff" />}
                          onColor="custom"
                          offText={<i className="jyllandon" />}
                          checked={this.state.jylland}
                          onChange={this.handleChangeJylland}
                          defaultValue={this.state.jylland}
                          id="2"
                        />
                        <p className="category">Fyn</p>
                        <Switch
                          onText={<i className="fynoff" />}
                          onColor="custom"
                          offText={<i className="fynon" />}
                          checked={this.state.fyn}
                          onChange={this.handleChangeFyn}
                          defaultValue={this.state.fyn}
                          id="3"
                        />
                        <p className="category">Udland</p>
                        <Switch
                          onText={<i className="udlandoff" />}
                          onColor="custom"
                          offText={<i className="udlandon" />}
                          checked={this.state.udland}
                          onChange={this.handleChangeUdland}
                          defaultValue={this.state.udland}
                          id="4"
                        />
                      </Col>
                      <Col xs={6} md={4}>
                        <p>Process</p>
                        <p
                          className="category"
                          style={{ color: "#4c7093", fontSize: "12px" }}
                        >
                          Select All
                        </p>
                        <Switch
                          onText={<i className="now-ui-icons ui-1_check" />}
                          onColor="custom"
                          offText={
                            <i className="now-ui-icons ui-1_simple-remove" />
                          }
                          checked={this.state.selectAllProcess}
                          onChange={this.handleChangeSeletcAllProcess}
                          defaultValue={this.state.selectAllProcess}
                          id="1"
                        />
                        <p
                          className="category"
                          style={{ color: "#e2b659", fontSize: "12px" }}
                        >
                          Packed
                        </p>
                        <Switch
                          onText={<i className="pakketoff" />}
                          onColor="custom"
                          //animate={false}
                          offText={<i className="pakketon" />}
                          checked={this.state.pakket}
                          onChange={this.handleChangePakket}
                          defaultValue={this.state.pakket}
                          id="sendt"
                        />
                        <p
                          className="category"
                          style={{ color: "#ff80a8", fontSize: "12px" }}
                        >
                          In Packing
                        </p>
                        <Switch
                          onText={<i className="igangoff" />}
                          onColor="custom"
                          offText={<i className="igangon" />}
                          checked={this.state.igang}
                          onChange={this.handleChangeIgang}
                          defaultValue={this.state.igang}
                          id="igang"
                        />
                        <p
                          className="category"
                          style={{ color: "#ff0000", fontSize: "12px" }}
                        >
                          Printed
                        </p>
                        <Switch
                          onText={<i className="loadedoff" />}
                          onColor="custom"
                          offText={<i className="loadedon" />}
                          checked={this.state.loaded}
                          onChange={this.handleChangeIgang}
                          defaultValue={this.state.loaded}
                          id="loaded"
                        />
                        <p
                          className="category"
                          style={{ color: "#ff0000", fontSize: "12px" }}
                        >
                          Loaded
                        </p>
                        <Switch
                          onText={<i className="unloadedoff" />}
                          onColor="custom"
                          offText={<i className="unloadedon" />}
                          checked={this.state.unloaded}
                          onChange={this.handleChangeIgang}
                          defaultValue={this.state.unloaded}
                          id="unloaded"
                        />
                        <p
                          className="category"
                          style={{ color: "#ff0000", fontSize: "12px" }}
                        >
                          Unloaded
                        </p>
                        <Switch
                          onText={<i className="printetoff" />}
                          onColor="custom"
                          offText={<i className="printeton" />}
                          checked={this.state.printet}
                          onChange={this.handleChangePrintet}
                          defaultValue={this.state.printet}
                          id="printet"
                        />
                        <p className="category" style={{ fontSize: "12px" }}>
                          Not started
                        </p>
                        <Switch
                          onText={<i className="ikkeprintetoff" />}
                          onColor="custom"
                          offText={<i className="ikkeprinteton" />}
                          checked={this.state.ikkePrintet}
                          onChange={this.handleChangeIkkePrintet}
                          defaultValue={this.state.ikkePrintet}
                          id="ikkeprintet"
                        />
                        <p className="category" style={{ fontSize: "12px" }}>
                          Waiting On Fruit & Vegs.
                        </p>
                        <Switch
                          onText={<i className="waitingOnFruitVegs" />}
                          onColor="custom"
                          offText={<i className="waitingOnFruitVegs" />}
                          checked={this.state.waitingOnFruitVegs}
                          onChange={this.handleChangeWaitingOnFruitVegs}
                          defaultValue={this.state.waitingOnFruitVegs}
                          id="waitingOnFruitVegs"
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <p>Printing type</p>

                        <FormGroup check className="form-check-radio">
                          <p className="category"> only one type at the time</p>
                          <Label className="mycontainer">
                            <Input
                              className="checkmark"
                              defaultValue="1"
                              id="option1"
                              name="radio"
                              type="radio"
                              checked={this.state.selectedOption === "1"}
                              onChange={this.handleOptionChange}
                            />
                            <p>Packing List</p> <span className="checkmark" />
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio">
                          <Label className="mycontainer">
                            <Input
                              className="checkmark"
                              defaultValue="2"
                              id="2"
                              name="exampleRadios"
                              type="radio"
                              checked={this.state.selectedOption === "2"}
                              onChange={this.handleOptionChange}
                            />
                            <p>Front Pages</p> <span className="checkmark" />
                          </Label>
                        </FormGroup>

                        <FormGroup check className="form-check-radio">
                          <Label className="mycontainer">
                            <Input
                              className="checkmark"
                              defaultValue="11"
                              id="11"
                              name="exampleRadios"
                              type="radio"
                              checked={this.state.selectedOption === "11"}
                              onChange={this.handleOptionChange}
                            />
                            <p>Total list</p> <span className="checkmark" />
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio">
                          <Label className="mycontainer">
                            <Input
                              className="checkmark"
                              defaultValue="4"
                              id="4"
                              name="exampleRadios"
                              type="radio"
                              checked={this.state.selectedOption === "4"}
                              onChange={this.handleOptionChange}
                            />
                            <p>SpecSheet</p> <span className="checkmark" />
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio">
                          <Label className="mycontainer">
                            <Input
                              className="checkmark"
                              defaultValue="7"
                              id="7"
                              name="exampleRadios"
                              type="radio"
                              checked={this.state.selectedOption === "7"}
                              onChange={this.handleOptionChange}
                            />
                            <p>Returliste</p> <span className="checkmark" />
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div>
                      "Press on View to update if regions has been changed"
                    </div>
                    <div style={{ display: "flex" }}>{this.viewnow()}</div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
