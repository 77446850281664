import React, { useEffect, useState } from "react";
import apiFacade from "../../../../auth/apiFacade";
import BarLoader from "react-spinners/BarLoader";
import jsPDF from "jspdf";

const Weekplan = ({ listOfId, refreshApi }) => {
  const [myListOfIds, setMyListOfIds] = useState([]);
  const [myData, setMyData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  // const cols = [{
  //     title: "Details",
  //     dataKey: 'details'
  // }, {
  //     title: "Values",
  //     dataKey: 'values'
  // }];

  useEffect(() => {
    setMyListOfIds(listOfId);
    if (refreshApi) {
      apiFacade
        .getData("/bookings/getbookings/" + listOfId.join())
        .then((data) => {
          setMyData(data);

          //setMyListOfIds(data)
        })
        .finally(() => setLoaded(true));
    }
  }, [refreshApi]);

  // var bookingInfoHeadRow = [
  //     { title: "Title", dataKey: "title" },
  //     { title: "Info", dataKey: "value" },
  // ];

  // function dynamicHeadRow() {
  //     return (
  //         { title: "Title", dataKey: "title" },
  //         { title: "Info", dataKey: "value" }
  //     )
  // }

  //  var doc = new jsPDF('p', 'pt');

  if (myData.length > 0) {
    let newBookingInfo = [];
    let idD = [];
    idD.push({ value: "Job ID" });
    listOfId.map((id, i) => {});

    myData.map((data) => {
      const body = [];
      body.push({
        region: data.region,
        address: data.address,
        bartenders: data.bartenders,
        contactPerson: data.contactPerson,
      });

      newBookingInfo.push({
        title: "Company name",
        value: data.companyName,
      });
      newBookingInfo.push({
        title: "Menu",
        value: data.bookinginfo.menutype,
      });

      newBookingInfo.push({
        title: "Date",
        value: data.date,
      });
      newBookingInfo.push({
        title: "Type",
        value: data.type,
      });
      newBookingInfo.push({
        title: "Bartenders",
        value: data.bartenders,
      });
      newBookingInfo.push({
        title: "Bar Modules",
        value: data.barModules,
      });
      newBookingInfo.push({
        title: "Duration",
        value: data.duration,
      });
      newBookingInfo.push({
        title: "Region",
        value: data.region,
      });
      newBookingInfo.push({
        title: "Persons",
        value: data.persons,
      });
      if (data.barbacks === 0) {
        newBookingInfo.push({
          title: "Barbacks",
          value: "None",
        });
      } else {
        newBookingInfo.push({
          title: "Barbacks",
          value: data.barbacks,
        });
      }

      if (data.waiters === 0) {
        newBookingInfo.push({
          title: "Waiters",
          value: "None",
        });
      } else {
        newBookingInfo.push({
          title: "Waiters",
          value: data.waiters,
        });
      }

      if (data.industrialDishwasher === false) {
        newBookingInfo.push({
          title: "IndustrialDishwasher",
          value: "None",
        });
      } else {
        newBookingInfo.push({
          title: "IndustrialDishwasher",
          value: data.industrialDishwasher,
        });
      }
      newBookingInfo.push({
        title: "Barinfo/Praktisk",
        value: data.additionalInfo,
      });
    });
  }

  if (refreshApi) {
    return (
      <div>
        <div className="sweet-loading" style={{ marginLeft: 300 }}>
          <BarLoader
            height={5}
            width={200}
            sizeUnit={"px"}
            size={500}
            color={"#4C8FBF"}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ marginLeft: 450 }}>
          <h5>Press View</h5>
        </div>
      </div>
    );
  }
};

export default Weekplan;
