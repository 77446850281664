import React from "react";
import { Row, Col, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { Typography } from "@material-ui/core/";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import types from "../../../redux/actionTypes";
import apiFacade from "../../../auth/apiFacade";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  customizedButton: {
    position: "absolute",
    left: "95%",
    top: "-9%",
    backgroundColor: "lightgray",
    color: "gray",
  },
}));

function cardNames(calculation_type, regionSelected) {
  let listOfNames = [];

  if (regionSelected.length > 0) {
    const names = regionSelected.map((data) => {
      if (calculation_type === data.calculationType) {
        listOfNames.push(data.region);
      }
    });
  }

  return (
    <div
      style={{ transition: "transform 2s ease-in-out, opacity 2s ease-in-out" }}
    >
      {listOfNames.join(", ")}
    </div>
  );
}

function typeOne(
  value,
  valuePerson,
  handleSliderChange,
  handleInputChange,
  handleBlur,
  handleSliderChangePerson,
  handleInputChangePerson,
  handleBlurPerson,
  valueSpild,
  handleSliderChangeSpild,
  handleInputChangeSpild,
  handleBlurSpild,
  handleClickOpen,
  handleClose,
  openDialog,
  region,
  handleCloseAndSave,
  barArmCal1,
  regionSelected,
  readOnly1,
  handleReadOnly,
  handleKmChange,
  handlePersonChange,
  dateFromDb,
  dispatch,
  kmTypeTwo,
  personTypeTwo,
  startcocktailTypeTwo,
  ekstraCocktailsTypeTwo,
  bartenderTypeTwo,
  skaleringfactorTypeTwo
) {
  let procentPerson = true;
  let procentKm = true;

  if (barArmCal1.personer > 0 && readOnly1 === false) {
    procentPerson = false;
  } else {
    procentPerson = true;
  }

  if (barArmCal1.km > 0 && readOnly1 === false) {
    procentKm = false;
  } else {
    procentKm = true;
  }

  return (
    <Card className="card-chart">
      <CardHeader>
        <Row>
          <Col xs={7} md={8}>
            <h6>{cardNames(1, regionSelected)}</h6>
          </Col>
          <Col
            xs={5}
            md={4}
            style={{
              textAlign: "right",
              marginBottom: "15px",
            }}
          >
            <Button
              variant="outlined"
              style={{ backgroundColor: "#d1d1d1", color: "#d16557" }}
              onClick={handleClickOpen}
            >
              Add region
            </Button>
            <Dialog open={openDialog} onClose={handleClose}>
              <DialogTitle style={{ textAlign: "center" }}>
                Select region
              </DialogTitle>
              <DialogContent>{region(1)}</DialogContent>
              <DialogActions>
                <Button
                  style={{
                    position: "absolute",
                    left: "1%",
                    bottom: "2%",
                    color: "#4CAF50",
                  }}
                  onClick={handleCloseAndSave}
                >
                  {" "}
                  Save
                </Button>

                <Button onClick={handleClose} color="secondary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row style={{ marginBottom: "15px" }}>
          <Col xs="3">
            <TextField
              required
              id="startcocktail"
              label="Start cocktail"
              defaultValue={startcocktailTypeTwo}
              type="number"
              onChange={(event) =>
                event.target.value < 0
                  ? (event.target.value = 0)
                  : dispatch({
                      type: types.calculations.barArmCal1,
                      payload: {
                        ...barArmCal1,
                        startcocktail: Number(event.target.value),
                      },
                    })
              }
              disabled={readOnly1}
              inputProps={{
                step: 0.01,
                min: 0,
                "aria-labelledby": "input-slider",
              }}
            />{" "}
          </Col>
          <Col xs="3">
            <TextField
              required
              id="skaleringfactor"
              label="Skalering"
              defaultValue={skaleringfactorTypeTwo}
              type="number"
              onChange={(event) =>
                event.target.value < 0
                  ? (event.target.value = 0)
                  : dispatch({
                      type: types.calculations.barArmCal1,
                      payload: {
                        ...barArmCal1,
                        skaleringfactor: Number(event.target.value),
                      },
                    })
              }
              disabled={readOnly1}
              inputProps={{
                step: 0.01,
                min: 0,
                "aria-labelledby": "input-slider",
              }}
            />
          </Col>
          <Col xs="3">
            <TextField
              required
              id="ekstraCocktails"
              label="Ekstra cocktails"
              defaultValue={ekstraCocktailsTypeTwo}
              type="number"
              onChange={(event) =>
                event.target.value < 0
                  ? (event.target.value = 0)
                  : dispatch({
                      type: types.calculations.barArmCal1,
                      payload: {
                        ...barArmCal1,
                        ekstraCocktails: Number(event.target.value),
                      },
                    })
              }
              disabled={readOnly1}
              inputProps={{
                step: 0.01,
                min: 0,
                "aria-labelledby": "input-slider",
              }}
            />{" "}
          </Col>
          <Col xs="3">
            <TextField
              required
              id="bartender"
              label="Cocktails per bartender"
              defaultValue={bartenderTypeTwo}
              type="number"
              onChange={(event) =>
                event.target.value < 0
                  ? (event.target.value = 0)
                  : dispatch({
                      type: types.calculations.barArmCal1,
                      payload: {
                        ...barArmCal1,
                        bartender: Number(event.target.value),
                      },
                    })
              }
              disabled={readOnly1}
              inputProps={{
                step: 1,
                min: 0,
                "aria-labelledby": "input-slider",
              }}
            />{" "}
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col xs="2">
            <TextField
              required
              id="km"
              label="Km"
              defaultValue={kmTypeTwo}
              type="number"
              onChange={(event) =>
                event.target.value < 0
                  ? (event.target.value = 0)
                  : handleKmChange(event)
              }
              disabled={readOnly1}
              inputProps={{
                step: 0.01,
                min: 0,
                "aria-labelledby": "input-slider",
              }}
            />{" "}
          </Col>
          <Col xs={7} md={8}>
            <Typography id="input-slider" gutterBottom>
              Procent for km
            </Typography>
            <Slider
              disabled={procentKm}
              value={typeof value === "number" ? value : 0}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              step={0.1}
              max={15}
            />
          </Col>
          <Col xs={3} md={2} style={{ marginTop: "15px" }}>
            <Input
              disabled={procentKm}
              style={{ width: 55 }}
              value={typeof value === "number" ? value : 0}
              margin="dense"
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 0.1,
                min: 0,
                max: 15,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="2">
            <TextField
              required
              id="person"
              label="Personer"
              defaultValue={personTypeTwo}
              type="number"
              onChange={(event) =>
                checkPerson(event.target.value)
                  ? (event.target.value = 0)
                  : handlePersonChange(event)
              }
              disabled={readOnly1}
              inputProps={{
                step: 1,
                min: 0,
                "aria-labelledby": "input-slider",
              }}
            />{" "}
          </Col>
          <Col  xs={7} md={8}>
            <Typography id="input-slider" gutterBottom>
              Procent for personer
            </Typography>
            <Slider
              disabled={procentPerson}
              value={typeof valuePerson === "number" ? valuePerson : 0}
              onChange={handleSliderChangePerson}
              aria-labelledby="input-slider"
              step={0.1}
              max={15}
            />
          </Col>
          <Col xs={3} md={2} style={{ marginTop: "15px" }}>
            <Input
              disabled={procentPerson}
              style={{ width: 55 }}
              value={typeof valuePerson === "number" ? valuePerson : 0}
              margin="dense"
              size="small"
              onChange={handleInputChangePerson}
              onBlur={handleBlurPerson}
              inputProps={{
                step: 0.1,
                min: 0,
                max: 15,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={9} md={10}>
            <Typography id="input-slider" gutterBottom>
              Procent for spild
            </Typography>
            <Slider
              disabled={readOnly1}
              value={typeof valueSpild === "number" ? valueSpild : 0}
              onChange={handleSliderChangeSpild}
              aria-labelledby="input-slider"
              step={0.1}
              max={5}
            />
          </Col>
          <Col xs={3} md={2} style={{ marginTop: "15px" }}>
            <Input
              style={{ width: 55 }}
              value={typeof valueSpild === "number" ? valueSpild : 0}
              margin="dense"
              size="small"
              onChange={handleInputChangeSpild}
              onBlur={handleBlurSpild}
              disabled={readOnly1}
              inputProps={{
                step: 0.1,
                min: 0,
                max: 5,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        {buttons(
          1,
          handleReadOnly,
          readOnly1,
          barArmCal1,
          dispatch,
          dateFromDb
        )}
      </CardFooter>
    </Card>
  );
}

function typeTwo(
  value,
  valuePerson,
  handleSliderChange,
  handleInputChange,
  handleBlur,
  handleSliderChangePerson,
  handleInputChangePerson,
  handleBlurPerson,
  valueSpild,
  handleSliderChangeSpild,
  handleInputChangeSpild,
  handleBlurSpild,
  handleClickOpen,
  handleClose,
  openDialog,
  region,
  handleCloseAndSave,
  barArmCal2,
  regionSelected,
  readOnly2,
  handleReadOnly,
  handleKmChange,
  handlePersonChange,
  dateFromDb,
  dispatch,
  kmTypeTwo,
  personTypeTwo,
  startcocktailTypeTwo,
  ekstraCocktailsTypeTwo,
  bartenderTypeTwo,
  skaleringfactorTypeTwo
) {
  let procentPerson = true;
  let procentKm = true;

  if (barArmCal2.personer > 0 && readOnly2 === false) {
    procentPerson = false;
  } else {
    procentPerson = true;
  }

  if (barArmCal2.km > 0 && readOnly2 === false) {
    procentKm = false;
  } else {
    procentKm = true;
  }

  return (
    <Card className="card-chart">
      <CardHeader>
        <Row>
          <Col xs={7} md={8}>
            <h6>{cardNames(2, regionSelected)}</h6>
          </Col>
          <Col
            xs={5}
            md={4}
            style={{
              textAlign: "right",
              marginBottom: "15px",
            }}
          >
            <Button
              variant="outlined"
              style={{ backgroundColor: "#d1d1d1", color: "#d16557" }}
              onClick={handleClickOpen}
            >
              Add region
            </Button>
            <Dialog open={openDialog} onClose={handleClose}>
              <DialogTitle style={{ textAlign: "center" }}>
                Select region
              </DialogTitle>
              <DialogContent>{region(2)}</DialogContent>
              <DialogActions>
                <Button
                  style={{
                    position: "absolute",
                    left: "1%",
                    bottom: "2%",
                    color: "#4CAF50",
                  }}
                  onClick={handleCloseAndSave}
                >
                  Save
                </Button>
                <Button onClick={handleClose} color="secondary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row style={{ marginBottom: "15px" }}>
          <Col xs="3">
            <TextField
              required
              id="startcocktailTypeTwo"
              label="Start cocktail"
              defaultValue={startcocktailTypeTwo}
              type="number"
              onChange={(event) =>
                event.target.value < 0
                  ? (event.target.value = 0)
                  : dispatch({
                      type: types.calculations.barArmCal2,
                      payload: {
                        ...barArmCal2,
                        startcocktail: Number(event.target.value),
                      },
                    })
              }
              disabled={readOnly2}
              inputProps={{
                step: 0.01,
                min: 0,
                "aria-labelledby": "input-slider",
              }}
            />{" "}
          </Col>
          <Col xs="3">
            <TextField
              required
              id="skaleringfactorTypeTwo"
              label="Skalering"
              defaultValue={skaleringfactorTypeTwo}
              type="number"
              onChange={(event) =>
                event.target.value < 0
                  ? (event.target.value = 0)
                  : dispatch({
                      type: types.calculations.barArmCal2,
                      payload: {
                        ...barArmCal2,
                        skaleringfactor: Number(event.target.value),
                      },
                    })
              }
              disabled={readOnly2}
              inputProps={{
                step: 0.01,
                min: 0,
                "aria-labelledby": "input-slider",
              }}
            />
          </Col>
          <Col xs="3">
            <TextField
              required
              id="ekstraCocktailsTypeTwo"
              label="Ekstra cocktails"
              defaultValue={ekstraCocktailsTypeTwo}
              type="number"
              onChange={(event) =>
                event.target.value < 0
                  ? (event.target.value = 0)
                  : dispatch({
                      type: types.calculations.barArmCal2,
                      payload: {
                        ...barArmCal2,
                        ekstraCocktails: Number(event.target.value),
                      },
                    })
              }
              disabled={readOnly2}
              inputProps={{
                step: 0.01,
                min: 0,
                "aria-labelledby": "input-slider",
              }}
            />{" "}
          </Col>
          <Col xs="3">
            <TextField
              required
              id="bartenderTypeTwo"
              label="Cocktails per bartender"
              defaultValue={bartenderTypeTwo}
              type="number"
              onChange={(event) =>
                event.target.value < 0
                  ? (event.target.value = 0)
                  : dispatch({
                      type: types.calculations.barArmCal2,
                      payload: {
                        ...barArmCal2,
                        bartender: Number(event.target.value),
                      },
                    })
              }
              disabled={readOnly2}
              inputProps={{
                step: 1,
                min: 0,
                "aria-labelledby": "input-slider",
              }}
            />{" "}
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col xs="2">
            <TextField
              required
              id="kmTypeTwo"
              label="Km"
              defaultValue={kmTypeTwo}
              type="number"
              onChange={(event) =>
                event.target.value < 0
                  ? (event.target.value = 0)
                  : handleKmChange(event)
              }
              disabled={readOnly2}
              inputProps={{
                step: 0.01,
                min: 0,
                "aria-labelledby": "input-slider",
              }}
            />{" "}
          </Col>
          <Col xs={7} md={8}>
            <Typography id="input-slider" gutterBottom>
              Procent for km
            </Typography>
            <Slider
              disabled={procentKm}
              value={typeof value === "number" ? value : 0}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              step={0.1}
              max={15}
            />
          </Col>
          <Col xs={3} md={2} style={{ marginTop: "15px" }}>
            <Input
              disabled={procentKm}
              style={{ width: 55 }}
              value={typeof value === "number" ? value : 0}
              margin="dense"
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 0.1,
                min: 0,
                max: 15,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="2">
            <TextField
              required
              id="personTypeTwo"
              label="Personer"
              defaultValue={personTypeTwo}
              type="number"
              onChange={(event) =>
                checkPerson(event.target.value)
                  ? (event.target.value = 0)
                  : handlePersonChange(event)
              }
              disabled={readOnly2}
              inputProps={{
                step: 1,
                min: 0,
                "aria-labelledby": "input-slider",
              }}
            />{" "}
          </Col>
          <Col xs={7} md={8}>
            <Typography id="input-slider" gutterBottom>
              Procent for personer
            </Typography>
            <Slider
              disabled={procentPerson}
              value={typeof valuePerson === "number" ? valuePerson : 0}
              onChange={handleSliderChangePerson}
              aria-labelledby="input-slider"
              step={0.1}
              max={15}
            />
          </Col>
          <Col xs={3} md={2} style={{ marginTop: "15px" }}>
            <Input
              disabled={procentPerson}
              style={{ width: 55 }}
              value={typeof valuePerson === "number" ? valuePerson : 0}
              margin="dense"
              size="small"
              onChange={handleInputChangePerson}
              onBlur={handleBlurPerson}
              inputProps={{
                step: 0.1,
                min: 0,
                max: 15,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={9} md={10}>
            <Typography id="input-slider" gutterBottom>
              Procent for spild
            </Typography>
            <Slider
              disabled={readOnly2}
              value={typeof valueSpild === "number" ? valueSpild : 0}
              onChange={handleSliderChangeSpild}
              aria-labelledby="input-slider"
              step={0.1}
              max={5}
            />
          </Col>
          <Col xs={3} md={2} style={{ marginTop: "15px" }}>
            <Input
              style={{ width: 55 }}
              value={typeof valueSpild === "number" ? valueSpild : 0}
              margin="dense"
              size="small"
              onChange={handleInputChangeSpild}
              onBlur={handleBlurSpild}
              disabled={readOnly2}
              inputProps={{
                step: 0.1,
                min: 0,
                max: 5,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Col>
        </Row>
      </CardBody>

      <CardFooter>
        {buttons(
          2,
          handleReadOnly,
          readOnly2,
          barArmCal2,
          dispatch,
          dateFromDb
        )}
      </CardFooter>
    </Card>
  );
}

function buttons(
  calType,
  handleReadOnly,
  readOnly2,
  barArmCal2,
  dispatch,
  dateFromDb
) {
  if (readOnly2) {
    return (
      <Row>
        <Col
          sm="12"
          style={{
            textAlign: "right",
          }}
        >
          <Button
            onClick={() => handleReadOnly(calType, !readOnly2)}
            variant="contained"
            style={{
              backgroundColor: "#d16557",
              color: "white",
            }}
            size="small"
            color="primary"
            className={useStyles.button}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col
          xs="2"
          style={{
            textAlign: "left",
          }}
        >
          <Button
            onClick={() => {
              handleReadOnly(calType, !readOnly2);
              save(calType, barArmCal2);
            }}
            variant="contained"
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
            }}
            size="small"
            className={useStyles.button}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </Col>
        <Col xs="8"></Col>
        <Col
          xs="2"
          style={{
            textAlign: "right",
          }}
        >
          <Button
            onClick={() => {
              handleReadOnly(calType, !readOnly2);
              cancel(calType, dispatch, dateFromDb);
            }}
            variant="contained"
            style={{
              color: "white",
            }}
            size="small"
            color="secondary"
            className={useStyles.button}
            startIcon={<ClearIcon />}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    );
  }
}

function cancel(calType, dispatch, dateFromDb) {
  if (calType === 1) {
    dispatch({
      type: types.calculations.dateFromDb1,
      payload: !dateFromDb,
    });
    dispatch({
      type: types.calculations.calFormBool1,
      payload: true,
    });
  } else if (calType === 2) {
    dispatch({
      type: types.calculations.dateFromDb2,
      payload: !dateFromDb,
    });
    dispatch({
      type: types.calculations.calFormBool2,
      payload: true,
    });
  }
}

function save(calType, saveData) {
  setTimeout(() => {
    apiFacade
      .putData(
        "https://pakkemodelapi.azurewebsites.net/api/CalculationBarArrangements/" +
          calType,
        saveData
      )
      .then((data) => {});
  }, 500);
}

function checkPerson(n) {
  var result = n - Math.floor(n) !== 0;

  if (result || n < 0) return true;
  else return false;
}

export { typeOne, typeTwo };
