import React from 'react'
import Components_s from './Components_s';
import Components_ from './Components_';
import { Switch, Route } from "react-router-dom";

export default function Components_sview() {
  return (
    <div>
      <React.Fragment>
        <Switch>
          <Route
            exact
            path="/components"
            render={(props) => <Components_s {...props} />}
          />
          <Route
            exact
            path="/components/:id"
            render={(props) => <Components_ {...props} />}
          />
        </Switch>
      </React.Fragment>
    </div>
  );
}
