import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Samplings from "./Samplings";
import Sampling from "./Sampling";
import Printall from "./Print/PrintAll/PrintAll";
import Packinglistprint from "./Print/PrintSinglePage/Packinglistprint";
import Frontpage from "./Print/PrintSinglePage/Frontpage";
import Returliste from "./Print/PrintSinglePage/Returlist";

export class Samplingsview extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            exact
            path="/samplings"
            render={props => <Samplings {...props} />}
          />
          <Route
            path="/samplings/printall"
            render={props => <Printall {...props} />}
          />
          <Route
            path="/samplings/:id/packinglistprint"
            render={props => <Packinglistprint {...props} />}
          />
          <Route
            path="/samplings/:id/returliste"
            render={props => <Returliste {...props} />}
          />
          <Route
            path="/samplings/:id/frontpage"
            render={props => <Frontpage {...props} />}
          />
          <Route
            path="/samplings/:id"
            render={props => <Sampling {...props} />}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Samplingsview;
