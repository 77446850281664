import React, { useState, useEffect } from "react";
import apiFacade from "../../../../auth/apiFacade";
import BarLoader from "react-spinners/BarLoader";
import jsPDF from "jspdf";
import "jspdf-autotable";
import hexToRgb from "pretty-easy-hex-to-rgb";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import types from "../../../../redux/actionTypes";
import { ScreenSize } from "helper/ScreenSize";

var bookingInfoHeadRow = [
  { header: "Title", dataKey: "title" },
  { header: "Info", dataKey: "info" },
];

const PackinglistAll = ({
  listOfId,
  refreshApi,
  componentDtos,
  sortingList,
}) => {
  const [myListOfIds, setMyListOfIds] = useState([]);
  const [myData, setMyData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [myComponentDtos, setMyComponentDtos] = useState([]);
  const [mySortingList, setmySortingList] = useState([]);
  const dispatch = useDispatch();
  const [packinglistRedux, setPackinglistRedux] = useState([]);
  const [pdfWidth, setPdfWidth] = useState(700);
  const [pdfHeight, setPdfHeight] = useState(900);

  useEffect(() => {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      setPdfWidth(300);
      setPdfHeight(350);
    } else if (screenSize === 2) {
      setPdfWidth(675);
      setPdfHeight(800);
    }

    setMyListOfIds(listOfId);
    setMyComponentDtos(componentDtos);
    setmySortingList(sortingList);
    if (refreshApi) {
      apiFacade
        .getData("/bookings/newgetlistofpacking/" + listOfId.join())
        .then((data) => {
          setMyData(data);

          //setMyListOfIds(data)
        })
        .finally(() => setLoaded(true));
    }
  }, [refreshApi]);

  let pageCount = 0;
  let totalPage = 0;
  let newCount = 0;
  let preSetpackinglistRedux = [];
  if (myData.length > 0) {

    let contentIframe = null;
    let pageNr = myData.length - 1;
    var doc = new jsPDF("p", "pt", "a4", true);
    var changeId = 0;

    myData.map((data, i) => {
      doc.setFontSize(18);
      doc.setFont("times");
      doc.setFontType("italic");
      doc.text("Packing List", 14, 30);
      doc.text(420, 30, "Booking id: " + `${myListOfIds[changeId]}`);
      doc.setFontSize(14);
      doc.autoTable({
        columns: bookingInfoHeadRow,
        body: data.packingListBookingInfoDtos,
        styles: {
          lineColor: [44, 62, 80],
          lineWidth: 1,
          // cellWidth: 'auto'
        },
        columnStyles: { title: { cellWidth: 150 } },
        headStyles: { fillColor: hexToRgb("#af7c7c") }, // Red
      });

      const stringToHTML = (str) => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(str, "text/html");
        return doc.body;
      };

      let Yderligere_step2 = "";
      let spaceBetweenPages = 45;
      if (data.yderligereInfo != null) {
        const Yderligere_step1 = stringToHTML(data.yderligereInfo);
        Yderligere_step2 = Yderligere_step1.innerText;
        doc.setFontSize(11);
        let splitTitle = doc.splitTextToSize(Yderligere_step2, 575);
        doc
          .setFont(undefined, "bold")
          .text(15, doc.lastAutoTable.finalY + 20, "Yderligere info: ")
          .setFont(undefined, "normal");
        doc.text(15, doc.lastAutoTable.finalY + 35, splitTitle);
        if (splitTitle.length <= 1) {
          spaceBetweenPages = 60;
        } else if (splitTitle.length > 1 && splitTitle.length <= 6) {
          spaceBetweenPages = 35 * splitTitle.length;
        } else if (splitTitle.length > 6) {
          spaceBetweenPages = 20 * splitTitle.length;
        }
      }

      if (data.additionalBuyInRequest != null) {
        let additionalBuyInRequest_step2 = "";
        const additionalBuyInRequest_step1 = stringToHTML(
          data.additionalBuyInRequest
        );
        additionalBuyInRequest_step2 = additionalBuyInRequest_step1.innerText;
        doc.setFontSize(11);
        let splitTitle_additionalBuyInRequest = doc.splitTextToSize(
          additionalBuyInRequest_step2,
          575
        );
        doc
          .setFont(undefined, "bold")
          .text(
            15,
            doc.lastAutoTable.finalY + spaceBetweenPages,
            "Additional buy in request: "
          )
          .setFont(undefined, "normal");
        doc.text(
          15,
          doc.lastAutoTable.finalY + spaceBetweenPages + 15,
          splitTitle_additionalBuyInRequest
        );

        if (splitTitle_additionalBuyInRequest.length <= 1) {
          spaceBetweenPages += 45;
        } else if (
          splitTitle_additionalBuyInRequest.length > 1 &&
          splitTitle_additionalBuyInRequest.length <= 6
        ) {
          spaceBetweenPages += 25 * splitTitle_additionalBuyInRequest.length;
        } else if (splitTitle_additionalBuyInRequest.length > 6) {
          spaceBetweenPages += 15 * splitTitle_additionalBuyInRequest.length;
        }
      }

      doc.line(
        118,
        doc.lastAutoTable.finalY + spaceBetweenPages + 7,
        280,
        doc.lastAutoTable.finalY + spaceBetweenPages + 7
      );
      doc
        .setFont(undefined, "bold")
        .text(
          10,
          doc.lastAutoTable.finalY + spaceBetweenPages + 7,
          "Responsible packer: "
        )
        .setFont(undefined, "bold");

      doc.text(
        300,
        doc.lastAutoTable.finalY + spaceBetweenPages + 7,
        "Responsible for return:"
      );
      doc.line(
        422,
        doc.lastAutoTable.finalY + spaceBetweenPages + 7,
        784,
        doc.lastAutoTable.finalY + spaceBetweenPages + 7
      );

      let pageNum = 2;
      let beforeLoop = doc.internal.getNumberOfPages();
      let startPage = doc.internal.getNumberOfPages();
      doc.setFontSize(18);
      doc.setFont("times");
      doc.setFontType("italic");
      doc.text(555, 820, `${"1"}` + "/");
      preSetpackinglistRedux = data.packingListCategoryDtos;
      data.packingListCategoryDtos.forEach((element) => {
        //let categoryNamePos = doc.lastAutoTable.finalY + spaceBetweenPages - 10;
        let headRows = [
          {
            title: element.categoryName,
            dataKey: "componentName",
          },
          { title: "Amount", dataKey: "amount" },
          { title: "Unit", dataKey: "unit" },
          { title: "Return", dataKey: "" },
        ];
        //doc.text(250, categoryNamePos, `${element.categoryName}`);

        doc.autoTable({
          columns: headRows,
          body: element.packingListComponentInfoDtos,
          startY: doc.lastAutoTable.finalY + spaceBetweenPages + 10,
          rowPageBreak: "auto",
          margin: { horizontal: 12 },
          styles: { overflow: "linebreak" },
          bodyStyles: { valign: "top" },
          columnStyles: { email: { cellWidth: "wrap" } },
          // columnStyles: { 0: { cellWidth: 1200 } },

          styles: {
            lineColor: [44, 62, 80],
            lineWidth: 1,
            cellWidth: "wrap",
            rowPageBreak: "auto",
            halign: "justify",
            //fillColor: hexToRgb(myColor)
          },
          columnStyles: {
            componentName: { cellWidth: 275 },
            unit: { cellWidth: 100 },
            amount: { cellWidth: 50 },
          },
          headStyles: { fillColor: hexToRgb("#36688d") }, // Red  textColor: hexToRgb("#000000")
          //pageBreak: 'avoid',
        });
        spaceBetweenPages = 15;

        if (beforeLoop !== doc.internal.getNumberOfPages()) {
          doc.setFontSize(18);
          doc.setFont("times");
          doc.setFontType("italic");
          doc.text("Packing List", 14, 30);
          doc.text(420, 30, "Booking id: " + `${data.bookingId}`);
          doc.text(555, 820, `${pageNum}` + "/");
          pageNum += 1;

          beforeLoop = doc.internal.getNumberOfPages();
        }
      });
      let endPage = doc.internal.getNumberOfPages();
      let cal = endPage - startPage + 1;

      for (let index = 0; index < cal; index++) {
        doc.setPage(startPage);
        doc.setFontSize(18);
        doc.setFont("times");
        doc.setFontType("italic");
        doc.text(570, 820, `${cal}`);
        startPage += 1;
      }
      doc.setPage(endPage);
      cal = 0;

      doc.setFontSize(18);
      doc.setFont("times");
      doc.setFontType("italic");
      doc.text("Packing List", 14, 30);
      doc.text(420, 30, "Booking id: " + `${data.bookingId}`);

      if (pageCount != doc.internal.getNumberOfPages()) {
        newCount = doc.internal.getNumberOfPages() - pageCount;
        pageCount = pageCount + newCount;
      }

      if (i < pageNr) {
        doc.addPage();
        changeId++;
        doc.setFontSize(18);
        doc.setFont("times");
        doc.setFontType("italic");
        doc.text("Packing List", 14, 30);
        doc.text(420, 30, "Booking id: " + `${myListOfIds[changeId]}`);
      }
    });
    const uri = doc.output("datauristring");

    const csvPackingList = [];
    preSetpackinglistRedux.forEach((element) => {
      element.packingListComponentInfoDtos.forEach((comp) => {
        let component = {
          id: myData[0].bookingId,
          componentName: comp.componentName,
          amount: comp.amount,
          unit: comp.unit,
          category: element.categoryName,
          return: "",
        };
        csvPackingList.push(component);

      });
    });

    if (csvPackingList.length > 0) {
      dispatch({
        type: types.packinglist.getPackingList,
        payload: csvPackingList,
      });
    }

    return (
      <iframe frameBorder="0" width={pdfWidth} height={pdfHeight} src={uri} />
    );
  }
  if (refreshApi) {
    return (
      <div>
        <div className="sweet-loading" style={{ marginLeft: 300 }}>
          <BarLoader
            height={5}
            width={200}
            sizeUnit={"px"}
            size={500}
            color={"#4C8FBF"}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ marginLeft: 450 }}>
          <h5>Press View</h5>
        </div>
      </div>
    );
  }
};

export default PackinglistAll;
