import React, { useState } from "react";
import {
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";
import CocktailMenuHeaderCell from "./CocktailMenuHeaderCell";

export default function CocktailMenuTable(props) {
  const [columnExtension, setColumnExtension] = useState([
    // { columnName: "status" },
    { columnName: "name" },
    { columnName: "action" },
  ]);

  return (
    <React.Fragment>
      <Grid rows={props.rows} columns={props.columns}>
        <SortingState
          defaultSorting={[{ columnName: props.sortIt, direction: "asc" }]}
        />
        <IntegratedSorting />
        <SearchState />
        <IntegratedFiltering />
        <Table
          columnExtensions={props.columnExtension}
          cellComponent={props.cell}
        />
        <TableHeaderRow
          columnExtensions={columnExtension}
          cellComponent={CocktailMenuHeaderCell}
          showSortingControls={true}
        />
        <Toolbar />
        <SearchPanel />
      </Grid>
    </React.Fragment>
  );
}
