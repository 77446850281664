import React, { useState, useEffect } from "react";
import apiFacade from "../../../../auth/apiFacade";
import BarLoader from "react-spinners/BarLoader";
import jsPDF from "jspdf";
import hexToRgb from "pretty-easy-hex-to-rgb";
import { fstat } from "fs";

function sortNumber(a, b) {
  return a - b;
}

var headRows = [
  { title: "Item", dataKey: "name" },
  { title: "Amount", dataKey: "amount" },
  { title: "Unit", dataKey: "unit" },
  { title: "Reol", dataKey: "reol" },
  { title: "Hylde", dataKey: "hylde" }
];

var samplingInfoHeadRow = [
  { title: "Title", dataKey: "title" },
  { title: "Info", dataKey: "value" }
];

const PackinglistFunc = ({
  listOfId,
  refreshApi,
  loading,
  navne,
  componentDtos,
  sortingList,
  listOfsamplings,

  packinglist,
  myData
}) => {
  let pageCount = 0;
  let totalPage = 0;
  let newCount = 0;
  const moment = require("moment");

  let contentIframe = null;
  let pageNr = myData.length - 1;
  var doc = new jsPDF("p", "pt", "a4", true);
  var changeId = 0;

  let newSamplingInfo = [];
  var reolUsorting = [];
  var reolSorting = [];

  var hyldeUsorting = [];
  var hyldeSorting = [];
  if (myData.length != 0) {
    newSamplingInfo.push({
      title: "Sampling Id",
      value: myData.sampling_Id
    });

    newSamplingInfo.push({
      title: "Outlet",
      value: myData.outlet
    });

    newSamplingInfo.push({
      title: "Samples",
      value: myData.samples
    });
    newSamplingInfo.push({
      title: "Kampagne",
      value: myData.kampagne
    });
    for (let v = 0; v < myData.samplingDates.length; v++) {
      let dag = v + 1;
      newSamplingInfo.push({
        title: "Tid - Dag" + dag,
        value:
          moment(`${myData.samplingDates[v].start}`, "YYYY/MM/DD HH:mm").format(
            "HH:mm"
          ) +
          " - " +
          moment(`${myData.samplingDates[v].end}`, "YYYY/MM/DD HH:mm").format(
            "HH:mm"
          )
      });
    }

    doc.setFontSize(14);
    doc.setFont("times");
    doc.setFontType("italic");
    // doc.text(`${data.bookingId}`, 500, 20)
    doc.autoTable({
      columns: samplingInfoHeadRow,
      body: newSamplingInfo,
      //startY: doc.lastAutoTable.finalY - 39,
      // margin: { horizontal: 40 },
      //styles: { overflow: 'linebreak' },
      //bodyStyles: { valign: 'top' },

      //theme: "striped",

      styles: {
        lineColor: [44, 62, 80],
        lineWidth: 1
        // cellWidth: 'auto'
      },
      columnStyles: { title: { cellWidth: 150 } },
      headStyles: { fillColor: hexToRgb("#af7c7c") } // Red
    });

    packinglist.map((data, i) => {
      reolUsorting.push(data.reol);
      hyldeUsorting.push(data.hylde);
    });

    if (reolUsorting.length > 0) {
      //først sorter fra duplicates
      reolSorting = reolUsorting.filter((el, i, a) => i === a.indexOf(el));
      hyldeSorting = hyldeUsorting.filter((el, i, a) => i === a.indexOf(el));
      // dernæste sorter fra 0 til højst
      reolSorting.sort((a, b) => a - b);
      hyldeSorting.sort();

      if (reolSorting.length > 0) {

        reolSorting.forEach(reolNr => {
          hyldeSorting.map(hylde => {
            var pagenr = doc.internal.getCurrentPageInfo();
            var reolText = "Reol";
            var hyldeText = reolNr + ".";
            var fuldText = "";
            if (hylde != null) {
              fuldText = reolText + " " + hyldeText + " " + hylde.toUpperCase();
            }
            var comp = [];

            packinglist.map(data => {
              if (data.reol == reolNr && data.hylde == hylde) {
                comp.push(data);
              }
            });

            if (reolNr > 89) {
              reolText = "";
              hyldeText = "";
              fuldText = hylde;
            }

            if (comp.length > 0) {
              //doc.setFontSize(16);
              doc.setFontSize(18);
              doc.setFont("times");
              doc.setFontType("italic");
              doc.text(fuldText, 14, doc.lastAutoTable.finalY + 40);

              doc.autoTable({
                columns: headRows,
                body: comp,
                startY: doc.lastAutoTable.finalY + 45,
                rowPageBreak: "auto",
                margin: { horizontal: 10 },
                styles: { overflow: "linebreak" },
                bodyStyles: { valign: "top" },
                columnStyles: { email: { cellWidth: "wrap" } },
                // columnStyles: { 0: { cellWidth: 1200 } },

                styles: {
                  lineColor: [44, 62, 80],
                  lineWidth: 1,
                  cellWidth: "wrap",
                  rowPageBreak: "auto",
                  halign: "justify"
                  //fillColor: hexToRgb(myColor)
                },
                columnStyles: {
                  name: { cellWidth: 200 }
                  // unit: { cellWidth: 100 },
                  // amount: { cellWidth: 50 },
                  // reol: { cellWidth: 40 },
                  // hylde: { cellWidth: 140 }
                },
                headStyles: { fillColor: hexToRgb("#36688d") } // Red  textColor: hexToRgb("#000000")
                //pageBreak: 'avoid',
              });
              doc.setFontSize(18);
              doc.setFont("times");
              doc.setFontType("italic");
              doc.text("Packing List", 14, 30);

              doc.text(420, 30, "Sampling id: " + `${myData.sampling_Id}`);
            }
          });
        });
      }
    }

    if (pageCount != doc.internal.getNumberOfPages()) {
      newCount = doc.internal.getNumberOfPages() - pageCount;
      pageCount = pageCount + newCount;
    }
    newSamplingInfo = [];

    const uri = doc.output("datauristring");
    return <iframe frameBorder="0" width={"900"} height={"700"} src={uri} />;
  }

  if (refreshApi) {
    return (
      <div>
        <div className="sweet-loading" style={{ marginLeft: 300 }}>
          <BarLoader
            height={5}
            width={200}
            sizeUnit={"px"}
            size={500}
            color={"#4C8FBF"}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ marginLeft: 450 }}>
          <h5>Press View</h5>
        </div>
      </div>
    );
  }
};

export default PackinglistFunc;
