import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications
import NotificationAlert from "react-notification-alert";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { useDispatch } from "react-redux";
import { useIsAuthenticated } from "@azure/msal-react";
import types from "../redux/actionTypes";
import { loginRequest, renewIdTokenRequest } from "../authConfig";
import { callMsGraph } from "../graph";
import apiFacade from "../auth/apiFacade";
import { useMsal } from "@azure/msal-react";

var ps;

function Admin(props) {
  const location = useLocation();
  const [sidebarMini, setSidebarMini] = React.useState(true);
  const [backgroundColor, setBackgroundColor] = React.useState("blue");
  const notificationAlert = React.useRef();
  const mainPanel = React.useRef();
  const isAuthenticated = useIsAuthenticated();
  const [graphData, setGraphData] = useState(null);
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();

  function RequestProfileData() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((res) => {
        callMsGraph(res.accessToken).then((res) => {
          setGraphData(res);
        });
      })
      .catch((e) => {
        instance.acquireTokenRedirect(request).then((res) => {
          callMsGraph(res.accessToken).then((res) => setGraphData(res));
        });
      });
  }

  useEffect(() => {
    setInterval(() => {
      if (graphData != null && isAuthenticated) {
        apiFacade.renewToken(instance, loginRequest, graphData.mail);
      }
      setTimeout(() => {
        if (!isAuthenticated) {
          dispatch({
            type: types.authentication.setProfile,
            payload: {
              firstName: "",
              lastName: "",
            },
          });
        }
      }, 600000);
    }, 3300000);

    if (isAuthenticated) {
      RequestProfileData();
      dispatch({ type: types.authentication.login, payload: true });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (graphData != null) {
      // test af ny token
      //apiFacade.renewToken(instance, loginRequest, graphData.mail);
      dispatch({
        type: types.authentication.setProfile,
        payload: {
          firstName: graphData.givenName,
          lastName: graphData.surname,
        },
      });
    }
  }, [graphData]);

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanel.current);
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);

  const minimizeSidebar = () => {
    var message = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
      message += "deactivated...";
    } else {
      setSidebarMini(true);
      message += "activated...";
    }
    document.body.classList.toggle("sidebar-mini");
    var options = {};
    options = {
      place: "tr",
      message: message,
      type: "info",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const handleColorClick = (color) => {
    setBackgroundColor(color);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      // if (prop.collapse) {
      //   return getRoutes(prop.views);
      // }
      // if (prop.layout === "/admin") {
      return (
        <Route
          // prop.layout +
          path={prop.path}
          component={prop.component}
          key={key}
        />
      );
      // } else {
      //   return null;
      // }
    });
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        //routes[i].layout +
        if (window.location.pathname.indexOf(routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  return (
    <div className="wrapper">
      <NotificationAlert ref={notificationAlert} />
      <Sidebar
        {...props}
        routes={props.routes}
        minimizeSidebar={minimizeSidebar}
        backgroundColor={backgroundColor}
      />
      <div className="main-panel" ref={mainPanel}>
        <AdminNavbar {...props} brandText={getActiveRoute(props.routes)} />
        <Switch>
          {getRoutes(props.routes)}
          <Redirect from="/" to={props.landingPage} />
        </Switch>
        {
          // we don't want the Footer to be rendered on full screen maps page
          window.location.href.indexOf("full-screen-maps") !== -1 ? null : (
            <Footer fluid />
          )
        }
      </div>
    </div>
  );
}

export default Admin;
