import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
var samplingStyle = {
  bold: {
    fontWeight: "bold"
  },
  paperShadow: {
    boxShadow: "1px 1px 5px 6px #ccc"
  },
  textColor: {
    color: "#3787C6",
  }
};

export default class PrintMenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <UncontrolledDropdown style={this.props.useStyle}>
          <DropdownToggle caret color="info">
            Print Menu
          </DropdownToggle>
          <DropdownMenu right style={samplingStyle.textColor}>
            <DropdownItem
              onClick={() => {
                this.props.history.push(
                  `/samplings/${this.props.match.params.id}/packinglistprint`
                );
              }}
            >
              Packing List
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                this.props.history.push(
                  `/samplings/${this.props.match.params.id}/frontpage`
                );
              }}
            >
              Front page
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                this.props.history.push(
                  `/samplings/${this.props.match.params.id}/returliste`
                );
              }}
            >
              Returliste
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  }
}
