import types from "../actionTypes";

const initialState = {
  id: "0",
  cocktails: [],
  cocktailmenus: [],
  selectedmenu: [],
  updateCocktails: false,
  addCocktail: null,
  products: [],
  openModal: false,
  getName: "",
  updateMenu: false,
  openModalDelete: false,
  openModalAdd: false,
  newOrUpdated: false,
  isLoading: false,
};

const cocktailsMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CocktailsMenu.id:
      return {
        ...state,
        id: action.payload,
      };
    case types.CocktailsMenu.cocktails:
      return {
        ...state,
        cocktails: action.payload,
      };
    case types.CocktailsMenu.cocktailmenus:
      return {
        ...state,
        cocktailmenus: action.payload,
      };
    case types.CocktailsMenu.selectedmenu:
      return {
        ...state,
        selectedmenu: action.payload,
      };
    case types.CocktailsMenu.emptySelectedmenu:
      return {
        ...state,
        selectedmenu: [],
      };
    case types.CocktailsMenu.updateCocktails:
      return {
        ...state,
        updateCocktails: action.payload,
      };
    case types.CocktailsMenu.updateMenu:
      return {
        ...state,
        updateMenu: action.payload,
      };
    case types.CocktailsMenu.addCocktail:
      return {
        ...state,
        addCocktail: action.payload,
      };
    case types.CocktailsMenu.products:
      return {
        ...state,
        products: action.payload,
      };
    case types.CocktailsMenu.openModal:
      return {
        ...state,
        openModal: action.payload,
      };
    case types.CocktailsMenu.openModalDelete:
      return {
        ...state,
        openModalDelete: action.payload,
      };
    case types.CocktailsMenu.openModalAdd:
      return {
        ...state,
        openModalAdd: action.payload,
      };
    case types.CocktailsMenu.getName:
      return {
        ...state,
        getName: action.payload,
      };
    case types.CocktailsMenu.newOrUpdated:
      return {
        ...state,
        newOrUpdated: action.payload,
      };
    case types.CocktailsMenu.isLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default cocktailsMenuReducer;
