import React, { Component } from "react";
import apiFacade from "../../../auth/apiFacade";

export class Menukort extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingLanguage: "",
      bookingInfo: [],
      isloading: true
    };
  }

  componentDidMount() {
    apiFacade.getData("/bookings/" + this.props.match.params.id).then(data => {
      this.setState({
        bookingLanguage: data.language,
        isloading: false,
        bookingInfo: data
      });
    });
  }

  render() {
    return <div>menukort</div>;
  }
}

export default Menukort;