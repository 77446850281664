import React from "react";
import { Switch, Route } from "react-router-dom";
import JobManagement from "./JobManagement.js";

export default function JobManagementView() {
  return (
    <div>
      <React.Fragment>
        <Switch>
          <Route
            exact
            path="/jobmanagement"
            render={(props) => <JobManagement {...props} />}
          />
        </Switch>
      </React.Fragment>
    </div>
  );
}
