import React, { Component } from "react";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import Moment from "react-moment";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import apiFacade from "../../auth/apiFacade";
import BookingsTable from "./BookingsTable/BookingsTable";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Print from "@material-ui/icons/Print";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
import "../../assets/css/checkboks.css";
import RangePicker from "react-range-picker";
import * as updateBookingMsg from "../Bookings/UpdateBookingMsg";
import { connect } from "react-redux";
import Fab from "@material-ui/core/Fab";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";
import { ScreenSize } from "helper/ScreenSize";

var bookingStyle = {
  cellStyling: {
    padding: "0px 10px 0px 0px",
  },
  dropdownmenustyle: {
    width: "100%",
    zIndex: "1001",
    right: "end",
  },
  dropdownitemstyle: {
    background: "red",
  },
  textColor: {
    color: "#3787C6",
  },
  cardchartMyFix: {
    position: "absolute",
    right: "220px",
    top: "12px",
  },
  cardchartMyFixEdit: {
    position: "absolute",
    right: "360px",
    top: "10px",
  },
  cardchartMyFixBack: {
    position: "fixed",
    bottom: "55px",
    right: "70px",
  },
  radioWhite: {
    border: "10px solid #90DDD0",
  },
  radioPink: {
    border: "10px solid #EF959D",
  },
  radioRed: {
    border: "10px solid #90DDD0",
  },
  customRange: {
    position: "absolute",
    right: "530px",
    top: "20px",
    boxShadow: "1px 10px 10px 1px #ccc",
  },
  stuck: {
    position: "fixed",
    marginLeft: "69%",
    zIndex: "1000",
  },
  stuck2: {
    position: "fixed",
    marginTop: "2%",
    marginLeft: "0%",
    zIndex: "1000",
  },
  stuck3: {
    position: "fixed",
    right: "275px",
    top: "95px",
    zIndex: "1000",
  },
  modalSize: {
    width: "1397px",
    height: "802px",
  },
};

class Vckbookings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onN: false,
      printing: false,
      sjælland: false,
      jylland: false,
      fyn: false,
      udland: false,
      sendt: false,
      klar: false,
      pakket: false,
      igang: false,
      printet: false,
      ikkePrintet: false,
      mandag: false,
      tirsdag: false,
      onsdag: false,
      torsdag: false,
      fredag: false,
      lordag: false,
      sondag: false,
      ingenDagValgt: false,
      selectAllProcess: false,
      selectAllRegions: false,
      selectAllWeekDays: false,
      listOfId: [],
      listOfIdtoUpdate: [],
      selectedOption: null,
      bookings: [],
      menus: [],
      mytable: [],
      isLoading: true,
      start: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
      end: this.enddate(7),
      newdate: 7,
      pageSizes: [5, 10, 15],
      modalDemo: false,
      datePickerHandler: false,
      defaultExpandedGroups: ["Jylland", "Fyn", "Sjælland", "Udland"],
      updateBookingById: false,
      updateBookingByDate: false,
      updateStuck: {
        position: "absolute",
        marginLeft: "80%",
        zIndex: "1000",
        marginTop: "2%",
      },
      errorStuck: {
        position: "fixed",
        marginTop: "0%",
        marginLeft: "-15%",
        zIndex: "1000",
      },
      columnsBooking: [
        { name: "id", title: "Id" },
        { name: "companyName", title: "Company Name" },
        { name: "date", title: "Date" },
        { name: "type", title: "Type" },
        { name: "persons", title: "Persons" },
        { name: "duration", title: "Duration" },
        { name: "process", title: "Process" },
        { name: "region", title: "Region" },
        { name: "view", title: "Actions" },
      ],
    };
  }

  componentDidMount() {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      this.setState({
        updateStuck: {
          position: "absolute",
          marginLeft: "55%",
          zIndex: "1000",
          marginTop: "8%",
        },
      });
      this.setState({
        errorStuck: {
          position: "fixed",
          marginTop: "0%",
          marginLeft: "0%",
          zIndex: "1000",
        },
      });
      this.setState({
        columnsBooking: [
          { name: "id", title: "Id" },
          { name: "region", title: "Region" },
          { name: "view", title: "Actions" },
          ,
        ],
      });
    } else if (screenSize === 2) {
      this.setState({
        updateStuck: {
          position: "absolute",
          marginLeft: "75%",
          zIndex: "1000",
          marginTop: "5%",
        },
      });
      this.setState({
        columnsBooking: [
          { name: "id", title: "Id" },
          { name: "date", title: "Date" },
          { name: "type", title: "Type" },
          { name: "process", title: "Process" },
          { name: "region", title: "Region" },
          { name: "view", title: "Actions" },
          ,
        ],
      });
    }

    //f.eks på date --> api/bookings?start=2019-03-20&end=2019-03-23
    apiFacade.getData("/bookings").then((data) => {
      this.setState({ bookings: data });
      if (data != null) {
        data.map((data) => {
          if (
            data.type === "Den virtuelle ginrejse" ||
            data.type === "Virtuelt cocktailkursus" ||
            data.type === "Den virtuelle ølsmagning"
          ) {
            this.state.mytable.push({
              id: data.id,
              companyName: data.companyName,
              date: data.date,
              type: data.type,
              bartenders: data.bartenders,
              barModules: data.barModules,
              duration: data.duration,
              region: data.region,
              persons: data.persons,
              isLoading: false,
              dateEnd: data.dateEnd,
              barbacks: data.barbacks,
              waiters: data.waiters,
              industrialDishwasher: data.industrialDishwasher,
              process: data.process,
              pakkedag: data.pakkeDag,
            });
          }
        });
        this.setState({ isLoading: false });
      }
    });
  }

  updateTable = (e) => {
    if (e.currentTarget.id === "30days") {
      // 30 days api call
      this.newBookingsbydate(this.startDate(0), 30);
      this.setState({ end: this.enddate(30) });
    }

    if (e.currentTarget.id === "7days") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(0), 7);
      this.state.end = this.enddate(7);
    }
    if (e.currentTarget.id === "tomorrow") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(-1), 1);
      this.state.start = this.startDate(-1);
      this.state.end = this.enddate(1);
    }
    if (e.currentTarget.id === "yesterday") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(1), -1);
      this.state.start = this.startDate(1);
      this.state.end = this.enddate(-1);
    }

    if (e.currentTarget.id === "today") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(0), 0);
      this.state.start = 0;
      this.state.start = this.startDate(0);
      this.state.end = 0;
      this.state.end = this.enddate(0);
    }
    if (e.currentTarget.id === "last7days") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(7), 0);
      this.state.start = this.startDate(7);
      this.state.end = 0;
      this.state.end = this.enddate(0);
    }
    if (e.currentTarget.id === "last30days") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(30), 0);
      this.state.start = this.startDate(30);
      this.state.end = 0;
      this.state.end = this.enddate(0);
    }
  };
  startDate = (newday) => {
    var new_date = new Date();
    var day = String(new_date.getDate());
    var month = String(new_date.getMonth() + 1);
    var myNewdate = String(new_date.getFullYear() + "/" + month + "/" + day);
    var date = new Date(myNewdate);
    var next_date = new Date(date.setDate(date.getDate() - newday));
    var month_ = "" + (next_date.getMonth() + 1);
    var day_ = "" + next_date.getDate();
    var year_ = next_date.getFullYear();

    if (month_.length < 2) month_ = "0" + month_;
    if (day_.length < 2) day_ = "0" + day_;

    var myNewdate_ = [year_, month_, day_].join("/");
    return myNewdate_;
  };

  enddate = (newday) => {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var myNewdate = [year, month, day].join("-");

    var date = new Date(myNewdate);
    var next_date = new Date(date.setDate(date.getDate() + newday));
    var month_ = "" + (next_date.getMonth() + 1);
    var day_ = "" + next_date.getDate();
    var year_ = next_date.getFullYear();

    if (month_.length < 2) month_ = "0" + month_;
    if (day_.length < 2) day_ = "0" + day_;

    var myNewdate_ = [year_, month_, day_].join("/");
    return myNewdate_;
  };

  newBookingsbydate = (startdate, nday) => {
    //bookings?start=2019-03-30&end=2019-04-03
    this.state.mytable = [];
    this.state.menus = [];
    this.state.bookings = [];
    apiFacade
      .getData("/bookings?start=" + startdate + "&end=" + this.enddate(nday))
      .then((data) => {
        data.map((data) => {
          if (
            data.type === "Den virtuelle ginrejse" ||
            data.type === "Virtuelt cocktailkursus" ||
            data.type === "Den virtuelle ølsmagning"
          ) {
            this.state.mytable.push({
              id: data.id,
              companyName: data.companyName,
              date: data.date,
              type: data.type,
              bartenders: data.bartenders,
              barModules: data.barModules,
              duration: data.duration,
              region: data.region,
              persons: data.persons,
              process: data.process,
              pakkedag: data.pakkeDag,
              isLoading: false,
              dateEnd: data.dateEnd,
            });
          }
        });
        this.setState({ isLoading: false });
      });
  };

  // bookings'
  // /bookings/rowData.id
  handleClick2 = (event) => {
    this.setState({ anchorE2: event.currentTarget });
  };

  handleClose2 = () => {
    this.setState({ anchorE2: null });
  };

  handleClickDate = (event) => {
    this.setState({ anchorE1: event.currentTarget });
  };

  handleCloseDate = () => {
    this.setState({ anchorE1: null });
  };

  //adgang til edit print menu, baseret på oid fra azure user
  editprintmenu = () => {
    if (
      //ja
      apiFacade.oid == "51db0d9a-5a9a-4644-b47f-71c3ad07e5b3" ||
      //KC
      apiFacade.oid === "177c16c3-d488-4edf-95e1-fe26e4a7bc25" ||
      //JM
      apiFacade.oid === "ef2331e0-89e7-4564-998b-3873100afc4d" ||
      //PM
      apiFacade.oid === "dba61e47-a124-45dd-be07-9c5a5da7d0ae"
    ) {
      return (
        <div
          className="card-chartMyFix"
          style={bookingStyle.cardchartMyFixEdit}
        >
          {/* <UncontrolledDropdown>
            <DropdownToggle caret color="info">
              Edit Packing List
                      </DropdownToggle>
            <DropdownMenu style={bookingStyle.textColor}>
              <DropdownItem
                onClick={() => {
                  this.props.history.push(
                    `/bookings/edit`
                  );

                }}
              >
                Edit Packing List
                </DropdownItem>


            </DropdownMenu>
          </UncontrolledDropdown> */}
          <Button
            style={{}}
            color="info"
            onClick={() => {
              this.props.history.push(`/bookingoverview/bookings/edit`);
            }}
          >
            {" "}
            Edit Packing List
          </Button>
        </div>
      );
    } else {
      return; //console.log("doesnt work");
    }
  };

  toggleModalDemo = () => {
    this.setState({
      modalDemo: !this.state.modalDemo,
    });
  };
  handleOptionChange = (changeEvent) => {
    this.setState({
      selectedOption: changeEvent.target.value,
    });
  };
  handleChangeFyn = (fyn) => {
    this.setState({ fyn: fyn.state.value });
  };
  handleChangeSjælland = (sjælland) => {
    this.setState({ sjælland: sjælland.state.value });
  };
  handleChangeJylland = (jylland) => {
    this.setState({ jylland: jylland.state.value });
  };
  handleChangeUdland = (udland) => {
    this.setState({ udland: udland.state.value });
  };
  handleChangeSelectAllRegions = (selectAllRegions) => {
    if (selectAllRegions.state.value === true) {
      document.getElementsByClassName("sjallandon")[0].click();
      document.getElementsByClassName("jyllandon")[0].click();
      document.getElementsByClassName("fynon")[0].click();
      document.getElementsByClassName("udlandon")[0].click();
    }
    if (selectAllRegions.state.value === false) {
      document.getElementsByClassName("sjallandoff")[0].click();
      document.getElementsByClassName("jyllandoff")[0].click();
      document.getElementsByClassName("fynoff")[0].click();
      document.getElementsByClassName("udlandoff")[0].click();
    }
    this.setState({
      selectAllRegions: selectAllRegions.state.value,
      sjælland: selectAllRegions.state.value,
      jylland: selectAllRegions.state.value,
      fyn: selectAllRegions.state.value,
      udland: selectAllRegions.state.value,
    });
  };
  handleChangeSendt = (sendt) => {
    this.setState({ sendt: sendt.state.value });
  };
  handleChangeKlar = (klar) => {
    this.setState({ klar: klar.state.value });
  };
  handleChangePakket = (pakket) => {
    this.setState({ pakket: pakket.state.value });
  };
  handleChangeIgang = (igang) => {
    this.setState({ igang: igang.state.value });
  };
  handleChangePrintet = (printet) => {
    this.setState({ printet: printet.state.value });
  };
  handleChangeIkkePrintet = (ikkePrintet) => {
    this.setState({ ikkePrintet: ikkePrintet.state.value });
  };
  handleChangeSeletcAllProcess = (selectAllProcess) => {
    if (selectAllProcess.state.value === true) {
      document.getElementsByClassName("sendton")[0].click();
      document.getElementsByClassName("klaron")[0].click();
      document.getElementsByClassName("igangon")[0].click();
      document.getElementsByClassName("printeton")[0].click();
      document.getElementsByClassName("pakketon")[0].click();
      document.getElementsByClassName("ikkeprinteton")[0].click();
    }
    if (selectAllProcess.state.value === false) {
      document.getElementsByClassName("sendtoff")[0].click();
      document.getElementsByClassName("klaroff")[0].click();
      document.getElementsByClassName("igangoff")[0].click();
      document.getElementsByClassName("printetoff")[0].click();
      document.getElementsByClassName("pakketoff")[0].click();
      document.getElementsByClassName("ikkeprintetoff")[0].click();
    }
    this.setState({
      selectAllProcess: selectAllProcess.state.value,
      sendt: selectAllProcess.state.value,
      klar: selectAllProcess.state.value,
      igang: selectAllProcess.state.value,
      pakket: selectAllProcess.state.value,
      printet: selectAllProcess.state.value,
      ikkePrintet: selectAllProcess.state.value,
    });
  };
  handleChangeMandag = (mandag) => {
    this.setState({ mandag: mandag.state.value });
  };
  handleChangeTirsdag = (tirsdag) => {
    this.setState({ tirsdag: tirsdag.state.value });
  };
  handleChangeOnsdag = (onsdag) => {
    this.setState({ onsdag: onsdag.state.value });
  };
  handleChangeTorsdag = (torsdag) => {
    this.setState({ torsdag: torsdag.state.value });
  };
  handleChangeFredag = (fredag) => {
    this.setState({ fredag: fredag.state.value });
  };
  handleChangeLordag = (lordag) => {
    this.setState({ lordag: lordag.state.value });
  };
  handleChangeSondag = (sondag) => {
    this.setState({ sondag: sondag.state.value });
  };

  handleChangeingenDagValgt = (ingenDagValgt) => {
    this.setState({ ingenDagValgt: ingenDagValgt.state.value });
  };
  handleChangeSelectAllWeekDays = (selectAllWeekDays) => {
    if (selectAllWeekDays.state.value === true) {
      document.getElementsByClassName("mandagon")[0].click();
      document.getElementsByClassName("tirsdagon")[0].click();
      document.getElementsByClassName("onsdagon")[0].click();
      document.getElementsByClassName("torsdagon")[0].click();
      document.getElementsByClassName("fredagon")[0].click();
      document.getElementsByClassName("lordagon")[0].click();
      document.getElementsByClassName("sondagon")[0].click();
      document.getElementsByClassName("ikkevalgton")[0].click();
    }
    if (selectAllWeekDays.state.value === false) {
      document.getElementsByClassName("mandagoff")[0].click();
      document.getElementsByClassName("tirsdagoff")[0].click();
      document.getElementsByClassName("onsdagoff")[0].click();
      document.getElementsByClassName("torsdagoff")[0].click();
      document.getElementsByClassName("fredagoff")[0].click();
      document.getElementsByClassName("lordagoff")[0].click();
      document.getElementsByClassName("sondagoff")[0].click();
      document.getElementsByClassName("ikkevalgtoff")[0].click();
    }
    this.setState({
      selectAllWeekDays: selectAllWeekDays.state.value,
      mandag: selectAllWeekDays.state.value,
      tirsdag: selectAllWeekDays.state.value,
      onsdag: selectAllWeekDays.state.value,
      torsdag: selectAllWeekDays.state.value,
      fredag: selectAllWeekDays.state.value,
      lordag: selectAllWeekDays.state.value,
      sondag: selectAllWeekDays.state.value,
      ingenDagValgt: selectAllWeekDays.state.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (e) {
      this.setState({
        printing: true,
      });
    }
  };

  printOrBooking = (columnsBooking, grouping) => {
    if (this.state.printing) {
      this.state.listOfId = [];
      //this.state.mytable[i].process
      if (this.state.sjælland === true) {
        //sendt
        if (this.state.sendt === true) {
          //mandag
          if (this.state.mandag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Klar
        if (this.state.klar === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Pakket
        if (this.state.pakket === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Igang
        if (this.state.igang === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Printet
        if (this.state.printet === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Ikke Printet
        if (this.state.ikkePrintet === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Sjælland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
      }
      // herfra jylland
      if (this.state.jylland === true) {
        //sendt
        if (this.state.sendt === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Klar
        if (this.state.klar === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Pakket
        if (this.state.pakket === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Igang
        if (this.state.igang === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //printet
        if (this.state.printet === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //ikke printet
        if (this.state.ikkePrintet === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Jylland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
      }
      //herfra fyn
      if (this.state.fyn === true) {
        //Sendt
        if (this.state.sendt === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Klar
        if (this.state.klar === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Pakket
        if (this.state.pakket === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Igang
        if (this.state.igang === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Printet
        if (this.state.printet === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //ikke Printet
        if (this.state.ikkePrintet === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Fyn" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
      }
      //herfra udland
      if (this.state.udland === true) {
        //Sendt
        if (this.state.sendt === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udand" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Sendt" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Klar
        if (this.state.klar === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udand" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Klar" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Pakket
        if (this.state.pakket === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udand" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Pakket" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Igang
        if (this.state.igang === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udand" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Igang" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Printet
        if (this.state.printet === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udand" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === "Printet" &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
        //Ikke Printet
        if (this.state.ikkePrintet === true) {
          //Mandag
          if (this.state.mandag) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Mandag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Tirdsag
          if (this.state.tirsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udand" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Tirsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Onsdag
          if (this.state.onsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Onsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Torsdag
          if (this.state.torsdag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Torsdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Fredag
          if (this.state.fredag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Fredag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Lørdag
          if (this.state.lordag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Lørdag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Søndag
          if (this.state.sondag === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === "Søndag"
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
          //Ikke tilvalgt
          if (this.state.ingenDagValgt === true) {
            for (let i = 0; i < this.state.mytable.length; i++) {
              if (
                this.state.mytable[i].region === "Udland" &&
                this.state.mytable[i].process === null &&
                this.state.mytable[i].pakkedag === null
              ) {
                this.state.listOfId.push(this.state.mytable[i].id);
              }
            }
          }
        }
      }
      // END

      if (this.state.listOfId) {
        this.props.history.push(`/bookingoverview/vbookings/vprintall`, {
          startDate: this.state.start,
          endDate: this.state.end,
          bookings: this.state.listOfId,
          type: this.state.selectedOption,
          allbookings: this.state.mytable,
          sjælland: this.state.sjælland,
          fyn: this.state.fyn,
          jylland: this.state.jylland,
          udland: this.state.udland,
          sendt: this.state.sendt,
          klar: this.state.klar,
          pakket: this.state.pakket,
          igang: this.state.igang,
          printet: this.state.printet,
          ikkePrintet: this.state.ikkePrintet,
          mandag: this.state.mandag,
          tirsdag: this.state.tirsdag,
          onsdag: this.state.onsdag,
          torsdag: this.state.torsdag,
          fredag: this.state.fredag,
          lordag: this.state.lordag,
          sondag: this.state.sondag,
          ingenDagValgt: this.state.ingenDagValgt,
          selectedOption: this.state.selectedOption,
          selectAllProcess: this.state.selectAllProcess,
          selectAllRegions: this.state.selectAllRegions,
          selectAllWeekDays: this.state.selectAllWeekDays,
        });
      }
    } else {
      return (
        <BookingsTable
          dataColumn={columnsBooking}
          myData={this.state.mytable}
          grouping={grouping}
          defaultExpandedGroups={this.state.defaultExpandedGroups}
        />
      );
    }
  };

  viewnow = () => {
    if (this.state.selectedOption != null) {
      if (
        this.state.sjælland != false ||
        this.state.fyn != false ||
        this.state.jylland != false ||
        this.state.udland != false
      ) {
        if (
          this.state.sendt != false ||
          this.state.klar != false ||
          this.state.printet != false ||
          this.state.ikkePrintet != false ||
          this.state.igang != false ||
          this.state.pakket != false
        ) {
          if (
            this.state.mandag != false ||
            this.state.tirsdag != false ||
            this.state.onsdag != false ||
            this.state.torsdag != false ||
            this.state.fredag != false ||
            this.state.lordag != false ||
            this.state.sondag != false ||
            this.state.ingenDagValgt != false
          ) {
            return (
              <Button
                type="submit"
                style={{ marginLeft: "auto", backgroundColor: "#1BEB11" }}
                //onClick={this.printType}
              >
                View
              </Button>
            );
          }
        }
      }
    }

    if (
      this.state.sjælland === false ||
      this.state.fyn === false ||
      this.state.jylland === false ||
      this.state.udland === false ||
      this.state.sendt === false ||
      this.state.klar === false ||
      this.state.printet === false ||
      this.state.ikkePrintet === false ||
      this.state.igang === false ||
      this.state.pakket === false ||
      this.state.mandag === false ||
      this.state.tirsdag === false ||
      this.state.onsdag === false ||
      this.state.torsdag === false ||
      this.state.fredag === false ||
      this.state.lordag === false ||
      this.state.sondag === false ||
      this.state.ingenDagValgt === false ||
      this.state.selectedOption === null
    ) {
      return (
        <div style={{ fontSize: 14 }}>
          <p className="italic">
            {" "}
            Choose one or more region, Proccess, Pakke dag & printing type to
            view
          </p>
        </div>
      );
    }
  };

  openDateRange = () => {
    this.setState({ datePickerHandler: true });
  };

  closeDateRange = () => {
    this.setState({ datePickerHandler: false });
  };

  customRange = () => {
    if (this.state.datePickerHandler) {
      return (
        <div>
          <RangePicker
            placeholderText={"Select date"}
            onDateSelected={this.onDateChanges}
            onClose={this.onDateChanges}
          />
        </div>
      );
    }
  };
  onDateChanges = (date, date2) => {
    if (date && date2) {
      var startMonth = date.getMonth() + 1;
      var endMonth = date2.getMonth() + 1;
      var start = null;
      start = date.getFullYear() + "-" + startMonth + "-" + date.getDate();
      var end = null;
      end = date2.getFullYear() + "-" + endMonth + "-" + date2.getDate();
    }
    if (start != null && end != null) {
      this.setState({ start: start, end: end });
      let myArr = new Array();

      apiFacade
        .getData("/bookings?start=" + start + "&end=" + end)
        .then((data) => {
          data.map((data) => {
            if (
              data.type === "Den virtuelle ginrejse" ||
              data.type === "Virtuelt cocktailkursus" ||
              data.type === "Den virtuelle ølsmagning"
            ) {
              myArr.push({
                id: data.id,
                companyName: data.companyName,
                date: data.date,
                type: data.type,
                bartenders: data.bartenders,
                barModules: data.barModules,
                duration: data.duration,
                region: data.region,
                persons: data.persons,
                process: data.process,
                pakkedag: data.pakkeDag,
                isLoading: false,
                dateEnd: data.dateEnd,
              });
            }
          });
          this.setState({ isLoading: false });
          return myArr;
        })
        .then((myArr) => {
          this.setState({ mytable: myArr });
        });
      this.setState({ datePickerHandler: false });
    }
  };

  refreshBtn = () => {
    if (this.state.start != null) {
      return window.location.replace(
        "https://pakkemodelapi.azurewebsites.net/api/updatebookings/UpdateRange?start=" +
          this.state.start +
          "&end=" +
          this.state.end +
          "&redirect=true"
      );
    } else {
      return window.location.replace(
        "https://pakkemodelapi.azurewebsites.net/api/updatebookings/UpdateRange?start=" +
          this.startDate(0) +
          "&end=" +
          this.state.end +
          "&redirect=true"
      );
    }
  };

  render() {
    //let value = document.getElementById("option1").value;
    const columns = [
      {
        title: "Id",
        render: (rowData) => {
          return (
            <div style={bookingStyle.cellStyling}>
              <a
                href={
                  "https://podio.com/fridaygroup/intranet/apps/bookinger/items/" +
                  rowData.id
                }
              >
                {rowData.id}
              </a>
            </div>
          );
        },
        /*    field: 'id',
                   type: 'numeric',
                   headerStyle: bookingStyle.cellStyling */
      },
      {
        title: "Company Name",
        field: "companyName",
      },
      {
        title: "Date",
        render: (rowData) => {
          return (
            <div>
              <Moment format="YYYY/MM/DD HH:mm">{rowData.date} </Moment>
            </div>
          );
        },
      },
      {
        title: "Type",
        field: "type",
        cellStyle: bookingStyle.cellStyling,
      },
      {
        title: "Persons",
        field: "persons",
        cellStyle: bookingStyle.cellStyling,
      },
      {
        title: "Bartenders",
        field: "bartenders",
        cellStyle: bookingStyle.cellStyling,
      },
      {
        title: "BarModules",
        field: "barModules",
        headerStyle: bookingStyle.cellStyling,
        cellStyle: bookingStyle.cellStyling,
      },
      {
        title: "Menu",
        field: "menuNames",
        headerStyle: bookingStyle.cellStyling,
        cellStyle: bookingStyle.cellStyling,
      },
      {
        title: "Duration",
        render: (rowData) => {
          return <div>{rowData.duration} Hours</div>;
        },
        cellStyle: bookingStyle.cellStyling,
      },
      {
        title: "Region",
        field: "region",
        defaultGroupOrder: 1,
        defaultGroupSort: "desc",
      },
    ];
    // const columnsBooking = [
    //   { name: "id", title: "Id" },
    //   { name: "companyName", title: "Company Name" },
    //   { name: "date", title: "Date" },
    //   { name: "type", title: "Type" },
    //   { name: "persons", title: "Persons" },
    //   { name: "duration", title: "Duration" },
    //   { name: "process", title: "Process" },
    //   { name: "region", title: "Region" },
    //   { name: "view", title: "Actions" },
    // ];
    const myActions = [
      {
        icon: "info",
        tooltip: "Show Booking Info",
        onClick: (event, rowData) => {
          this.props.history.push("/bookingoverview/vbookings/" + rowData.id);
        },
      },
    ];
    const grouping = [{ columnName: "region" }];
    const tableColumnExtensions = [{ columnName: "name", width: 800 }];
    const { anchorE1, anchorE2 } = this.state;
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={6}>
              <Card style={{ minHeight: "130px" }}>
                <CardBody>
                  <Button
                    style={{
                      width: "100%",
                      marginTop: "2%",
                      backgroundColor: "#d16557",
                    }}
                    size="md"
                    onClick={this.toggleModalDemo}
                  >
                    <Print /> &nbsp; Print All
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card style={{ minHeight: "130px" }}>
                <CardBody>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      style={{ width: "100%", backgroundColor: "#d16557" }}
                    >
                      <Moment format="YYYY/MM/DD">{this.state.start}</Moment>{" "}
                      &nbsp;
                      <Moment /* add={{ days: 7 }} */ format="YYYY/MM/DD">
                        {this.state.end}
                      </Moment>
                    </DropdownToggle>
                    <DropdownMenu style={bookingStyle.dropdownmenustyle}>
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={this.updateTable}
                        id="30days"
                      >
                        Next 30 Days
                      </DropdownItem>
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={this.updateTable}
                        id="7days"
                      >
                        Next 7 Days
                      </DropdownItem>
                      <DropdownItem                        
                        style={{ color: "#d16557" }}
                        onClick={this.updateTable}
                        id="tomorrow"
                      >
                        Tomorrow
                      </DropdownItem>
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={this.updateTable}
                        id="today"
                      >
                        Today
                      </DropdownItem>
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={this.updateTable}
                        id="yesterday"
                      >
                        Yesterday
                      </DropdownItem>
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={this.updateTable}
                        id="last7days"
                      >
                        Last 7 days
                      </DropdownItem>
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={this.updateTable}
                        id="last30days"
                      >
                        Last 30 days
                      </DropdownItem>
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={this.openDateRange}
                      >
                        Custom Range
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {this.customRange()}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div style={this.state.updateStuck}>
            <Fab
              disabled={this.state.updateBookingByDate}
              style={{
                backgroundColor: "#d16557",
                color: "#FFFFFF",
              }}
              aria-label="add"
              onClick={() => {
                apiFacade
                  .getData(
                    "/Virtuellebookings/UpdateBookingsByDate/?start=" +
                      this.state.start +
                      "&end=" +
                      this.state.end
                  )
                  .then((data) => {
                    if (data === "OK") {
                      //console.log(data + " _______________O::K___");
                      this.setState({
                        updateBookingByDate: true,
                      });
                      setTimeout(() => {
                        this.setState({
                          updateBookingByDate: false,
                        });
                      }, 8000);
                    }
                  });
              }}
            >
              <UpdateRoundedIcon style={{ fontSize: 40 }} />
            </Fab>
            <div style={{ marginLeft: "-10%" }}>Update All</div>
          </div>
          <Row>
            <Col xs="auto" md={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h4">
                    <Row>
                      <Col xs={12} md={6}>
                        {" "}
                        Virtual Bookings
                      </Col>
                      <Col xs={12} md={6}>
                        {updateBookingMsg.updateBookingMsg(
                          this.props.vckBooking.updateBookingById,
                          this.props.vckBooking.bookingId,
                          this.state.updateBookingByDate,
                          this.state.start,
                          this.state.end,
                          this.state.errorStuck
                        )}
                      </Col>
                    </Row>
                  </CardTitle>
                  <Modal
                    isOpen={this.state.modalDemo}
                    toggle={this.toggleModalDemo}
                    size="lg"
                    style={{ maxWidth: "800px", width: "100%" }}
                  >
                    <form name="printall" onSubmit={this.handleSubmit}>
                      <ModalHeader
                        className="justify-content-center"
                        toggle={this.toggleModalDemo}
                      >
                        Print All Menu
                      </ModalHeader>
                      <ModalBody>
                        <Row>
                          <Col xs={12} md={2}>
                            <p>Regions</p>
                            <p
                              className="category"
                              style={{ color: "#4c7093", fontSize: "12px" }}
                            >
                              Select All
                            </p>
                            <Switch
                              onText={<i className="now-ui-icons ui-1_check" />}
                              onColor="custom"
                              offText={
                                <i className="now-ui-icons ui-1_simple-remove" />
                              }
                              checked={this.state.selectAllRegions}
                              onChange={this.handleChangeSelectAllRegions}
                              defaultValue={this.state.selectAllRegions}
                              id="1"
                            />
                            <p className="category">Sjælland</p>
                            <Switch
                              onText={<i className="sjallandoff" />}
                              onColor="custom"
                              offText={<i className="sjallandon" />}
                              checked={this.state.sjælland}
                              onChange={this.handleChangeSjælland}
                              defaultValue={this.state.sjælland}
                              id="1"
                            />
                            <p className="category">Jylland</p>
                            <Switch
                              onText={<i className="jyllandoff" />}
                              onColor="custom"
                              offText={<i className="jyllandon" />}
                              checked={this.state.jylland}
                              onChange={this.handleChangeJylland}
                              defaultValue={this.state.jylland}
                              id="2"
                            />
                            <p className="category">Fyn</p>
                            <Switch
                              onText={<i className="fynoff" />}
                              onColor="custom"
                              offText={<i className="fynon" />}
                              checked={this.state.fyn}
                              onChange={this.handleChangeFyn}
                              defaultValue={this.state.fyn}
                              id="3"
                            />
                            <p className="category">Udland</p>
                            <Switch
                              onText={<i className="udlandoff" />}
                              onColor="custom"
                              offText={<i className="udlandon" />}
                              checked={this.state.udland}
                              onChange={this.handleChangeUdland}
                              defaultValue={this.state.udland}
                              id="4"
                            />
                          </Col>
                          <Col xs={12} md={2}>
                            <p>Process</p>
                            <p
                              className="category"
                              style={{ color: "#4c7093", fontSize: "12px" }}
                            >
                              Select All
                            </p>
                            <Switch
                              onText={<i className="now-ui-icons ui-1_check" />}
                              onColor="custom"
                              offText={
                                <i className="now-ui-icons ui-1_simple-remove" />
                              }
                              checked={this.state.selectAllProcess}
                              onChange={this.handleChangeSeletcAllProcess}
                              defaultValue={this.state.selectAllProcess}
                              id="1"
                            />
                            <p
                              className="category"
                              style={{ color: "#7ddf3f", fontSize: "12px" }}
                            >
                              Sendt
                            </p>
                            <Switch
                              name="sendt"
                              //animate={false}
                              onText={<i className="sendtoff" />}
                              onColor="custom"
                              offText={<i className="sendton" />}
                              checked={this.state.sendt}
                              onChange={this.handleChangeSendt}
                              defaultValue={this.state.sendt}
                              id="sendt"
                            />
                            <p
                              className="category"
                              style={{ color: "#32f0b2", fontSize: "12px" }}
                            >
                              Klar
                            </p>
                            <Switch
                              onText={<i className="klaroff" />}
                              onColor="custom"
                              //animate={false}
                              offText={<i className="klaron" />}
                              checked={this.state.klar}
                              onChange={this.handleChangeKlar}
                              defaultValue={this.state.klar}
                              id="klar"
                            />
                            <p
                              className="category"
                              style={{ color: "#e2b659", fontSize: "12px" }}
                            >
                              Pakket
                            </p>
                            <Switch
                              onText={<i className="pakketoff" />}
                              onColor="custom"
                              //animate={false}
                              offText={<i className="pakketon" />}
                              checked={this.state.pakket}
                              onChange={this.handleChangePakket}
                              defaultValue={this.state.pakket}
                              id="sendt"
                            />
                            <p
                              className="category"
                              style={{ color: "#ff80a8", fontSize: "12px" }}
                            >
                              Igang
                            </p>
                            <Switch
                              onText={<i className="igangoff" />}
                              onColor="custom"
                              offText={<i className="igangon" />}
                              checked={this.state.igang}
                              onChange={this.handleChangeIgang}
                              defaultValue={this.state.igang}
                              id="igang"
                            />
                            <p
                              className="category"
                              style={{ color: "#ff0000", fontSize: "12px" }}
                            >
                              Printet
                            </p>
                            <Switch
                              onText={<i className="printetoff" />}
                              onColor="custom"
                              offText={<i className="printeton" />}
                              checked={this.state.printet}
                              onChange={this.handleChangePrintet}
                              defaultValue={this.state.printet}
                              id="printet"
                            />
                            <p
                              className="category"
                              style={{ fontSize: "12px" }}
                            >
                              Ikke printet
                            </p>
                            <Switch
                              onText={<i className="ikkeprintetoff" />}
                              onColor="custom"
                              offText={<i className="ikkeprinteton" />}
                              checked={this.state.ikkePrintet}
                              onChange={this.handleChangeIkkePrintet}
                              defaultValue={this.state.ikkePrintet}
                              id="ikkeprintet"
                            />
                          </Col>
                          <Col xs={12} md={2}>
                            <p>Pakke dag</p>
                            <p
                              className="category"
                              style={{ color: "#4c7093", fontSize: "12px" }}
                            >
                              Select All
                            </p>
                            <Switch
                              onText={<i className="now-ui-icons ui-1_check" />}
                              onColor="custom"
                              offText={
                                <i className="now-ui-icons ui-1_simple-remove" />
                              }
                              checked={this.state.selectAllWeekDays}
                              onChange={this.handleChangeSelectAllWeekDays}
                              defaultValue={this.state.selectAllWeekDays}
                              id="1"
                            />
                            <p
                              className="category"
                              style={{ fontSize: "12px" }}
                            >
                              Mandag
                            </p>
                            <Switch
                              onText={<i className="mandagoff" />}
                              onColor="custom"
                              offText={<i className="mandagon" />}
                              checked={this.state.mandag}
                              onChange={this.handleChangeMandag}
                              defaultValue={this.state.mandag}
                              id="1"
                            />
                            <p
                              className="category"
                              style={{ fontSize: "12px" }}
                            >
                              Tirsdag
                            </p>
                            <Switch
                              onText={<i className="tirsdagoff" />}
                              onColor="custom"
                              offText={<i className="tirsdagon" />}
                              checked={this.state.tirsdag}
                              onChange={this.handleChangeTirsdag}
                              defaultValue={this.state.tirsdag}
                              id="2"
                            />
                            <p
                              className="category"
                              style={{ fontSize: "12px" }}
                            >
                              Onsdag
                            </p>
                            <Switch
                              onText={<i className="onsdagoff" />}
                              onColor="custom"
                              offText={<i className="onsdagon" />}
                              checked={this.state.onsdag}
                              onChange={this.handleChangeOnsdag}
                              defaultValue={this.state.onsdag}
                              id="3"
                            />
                            <p
                              className="category"
                              style={{ fontSize: "12px" }}
                            >
                              Torsdag
                            </p>
                            <Switch
                              onText={<i className="torsdagoff" />}
                              onColor="custom"
                              offText={<i className="torsdagon" />}
                              checked={this.state.torsdag}
                              onChange={this.handleChangeTorsdag}
                              defaultValue={this.state.torsdag}
                              id="4"
                            />
                            <p
                              className="category"
                              style={{ fontSize: "12px" }}
                            >
                              Fredag
                            </p>
                            <Switch
                              onText={<i className="fredagoff" />}
                              onColor="custom"
                              offText={<i className="fredagon" />}
                              checked={this.state.fredag}
                              onChange={this.handleChangeFredag}
                              defaultValue={this.state.fredag}
                              id="5"
                            />
                            <p
                              className="category"
                              style={{ fontSize: "12px" }}
                            >
                              Lørdag
                            </p>
                            <Switch
                              onText={<i className="lordagoff" />}
                              onColor="custom"
                              offText={<i className="lordagon" />}
                              checked={this.state.lordag}
                              onChange={this.handleChangeLordag}
                              defaultValue={this.state.lordag}
                              id="6"
                            />
                            <p
                              className="category"
                              style={{ fontSize: "12px" }}
                            >
                              Søndag
                            </p>
                            <Switch
                              onText={<i className="sondagoff" />}
                              onColor="custom"
                              offText={<i className="sondagon" />}
                              checked={this.state.sondag}
                              onChange={this.handleChangeSondag}
                              defaultValue={this.state.sondag}
                              id="7"
                            />
                            <p
                              className="category"
                              style={{ fontSize: "12px" }}
                            >
                              Ikke valgt
                            </p>
                            <Switch
                              onText={<i className="ikkevalgtoff" />}
                              onColor="custom"
                              offText={<i className="ikkevalgton" />}
                              checked={this.state.ingenDagValgt}
                              onChange={this.handleChangeingenDagValgt}
                              defaultValue={this.state.ingenDagValgt}
                              id="8"
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <p>Printing type</p>
                            {/* <FormGroup check className="form-check-radio">
                              <p className="category">
                                {" "}
                                only one type at the time
                              </p>
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="1"
                                  id="option1"
                                  name="radio"
                                  type="radio"
                                  checked={this.state.selectedOption === "1"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Packing List</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup> */}
                            <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="8"
                                  id="8"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "8"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Packing List VCK</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="2"
                                  id="2"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "2"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Front Pages</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                            {/* <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="3"
                                  id="3"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "3"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Menus</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup> */}
                            {/* <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="4"
                                  id="4"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "4"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Specsheets</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="5"
                                  id="5"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "5"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Weekplan</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup> */}
                            <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="6"
                                  id="6"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "6"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Total VCK</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="7"
                                  id="7"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "7"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Returliste</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="danger" onClick={this.toggleModalDemo}>
                          Close
                        </Button>
                        {this.viewnow()}
                      </ModalFooter>
                    </form>
                  </Modal>
                  {/* alle skal ikke kunne edit print menu */}
                  {this.editprintmenu()}
                </CardHeader>
                <CardBody>
                  {/* <div style={bookingStyle.stuck}>
                    <IconButton color="primary" onClick={this.refreshBtn}>
                      <SyncRoundedIcon style={{ fontSize: 40 }} />
                    </IconButton>
                  </div> */}
                  {this.printOrBooking(this.state.columnsBooking, grouping)}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { vckBooking } = state;
  return { vckBooking: vckBooking };
}
export default connect(mapStateToProps)(Vckbookings);

{
  /*  <BookingsTable rows={this.state.mytable} columns={columnsDemo} /> */
}
{
  /* <Demo dataColumn={columnsBooking} myData={this.state.mytable} grouping={grouping} defaultExpandedGroups={this.state.defaultExpandedGroups} />
   */
}
