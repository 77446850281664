import React, { Component } from "react";
import apiFacade from "../../auth/apiFacade";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { FaGlassCheers, FaCocktail } from "react-icons/fa";
import { FcPrint } from "react-icons/fc";
import { MdUpdate } from "react-icons/md";
import { GoPackage } from "react-icons/go";
import { GrDeliver } from "react-icons/gr";
import { RiseLoader } from "react-spinners";
import RangePicker from "react-range-picker";
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import Services from "../BookingError/Func/Services";
import TableERR from "../BookingError/BookingErrorTable/BookingErrorTable";
import { connect } from "react-redux";
import { ScreenSize } from "helper/ScreenSize";
import { ThreeSixty } from "@material-ui/icons";

const SettingsStyle = {
  bagCal: {
    //backgroundImage: `url(${Numbers})`
    // -moz-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6),
    // -webkit-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6),
    boxShadow: "5px 5px 10px 2px #777",
    //width: "100vw",
    //height: "100vh",
    //objectFit: "cover",
    //filter: "brightness(.4)"
  },
};

class BookingOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thisWeekId: [],
      nextWeekId: [],
      mytableThisWeek: [],
      mytableNextWeek: [],
      mandag: 0,
      tirsdag: 0,
      onsdag: 0,
      torsdag: 0,
      fredag: 0,
      lørdag: 0,
      søndag: 0,
      ikkevalgt: 0,
      mandagId: [],
      tirsdagId: [],
      onsdagId: [],
      torsdagId: [],
      fredagId: [],
      lørdagId: [],
      søndagId: [],
      ikkevalgtId: [],
      nextMandag: 0,
      nextTirsdag: 0,
      nextOnsdag: 0,
      nextTorsdag: 0,
      nextFredag: 0,
      nextLørdag: 0,
      nextSøndag: 0,
      nextIkkevalgt: 0,
      nextMandagId: [],
      nextTirsdagId: [],
      nextOnsdagId: [],
      nextTorsdagId: [],
      nextFredagId: [],
      nextLørdagId: [],
      nextSøndagId: [],
      nextIkkevalgtId: [],
      menus: [],
      selectedOption: 8,
      datePickerHandler: false,
      didUpdate: false,
      bookingErr: [],
      vckIcon: "45%",
      vckText: "37%",
      bIcon: "45%",
      bText: "41.5%",
      errorColumn: Services.getErrorColumn(),
    };
  }

  componentDidMount() {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      this.setState({ vckIcon: "39%" });
      this.setState({ vckText: "22%" });
      this.setState({ bIcon: "37%" });
      this.setState({ bText: "30%" });
      this.setState({
        errorColumn: [
          { name: "id", title: "Id" },
          { name: "errorMsg", title: "Error description" },
        ],
      });
    } else if (screenSize === 2) {
      this.setState({ vckIcon: "39%" });
      this.setState({ vckText: "22%" });
      this.setState({ bIcon: "37%" });
      this.setState({ bText: "30%" });
    }

    apiFacade.getData("/bookings/thisweekvck").then((data) => {
      apiFacade.getData("/menus").then((data2) => {
        this.setState({ menus: data2 });

        data.map((data) => {
          this.state.menus.map((data2) => {
            if (data.menu == data2.id) {
              this.state.mytableThisWeek.push({
                menuNames: data2.name,
                id: data.id,
                companyName: data.companyName,
                date: data.date,
                type: data.type,
                bartenders: data.bartenders,
                barModules: data.barModules,
                duration: data.duration,
                region: data.region,
                persons: data.persons,
                process: data.process,
                pakkedag: data.pakkeDag,
                isLoading: false,
                dateEnd: data.dateEnd,
                pakkeDag: data.pakkeDag,
                process: data.process,
              });
            }
          });
        });
        this.setState({ isLoading: false });
      });
    });
    apiFacade.getData("/bookings/thisweekvck").then((data) => {
      let totalpacks = 0;
      let mandag = 0;
      let tirsdag = 0;
      let onsdag = 0;
      let torsdag = 0;
      let fredag = 0;
      let lørdag = 0;
      let søndag = 0;
      let ikkevalgt = 0;
      const weekIds = [];
      this.setState({ mytableThisWeek: data });
      data.forEach((element) => {
        totalpacks = totalpacks + element.bartenders + element.persons;
        weekIds.push(element.id);

        if (element.pakkeDag === "Mandag") {
          mandag = mandag + element.bartenders + element.persons;
          this.state.mandagId.push(element.id);
        }
        if (element.pakkeDag === "Tirsdag") {
          tirsdag = tirsdag + element.bartenders + element.persons;
          this.state.tirsdagId.push(element.id);
        }
        if (element.pakkeDag === "Onsdag") {
          onsdag = onsdag + element.bartenders + element.persons;
          this.state.onsdagId.push(element.id);
        }
        if (element.pakkeDag === "Torsdag") {
          torsdag = torsdag + element.bartenders + element.persons;
          this.state.torsdagId.push(element.id);
        }
        if (element.pakkeDag === "Fredag") {
          fredag = fredag + element.bartenders + element.persons;
          this.state.fredagId.push(element.id);
        }
        if (element.pakkeDag === "Lørdag") {
          lørdag = lørdag + element.bartenders + element.persons;
          this.state.lørdagId.push(element.id);
        }
        if (element.pakkeDag === "Søndag") {
          søndag = søndag + element.bartenders + element.persons;
          this.state.søndagId.push(element.id);
        }
        if (element.pakkeDag === null) {
          ikkevalgt = ikkevalgt + element.bartenders + element.persons;
          this.state.ikkevalgtId.push(element.id);
        }
      });
      this.setState({ thisWeek: totalpacks });
      this.setState({ mandag: mandag });
      this.setState({ tirsdag: tirsdag });
      this.setState({ onsdag: onsdag });
      this.setState({ torsdag: torsdag });
      this.setState({ fredag: fredag });
      this.setState({ lørdag: lørdag });
      this.setState({ søndag: søndag });
      this.setState({ ikkevalgt: ikkevalgt });
      this.setState({ thisWeekId: weekIds });
    });
    apiFacade.getData("/bookings/nextweekvck").then((data) => {
      apiFacade.getData("/menus").then((data2) => {
        this.setState({ menus: data2 });

        data.map((data) => {
          this.state.menus.map((data2) => {
            if (data.menu == data2.id) {
              this.state.mytableNextWeek.push({
                menuNames: data2.name,
                id: data.id,
                companyName: data.companyName,
                date: data.date,
                type: data.type,
                bartenders: data.bartenders,
                barModules: data.barModules,
                duration: data.duration,
                region: data.region,
                persons: data.persons,
                process: data.process,
                pakkedag: data.pakkeDag,
                isLoading: false,
                dateEnd: data.dateEnd,
                pakkeDag: data.pakkeDag,
                process: data.process,
              });
            }
          });
        });
        this.setState({ isLoading: false });
      });
    });
    apiFacade.getData("/bookings/nextweekvck").then((data) => {
      let totalpacks = 0;
      let mandag = 0;
      let tirsdag = 0;
      let onsdag = 0;
      let torsdag = 0;
      let fredag = 0;
      let lørdag = 0;
      let søndag = 0;
      let ikkevalgt = 0;
      const weekIds = [];
      data.forEach((element) => {
        totalpacks = totalpacks + element.bartenders + element.persons;
        weekIds.push(element.id);
        if (element.pakkeDag === "Mandag") {
          mandag = mandag + element.bartenders + element.persons;
          this.state.nextMandagId.push(element.id);
        }
        if (element.pakkeDag === "Tirsdag") {
          tirsdag = tirsdag + element.bartenders + element.persons;
          this.state.nextTirsdagId.push(element.id);
        }
        if (element.pakkeDag === "Onsdag") {
          onsdag = onsdag + element.bartenders + element.persons;
          this.state.nextOnsdagId.push(element.id);
        }
        if (element.pakkeDag === "Torsdag") {
          torsdag = torsdag + element.bartenders + element.persons;
          this.state.nextTorsdagId.push(element.id);
        }
        if (element.pakkeDag === "Fredag") {
          fredag = fredag + element.bartenders + element.persons;
          this.state.nextFredagId.push(element.id);
        }
        if (element.pakkeDag === "Lørdag") {
          lørdag = lørdag + element.bartenders + element.persons;
          this.state.nextLørdagId.push(element.id);
        }
        if (element.pakkeDag === "Søndag") {
          søndag = søndag + element.bartenders + element.persons;
          this.state.nextSøndagId.push(element.id);
        }
        if (element.pakkeDag === null) {
          ikkevalgt = ikkevalgt + element.bartenders + element.persons;
          this.state.nextIkkevalgtId.push(element.id);
        }
      });
      this.setState({ nextWeek: totalpacks });
      this.setState({ nextMandag: mandag });
      this.setState({ nextTirsdag: tirsdag });
      this.setState({ nextOnsdag: onsdag });
      this.setState({ nextTorsdag: torsdag });
      this.setState({ nextFredag: fredag });
      this.setState({ nextLørdag: lørdag });
      this.setState({ nextSøndag: søndag });
      this.setState({ nextIkkevalgt: ikkevalgt });
      this.setState({ nextWeekId: weekIds });
    });
    Services.getData().then((dataErr) => {
      this.setState({ bookingErr: dataErr });
    });
  }
  openDateRange = () => {
    this.setState({ datePickerHandler: true });
  };

  closeDateRange = () => {
    this.setState({ datePickerHandler: false });
  };
  PickerWithCustomePlaceholder = () => {
    const placeholder = ({ startDate, endDate }) => {
      if (!startDate) {
        return <div className="placeholder"> Select date range </div>;
      }

      return (
        <div className="placeholderWrap">
          <div className="placeholder">From - {startDate.toLocaleString()}</div>
          {endDate && (
            <div className="placeholder">To - {endDate.toLocaleString()}</div>
          )}
        </div>
      );
    };
    if (this.state.datePickerHandler) {
      return (
        <RangePicker
          placeholder={placeholder}
          onDateSelected={(f, l) => {
            if (l) {
              var startMonth = f.getMonth() + 1;
              var endMonth = l.getMonth() + 1;
              var start = null;
              start = f.getFullYear() + "-" + startMonth + "-" + f.getDate();
              var end = null;
              end = l.getFullYear() + "-" + endMonth + "-" + l.getDate();
              this.setState({ datePickerHandler: false });
              this.setState({ didUpdate: true });
            }
          }}
          onClose={() => {}}
        />
      );
    }
  };
  updateSuccess = () => {
    if (this.state.didUpdate) {
      return <div>update Success</div>;
    }
  };
  pushThis = (
    man,
    tirs,
    ons,
    tors,
    fre,
    lør,
    søn,
    ikva,
    mytable,
    ids,
    selectAllWeekDays
  ) => {
    this.props.history.push(`/bookingoverview/vbookings/vprintall`, {
      bookings: ids,
      type: 8,
      allbookings: mytable,
      sjælland: true,
      fyn: true,
      jylland: true,
      udland: true,
      sendt: true,
      klar: true,
      pakket: true,
      igang: true,
      printet: true,
      ikkePrintet: true,
      waitingOnFruitVegs: true,
      mandag: man,
      tirsdag: tirs,
      onsdag: ons,
      torsdag: tors,
      fredag: fre,
      lordag: lør,
      sondag: søn,
      ingenDagValgt: ikva,
      selectedOption: "8",
      selectAllProcess: true,
      selectAllRegions: true,
      selectAllWeekDays: selectAllWeekDays,
    });
  };
  chooseBooking = (bookingTypeID) => {
    if (bookingTypeID === 1) {
      this.props.history.push(`/bookingoverview/vbookings`);
    }
    if (bookingTypeID === 2) {
      this.props.history.push(`/bookingoverview/bookings`);
    }
  };
  printThis = (chooseWeekOrDay, week) => {
    let mandag = new Array();
    let tirsdag = new Array();
    let onsdag = new Array();
    let torsdag = new Array();
    let fredag = new Array();
    let lørdag = new Array();
    let søndag = new Array();
    let ikkevalgt = new Array();
    let mytable = new Array();
    let mytableForThisDay = new Array();
    if (week === 1) {
      mytable = this.state.mytableThisWeek;
      mandag = this.state.mandagId;
      tirsdag = this.state.tirsdagId;
      onsdag = this.state.onsdagId;
      torsdag = this.state.torsdagId;
      fredag = this.state.fredagId;
      lørdag = this.state.lørdagId;
      søndag = this.state.søndagId;
      ikkevalgt = this.state.ikkevalgtId;
    } else if (week === 2) {
      mytable = this.state.mytableNextWeek;
      mandag = this.state.nextMandagId;
      tirsdag = this.state.nextTirsdagId;
      onsdag = this.state.nextOnsdagId;
      torsdag = this.state.nextTorsdagId;
      fredag = this.state.nextFredagId;
      lørdag = this.state.nextLørdagId;
      søndag = this.state.nextSøndagId;
      ikkevalgt = this.state.nextIkkevalgtId;
    }

    if (chooseWeekOrDay === 1) {
      if (this.state.thisWeekId.length > 0) {
        this.pushThis(
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          this.state.mytableThisWeek,
          this.state.thisWeekId,
          true
        );
      }
    }
    if (chooseWeekOrDay === 2) {
      if (this.state.thisWeekId.length > 0) {
        this.pushThis(
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          this.state.mytableNextWeek,
          this.state.nextWeekId,
          true
        );
      }
    }
    if (chooseWeekOrDay === "Mandag") {
      mandag.forEach((id) => {
        mytable.forEach((element) => {
          if (element.id === id) {
            mytableForThisDay.push(element);
          }
        });
      });

      if (mytableForThisDay.length > 0) {
        this.pushThis(
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          mytableForThisDay,
          mandag,
          false
        );
      }
    }
    if (chooseWeekOrDay === "Tirsdag") {
      tirsdag.forEach((id) => {
        mytable.forEach((element) => {
          if (element.id === id) {
            mytableForThisDay.push(element);
          }
        });
      });

      if (mytableForThisDay.length > 0) {
        this.pushThis(
          false,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          mytableForThisDay,
          tirsdag,
          false
        );
      }
    }
    if (chooseWeekOrDay === "Onsdag") {
      onsdag.forEach((id) => {
        mytable.forEach((element) => {
          if (element.id === id) {
            mytableForThisDay.push(element);
          }
        });
      });

      if (mytableForThisDay.length > 0) {
        this.pushThis(
          false,
          false,
          true,
          false,
          false,
          false,
          false,
          false,
          mytableForThisDay,
          onsdag,
          false
        );
      }
    }
    if (chooseWeekOrDay === "Torsdag") {
      torsdag.forEach((id) => {
        mytable.forEach((element) => {
          if (element.id === id) {
            mytableForThisDay.push(element);
          }
        });
      });

      if (mytableForThisDay.length > 0) {
        this.pushThis(
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          false,
          mytableForThisDay,
          torsdag,
          false
        );
      }
    }
    if (chooseWeekOrDay === "Fredag") {
      fredag.forEach((id) => {
        mytable.forEach((element) => {
          if (element.id === id) {
            mytableForThisDay.push(element);
          }
        });
      });

      if (mytableForThisDay.length > 0) {
        this.pushThis(
          false,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          mytableForThisDay,
          fredag,
          false
        );
      }
    }
    if (chooseWeekOrDay === "Lørdag") {
      lørdag.forEach((id) => {
        mytable.forEach((element) => {
          if (element.id === id) {
            mytableForThisDay.push(element);
          }
        });
      });

      if (mytableForThisDay.length > 0) {
        this.pushThis(
          false,
          false,
          false,
          false,
          false,
          true,
          false,
          false,
          mytableForThisDay,
          lørdag,
          false
        );
      }
    }
    if (chooseWeekOrDay === "Søndag") {
      søndag.forEach((id) => {
        mytable.forEach((element) => {
          if (element.id === id) {
            mytableForThisDay.push(element);
          }
        });
      });

      if (mytableForThisDay.length > 0) {
        this.pushThis(
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          false,
          mytableForThisDay,
          søndag,
          false
        );
      }
    }
    if (chooseWeekOrDay === "ikke valgt") {
      ikkevalgt.forEach((id) => {
        mytable.forEach((element) => {
          if (element.id === id) {
            mytableForThisDay.push(element);
          }
        });
      });

      if (mytableForThisDay.length > 0) {
        this.pushThis(
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          mytableForThisDay,
          ikkevalgt,
          false
        );
      }
    }
  };
  thisweekDays = () => {
    let days = [];
    if (this.state.mandag > 0) {
      days.push({ day: "Mandag", antal: this.state.mandag });
    }
    if (this.state.tirsdag > 0) {
      days.push({ day: "Tirsdag", antal: this.state.tirsdag });
    }
    if (this.state.onsdag > 0) {
      days.push({ day: "Onsdag", antal: this.state.onsdag });
    }
    if (this.state.torsdag > 0) {
      days.push({ day: "Torsdag", antal: this.state.torsdag });
    }
    if (this.state.fredag > 0) {
      days.push({ day: "Fredag", antal: this.state.fredag });
    }
    if (this.state.lørdag > 0) {
      days.push({ day: "Lørdag", antal: this.state.lørdag });
    }
    if (this.state.søndag > 0) {
      days.push({ day: "Søndag", antal: this.state.søndag });
    }
    if (this.state.ikkevalgt > 0) {
      days.push({ day: "ikke valgt", antal: this.state.ikkevalgt });
    }
    if (days.length > 0) {
      return (
        <div>
          {days.map((day, index) => (
            <p key={index}>
              <Row>
                <Col xs="2">
                  <IconButton
                    color="blue"
                    aria-label="Print"
                    size={"small"}
                    onClick={() => {
                      this.printThis(day.day, 1);
                    }}
                  >
                    <FcPrint size={"15px"} />
                  </IconButton>
                </Col>
                <Col xs="5">
                  <span>{day.day}</span>
                </Col>
                <Col xs="3">
                  <span style={{ marginLeft: "10px" }}>{day.antal}</span>{" "}
                </Col>
                <Col xs="2">
                  <span>
                    {" "}
                    <GoPackage />
                  </span>
                </Col>
              </Row>
              <hr />
            </p>
          ))}
        </div>
      );
    }
  };

  nextweekDays = () => {
    let days = [];
    if (this.state.nextMandag > 0) {
      days.push({ day: "Mandag", antal: this.state.nextMandag });
    }
    if (this.state.nextTirsdag > 0) {
      days.push({ day: "Tirsdag", antal: this.state.nextTirsdag });
    }
    if (this.state.nextOnsdag > 0) {
      days.push({ day: "Onsdag", antal: this.state.nextOnsdag });
    }
    if (this.state.nextTorsdag > 0) {
      days.push({ day: "Torsdag", antal: this.state.nextTorsdag });
    }
    if (this.state.nextFredag > 0) {
      days.push({ day: "Fredag", antal: this.state.nextFredag });
    }
    if (this.state.nextLørdag > 0) {
      days.push({ day: "Lørdag", antal: this.state.nextLørdag });
    }
    if (this.state.nextSøndag > 0) {
      days.push({ day: "Søndag", antal: this.state.nextSøndag });
    }
    if (this.state.nextIkkevalgt > 0) {
      days.push({ day: "ikke valgt", antal: this.state.nextIkkevalgt });
    }
    if (days.length > 0) {
      return (
        <div>
          {days.map((day, index) => (
            <p key={index}>
              <Row>
                <Col xs="2">
                  <IconButton
                    color="blue"
                    aria-label="Print"
                    size={"small"}
                    onClick={() => {
                      this.printThis(day.day, 2);
                    }}
                  >
                    <FcPrint size={"15px"} />
                  </IconButton>
                </Col>
                <Col xs="5">
                  <span>{day.day}</span>
                </Col>
                <Col xs="3">
                  <span style={{ marginLeft: "10px" }}>{day.antal}</span>{" "}
                </Col>
                <Col xs="2">
                  <span>
                    {" "}
                    <GoPackage />
                  </span>
                </Col>
              </Row>
              <hr />
            </p>
          ))}
        </div>
      );
    }
  };

  dataloading = () => {
    if (this.state.thisWeek.length <= 0) {
      return (
        <div style={{ marginTop: 300, marginBottom: 400 }}>
          <div className="sweet-loading" style={{ marginLeft: 600 }}>
            <RiseLoader sizeUnit={"px"} size={20} color={"#4C8FBF"} />
          </div>
        </div>
      );
    } else {
      return this.thisweekDays();
    }
  };
  bookingErr = () => {
    if (this.state.bookingErr.length > 0) {
      return (
        <div>
          <Card>
            <CardTitle>
              <p style={{ color: "Tomato" }}>
                ERROR: Disse bookinger er ikke med i pakkemodel
              </p>
            </CardTitle>
            <CardBody>
              <TableERR
                myData={this.state.bookingErr}
                dataColumn={this.state.errorColumn}
              />{" "}
            </CardBody>
          </Card>
        </div>
      );
    }
  };
  render() {
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            {/* Virtuelt Bookings from here ---> */}
            <Col xs={12} md={6}>
              <Card className="card-chart">
                <CardBody>
                  <IconButton
                    style={{ marginLeft: this.state.vckIcon }}
                    aria-label="View more"
                    onClick={() => {
                      this.chooseBooking(1);
                    }}
                  >
                    <FaCocktail
                      size={"50px"}
                      color={"#d16557"}
                      //style={{ marginLeft: "35%" }}
                    />
                  </IconButton>
                  <p style={{ marginLeft: this.state.vckText }}>
                    {" "}
                    View all virtual bookings{" "}
                  </p>
                </CardBody>
              </Card>
            </Col>
            {/* normal bookings from here ---> */}
            <Col xs={12} md={6}>
              <Card className="card-chart">
                <CardBody>
                  <IconButton
                    style={{ marginLeft: this.state.bIcon }}
                    aria-label="View more"
                    onClick={() => {
                      this.chooseBooking(2);
                    }}
                  >
                    <FaGlassCheers
                      size={"50px"}
                      color={"#d16557"}
                      //style={{ marginLeft: "35%" }}
                    />
                  </IconButton>
                  <p style={{ marginLeft: this.state.bText }}>
                    {" "}
                    View all bookings{" "}
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12">{this.bookingErr()}</Col>
          </Row>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { auth } = state;
  return { auth: auth };
}

export default connect(mapStateToProps)(BookingOverview);
