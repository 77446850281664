import types from "../actionTypes";

const initialState = {
  assignmentId: null,
  assignmentBool: false,
  jobId: null,
  assignmentName: "",
  statusChangeArr: [{componentId: 0, status: "In progress"}],
  statusBool: false,
  managementId: null,
};

const assignmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.assignment.managementId:
      return {
        ...state,
        managementId: action.payload,
      };
    case types.assignment.statusBool:
      return {
        ...state,
        statusBool: action.payload,
      };
    case types.assignment.statusChangeArr:
      return {
        ...state,
        statusChangeArr: action.payload,
      };
    case types.assignment.assignmentId:
      return {
        ...state,
        assignmentId: action.payload,
      };
      case types.assignment.assignmentName:
      return {
        ...state,
        assignmentName: action.payload,
      };
    case types.assignment.assignmentBool:
      return {
        ...state,
        assignmentBool: action.payload,
      };
    case types.assignment.jobId:
      return {
        ...state,
        jobId: action.payload,
      };
      case types.assignment.changeStatus:
        let statusCompId = state.statusChangeArr.find(
          (f) => f.componentId === action.payload.componentId
        );
          statusCompId.status = action.payload.status;
    default:
      return state;
  }
};

export default assignmentReducer;
