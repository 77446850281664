import React, { useState, useEffect } from "react";
import { useCSVReader, formatFileSize } from "react-papaparse";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import {
  Alert,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Input,
  Form,
  FormGroup,
  Button,
} from "reactstrap";
import apiFacade from "../../auth/apiFacade";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableInlineCellEditing,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";
import { EditingState } from "@devexpress/dx-react-grid";
import { SearchState, IntegratedFiltering } from "@devexpress/dx-react-grid";
import { ScreenSize } from "helper/ScreenSize";
import PacmanLoader from "react-spinners/PacmanLoader";

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const GREY_DIM = "#686868";

const styles = {
  zone: {
    alignItems: "center",
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  },
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 60,
    width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  },
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  },
  stuck: {
    position: "fixed",
    bottom: "15%",
    right: "5%",
  },
  alert: {
    position: "fixed",
    left: "43%",
    color: "black",
  },
  pacman: {
    position: "fixed",
    bottom: "50%",
    right: "50%",
    zIndex: "1",
  },
};

const FocusableCell = ({ onClick, ...restProps }) => {
  const { column } = restProps;
  if (
    column.name === "name" ||
    column.name === "amount" ||
    column.name === "unit" ||
    column.name === "category"
  ) {
    return <Table.Cell {...restProps} />;
  }
  return <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />;
};

export default function Wares() {
  const { CSVReader } = useCSVReader();
  const [searchInput, setSearchInput] = useState(null);
  const [tableSearch, setTableSearch] = useState("");
  const [csvFile, setCsvFile] = useState(false);
  const [myData, setMyData] = useState([]);
  const [resBool, setResBool] = useState(false);
  const [saveBool, setSaveBool] = useState(true);
  const [resMes, setResMes] = useState();
  const [resColor, setResColor] = useState("success");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [whatRow, setWhatRow] = useState(true);
  const [columns, setColumns] = useState([
    { name: "name", title: "Name" },
    { name: "amount", title: "Amount" },
    { name: "unit", title: "Unit" },
    { name: "category", title: "Category" },  
    { name: "extraWares", title: "Extra Wares" },
    { name: "reducedWares", title: "Reduced Wares" },
    { name: "return", title: "Return" },
  ]);
  const [rows, setRows] = useState([]);
  const getRowId = (row) => row.componentId;

  useEffect(() => {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      setColumns([
        { name: "name", title: "Name" },     
        { name: "extraWares", title: "Extra Wares" },
        { name: "reducedWares", title: "Reduced Wares" },
        { name: "return", title: "Return" },
      ]);
      setWhatRow(false);
    } else if (screenSize === 2) {
      setWhatRow(false);
    }
  }, []);

  const handleSearchBar = (event) => {
    if (searchInput != null) {
      apiFacade.getData("/PackingListReturns/" + searchInput).then((data) => {
        setMyData(data);
        setRows(data.packingListReturnDto);
      });
    }
    event.preventDefault();
  };

  const handleChangeSearchBar = (event) => {
    if (event.target.value !== "" && csvFile) {
      setRows([]);
      setCsvFile(false);
      document.getElementById("IconButtonId").click();
    }
    setSearchInput(event.target.value);
    if (event.target.value.length > 3 && event.target.value.length < 6) {
      setButtonDisabled(false);
    } else if (event.target.value.length === 0 && rows.length > 0) {
      setRows([]);
      setTableSearch("");
    } else {
      setButtonDisabled(true);
    }
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId =
        rows.length > 0 ? rows[rows.length - 1].componentId + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          componentId: startingAddedId + index,
          ...row,
        })),
      ];
    }
    if (changed) {
      changedRows = rows.map((row) =>
        changed[row.componentId] ? { ...row, ...changed[row.componentId] } : row
      );
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter((row) => !deletedSet.has(row.componentId));
    }
    setRows(changedRows);
  };

  const handleCSVResult = (results) => {
    let data = { data: results.data };
    setCsvFile(true);
    setRows([]);
    setSearchInput(null);
    document.getElementById("searchCSVById").value = "";
    apiFacade.postDataWorking("/PackingListReturns/csv", data).then((data) => {
      setRows(data.packingListReturnDto);
      setMyData(data);
    });
  };

  const handleRemove = (file) => {
    if (file === null && rows.length > 0 && searchInput === null) {
      setTimeout(() => {
        setRows([]);
        setCsvFile(false);
      }, 1000);
    }
  };

  const saveData = () => {    
    let url = "https://pakkemodelapi.azurewebsites.net/api";
    let data = { bookingId: myData.bookingId, packingListReturnDto: rows };
    setSaveBool(false);
    apiFacade.putData(url + "/PackingListReturns/", data).then((res) => {
      if (res.ok) {
        apiFacade.getData("/PriceOnPakkelistes/" + myData.bookingId);
        setResColor("success");
        setResMes("The data has been saved");
        setSaveBool(true);
        setResBool(true);
        setTimeout(() => {
          setResBool(false);
        }, 3000);
      } else {
        setResBool(true);
        setResColor("danger");
        setResMes("Error the data could not be saved");
        setTimeout(() => {
          setResBool(false);
        }, 3000);
      }
    });
  };

  const waitRes = () => {
    if (resBool) {
      return (
        <Alert style={styles.alert} color={resColor}>
          {resMes}
        </Alert>
      );
    }
  };

  const saveButton = () => {
    if (rows.length > 0) {
      if (saveBool) {
        return (
          <Button
            onClick={() => saveData()}
            color="success"
            style={styles.stuck}
          >
            {" "}
            Save{" "}
          </Button>
        );
      } else {
        return <PacmanLoader size={50} css={styles.pacman} color={"#d16557"} />;
      }
    }
  };

  const headerRow = () => {
    if (whatRow) {
      return (
        <Row>
          <Col xs={12} md={6}>
            <Card style={{ height: "90%" }}>
              <CardHeader>
                <CardTitle tag="h4">Digital Packing List</CardTitle>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSearchBar}>
                  <Row>
                    <Col xs={12} md={7}>
                      <FormGroup>
                        <Input
                          style={{
                            padding: "20px",
                            fontSize: "16px",
                            borderRadius: "25px",
                            borderColor: "#d16557",
                          }}
                          onChange={handleChangeSearchBar}
                          id="searchCSVById"
                          name="searchCSVById"
                          placeholder="Booking id"
                          type="number"
                          min="0"
                          max="99999"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={5}>
                      <Button
                        disabled={buttonDisabled}
                        style={{backgroundColor: "#d16557"}}
                        type="submit"
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card style={{ height: "90%" }}>
              <CardHeader>
                <CardTitle tag="h4">Upload CSV</CardTitle>
              </CardHeader>
              <CardBody>
                <CSVReader
                  onUploadAccepted={(results) => {
                    handleCSVResult(results);                    
                  }}
                  onDragOver={(event) => {
                    event.preventDefault();                    
                  }}
                  onDragLeave={(event) => {
                    event.preventDefault();                    
                  }}
                >
                  {({ getRootProps, acceptedFile, getRemoveFileProps }) => (
                    <>
                      <div
                        {...getRootProps()}
                        style={Object.assign(
                          {},
                          styles.zone,
                          styles.zoneHover
                        )}
                      >
                        {acceptedFile ? (
                          <>
                            <div style={styles.file} id="filename-1">
                              <div style={styles.info}>
                                <span style={styles.size}>
                                  {formatFileSize(acceptedFile.size)}
                                </span>
                                <span style={styles.name}>
                                  {acceptedFile.name}
                                </span>
                              </div>
                              <IconButton
                                id="IconButtonId"
                                {...getRemoveFileProps()}
                                style={styles.remove}
                              >
                                <HighlightOffTwoToneIcon color="secondary"></HighlightOffTwoToneIcon>
                              </IconButton>
                            </div>
                          </>
                        ) : (
                          <div>
                            Drop CSV file here or click to upload
                            {handleRemove(acceptedFile)}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </CSVReader>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col xs="12">
            <Card style={{ height: "90%" }}>
              <CardHeader>
                <CardTitle tag="h4">Wares list</CardTitle>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSearchBar}>
                  <Row>
                    <Col md={7}>
                      <FormGroup>
                        <Input
                          style={{
                            padding: "20px",
                            fontSize: "16px",
                            borderRadius: "25px",
                            borderColor: "#d16557",
                          }}
                          onChange={handleChangeSearchBar}
                          id="searchCSVById"
                          name="searchCSVById"
                          placeholder="Booking id"
                          type="number"
                          min="0"
                          max="99999"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={5}>
                      <Button
                        disabled={buttonDisabled}
                        style={{backgroundColor: "#d16557"}}
                        type="submit"
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    }
  };

  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        {headerRow()}
        <Row>
          <Col>
            <Card>
              <Paper>
                <Grid rows={rows} columns={columns} getRowId={getRowId}>
                  {waitRes()}
                  {saveButton()}
                  <SearchState
                    value={tableSearch}
                    onValueChange={setTableSearch}
                  />
                  <IntegratedFiltering />
                  <EditingState onCommitChanges={commitChanges} />
                  <Table cellComponent={FocusableCell} />
                  <TableHeaderRow />
                  <TableInlineCellEditing />
                  <Toolbar />
                  <SearchPanel />
                </Grid>
              </Paper>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
