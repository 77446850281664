import React from 'react'
import { Table, Button, Row, Col } from 'reactstrap';


const CocktailsTable = props => {



    const addCocktail = (props) => {

        if (props.addRow) {
            return (
                <td>
                    <Button color="info" size="sm"
                        onClick={() => {
                            props.addToggle()

                        }}
                        className="button muted-button"
                    >
                        Add drink
              </Button>
                </td>

            )
        }
    }

    const deleteCocktail = (props, id) => {

        if (props.addRow && props.data.length > 0) {
            return (
                <td>
                    <Button color="warning " size="sm"
                        onClick={() => {

                            props.delToggle(id)
                        }}
                        className="button muted-button"
                    >
                        Delete
              </Button>
                </td>

            )
        }
    }


    const deleteCocktailTableHead = (props) => {

        if (props.addRow && props.data.length > 0) {
            return (
                <th>Delete</th>
            )
        }
    }

    return (

        (
            <dvi>
                <Row>
                    <Col xs={{ offset: 10 }}>
                        {addCocktail(props)}
                    </Col>
                </Row>

                <Table>

                    <thead>
                        {props.data.length > 0 ? (
                            <tr>
                                <th>Navn</th>
                                <th>Beskrivelse</th>
                                <th>Pris</th>
                                <th>GlassType</th>
                                <th>Edit</th>
                                {deleteCocktailTableHead(props)}
                            </tr>
                        ) : (
                                <div></div>
                            )}

                    </thead>
                    <tbody>
                        {props.data.length > 0 ? (
                            props.data.map((data, i) => (
                                <tr key={i}>
                                    <td>{data.customName}</td>
                                    <td>{data.beskrivelse}</td>
                                    <td>{data.price}</td>
                                    <td>{data.glassName}</td>
                                    <td>
                                        <Button color="success" size="sm"
                                            onClick={() => {
                                                props.editRow(data); props.onToggle();
                                            }}
                                            className="button muted-button"
                                        >
                                            Edit
              </Button>
                                    </td>
                                    {deleteCocktail(props, data.id)}

                                </tr>
                            ))
                        ) : (
                                <tr>
                                    <td colSpan={3}>No drinks</td>
                                </tr>
                            )}
                    </tbody>
                </Table>
            </dvi>
        )
    )
}

export default CocktailsTable
