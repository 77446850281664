import React, { useState, useEffect } from "react";
import apiFacade from "../../../../auth/apiFacade";
import BarLoader from "react-spinners/BarLoader";
import jsPDF from "jspdf";
import hexToRgb from "pretty-easy-hex-to-rgb";

function FrontpageAll({ listOfId, refreshApi, loading, navne }) {
  const [myListOfIds, setMyListOfIds] = useState([]);
  const [myData, setMyData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setMyListOfIds(listOfId);
    if (refreshApi) {
      apiFacade
        .getData("/samplings/getsamplings/" + listOfId.join())
        .then(data => {
          setMyData(data);

          //setMyListOfIds(data)
        })
        .finally(() => setLoaded(true));
    }
  }, [refreshApi]);

  if (myData.length > 0) {
    let contentIframe = null;
    let pageNr = myData.length - 1;
    var doc = new jsPDF("l", "pt", "a4");

    myData.map((data, i) => {
      var versionDate = data.samplingDates.length;
      doc.setFontSize(14);
      doc.setFontType("normal");
      doc.text(`${data.region}`, 770, 20);
      //doc.line(20, 300, 200, 300);
      // ID
      doc.setFontSize(50);
      doc.setFontType("bold");
      doc.text(
        "Sampling ID: " + `${data.sampling_Id}`,
        doc.internal.pageSize.width / 2,
        80,
        null,
        null,
        "center"
      );

      // //Date

      if (versionDate == 1) {
        const moment = require("moment");
        var date = moment(`${data.samplingDates[0].start}`, "YYYY/MM/DD");
        doc.setFontSize(33);
        doc.setFontType("boold");
        doc.text(
          `Dato: ${date.format("DD/MM/YYYY")}`,
          doc.internal.pageSize.width / 2,
          127,
          null,
          null,
          "center"
        );
        //TIME
        //dag 1
        var startTime = moment(`${data.samplingDates[0].start}`);
        var endTime = moment(`${data.samplingDates[0].end}`);

        doc.setFontSize(33);
        doc.setFontType("boold");
        doc.text(
          `Start: ${startTime.format("HH:mm ")}- ${endTime.format("HH:mm")}`,
          doc.internal.pageSize.width / 2,
          165,
          null,
          null,
          "center"
        );
      }
      if (versionDate == 2) {
        const moment = require("moment");
        var date = moment(`${data.samplingDates[0].start}`, "YYYY/MM/DD");
        var date2 = moment(`${data.samplingDates[1].start}`, "YYYY/MM/DD");
        doc.setFontSize(33);
        doc.setFontType("boold");
        doc.text(
          `Dato: ${date.format("DD/MM/YYYY")} + ${date2.format("DD/MM/YYYY")}`,
          doc.internal.pageSize.width / 2,
          127,
          null,
          null,
          "center"
        );
        //TIME
        //dag 1
        var startTime = moment(`${data.samplingDates[0].start}`);
        var endTime = moment(`${data.samplingDates[0].end}`);
        // dag 2
        var startTime1 = moment(`${data.samplingDates[1].start}`);
        var endTime1 = moment(`${data.samplingDates[1].end}`);

        doc.setFontSize(33);
        doc.setFontType("boold");
        doc.text(
          `Start: ${startTime.format("HH:mm ")}- ${endTime.format(
            "HH:mm"
          )} + ${startTime1.format("HH:mm ")}- ${endTime1.format("HH:mm")}`,
          doc.internal.pageSize.width / 2,
          165,
          null,
          null,
          "center"
        );
      }
      if (versionDate == 3) {
        const moment = require("moment");
        var date = moment(`${data.samplingDates[0].start}`, "YYYY/MM/DD");
        var date2 = moment(`${data.samplingDates[1].start}`, "YYYY/MM/DD");
        var date3 = moment(`${data.samplingDates[2].start}`, "YYYY/MM/DD");
        doc.setFontSize(33);
        doc.setFontType("boold");
        doc.text(
          `Dato: ${date.format("DD/MM/YYYY")} + ${date2.format(
            "DD/MM/YYYY"
          )} + ${date3.format("DD/MM/YYYY")}`,
          doc.internal.pageSize.width / 2,
          127,
          null,
          null,
          "center"
        );

        //TIME
        //dag 1
        var startTime = moment(`${data.samplingDates[0].start}`);
        var endTime = moment(`${data.samplingDates[0].end}`);
        // dag 2
        var startTime1 = moment(`${data.samplingDates[1].start}`);
        var endTime1 = moment(`${data.samplingDates[1].end}`);
        // dag 3
        var startTime2 = moment(`${data.samplingDates[2].start}`);
        var endTime2 = moment(`${data.samplingDates[2].end}`);

        doc.setFontSize(33);
        doc.setFontType("boold");
        doc.text(
          `Start: ${startTime.format("HH:mm ")}- ${endTime.format(
            "HH:mm"
          )} + ${startTime1.format("HH:mm ")}- ${endTime1.format(
            "HH:mm"
          )} + ${startTime2.format("HH:mm ")}- ${endTime2.format("HH:mm")}`,
          doc.internal.pageSize.width / 2,
          165,
          null,
          null,
          "center"
        );
      }
      if (versionDate == 4) {
        const moment = require("moment");
        var date = moment(`${data.samplingDates[0].start}`, "YYYY/MM/DD");
        var date2 = moment(`${data.samplingDates[1].start}`, "YYYY/MM/DD");
        var date3 = moment(`${data.samplingDates[2].start}`, "YYYY/MM/DD");
        var date4 = moment(`${data.samplingDates[3].start}`, "YYYY/MM/DD");
        doc.setFontSize(33);
        doc.setFontType("boold");
        doc.text(
          `Dato: ${date.format("DD/MM/YYYY")} + ${date2.format(
            "DD/MM/YYYY"
          )} + ${date3.format("DD/MM/YYYY")} + ${date4.format("DD/MM/YYYY")}`,
          doc.internal.pageSize.width / 2,
          127,
          null,
          null,
          "center"
        );

        //TIME
        //dag 1
        var startTime = moment(`${data.samplingDates[0].start}`);
        var endTime = moment(`${data.samplingDates[0].end}`);
        // dag 2
        var startTime1 = moment(`${data.samplingDates[1].start}`);
        var endTime1 = moment(`${data.samplingDates[1].end}`);
        // dag 3
        var startTime2 = moment(`${data.samplingDates[2].start}`);
        var endTime2 = moment(`${data.samplingDates[2].end}`);
        // dag 4
        var startTime3 = moment(`${data.samplingDates[3].start}`);
        var endTime3 = moment(`${data.samplingDates[3].end}`);

        doc.setFontSize(33);
        doc.setFontType("boold");
        doc.text(
          `Start: ${startTime.format("HH:mm ")}- ${endTime.format(
            "HH:mm"
          )} + ${startTime1.format("HH:mm ")}- ${endTime1.format(
            "HH:mm"
          )} + ${startTime2.format("HH:mm ")}- ${endTime2.format(
            "HH:mm"
          )} + ${startTime3.format("HH:mm ")}- ${endTime3.format("HH:mm")}`,
          doc.internal.pageSize.width / 2,
          165,
          null,
          null,
          "center"
        );
      }

      // if its company
      if (data.outlet != "") {
        var info = data.outlet;
        var forretningNameArr = info.split(",");

        if (forretningNameArr.length <= 2) {
          forretningNameArr = forretningNameArr[0].split("-");
        }

        doc.setFontSize(28);
        doc.setFontType("boold");
        doc.text(
          `Forretning: ${forretningNameArr[0]}`,
          doc.internal.pageSize.width / 2,
          200,
          null,
          null,
          "center"
        );
      }
      // // if not then --> privat
      // else {
      //   doc.setFontSize(28);
      //   doc.setFontType("boold");
      //   doc.text(
      //     `Privat: ${data.contactPerson}`,
      //     doc.internal.pageSize.width / 2,
      //     200,
      //     null,
      //     null,
      //     "center"
      //   );
      // }

      // // Adresse
      doc.setFontSize(28);
      doc.setFontType("boold");
      doc.text(
        `Address: ${data.adresse}`,
        doc.internal.pageSize.width / 2,
        240,
        null,
        null,
        "center"
      );

      // // kontakt person samt tlf. nr
      //doc.line(doc.internal.pageSize.width / 2 - 45, 263, doc.internal.pageSize.width / 2 - 45, 283);
      if (data.kontaktPersonOutlet != "") {
        doc.setFontSize(17);
        doc.setFontType("boold");
        doc.text(
          `Kontaktperson: ${data.kontaktPersonOutlet} `,
          doc.internal.pageSize.width / 2,
          280,
          null,
          null,
          "center"
        );
      } else {
        doc.setFontSize(21);
        doc.setFontType("boold");
        doc.text(
          `Kontaktperson: Ved spørgsmål kontakt 7199 0174, tast 9 ved valgmulighed`,
          doc.internal.pageSize.width / 2,
          280,
          null,
          null,
          "center"
        );
      }
      doc.setFontSize(21);
      doc.setFontType("boold");
      if (data.samples) {
        doc.text(
          `Hvad skal der samples: ${data.samples} `,
          doc.internal.pageSize.width / 2,
          320,
          null,
          null,
          "center"
        );
      } else {
        doc.text(
          `Hvad skal der samples: Ikke angivet i podio `,
          doc.internal.pageSize.width / 2,
          320,
          null,
          null,
          "center"
        );
      }

      var beskrivelseTekst = "Beskrivelse af event:";

      doc.setFontSize(21);
      doc.setFontType("boold");
      doc.text(
        `Beskrivelse af event: `,
        doc.internal.pageSize.width / 2 - 160,
        355,
        null,
        null,
        "center"
      );

      doc.fromHTML(data.beskrivelseAfEvent, 350, 328, {
        width: 470,
        setFontSize: "130"
      });

      if (i < pageNr) {
        doc.addPage();
      }
    });
    const uri = doc.output("datauristring");
    return <iframe frameBorder="0" width={"900"} height={"700"} src={uri} />;
  }

  if (refreshApi) {
    return (
      <div>
        <div className="sweet-loading" style={{ marginLeft: 300 }}>
          <BarLoader
            height={5}
            width={200}
            sizeUnit={"px"}
            size={500}
            color={"#4C8FBF"}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ marginLeft: 450 }}>
          <h5>Press View</h5>
        </div>
      </div>
    );
  }
}
export default FrontpageAll;
