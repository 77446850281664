import React, { Component } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { AppBar, Typography } from "@material-ui/core/";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import { FaSave } from "react-icons/fa";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import apiFacade from "../../../auth/apiFacade";
import SamplingTable from "../Tables/SamplingTable.js";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  }
}));

const componentColumn = [
  { name: "id", title: "ID" },
  { name: "name", title: "Name" },
  { name: "product_id", title: "Product ID" },
  { name: "actions", title: "Actions" }
];

export default class Catering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myData: [],
      isLoading: true
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    await apiFacade.getData("/samplingcategories").then(data => {
      data.forEach(element => {
        this.state.myData.push(element);
      });
    });
    await this.setState({ isLoading: false });
  }

  datacheck = () => {
    if (!this.state.isLoading) {
      return (
        <SamplingTable
          dataColumn={componentColumn}
          myData={this.state.myData}
          /*  grouping={grouping}
          defaultExpandedGroups={this.state.defaultExpandedGroups} */
        />
      );
    } else {
      return <div>Loading</div>;
    }
  };
  render() {
    return (
      <div>
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <Row>
                <Col xs="6">
                  <CardHeader>
                    <p>
                      <h6> Important!!</h6>
                      To get the right packinglist then Read this before adding
                      a new Samplingtype to this list{" "}
                    </p>
                  </CardHeader>

                  <CardBody>
                    <Row>
                      <Col xs="6">
                        <p>
                          If a new sampling type and a new product are created,
                          they are combined by specifying the same product id.
                          Remember exactly the same name listed in Podio.
                        </p>
                      </Col>
                      <Col xs="6">
                        <p>
                          Hvis der oprettes en ny samplingstype samt et nyt
                          produkt, kombineres de ved, at angive samme produkt
                          id. Husk nøjagtigt samme navn som er angivet i Podio.
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Col>
                <Col xs="6">
                  <CardHeader>
                    <p>View all Samplingstype and their products id</p>
                  </CardHeader>

                  <CardBody>{this.datacheck()}</CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
