import React, { useEffect, useState } from "react";
import * as GlassesFuncs from "./Func/GlassesFunc";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { CardBody, Row, Col, Card, CardHeader, CardTitle } from "reactstrap";
import GlassesTable from "./Tables/GlassesTable";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { ScreenSize } from "helper/ScreenSize";

var bookingStyle = {
  stuck: {
    position: "fixed",
    marginTop: "1%",
    marginLeft: "80%",
    zIndex: "1000",
    backgroundColor: "#d16557",
    color: "#FFFFFF",
  },
  stuck2: {
    position: "fixed",
    marginTop: "1%",
    marginLeft: "60%",
    zIndex: "1000",
    backgroundColor: "#d16557",
    color: "#FFFFFF",
  },
};

export default function Glasses() {
  const [glasData, setGlasData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [componentColumn, setComponentColumn] = useState(GlassesFuncs.getGlassColumn()); 
  const [stuckStyle, setStuckStyle] = useState(bookingStyle.stuck);

  useEffect(() => {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      setComponentColumn([
        { name: "name", title: "Name" },
        { name: "actions", title: "Actions" },
      ]);
      setStuckStyle(bookingStyle.stuck2);
    } else if (screenSize === 2) {
      setComponentColumn([
        { name: "name", title: "Name" },
        { name: "size", title: "Size" },
        { name: "unitName", title: "Unit" },
        { name: "iconlink", title: "Image" },
        { name: "actions", title: "Actions" },
      ]);
    }
    GlassesFuncs.getAllglasses().then((data) => {
      setGlasData(data);
      setIsLoading(false);
    });
  }, []);

  if (!isLoading) {
    return (
      <div>
        <PanelHeader />
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle>Glass Types</CardTitle>
                </CardHeader>
                <CardBody>
                  <GlassesTable
                    myData={glasData}
                    dataColumn={componentColumn}
                  />
                </CardBody>
              </Card>
            </Col>
            <Fab
              style={stuckStyle}
              aria-label="add"
              // onClick={method here!!!!}
            >
              <AddIcon style={{ fontSize: 40 }} />
            </Fab>
          </Row>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <PanelHeader />
        <div className="content">
          <Row>
            <Col xs="12">
              <Card>
                <CardHeader>
                  <CardTitle>Glass Types</CardTitle>
                </CardHeader>
                <CardBody>Loading...</CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
