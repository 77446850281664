import React, { Component } from "react";
import {
  Row,
  Button,
  Col,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { ScreenSize } from "helper/ScreenSize";
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import FrontpageAll from "./PrintAll/FrontpageAll";

var packingStyle = {
  textColor: {
    color: "#3787C6",
  },
  backbtn: {
    position: "absolute",
    top: "10px",
  },
  printMenu: {
    position: "absolute",
    top: "18%",
    left: "100px",
  },
  fixedElement: {
    position: "fixed",
    width: 250,
    height: 80,
  },
};

export default class Frontpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      jobType: [],
      myListOfIds: [],
      colSize1: 2,
      colSize2: 8,
    };
  }

  componentDidMount() {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1 || screenSize === 2) {
     this.setState({ colSize1: 12});
     this.setState({ colSize2: 12});
    }

    // this.getdata(this.props.match.params.id);
    this.state.myListOfIds.push(this.props.match.params.id);
  }

  // withOrWithOutMenu = () => {
  //   if (this.state.isLoading !== true && this.state.jobType.length > 0) {
  //     if (this.state.jobType[0].value !== "Cocktailkursus") {
  //       return (
  //         <DropdownItem
  //           onClick={() => {
  //             this.props.history.push(
  //               `/bookings/${this.props.match.params.id}/menu`
  //             );
  //           }}
  //         >
  //           Menu
  //         </DropdownItem>
  //       );
  //     }
  //   }
  // };

  render() {
    const { colSize2, colSize1 } = this.state;
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={colSize2} className="mr-auto">
              <Card className="card-chart ">
                <CardHeader>
                  <CardTitle>Frontpage</CardTitle>
                </CardHeader>
                <CardBody>
                  <FrontpageAll
                    listOfId={this.state.myListOfIds}
                    refreshApi={true}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={colSize1}>
              <Card>
                <CardBody>
                  <Button
                    color="danger"
                    onClick={() => {
                      this.props.history.push(
                        `/bookingoverview/bookings/${this.props.match.params.id}`
                      );
                    }}
                  >
                    Back
                  </Button>
                  <UncontrolledDropdown style={packingStyle.printMenu}>
                    <DropdownToggle
                      style={{ backgroundColor: "#d16557" }}
                      caret
                      color="info"
                    >
                      Print
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={() => {
                          this.props.history.push(
                            `/bookingoverview/bookings/${this.props.match.params.id}/packinglistprint`
                          );
                        }}
                      >
                        Packing List
                      </DropdownItem>
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={() => {
                          this.props.history.push(
                            `/bookingoverview/bookings/${this.props.match.params.id}/frontpage`
                          );
                        }}
                      >
                        Front page
                      </DropdownItem>
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={() => {
                          this.props.history.push(
                            `/bookingoverview/bookings/${this.props.match.params.id}/specsheet`
                          );
                        }}
                      >
                        Specsheet
                      </DropdownItem>
                      {/* {this.withOrWithOutMenu()} */}
                      <DropdownItem
                        style={{ color: "#d16557" }}
                        onClick={() => {
                          this.props.history.push(
                            `/bookingoverview/bookings/${this.props.match.params.id}/returliste`
                          );
                        }}
                      >
                        Returliste
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
