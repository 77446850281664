import React, { useState, useEffect } from "react";
import apiFacade from "../../../../auth/apiFacade";
import PresComp from "./PresComp";
import BarLoader from "react-spinners/BarLoader";
import jsPDF from "jspdf";
import hexToRgb from "pretty-easy-hex-to-rgb";
import { fstat } from "fs";
import Moment from "react-moment";
import { ScreenSize } from "helper/ScreenSize";

function sortNumber(a, b) {
  return a - b;
}

var headRows = [
  { title: "Item", dataKey: "name" },
  { title: "Amount", dataKey: "amount" },
  { title: "Unit", dataKey: "unit" },
  { title: "Zone", dataKey: "zone" },
  { title: "Bay", dataKey: "bay" },
  { title: "Shel", dataKey: "shelf" },
  { title: "Position", dataKey: "position" },
];

var bookingInfoHeadRow = [
  { title: "Title", dataKey: "title" },
  { title: "Info", dataKey: "value" },
];

const Totallist = ({
  listOfId,
  refreshApi,
  loading,
  navne,
  componentDtos,
  sortingList,
  startDate,
  endDate,
}) => {
  const [myListOfIds, setMyListOfIds] = useState([]);
  const [myData, setMyData] = useState([]);
  const [newPackinglist, setNewPackinglist] = useState([]);
  const [totalListByCategory, setTotalListByCategory] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [myComponentDtos, setMyComponentDtos] = useState([]);
  const [mySortingList, setmySortingList] = useState([]);
  const [pdfWidth, setPdfWidth] = useState(700);
  const [pdfHeight, setPdfHeight] = useState(900);

  useEffect(() => {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      setPdfWidth(300);
      setPdfHeight(350);
    } else if (screenSize === 2) {
      setPdfWidth(675);
      setPdfHeight(800);
    }
    setMyListOfIds(listOfId);
    setMyComponentDtos(componentDtos);
    setmySortingList(sortingList);
    if (refreshApi) {
      apiFacade
        .getData("/bookings/totallistofpacking/" + listOfId.join())
        .then((data) => {
          setNewPackinglist(data);
          data.forEach((element) => {});
          //setMyListOfIds(data)
        });
      apiFacade
        .getData("/bookings/getlistofpacking/" + listOfId.join())
        .then((data) => {
          setMyData(data);

          //setMyListOfIds(data)
        })
        .finally(() => setLoaded(true));
    }
  }, [refreshApi]);

  let pageCount = 0;
  let totalPage = 0;
  let newCount = 0;
  let bookingMsg = [];
  if (myData.length > 0) {
    let contentIframe = null;
    let pageNr = myData.length - 1;
    var doc = new jsPDF("p", "pt", "a4", true);
    var changeId = 0;
    var comp = [];
    myData.map((data, i) => {
      let newBookingInfo = [];
      let newDate = data.bookingInfo[0].date.slice(0, 10).split("-");
      if (
        data.bookingInfo[0].additionalInfo !== null ||
        data.bookingInfo[0].additionalBuyInRequest !== null
      ) {
        let additionalBuyInRequest = "";
        let yderligereInfo = "";
        if (data.bookingInfo[0].additionalBuyInRequest !== null) {
          additionalBuyInRequest = data.bookingInfo[0].additionalBuyInRequest;
        }
        if (data.bookingInfo[0].additionalInfo !== null) {
          yderligereInfo = data.bookingInfo[0].additionalInfo;
        }
        let yderligereInfoObject = {
          Id: data.bookingInfo[0].id,
          yderligereInfo: yderligereInfo,
          additionalBuyInRequest: additionalBuyInRequest,
        };
        bookingMsg.push(yderligereInfoObject);
      }

      const componentDtos = [];
      const componentWithPid = [];

      const listOfComponents = data.packinglist.map((id) => {
        return {
          componentId: id.component.id,
        };
      });

      sortingList.map((sorting) => {
        sorting.componentDtos.map((dtos) => {
          componentDtos.push(dtos);
        });
      });

      listOfComponents.map((plist) => {
        componentDtos.map((d) => {
          if (d.componentId == plist.componentId) {
            componentWithPid.push({
              componentId: d.componentId,
              pladsId: d.pladsId,
              colorId: d.colorId,
              categoryPlads: d.categoryPlads,
              bay: d.bay,
              shelf: d.shelf,
              zone: d.zone,
              position: d.position,
            });
          }
        });
      });

      let myNewData = [];
      if (data.packinglist.length > 0) {
        var carlsbergAmount = null;
        var unibrewAmount = null;
        myNewData = data.packinglist.map((packing, i) => {
          var pladsID = null;
          var colorId = null;
          var categoryPlads = null;
          var bay = null;
          var shelf = null;
          var amount = packing.amount;
          var zone = null;
          var position = null;

          if (packing.component.id === 126) {
            unibrewAmount = Math.round((packing.amount * 75) / 50);
          }
          if (packing.component.id === 185) {
            carlsbergAmount = Math.round((packing.amount * 75) / 50);
          }

          componentWithPid.map((s) => {
            if (s.componentId == packing.component.id) {
              pladsID = s.pladsId;
              colorId = s.colorId;
              categoryPlads = s.categoryPlads;
              bay = s.bay;
              shelf = s.shelf;
              zone = s.zone;
              position = s.position;
            }
          });
          if (packing.component.id === 233) {
            amount = unibrewAmount;
          }
          if (packing.component.id === 147) {
            amount = carlsbergAmount;
          }
          return {
            componentId: packing.component.id,
            name: packing.component.name,
            amount: amount,
            category: packing.component.category.id,
            pladsId: pladsID,
            unit: packing.unit,
            colorId: colorId,
            categoryPlads: categoryPlads,
            bay: bay,
            shelf: shelf,
            zone: zone,
            position: position,
          };
        });

        if (myNewData[0].shelf != null) {
          var reolUsorting = [];
          var reolSorting = [];

          var hyldeUsorting = [];
          var hyldeSorting = [];

          myNewData.map((data, i) => {
            reolUsorting.push(data.bay);
            hyldeUsorting.push(data.shelf);
          });

          if (reolUsorting.length > 0) {
            //først sorter fra duplicates
            reolSorting = reolUsorting.filter(
              (el, i, a) => i === a.indexOf(el)
            );
            hyldeSorting = hyldeUsorting.filter(
              (el, i, a) => i === a.indexOf(el)
            );
            // dernæste sorter fra 0 til højst
            reolSorting.sort((a, b) => a - b);
            hyldeSorting.sort();

            if (reolSorting.length > 0) {
              reolSorting.forEach((reolNr) => {
                hyldeSorting.map((hylde) => {
                  var pagenr = doc.internal.getCurrentPageInfo();
                  var reolText = "Reol";
                  var hyldeText = reolNr + ".";
                  var fuldText = "";
                  if (hylde != null) {
                    fuldText =
                      reolText + " " + hyldeText + " " + hylde.toUpperCase();
                  }

                  myNewData.map((data) => {
                    if (data.bay === reolNr && data.shelf === hylde) {
                      let newItem = comp.find(
                        (x) => x.componentId === data.componentId
                      );

                      if (newItem) {
                        newItem.amount = newItem.amount + data.amount;
                      } else {
                        comp.push(data);
                      }
                    }
                  });

                  if (reolNr > 89) {
                    reolText = "";
                    hyldeText = "";
                    fuldText = hylde;
                  }
                });
              });
            }
          }
        }
      }

      newBookingInfo = [];
    });

    if (comp.length > 0) {
      let newStartdate = "";
      let newEnddate = "";
      if (startDate.includes("/")) {
        newStartdate = startDate.split("/");
      } else {
        newStartdate = startDate.split("-");
      }
      if (endDate.includes("/")) {
        newEnddate = endDate.split("/");
      } else {
        newEnddate = endDate.split("-");
      }
      doc.setFontSize(18);
      doc.setFont("times");
      doc.setFontType("italic");
      doc.text("Total list", 14, 30);
      doc.text(
        370,
        30,
        "Date " +
          `${
            newStartdate[2] +
            "/" +
            newStartdate[1] +
            "/" +
            newStartdate[0] +
            " - " +
            newEnddate[2] +
            "/" +
            newEnddate[1] +
            "/" +
            newEnddate[0]
          }`
      );
      // //doc.setFontSize(16);
      // doc.setFontSize(18);
      // doc.setFont("times");
      // doc.setFontType("italic");
      // doc.autoTable({
      //   columns: headRows,
      //   body: comp,
      //   startY: 45,
      //   rowPageBreak: "auto",
      //   margin: { horizontal: 10 },
      //   styles: { overflow: "linebreak" },
      //   bodyStyles: { valign: "top" },
      //   columnStyles: { email: { cellWidth: "wrap" } },
      //   // columnStyles: { 0: { cellWidth: 1200 } },

      //   styles: {
      //     lineColor: [44, 62, 80],
      //     lineWidth: 1,
      //     cellWidth: "wrap",
      //     rowPageBreak: "auto",
      //     halign: "justify",
      //     //fillColor: hexToRgb(myColor)
      //   },
      //   columnStyles: {
      //     name: { cellWidth: 200 },
      //     // unit: { cellWidth: 100 },
      //     // amount: { cellWidth: 50 },
      //     // reol: { cellWidth: 40 },
      //     // hylde: { cellWidth: 140 }
      //   },
      //   headStyles: { fillColor: hexToRgb("#36688d") }, // Red  textColor: hexToRgb("#000000")
      //   //pageBreak: 'avoid',
      // });

      let startP = 60;
      if (newPackinglist.length > 0) {
        newPackinglist.forEach((element) => {
          //let categoryNamePos = doc.lastAutoTable.finalY + spaceBetweenPages - 10;
          let headRows = [
            {
              title: element.categoryName,
              dataKey: "componentName",
            },
            { title: "Amount", dataKey: "amount" },
            { title: "Unit", dataKey: "unit" },
          ];
          //doc.text(250, categoryNamePos, `${element.categoryName}`);

          doc.autoTable({
            columns: headRows,
            body: element.packingListComponentInfoDtos,
            startY: startP,
            rowPageBreak: "auto",
            margin: { horizontal: 10 },
            styles: { overflow: "linebreak" },
            bodyStyles: { valign: "top" },
            columnStyles: { email: { cellWidth: "wrap" } },
            // columnStyles: { 0: { cellWidth: 1200 } },

            styles: {
              lineColor: [44, 62, 80],
              lineWidth: 1,
              cellWidth: "wrap",
              rowPageBreak: "auto",
              halign: "justify",
              //fillColor: hexToRgb(myColor)
            },
            columnStyles: {
              componentName: { cellWidth: 350 },
              amount: { cellWidth: 80 },
            },
            headStyles: { fillColor: hexToRgb("#36688d") }, // Red  textColor: hexToRgb("#000000")
            //pageBreak: 'avoid',
          });
          startP = doc.lastAutoTable.finalY + 5;

          // if (beforeLoop !== doc.internal.getNumberOfPages()) {
          //   doc.setFontSize(18);
          //   doc.setFont("times");
          //   doc.setFontType("italic");
          //   doc.text("Packing List", 14, 30);
          //   doc.text(420, 30, "Booking id: " + `${data.bookingId}`);
          //   doc.text(555, 820, `${pageNum}` + "/");
          //   pageNum += 1;

          //   beforeLoop = doc.internal.getNumberOfPages();
          // }
        });
      }
      const stringToHTML = (str) => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(str, "text/html");
        return doc.body;
      };

      doc.addPage();
      let spaceBetweenText = 25;

      if (bookingMsg.length > 0) {
        bookingMsg.forEach((element) => {
          let Id = element.Id;

          const additionalBuyInRequest_step1 = stringToHTML(
            element.additionalBuyInRequest
          );
          let additionalBuyInRequest_step2 =
            additionalBuyInRequest_step1.innerText;
          let splitTitle_additionalBuyInRequest = doc.splitTextToSize(
            additionalBuyInRequest_step2,
            575
          );
          const Yderligere_step1 = stringToHTML(element.yderligereInfo);
          let Yderligere_step2 = Yderligere_step1.innerText;
          doc.setFontSize(10);
          let splitTitle = doc.splitTextToSize(Yderligere_step2, 575);
          if (spaceBetweenText >= 800) {
            doc.addPage();
            spaceBetweenText = 25;
          } else if (spaceBetweenText >= 730 && splitTitle.length > 3) {
            doc.addPage();
            spaceBetweenText = 25;
          }

          doc.setFontSize(12);
          doc
            .setFont(undefined, "bold")
            .text(15, spaceBetweenText, "Job id: " + Id)
            .setFont(undefined, "normal");
          spaceBetweenText += 15;
          if (Yderligere_step2.length > 0) {
            doc.setFontSize(10);
            doc
              .setFont(undefined, "bold")
              .text(15, spaceBetweenText, "Yderligere info: ")
              .setFont(undefined, "normal");
            spaceBetweenText += 15;
            doc.text(15, spaceBetweenText, splitTitle);
          }

          if (additionalBuyInRequest_step2.length > 0) {
            if (Yderligere_step2.length > 0) {
              if (splitTitle.length <= 6) {
                spaceBetweenText += 20 * splitTitle.length;
              } else if (splitTitle.length > 6) {
                spaceBetweenText += 15 * splitTitle.length;
              }
            }
            doc.setFontSize(10);
            doc
              .setFont(undefined, "bold")
              .text(15, spaceBetweenText, "Additional buy in request: ")
              .setFont(undefined, "normal");
            spaceBetweenText += 15;
            doc.text(15, spaceBetweenText, splitTitle_additionalBuyInRequest);
          }

          if (additionalBuyInRequest_step2.length > 0) {
            if (splitTitle_additionalBuyInRequest.length <= 1) {
              spaceBetweenText += 25;
            } else if (
              splitTitle_additionalBuyInRequest.length > 1 &&
              splitTitle_additionalBuyInRequest.length <= 6
            ) {
              spaceBetweenText += 20 * splitTitle_additionalBuyInRequest.length;
            } else if (splitTitle_additionalBuyInRequest.length > 6) {
              spaceBetweenText += 15 * splitTitle_additionalBuyInRequest.length;
            }
          } else {
            if (splitTitle.length <= 1) {
              spaceBetweenText += 25;
            } else if (splitTitle.length > 1 && splitTitle.length <= 6) {
              spaceBetweenText += 20 * splitTitle.length;
            } else if (splitTitle.length > 6) {
              spaceBetweenText += 15 * splitTitle.length;
            }
          }
        });
      }

      //    yderligereInfo
    }

    const uri = doc.output("datauristring");
    return (
      <iframe frameBorder="0" width={pdfWidth} height={pdfHeight} src={uri} />
    );
  }
  if (refreshApi) {
    return (
      <div>
        <div className="sweet-loading" style={{ marginLeft: 300 }}>
          <BarLoader
            height={5}
            width={200}
            sizeUnit={"px"}
            size={500}
            color={"#4C8FBF"}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ marginLeft: 450 }}>
          <h5>Press View</h5>
        </div>
      </div>
    );
  }
};

export default Totallist;
