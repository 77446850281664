import React from 'react'
import PanelHeader from "../../components/PanelHeader/PanelHeader.js";

export default function Components_() {
  return (
    <div>
        <PanelHeader size="sm"/>
          Component
    </div>
  );
}
