import React, { useState, useEffect } from "react";
import * as ContainersFunc from "./Func/ContainersFunc";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Button,
} from "reactstrap";
import ContainerTable from "./Table/ContainerTable";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import apiFacade from "../../auth/apiFacade";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { ScreenSize } from "helper/ScreenSize";

var bookingStyle = {
  stuck: {
    position: "fixed",
    marginTop: "1%",
    marginLeft: "80%",
    zIndex: "1000",
    color: "#FFFFFF",
  },
  stuck2: {
    position: "fixed",
    marginTop: "1%",
    marginLeft: "60%",
    zIndex: "1000",
    color: "#FFFFFF",
  },
};

export default function Containers(props) {
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMsgs, setAlertMsgs] = useState([]);
  const [openSaved, setOpenSaved] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [savedTitle, setSavedTitle] = useState("");
  const [stuckStyle, setStuckStyle] = useState(bookingStyle.stuck);
  const [componentColumn, setComponentColumn] = useState(
    ContainersFunc.getContainerColumn()
  );
  const [containerValues, setContainerValues] = useState({
    name: "",
    size: "",
    suffix: "",
  });

  useEffect(() => {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      setComponentColumn([
        { name: "name", title: "Name" },
        { name: "actions", title: "Actions" },
      ]);
      setStuckStyle(bookingStyle.stuck2);
    }
    ContainersFunc.getAllcontainers().then((data) => {
      setApiData(data);
      setIsLoading(false);
    });
  }, []);

  const addToggle = () => {
    setAddModel(!addModel);
  };

  const addContainer = () => {
    setAlertMsgs([]);

    const { name, size, suffix } = containerValues;
    if (!name.length) {
      alertMsgs.push("Name");
    }
    if (!size.length) {
      alertMsgs.push("Size");
    }
    if (!suffix.length) {
      alertMsgs.push("Suffix (L, cl, kg, stk etc.)");
    }
    if (alertMsgs.length > 0) {
      setAlertVisible(true);
      window.setTimeout(() => {
        setAlertVisible(false);
      }, 12000);
    } else {
      setSavedTitle(" Added");
      apiFacade
        .postData2(
          "https://pakkemodelapi.azurewebsites.net/api/containers",
          containerValues
        )
        .then((response) => {
          setOpenSaved(true);
        })
        .catch((err) => console.error("Error", err));
      window.setTimeout(() => {
        setOpenSaved(!openSaved);
        window.location.reload();
      }, 2000);
    }
  };

  const onDismissAlert = () => setAlertVisible(true);

  const theAlertMsg = () => {
    const alerts = [];
    for (let index = 0; index < alertMsgs.length; index++) {
      alerts.push(
        <Alert
          severity="error"
          style={{ fontSize: "15px" }}
          isOpen={alertVisible}
          toggle={onDismissAlert}
          color="danger"
        >
          <div style={{ marginTop: "5px" }}>
            {" "}
            <p> {alertMsgs[index]} field is required</p>
          </div>
        </Alert>
      );
    }
    return alerts;
  };

  const inputValueprintById = (id, where) => {
    let getValue = document.getElementById(id).value;
    if (getValue !== "" || where === "Name") {
      if (where === "Name") {
        setContainerValues({ ...containerValues, name: getValue });
      }
      if (where === "Size") {
        setContainerValues({ ...containerValues, size: getValue });
      }
      if (where === "Suffix") {
        setContainerValues({ ...containerValues, suffix: getValue });
      }
    }
  };

  if (!isLoading) {
    return (
      <div>
        <PanelHeader />
        <div className="content">
          <Row>
            <Col xs="12">
              <Card>
                <CardHeader>
                  <CardTitle>Container Types</CardTitle>
                </CardHeader>
                <CardBody>
                  <ContainerTable
                    myData={apiData}
                    dataColumn={componentColumn}
                  />
                  <Modal
                    fade={false}
                    isOpen={addModel}
                    style={{ maxWidth: "1600px", width: "50%" }}
                  >
                    <Alert
                      isOpen={openSaved}
                      style={{
                        marginTop: "10px",
                        textAlign: "center",
                        fontSize: "20px",
                        width: "310px",
                        marginLeft: "33%",
                      }}
                    >
                      <NotificationsActiveIcon />
                      {"  Successfully Added"}
                    </Alert>
                    <ModalHeader>Add Container</ModalHeader>
                    <ModalBody>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col xs="9">
                              <h6>Add Container</h6>
                              <Row>
                                <Col xs="4">
                                  <form noValidate autoComplete="off">
                                    <TextField
                                      id="nName"
                                      label={"Name"}
                                      defaultValue={""}
                                      onChange={() => {
                                        inputValueprintById("nName", "Name");
                                      }}
                                    />
                                  </form>
                                </Col>
                                <Col xs="4">
                                  <form noValidate autoComplete="off">
                                    <TextField
                                      id="sSize"
                                      label={"Size"}
                                      defaultValue={""}
                                      onChange={() => {
                                        inputValueprintById("sSize", "Size");
                                      }}
                                    />
                                  </form>
                                </Col>
                                <Col xs="4">
                                  <form noValidate autoComplete="off">
                                    <TextField
                                      id="sSuffix"
                                      label={"Suffix"}
                                      defaultValue={""}
                                      onChange={() => {
                                        inputValueprintById(
                                          "sSuffix",
                                          "Suffix"
                                        );
                                      }}
                                    />
                                  </form>
                                </Col>
                                <Col xs="12" style={{ marginTop: "10px" }}>
                                  {theAlertMsg()}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="success"
                        size="sm"
                        onClick={() => addContainer()}
                      >
                        Add
                      </Button>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => addToggle()}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
            <div style={stuckStyle}>
              <Fab
                style={{ backgroundColor: "#d16557", color: "#FFFFFF" }}
                aria-label="add"
                onClick={() => {
                  addToggle();
                }}
              >
                <AddIcon style={{ fontSize: 40 }} />
              </Fab>
              {/* from here addContainer modal*/}
            </div>
          </Row>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <PanelHeader />
        <div className="content">
          <Row>
            <Col xs="12">
              <Card>
                <CardHeader>
                  <CardTitle>Container Types</CardTitle>
                </CardHeader>
                <CardBody>Loading........</CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
