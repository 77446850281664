import jwt_decode from "jwt-decode";
import queryString from "query-string";
import { oauth2 } from "./oauth2";
//import * as Msal from "msal";
const { REACT_APP_REDIRECT_URI, REACT_APP_AUTHORITY, REACT_APP_CLIENT_ID } =
  process.env;

const URL = require("../../package.json").url.server;
const configMsal = {
  auth: {
    clientId: REACT_APP_CLIENT_ID,
    authority: REACT_APP_AUTHORITY,
    redirectUri: REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
    secureCookies: false,
  },
};
class ApiFacade {
  email;
  firstName;
  lastName;
  accessToken;
  expiresAt;
  refreshToken;
  expiresOn;
  oid;

  getOid() {
    return this.oid;
  }
  getFirstName() {
    return this.firstName;
  }

  getLastName() {
    return this.lastName;
  }

  getToken() {
    return localStorage.getItem("accessToken");
  }

  setToken(token) {
    this.accessToken = token;
  }
  getExpOn() {
    return this.expiresOn;
  }

  setExpOn(expiresOn) {
    this.expiresOn = expiresOn;
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  setRefreshToken(refreshToken) {
    this.refreshToken = refreshToken;
  }

  // async newLogin() {
  //   console.log(process.env);
  //   console.log("test");
  //   let tokenResponse = null;
  //   var client = new Msal.UserAgentApplication(configMsal);
  //   var request = {
  //     scopes: ["user.read offline_access"],
  //   };
  //   let loginResponse = await client.loginPopup(request);
  //   console.log(loginResponse);
  //   tokenResponse = await client.acquireTokenSilent(request);
  //   console.log(tokenResponse);
  //   //const myEmail = "https://graph.microsoft.com/v1.0/me/messages";
  //   const myInfo = "https://graph.microsoft.com/v1.0/me";
  //   let payload = await fetch(myInfo, {
  //     headers: {
  //       Authorization: "Bearer " + tokenResponse.accessToken,
  //     },
  //   });
  //   let json = null;
  //   json = await payload.json();

  //   if (json) {
  //     console.log(json);
  //     this.firstName = json.given_name;
  //     this.lastName = json.family_name;
  //     this.oid = json.id;
  //     this.accessToken = tokenResponse.accessToken;

  //     return { tokenResponse, json };
  //   }
  //   console.log(json);
  // }

  // logout() {
  //   var client = new Msal.UserAgentApplication(configMsal);
  //   client.logout();
  //   console.log(localStorage.getItem("accessToken"));
  //   this.accessToken = null;
  //   this.expiresOn = null;
  //   this.firstName = null;
  //   this.lastName = null;
  //   localStorage.removeItem("accessToken");
  //   localStorage.removeItem("expiresOn");
  // }

  isAuthenticated() {
    var now = Date.now() / 1000;
    var exp = localStorage.getItem("expiresOn");
    if (!exp > now) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("expiresOn");
      sessionStorage.clear();
    }
    return exp > now;
  }

  login(userName, password) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "cache-control": "no-cache",
    };

    return new Promise((resolve, reject) => {
      fetch(oauth2.url, {
        method: "POST",
        headers: headers,
        body: queryString.stringify({
          resource: oauth2.resource,
          grant_type: oauth2.grant_type,
          client_id: oauth2.client_id,
          username: userName,
          password: password,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.accessToken = data.access_token;
          var decoded = jwt_decode(this.accessToken);
          this.firstName = decoded.given_name;
          this.lastName = decoded.family_name;
          this.oid = decoded.oid;
          return resolve(data);
        })
        .catch((err) => reject(err));
    });
  }

  postData(endpoint, data) {
    const headers = {
      /*  "Accept": "application/json", */
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.accessToken,
    };

    return new Promise((resolve, reject) => {
      fetch(URL + endpoint, {
        method: "POST",
        headers: headers,
        body: queryString.stringify(data),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          return resolve(data);
        })
        .catch((err) => reject(err));
    });
  }

  postData2(endpoint, data) {
    // Default options are marked with *
    return fetch(endpoint, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    }).then((response) => response.json()); // parses JSON response into native Javascript objects
  }

  postToLocalHost(endpoint, data) {
    // Default options are marked with *
    return fetch("https://localhost:5001/api" + endpoint, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    }).then((response) => response.json()); // parses JSON response into native Javascript objects
  }

  postDataWorking(endpoint, data) {
    // Default options are marked with *
    return fetch(URL + endpoint, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    }).then((response) => response.json()); // parses JSON response into native Javascript objects
  }
  postDataN(url, dataN) {
    // Default options are marked with *
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': 'Bearer ' + localStorage.getItem("accessToken")
      },
      body: JSON.stringify(dataN),
    });
  }

  deleteData(endpoint) {
    fetch(endpoint, {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    })
      .then(() => {
        //console.log("removed");
      })
      .catch((err) => {
        console.error(err);
      });
  }

  deleteDataWithObj(endpoint, data) {
    return fetch(URL + endpoint, {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    }).then((response) => response.json()); // parses JSON response into native Javascript objects
  }
  putData(endpoint, data) {
    return fetch(endpoint, {
      method: "PUT",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => err);
  }
  putDataWithJson(endpoint, data) {
    return fetch(endpoint, {
      method: "PUT",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => err);
  }

  putDataLocalHost(endpoint, data) {
    return fetch("https://localhost:5001/api/" + endpoint, {
      method: "PUT",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => err);
  }

  getDataRisika() {
    const headers = {
      //Accept: "application/json",
      mode: "no-cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      ContentType: "application/json",
      Authorization:
        "jwt eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjQ2NSwidHlwZSI6InRlbXBvcmFyeS10b2tlbiIsInRpZXIiOjIsInJpZ2h0cyI6eyJESyI6eyJzY29yZV9tb2RlbHMiOlt7Im1vZGVsIjoiQk9IUiIsImV4cGxhbmF0aW9uIjpmYWxzZX1dfX0sImdyYW50X3Blcm1pdCI6bnVsbCwiY29tcGFueSI6MzMsImFsbG93X2VhbiI6dHJ1ZSwiYWxsb3dfbW9uaXRvcmluZyI6dHJ1ZSwiYWxsb3dfcGRmX3JlcG9ydHMiOnRydWUsImFsbG93X2FwaSI6dHJ1ZSwiYWxsb3dfYnJvd3Nlcl9leHRlbnNpb24iOnRydWUsImFsbG93X2NvbXBhbnlfc2VhcmNoIjp0cnVlLCJhbGxvd19wZXJzb25fc2VhcmNoIjp0cnVlLCJhbGxvd19jb21wYW55X2V4cG9ydCI6dHJ1ZSwiYWxsb3dfcGVyc29uX2V4cG9ydCI6dHJ1ZSwiYWxsb3dfbW9uaXRvcmluZ19leHBvcnQiOnRydWUsImFsbG93X3JlbGF0aW9ucyI6dHJ1ZSwiYWxsb3dfaGlnaGxpZ2h0cyI6dHJ1ZSwiYWxsb3dfY3JlZGl0X3BvbGljeSI6WyJleGNsdWRlX3Jpc2lrYV9zY29yZXMiLCJleGNsdWRlX2NvbXBhbnlfdHlwZXMiLCJuZWdhdGl2ZV9lcXVpdHlfbGltaXQiLCJhZ2VfbGltaXQiXSwiYWxsb3dfYmFzaWMiOnRydWUsImFsbG93X2ZpbmFuY2lhbCI6dHJ1ZSwiYWxsb3dfY3JlZGl0Ijp0cnVlLCJhbGxvd19oaWVyYXJjaHkiOnRydWUsImFsbG93X2NvdW50cmllcyI6WyJkayJdLCJhbGxvd19ub3RlcyI6dHJ1ZSwic2VhcmNoX2V4cG9ydHNfbGltaXQiOjUwMDAsImNyZWRpdF9wb2xpY2llcyI6W10sImlhdCI6MTY2MDA0OTUyMSwibmJmIjoxNjYwMDQ5NTIxLCJleHAiOjE2NjAwNTAxMjF9.2TaS6sTdWUJnsylns01gQ-Q0QlnlhaZVMvi0azxHWug",
    };

    return new Promise((resolve, reject) => {
      fetch("https://cors-anywhere.herokuapp.com/https://api.risika.dk/v1.2/dk/list/industries", {
        method: "GET",
        headers: headers,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          return resolve(data);
        })
        .catch((err) => reject(err));
    });
  }

  getData(endpoint) {
    const headers = {
      Accept: "application/json",
      // Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    return new Promise((resolve, reject) => {
      fetch(URL + endpoint, {
        method: "GET",
        headers: headers,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          return resolve(data);
        })
        .catch((err) => reject(err));
    });
  }

  getDataFromLocalHost(endpoint) {
    const headers = {
      Accept: "application/json",
      // Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    return new Promise((resolve, reject) => {
      fetch("https://localhost:5001/api/" + endpoint, {
        method: "GET",
        headers: headers,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          return resolve(data);
        })
        .catch((err) => reject(err));
    });
  }

  getDataOwnEndPoint(endpoint) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    return new Promise((resolve, reject) => {
      fetch(endpoint, {
        method: "GET",
        headers: headers,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          return resolve(data);
        })
        .catch((err) => reject(err));
    });
  }

  getDataText(endpoint) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    return new Promise((resolve, reject) => {
      fetch(URL + endpoint, {
        method: "GET",
        headers: headers,
      })
        .then((res) => res.text()) //<————————————-
        .then((json) => console.log(json));
    });
  }

  readTokenData = () => {
    const jwtToken = localStorage.getItem("accessToken");
    if (jwtToken !== "undefined") {
      var decoded = jwt_decode(jwtToken);
      this.firstName = decoded.given_name;
      this.lastName = decoded.family_name;
      this.oid = decoded.oid;
      this.expiresOn = decoded.expires_on;
      this.refreshToken = decoded.refresh_token;
    }
  };
}

const facade = new ApiFacade();

if (facade.getToken() !== null) {
  facade.readTokenData();
}

export default facade;
