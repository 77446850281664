import React, { useState, useEffect } from "react";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Info from "@material-ui/icons/InfoSharp";
import { withStyles } from "@material-ui/core/styles";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";
import * as ContainerFunc from "../Func/ContainersFunc";
import { ScreenSize } from "helper/ScreenSize";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: "none",
  },
});

const CustomTableCellStyles = {
  style1: {
    //color: "#3787C6",
    fontSize: 12,
    align: "left",
    paddingLeft: 25,
  },
  style2: {
    //color: "#3787C6",
    fontSize: 12,
    align: "left",
    paddingLeft: 0,
  },
  style3: {
    //color: "#3787C6",
    fontSize: 12,
    textAlign: "center",
    paddingLeft: 0,
  },
};

const ContainerCell = (props) => {
  const [vModal, setModal] = useState(false);
  const [eModal, setEModal] = useState(false);
  const [dModal, setDModal] = useState(false);
  const [screenStyle, setScreenStyle] = useState(CustomTableCellStyles.style3);

  const viewToggle = () => setModal(!vModal);
  const editToggle = () => setEModal(!eModal);
  const deleteToggle = () => setDModal(!dModal);

  useEffect(() => {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 2 || screenSize === 1) {
      setScreenStyle(CustomTableCellStyles.style2);
    }
  }, []);

  if (props.column.name === "id") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>{props.row.id}</TableCell>
    );
  }

  if (props.column.name === "name") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.name}
      </TableCell>
    );
  }

  if (props.column.name === "size") {
    return <TableCell>{props.row.size}</TableCell>;
  }
  if (props.column.name === "suffix") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.suffix}
      </TableCell>
    );
  }

  if (props.column.name === "actions") {
    return (
      <React.Fragment>
        <TableCell style={screenStyle}>
          <IconButton
            color="primary"
            aria-label="Edit"
            onClick={() => {
              editToggle();
            }}
          >
            <EditTwoToneIcon />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="View"
            onClick={() => {
              viewToggle();
            }}
          >
            <Info />
          </IconButton>
          <IconButton
            color="secondary"
            aria-label="Delete"
            onClick={() => {
              deleteToggle();
            }}
          >
            <DeleteForeverTwoToneIcon />
          </IconButton>
        </TableCell>
        {/* from here View modal*/}
        {ContainerFunc.viewModel(vModal, viewToggle, props.row.name, props.row)}
        {/* from here Edit modal*/}
        {ContainerFunc.editModel(eModal, editToggle, props.row.name, props.row)}
        {/* from here delete modal*/}
        {ContainerFunc.deleteModel(
          dModal,
          deleteToggle,
          props.row.name,
          props.row
        )}
      </React.Fragment>
    );
  }

  return <TableCell>No Data</TableCell>;
};

export default withStyles(styles)(ContainerCell);
