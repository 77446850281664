import AssignmentView from "views/Assignments/AssignmentView.js";

const routes = [
  {
    path: "/assignments",
    name: "Job Assignments",
    icon: "now-ui-icons design_bullet-list-67",
    component: AssignmentView,
  },
];

export default routes;
