import React from "react";
import apiFacade from "../../../auth/apiFacade";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  CardBody,
  Card,
} from "reactstrap";
import PlasticGlassTable from "./../Tables/PlasticGlassTable";
import TextField from "@material-ui/core/TextField";

function getAllglasses() {
  const data = apiFacade.getData("/newglasses").then((data_) => {
    return data_;
  });
  return data;
}

function getGlass() {
  return "You got a glass!";
}

function getGlassColumn() {
  const componentColumn = [
    { name: "id", title: "Id" },
    { name: "name", title: "Name" },
    { name: "size", title: "Size" },
    { name: "unitName", title: "Unit" },
    { name: "distributorName", title: "Distributor" },
    { name: "categoryName", title: "Category" },
    { name: "iconlink", title: "Image" },
    { name: "actions", title: "Actions" },
  ];
  return componentColumn;
}

function viewModel(modal, editToggle, name, iconlink, rows, glass) {
  return (
    <Modal
      isOpen={modal}
      toggle={editToggle}
      size="lg"
      style={{ maxWidth: "800px", width: "80%" }}
    >
      <ModalHeader toggle={editToggle}>{name}</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Row>
              <Col xs="9">
                <h6>Glass version</h6>
                <Row>
                  <Col xs="6">
                    <TextField
                      id="standard-read-only-input"
                      label="Size:"
                      value={glass.size}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Col>

                  <Col xs="6">
                    <TextField
                      id="standard-read-only-input"
                      label="Unit:"
                      value={glass.unitName}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <TextField
                      id="standard-read-only-input"
                      label="Distributor:"
                      value={glass.distributorName}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Col>
                  <Col xs="6">
                    <TextField
                      id="standard-read-only-input"
                      label="Category:"
                      value={glass.categoryName}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs="3">
                {
                  <img
                    style={{ marginTop: "25px" }}
                    src={"https://pakkemodelapi.azurewebsites.net" + iconlink}
                    width="80"
                    height="100"
                    alt="anImg"
                  />
                }
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col xs="12">
                <h6>Plastic version</h6>
                <PlasticGlassTable rows={rows} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
}

export { getAllglasses, getGlass, getGlassColumn, viewModel };
