import { createStore } from "redux";
import rootReducer from "./reducers";
import { persistStore } from "redux-persist";

export let store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export let persistor = persistStore(store);

export default { store, persistor };