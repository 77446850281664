import React, { useState, useEffect } from "react";
import {
  Button,
  CardBody,
  Card,
  CardTitle,
  Collapse,
  Row,
  Col,
  Input,
} from "reactstrap";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import apiFacade from "../../auth/apiFacade";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0),
      width: "100%",
      height: "auto",
      borderBottom: "solid",
      borderBottomColor: "#d16557",
      borderBottomWidth: "2px",
      borderLeft: "solid",
      borderLeftColor: "#d16557",
      borderLeftWidth: "1px",
      borderRight: "solid",
      borderRightColor: "#d16557",
      borderRightWidth: "1px",
      marginBottom: "10px",
    },
  },
  searchField: {
    borderRadius: "25px",
    borderColor: "#d16557",
  },
}));

var SpecsStyles = {
  buttonStyle: {
    width: "100%",
    borderStyle: "solid",
    borderColor: "#d16557",
    backgroundColor: "#d16557",
    fontSize: "16px",
  },
  specsColStyleTitle: {
    fontSize: "25px",
  },
  specsColStyle: {
    fontWeight: "bold",
    fontSize: "15px",
  },
};
export default function Specs() {
  const classes = useStyles();
  const [specSheet, setSpecSheet] = useState([]);
  const [numberIndex, setNumberIndex] = useState(0);
  const [name, setName] = useState("");
  const [dataSearched, setDataSearched] = useState([]);

  const componentLoop = (components) => {
    const comp = components.map((data, index) => {
      return (
        <Row key={index}>
          <Col xs={6}>{data.componentName}</Col>
          <Col xs={6}>{data.amountOfSuffixes}</Col>
        </Row>
      );
    });
    return comp;
  };

  const mapData = (specList) => {
    const mapSpecList = specList.map((spec, i) => {
      const handleOpen = (specName) => {
        let index = specList.findIndex(
          ({ productName }) => productName === specName
        );
        let updatedTempList = [];
        if (numberIndex !== index) {
          updatedTempList = specList.map((t, i) =>
            i === numberIndex ? { ...t, closed: false } : t
          );
          updatedTempList = updatedTempList.map((t, i) =>
            i === index ? { ...t, closed: !specList[index].closed } : t
          );
          setNumberIndex(index);
        } else {
          updatedTempList = specList.map((t, i) =>
            i === index ? { ...t, closed: !specList[index].closed } : t
          );
          setNumberIndex(index);
        }
        setDataSearched([...updatedTempList]);
      };
      return (
        <div key={i} style={{ marginBottom: "-15px" }}>
          <Button
            onClick={() => handleOpen(spec.productName)}
            style={SpecsStyles.buttonStyle}
          >
            {spec.productName}
          </Button>
          <Collapse isOpen={spec.closed}>
            <div className={classes.root}>
              <Paper>
                <Row>
                  <Col xs={12}>
                    <div style={SpecsStyles.specsColStyleTitle}>
                      {spec.productName}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <span style={SpecsStyles.specsColStyle}>Glass:</span>
                  </Col>
                  <Col xs={6}>{spec.glassName}</Col>
                  <Col xs={6}>
                    <span style={SpecsStyles.specsColStyle}>Ice:</span>
                  </Col>
                  <Col xs={6}>{spec.ice}</Col>
                  <Col xs={6}>
                    <span style={SpecsStyles.specsColStyle}>Method:</span>
                  </Col>
                  <Col xs={6}>{spec.method}</Col>
                  <Col xs={6}>
                    <span style={SpecsStyles.specsColStyle}>Component:</span>
                  </Col>
                  <Col xs={6}>
                    <span style={SpecsStyles.specsColStyle}>Amount:</span>
                  </Col>
                </Row>
                {componentLoop(spec.productComponentDto)}
              </Paper>
            </div>
          </Collapse>
        </div>
      );
    });
    return mapSpecList;
  };

  useEffect(() => {
    apiFacade.getData("/Products/specs").then((data) => {
      setSpecSheet(data);
    });
  }, []);

  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = specSheet.filter((data) => {
        return data.productName.toLowerCase().includes(keyword.toLowerCase());
      });
      if (results.length > 0) {
        setDataSearched([...results]);
      } else {
        setDataSearched([]);
      }
    } else {
      setDataSearched([]);
    }
    setName(keyword);
  };

  const showCollapseList = () => {
    if (dataSearched.length > 0) {
      return mapData(dataSearched, true);
    } else if (name.length > 0) {
      return mapData(dataSearched, true);
    } else {
      return mapData(specSheet, false);
    }
  };

  if (specSheet.length > 0) {
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={1}></Col>
            <Col xs={10}>
              <Card>
                <CardTitle
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "30px",
                  }}
                >
                  CocktailCompany specsheet
                </CardTitle>
                <CardBody>
                  <Input
                    style={{ padding: "20px", fontSize: "16px" }}
                    className={classes.searchField}
                    value={name}
                    onChange={filter}
                    id="searchCocktails"
                    name="textInput"
                    placeholder="Search"
                    type="text"
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs={1}></Col>
            <Col xs={1}></Col>
            <Col xs={10}>{showCollapseList()}</Col>
            <Col xs={1}></Col>
          </Row>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
