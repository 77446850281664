import React, { useEffect, useState } from "react";
import apiFacade from "../../../../auth/apiFacade";
import BarLoader from "react-spinners/BarLoader";
import jsPDF from "jspdf";
import hexToRgb from "pretty-easy-hex-to-rgb";
var headRows = [
  { title: "Item", dataKey: "name" },
  { title: "Amount", dataKey: "totalAmount" },
  { title: "In Stock", dataKey: "instock" },
  { title: "Unit", dataKey: "unit" },
  { title: "Reol", dataKey: "reol" },
  { title: "Hylde", dataKey: "hylde" },
  { title: "Check Box", dataKey: "checkBox" }
];

const TotalComponenets = ({
  listOfId,
  refreshApi,
  loading,
  navne,
  componentDtos,
  sortingList,
  listOfsamplings,
  startDate,
  endDate
}) => {
  const [myListOfIds, setMyListOfIds] = useState([]);
  const [myData, setMyData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [myComponentDtos, setMyComponentDtos] = useState([]);
  const [mySortingList, setmySortingList] = useState([]);

  useEffect(() => {
    setMyListOfIds(listOfId);
    setMyComponentDtos(componentDtos);
    setmySortingList(sortingList);
    if (refreshApi) {

      apiFacade
        .getData("/samplings/totallist/" + listOfId.join())
        .then(data => {
          setMyData(data);

          //setMyListOfIds(data)
        })
        .finally(() => setLoaded(true));
    }
  }, [refreshApi]);
  if (myData.length > 0) {
    var reolUsorting = [];
    var reolSorting = [];

    var hyldeUsorting = [];
    var hyldeSorting = [];
    let startCheckBok = 88;
    let startPosition = 60;
    let startPositionTitle = 55;
    let startDateChangeSign = startDate.split("-").join("/");
    let endDateChangeSign = endDate.split("-").join("/");
    let startDateSplit = startDateChangeSign.split("/");
    let endDateSplit = endDateChangeSign.split("/");
    var doc = new jsPDF("p", "pt", "a4");
    doc.text("Total List", 14, 30);
    doc.text(
      "Date: " +
        startDateSplit[2] +
        "/" +
        startDateSplit[1] +
        "/" +
        startDateSplit[0] +
        " - " +
        endDateSplit[2] +
        "/" +
        endDateSplit[1] +
        "/" +
        endDateSplit[0],
      185,
      30
    );

    myData.map(totallist => {
      reolUsorting.push(totallist.reol);
      hyldeUsorting.push(totallist.hylde);
    });

    if (reolUsorting.length > 0) {
      //først sorter fra duplicates
      reolSorting = reolUsorting.filter((el, i, a) => i === a.indexOf(el));
      hyldeSorting = hyldeUsorting.filter((el, i, a) => i === a.indexOf(el));
      // dernæste sorter fra 0 til højst
      reolSorting.sort((a, b) => a - b);
      hyldeSorting.sort();

      if (reolSorting.length > 0) {

        reolSorting.forEach(reolNr => {
          hyldeSorting.map(hylde => {
            var pagenr = doc.internal.getCurrentPageInfo();

            var reolText = "Reol";
            var hyldeText = reolNr + ".";
            var fuldText = "";

            if (hylde != null) {
              fuldText = reolText + " " + hyldeText + " " + hylde.toUpperCase();
            }

            var comp = [];

            myData.map(data => {
              if (data.reol == reolNr && data.hylde == hylde) {
                comp.push(data);
              }
            });

            if (reolNr > 89) {
              reolText = "";
              hyldeText = "";
              fuldText = hylde;
            }

            if (comp.length > 0) {
              if (startPosition > 700) {
                doc.addPage();
                startPosition = 60;
                startPositionTitle = 55;
                startCheckBok = 88;
              }
              //doc.setFontSize(16);
              doc.setFontSize(18);
              doc.setFont("times");
              doc.setFontType("italic");
              doc.text(fuldText, 14, startPositionTitle);
              doc.autoTable({
                columns: headRows,
                body: comp,
                startY: startPosition,
                rowPageBreak: "auto",
                margin: { horizontal: 10 },
                styles: { overflow: "linebreak" },
                bodyStyles: { valign: "top" },
                columnStyles: { email: { cellWidth: "wrap" } },
                // columnStyles: { 0: { cellWidth: 1200 } },

                styles: {
                  lineColor: [44, 62, 80],
                  lineWidth: 1,
                  cellWidth: "wrap",
                  rowPageBreak: "auto",
                  halign: "justify"
                  //fillColor: hexToRgb(myColor)
                },
                columnStyles: {
                  name: { cellWidth: 250 },
                  unit: { cellWidth: 50 },
                  totalAmount: { cellWidth: 50 },
                  instock: { cellWidth: 50 },
                  reol: { cellWidth: 40 },
                  hylde: { cellWidth: 75 },
                  checkBox: { cellWidth: 65 }
                },
                headStyles: { fillColor: hexToRgb("#36688d") } // Red  textColor: hexToRgb("#000000")
                //pageBreak: 'avoid',
              });

              for (let index = 0; index < comp.length; index++) {
                doc.rect(550, startCheckBok, 10, 10);
                startCheckBok += 20;
              }

              // doc.setFontSize(18);
              // doc.setFont("times");
              // doc.setFontType("italic");
              // doc.text("Total List", 14, 30);
              //doc.text(420, 30, "Sampling id: " + `${myNewData.sampling_id}`);
              startCheckBok = doc.lastAutoTable.finalY + 75;
              startPosition = doc.lastAutoTable.finalY + 45;
              startPositionTitle = doc.lastAutoTable.finalY + 40;
            }
          });
        });
      }
    }

    let pageNr = myData.length - 1;

    const uri = doc.output("datauristring");
    return <iframe frameBorder="0" width={"900"} height={"700"} src={uri} />;
  }
  if (refreshApi) {
    return (
      <div>
        <div className="sweet-loading" style={{ marginLeft: 300 }}>
          <BarLoader
            height={5}
            width={200}
            sizeUnit={"px"}
            size={500}
            color={"#4C8FBF"}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ marginLeft: 450 }}>
          <h5>Press View</h5>
        </div>
      </div>
    );
  }
};
export default TotalComponenets;
