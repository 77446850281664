import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from "reactstrap";

export default function combindJobtypePackage() {
  return (
    <div>
      <Row>
        <Col xs="4">
          <Card>
            <CardHeader>
              <h6>Choose a package</h6>
            </CardHeader>
            <CardBody></CardBody>
          </Card>
        </Col>
        <Col xs="4">
          <Card>
            <CardHeader>
              <h6>Choose a jybtype</h6>
            </CardHeader>
            <CardBody></CardBody>
          </Card>
        </Col>
        <Col xs="4">
          <Card>
            <CardHeader>
              <h6>Personers per package</h6>
            </CardHeader>
            <CardBody></CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
