import React, { useState, Fragment } from "react";
import AddDrinkForm from "./Forms/AddDrinkForm";
import EditCocktailForm from "./Forms/EditCocktailForm";
import CocktailsTable from "./Tables/CocktailsTable";
import apiFacade from "../../../auth/apiFacade";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";

var bookingStyle = {
  cellStyling: {
    padding: "0px 10px 0px 0px",
  },
  dropdownitemstyle: {
    background: "red",
  },
  textColor: {
    color: "#3787C6",
  },
  cardchartMyFix: {
    position: "absolute",
    right: "220px",
    top: "12px",
  },
  cardchartMyFixEdit: {
    position: "absolute",
    right: "330px",
    top: "0px",
  },
  cardchartMyFixBack: {
    position: "fixed",
    bottom: "55px",
    right: "70px",
  },
  radioWhite: {
    border: "10px solid #90DDD0",
  },
  radioPink: {
    border: "10px solid #EF959D",
  },
  radioRed: {
    border: "10px solid #90DDD0",
  },
};

const ViewTable = (props) => {
  const usersData = [
    {
      id: 1,
      name: "MOJITO",
      beskrivelse:
        "SPRØD HAVANA CLUB 3 ÅRS LYS ROM MUDDLET MED FRISKSKÅRNE LIMEBÅDE OG GYLDENT RØRSUKKER. TILSAT MYNTE OG EN SMULE GINGER ALE.",
      pris: "0",
      glassType: "Highball Glas",
    },
  ];

  const initialFormState = {
    id: null,
    name: "",
    beskrivelse: "",
    pris: 0,
    glassType: "",
  };

  // Setting state
  const [cocktails, setCocktails] = useState(props.data);
  const [drink, setNewDrink] = useState([]);
  const [currentUser, setCurrentUser] = useState(initialFormState);
  const [editing, setEditing] = useState(false);
  const [didUpdate, setdidUpdate] = useState(false);
  const [updateId, setupdateId] = useState(0);
  const [deleteId, setdeleteId] = useState(0);

  // CRUD operations
  const addDrink = (nDrink) => {
    setNewDrink([...drink, nDrink]);
  };

  const deleteDrink = () => {
    // setEditing(false)
    fetch("https://pakkemodelapi.azurewebsites.net/api/EditMenus/" + deleteId, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    })
      .then((res) => res.text()) // OR res.json()
      .then((res) => console.log(res));

    setdeleteModal(!deletemodal);
    setTimeout(function () {
      window.location.reload();
    }, 1000);
    // setUsers(users.filter(user => user.id !== id))
  };

  const updateCocktail = (id, updatedCocktail) => {
    setEditing(false);
    setdidUpdate(true);
    setCocktails(
      cocktails.map((cocktail) =>
        cocktail.id === id ? updatedCocktail : cocktail
      )
    );
    setupdateId(id);
  };

  if (didUpdate === true) {
    cocktails.forEach((element) => {
      if (element.id == updateId) {
        apiFacade
          .putData(
            "https://pakkemodelapi.azurewebsites.net/api/EditMenus/" + updateId,
            element
          )
          .then((data) => {
          });
      }
    });

    setdidUpdate(false);
    setTimeout(function () {
      window.location.reload();
    }, 1000);
  }
  const editRow = (data) => {
    setEditing(true);
    setCurrentUser({
      id: data.id,
      customName: data.customName,
      beskrivelse: data.beskrivelse,
      price: data.price,
      glassType: data.glassName,
      bookingId: data.bookingId,
      glassLink: data.glassLink,
      glassId: data.glassId,
      cocktailOrNot: data.cocktailOrNot,
    });
  };

  const [modal, setModal] = useState(false);
  const [addmodal, setaddModal] = useState(false);
  const [deletemodal, setdeleteModal] = useState(false);

  const delToggle = (id) => {
    setdeleteId(id);
    setdeleteModal(!deletemodal);
  };
  const addtoggle = () => setaddModal(!addmodal);
  const toggle = () => setModal(!modal);

  if (props.isItCocktail) {
    return (
      <div>
        <div>
          <Row>
            <Col xs="auto" md={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle>Cocktails</CardTitle>
                </CardHeader>

                <CardBody>
                  <CocktailsTable
                    editRow={editRow}
                    data={props.data}
                    onToggle={toggle}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Update Cocktail</ModalHeader>
          <ModalBody>
            <Card className="card-chart">
              <CardBody>
                <Fragment>
                  <EditCocktailForm
                    editing={editing}
                    setEditing={setEditing}
                    add
                    currentCocktail={currentUser}
                    updateUser={updateCocktail}
                    onToggle={toggle}
                  />
                </Fragment>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <Row>
            <Col xs="auto" md={4}>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle>Add logo or change name</CardTitle>
                </CardHeader>

                <CardBody></CardBody>
              </Card>
            </Col>
            <Col xs="auto" md={8}>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle>Drikkevarer</CardTitle>
                </CardHeader>

                <CardBody>
                  <CocktailsTable
                    editRow={editRow}
                    data={props.data}
                    onToggle={toggle}
                    addRow={addDrink}
                    addToggle={addtoggle}
                    delToggle={delToggle}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {/* //edit modal */}
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Update Cocktail</ModalHeader>
          <ModalBody>
            <Card className="card-chart">
              <CardBody>
                <Fragment>
                  <EditCocktailForm
                    editing={editing}
                    setEditing={setEditing}
                    currentCocktail={currentUser}
                    updateUser={updateCocktail}
                    onToggle={toggle}
                  />
                </Fragment>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        {/* //add modal */}
        <Modal isOpen={addmodal} toggle={addtoggle}>
          <ModalHeader toggle={addtoggle}>Add Drink</ModalHeader>
          <ModalBody>
            <Card className="card-chart">
              <CardBody>
                <Fragment>
                  <AddDrinkForm
                    addDrink={addDrink}
                    addToggle={addtoggle}
                    bookingId={props.bookingId}
                    //deleteUser={deleteUser}
                  />
                </Fragment>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        {/* //delete modal */}
        <Modal
          isOpen={deletemodal}
          toggle={delToggle}
          style={{ marginTop: "220px", width: "330px" }}
        >
          <ModalBody>
            <Fragment>
              <h6>Are you sure to delete this item?</h6>
              <Row>
                <Col xs="7">
                  <Button onClick={deleteDrink} color="danger">
                    Delete
                  </Button>
                </Col>
                <Col xs="5">
                  <Button onClick={delToggle}>Cancel</Button>
                </Col>
              </Row>
            </Fragment>
          </ModalBody>
        </Modal>
      </div>
    );
  }
};
export default ViewTable;
