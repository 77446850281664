import types from "../actionTypes";

const initialState = {
  updateBookingByDate: "",
  updateBookingById: false,
  bookingId: "",
};

const vckBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.vckBooking.updateBookingById:
      return {
        ...state,
        updateBookingById: action.payload,
      };
    case types.vckBooking.updateBookingByDate:
      return {
        ...state,
        updateBookingByDate: action.payload,
      };
    case types.vckBooking.bookingId:
      return {
        ...state,
        bookingId: action.payload,
      };
    default:
      return state;
  }
};

export default vckBookingReducer;
