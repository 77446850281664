import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import LoginPage from "views/Pages/LoginPage";
import routes from "routes.js";
import Specs from "views/Specs/Specs.js";

function Auth(props) {
  const [filterColor, setFilterColor] = React.useState("yellow");
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const isSpecs = () => {
    if (window.location.pathname === "/specs") {
      return <Specs />;
    } else {
      return (
        <div className="wrapper wrapper-full-page">
          <div className="full-page section-image" filter-color={filterColor}>
            <LoginPage />
          </div>
        </div>
      );
    }
  };

  return <>{isSpecs()}</>;
}

export default Auth;

// Dette er gammel kode som lå return (); - Ikke sikker på om noget af det skal bruges??????
{
  /* <AuthNavbar {...props} /> */
}
//<div className="wrapper wrapper-full-page">
//<div className="full-page section-image" filter-color={filterColor}>
//{isSpecs()}
{
  /* <Switch>
  {routes.map((prop, key) => {
    if (prop.collapse) {
      return prop.views.map((prop2, key2) => {
        return (
          <Route
            path={prop2.path}
            component={prop2.component}
            key={key2}
          />
        );
      });
    }
    if (prop.redirect)
      return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
    return (
      <Route path={prop.path} component={prop.component} key={key} />
    );
  })}
</Switch> */
}
{
  /* <Footer fluid /> */
}
//</div>
//</div>
