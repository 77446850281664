import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import { useDispatch } from "react-redux";
import types from "../../../redux/actionTypes";
import apiFacade from "../../../auth/apiFacade";

function ResetValue() {
  document
    .getElementsByClassName(
      "MuiButtonBase-root MuiIconButton-root MuiAutocomplete-clearIndicator MuiAutocomplete-clearIndicatorDirty"
    )[0]
    .click();
}

async function addMenu(name) {
  let menus = { Name: name };
  let newMenu;
  // await apiFacade.postDataWorking("/Menus/", menus).then((data) => {
  //   newMenu = data;
  // });
  // await apiFacade.postToLocalHost("/Menus/", menus).then((data) => {
  //   newMenu = data;
  // });
  return newMenu;
}

async function deleteMenu(id) {
  let url = "https://pakkemodelapi.azurewebsites.net/api";
  let menus = { Id: id };
  // await apiFacade
  //   .putData(url + "/Menus/deletemenu/" + id, menus)
  //   .then((data) => {});
  // await apiFacade
  //   .putDataLocalHost("Menus/deletemenu/" + id, menus)
  //   .then((data) => {});
}

async function editMenuName(id, name) {
  let url = "https://pakkemodelapi.azurewebsites.net/api";
  let menus = { Id: id, Name: name };
  // await apiFacade.putData(url + "/Menus/" + id, menus).then((data) => {});
  // await apiFacade.putDataLocalHost("Menus/" + id, menus).then((data) => {});
}

function ComboBox(id, products, isLoading) {
  const dispatch = useDispatch();

  return (
    <Autocomplete
      disabled={isLoading}
      id="combo-box-demo"
      onChange={(event, newValue) => {
        if (newValue !== null) {
          dispatch({
            type: types.CocktailsMenu.addCocktail,
            payload: { menuId: id, productId: newValue.id },
          });
        } else {
          dispatch({
            type: types.CocktailsMenu.addCocktail,
            payload: null,
          });
        }
      }}
      PaperComponent={({ children }) => (
        <Paper style={{ background: "#dbdbdb" }}>{children}</Paper>
      )}
      options={products}
      getOptionLabel={(option) => option.name}
      style={{ width: "75%" }}
      renderInput={(params) => (
        <TextField {...params} label="Products" variant="outlined" />
      )}
    />
  );
}

export { ComboBox, ResetValue, editMenuName, deleteMenu, addMenu };
