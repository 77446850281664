import * as React from "react";
import {
  IntegratedSorting,
  SortingState,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";
import ContainerCell from "./ContainerCell";

export default function ContainerTable(props) {
  const tableColumnExtensions = [
    //company name
    { columnName: "id" },
    { columnName: "name" },
    { columnName: "size" },
    { columnName: "suffix" },
    { columnName: "actions", align: "center" },
  ];

  if (props.myData.length > 0) {
    return (
      <Grid rows={props.myData} columns={props.dataColumn}>
        <SortingState
        /*  defaultSorting={[
						{ columnName: "category_Name", direction: "desc" }
					  ]} */
        />
        <IntegratedSorting />
        <DragDropProvider />
        <SearchState />
        <IntegratedFiltering />
        <Table
          columnExtensions={tableColumnExtensions}
          cellComponent={ContainerCell}
        />
        {/* <TableColumnResizing defaultColumnWidths={defaultColumnWidths} /> */}
        <TableHeaderRow showSortingControls={true} />
        <Toolbar />
        <SearchPanel />
      </Grid>
    );
  } else {
    return <div>Loading</div>;
  }
}
