import { TableCell } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardTitle,
  CardBody,
  Alert,
  CardHeader,
} from "reactstrap";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";
import Info from "@material-ui/icons/InfoSharp";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import apiFacade from "../../../auth/apiFacade";
import { useSelector, useDispatch } from "react-redux";
import types from "../../../redux/actionTypes";
import JobPackerTable from "./JobPackerTable";
import JobPackerComponentCell from "./JobPackerComponentCell";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const CustomTableCellStyles = {
  style1: {
    //color: "#3787C6",
    fontSize: 12,
    align: "left",
    paddingLeft: 10,
  },
  style2: {
    //color: "#3787C6",
    fontSize: 12,
    align: "left",
    paddingRight: 0,
    height: 100,
  },
};

const JobPackerCell = (props) => {
  const dispatch = useDispatch();
  const { packerBool } = useSelector((state) => state.pakker);
  const { errorArr } = useSelector((state) => state.pakker);
  const { errorBool } = useSelector((state) => state.pakker);
  const { departments } = useSelector((state) => state.jobManagement);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openSaved, setOpenSaved] = useState(false);
  const [savedTitle, setsavedTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [firstName, setFirstName] = useState(props.row.firstName);
  const [lastName, setLastName] = useState(props.row.lastName);
  const [userName, setUserName] = useState(props.row.userName);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [apiData, setApiData] = useState();
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [errorMsg, setErrorMsg] = useState([]);
  const [errorTemp, setErrorTemp] = useState(errorArr);
  const [saveAlert, setSaveAlert] = useState(false);
  const [departmentId, setDepartmentId] = useState([]);

  const getRowId = (row) => {
    return row.managementId;
  };
  const [columns, setColumns] = useState([
    { name: "jobId", title: "Job id" },
    { name: "assignmentName", title: "Assignment" },
    { name: "packingDate", title: "Packing date" },
    { name: "assignmentStatus", title: "Status" },
  ]);
  const [headerExtension] = useState([
    { columnName: "jobId", width: "auto" },
    { columnName: "assignmentName", width: "auto" },
    { columnName: "packingDate", width: "auto" },
    { columnName: "assignmentStatus", width: "auto" },
  ]);
  const [tableColumnExtensions] = useState([
    { columnName: "jobId", width: "auto" },
    { columnName: "assignmentName", width: "auto" },
    { columnName: "packingDate", width: "auto" },
    { columnName: "assignmentStatus", width: "auto" },
  ]);

  const resetAll = () => {
    setFirstName(props.row.firstName);
    setLastName(props.row.lastName);
    setUserName(props.row.userName);
    setPassword("");
    setRepeatPassword("");
    setErrorMsg([]);
  };

  const deleteToggle = () => setDeleteModal(!deleteModal);
  const toggle = () => setModal(!modal);
  const editToggle = () => {
    setEditModal(!editModal);
    resetAll();
  };

  useEffect(() => {
    let lastN = true;
    if (lastName.length > 0 && lastName !== props.row.lastName) {
      lastN = false;
    }
    let firstN = true;
    if (firstName.length > 0 && firstName !== props.row.firstName) {
      firstN = false;
    }
    setTimeout(() => {
      if (errorArr.length > 1) {
        setDisableSaveBtn(true);
      } else if (errorArr.length === 1) {
        if (
          !lastN ||
          !firstN ||
          userName !== props.row.userName ||
          (password.length >= 6 && password.length >= 0)
        ) {
          setDisableSaveBtn(false);
        } else {
          setDisableSaveBtn(true);
        }
      }
      setErrorTemp(errorArr);
    }, 500);
  }, [errorBool]);

  const colWithtwo = (value1, value2, value3, name1, name2, name3) => {
    return (
      <Row>
        <Col xs={12} md={4}>
          {" "}
          <TextField
            style={{ fontWeight: "bold" }}
            id="standard-name"
            disabled
            label={name1}
            value={value1}
          />
        </Col>
        <Col xs={12} md={4}>
          <TextField
            style={{ fontWeight: "bold" }}
            id="standard-fut"
            disabled
            label={name2}
            value={value2}
          />
        </Col>
        <Col xs={12} md={4}>
          <TextField
            style={{ fontWeight: "bold" }}
            id="standard-prev"
            disabled
            label={name3}
            value={value3}
          />
        </Col>
      </Row>
    );
  };

  const menuItems = () => {
    if (departments.length > 0) {
      let mappedDep = departments.map((data, i) => {
        return (
          <MenuItem key={i} value={data.id}>
            {data.department_}
          </MenuItem>
        );
      });
      return mappedDep;
    } else {
      return (
        <div></div>
      );
    }
  };

  const editPacker = () => {
    return (
      <div>
        <Row>
          <Col xs={12} md={4}>
            {" "}
            <TextField
              style={{ fontWeight: "bold" }}
              id="standard-firstName"
              label={"First name"}
              type={"text"}
              value={firstName}
              onChange={changeFirstName}
            />
          </Col>
          <Col xs={12} md={4}>
            <TextField
              style={{ fontWeight: "bold" }}
              id="standard-lastName"
              label={"Last name"}
              type={"text"}
              value={lastName}
              onChange={changeLastName}
            />
          </Col>
          <Col xs={12} md={4}>
            <TextField
              style={{ fontWeight: "bold" }}
              id="standard-userName"
              label={"Username"}
              value={userName}
              onChange={changeUserName}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <TextField
              style={{ fontWeight: "bold" }}
              id="standard-password"
              label={"Password"}
              type={"password"}
              value={password}
              onChange={changePassword}
            />
          </Col>
          <Col xs={12} md={4}>
            <TextField
              style={{ fontWeight: "bold" }}
              id="standard-repeatpassword"
              label={"Repeat password"}
              type={"password"}
              value={repeatPassword}
              onChange={changeRepeatPassword}
            />
          </Col>
          <Col xs={12} md={4}>
            <FormControl style={{ width: "72%" }}>
              <InputLabel id="department-label">Departments</InputLabel>
              <Select
                labelId="select-label"
                id="department-select"
                value={departmentId}
                onChange={(e) => {
                  setDepartmentId(e.target.value);
                }}
              >
                {menuItems()}
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>{alertErrMsg()}</Col>
        </Row>
      </div>
    );
  };

  const alertErrMsg = () => {
    if (errorTemp.length > 0) {
      let mappedMsg = errorTemp.map((data, i) => {
        if (i > 0) {
          return (
            <Alert
              key={i}
              color="light"
              style={{ marginTop: "20px", color: "red" }}
            >
              {data.msg}
            </Alert>
          );
        }
      });
      return mappedMsg;
    }
  };

  const changeFirstName = (event) => {
    const result = event.target.value.replace(/[^a-å]/gi, "");
    setFirstName(result);
    setTimeout(() => {
      dispatch({
        type: types.pakker.errorBool,
        payload: !errorBool,
      });
    }, 500);
  };
  const changeLastName = (event) => {
    const result = event.target.value.replace(/[^a-å]/gi, "");
    setLastName(result);
    setTimeout(() => {
      dispatch({
        type: types.pakker.errorBool,
        payload: !errorBool,
      });
    }, 500);
  };
  const changeUserName = (event) => {
    const result = event.target.value.replace(/[^a-å]/gi, "");
    setUserName(result);
    if (document.getElementById("standard-userName").value.length >= 5) {
      errorHandlingRemove(2);
      apiFacade
        .getData(
          "/Pakkers/" +
            document.getElementById("standard-userName").value +
            "/" +
            props.row.id
        )
        .then((data) => {
          if (!data) {
            errorHandlingRemove(3);
          } else {
            errorHandlingAdd(3, "The username is already in use.");
            errorHandlingRemove(2);
          }
        });
    } else {
      errorHandlingRemove(3);
      errorHandlingAdd(
        2,
        "The username needs to be at least 5 characters long."
      );
    }
  };
  const changePassword = (event) => {
    checkPasswords();
    setPassword(event.target.value);
  };
  const changeRepeatPassword = (event) => {
    checkPasswords();
    setRepeatPassword(event.target.value);
  };
  const checkPasswords = () => {
    if (
      document.getElementById("standard-repeatpassword").value.length !== 0 ||
      document.getElementById("standard-password").value.length !== 0
    ) {
      if (
        document.getElementById("standard-repeatpassword").value ===
          document.getElementById("standard-password").value &&
        document.getElementById("standard-password").value.length >= 6
      ) {
        errorHandlingRemove(1);
      } else {
        errorHandlingAdd(
          1,
          "The password needs to be at least 6 characters long & needs to match each other"
        );
      }
    } else {
      errorHandlingRemove(1);
    }
  };

  const errorHandlingRemove = (errorId) => {
    dispatch({
      type: types.pakker.errorArrRemove,
      payload: errorId,
    });
    setTimeout(() => {
      dispatch({
        type: types.pakker.errorBool,
        payload: !errorBool,
      });
    }, 500);
  };

  const errorHandlingAdd = (errorId, errMsg) => {
    let pl = { id: errorId, msg: errMsg };
    dispatch({
      type: types.pakker.errorArr,
      payload: pl,
    });
    setTimeout(() => {
      dispatch({
        type: types.pakker.errorBool,
        payload: !errorBool,
      });
    }, 500);
  };

  const deleteCom = () => {
    setsavedTitle("  Deactivated");
    apiFacade
      .putData(
        "https://pakkemodelapi.azurewebsites.net/api/JobManagement/deactivatepacker/" +
          props.row.id
      )
      .then(() => {
        dispatch({
          type: types.pakker.packerBool,
          payload: !packerBool,
        });
      });
    setOpenSaved(true);

    window.setTimeout(() => {
      //window.location.reload();
      deleteToggle();
    }, 1000);
  };

  const saveChanges = () => {
    setsavedTitle("  Saved");
    let pakker = {
      Id: props.row.id,
      FirstName: firstName,
      LastName: lastName,
      UserName: userName,
      Status: true,
      Password: password,
      DepartmentId: departmentId,
    };
    apiFacade
      .putData(
        "https://pakkemodelapi.azurewebsites.net/api/pakkers/putjobmanagementpakker",
        pakker
      )
      .then((data) => {
        setSaveAlert(true);
        setDisableSaveBtn(true);
        setTimeout(() => {
          editToggle();
          setSaveAlert(false);
        }, 3000);
        setTimeout(() => {
          dispatch({
            type: types.pakker.packerBool,
            payload: !packerBool,
          });
        }, 4000);
      });
  };

  const saveAlertMSG = () => {
    if (saveAlert) {
      return <Alert color="success">The Data has been saved</Alert>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const getApiData = () => {
    apiFacade
      .getData("/jobmanagement/getpakkerjobs/" + props.row.id)
      .then((data) => {
        setApiData(data);
      });
  };

  const ifJobPackerTable = (a) => {
    if (a === 1) {
      if (apiData.futureJobManagements.length > 0) {
        return (
          <JobPackerTable
            rows={apiData.futureJobManagements}
            columns={columns}
            headerExtension={headerExtension}
            tableColumnExtensions={tableColumnExtensions}
            JobPackerCell={JobPackerComponentCell}
            getRowId={getRowId}
          />
        );
      } else {
        return <div>No jobs assigned</div>;
      }
    } else if (a === 2) {
      if (apiData.prevJobManagements.length > 0) {
        return (
          <JobPackerTable
            rows={apiData.prevJobManagements}
            columns={columns}
            headerExtension={headerExtension}
            tableColumnExtensions={tableColumnExtensions}
            JobPackerCell={JobPackerComponentCell}
            getRowId={getRowId}
          />
        );
      } else {
        return <div>No completed jobs</div>;
      }
    }
  };

  const Job = () => {
    if (apiData !== undefined) {
      return (
        <Row>
          <Col xs={6}>
            <Card>
              <CardHeader>Jobs</CardHeader>
              <CardBody>{ifJobPackerTable(1)}</CardBody>
            </Card>
          </Col>
          <Col xs={6}>
            <Card>
              <CardHeader>Completed Jobs</CardHeader>
              <CardBody>{ifJobPackerTable(2)}</CardBody>
            </Card>
          </Col>
        </Row>
      );
    }
  };

  const resetNames = () => {
    setFirstName(props.row.firstName);
    setLastName(props.row.lastName);
  };

  if (props.column.name === "firstName") {
    return (
      <TableCell style={{ height: 100 }}>
        <Row>
          <Col xs={12} style={{ fontWeight: "bold" }}>
            {props.row.firstName + " " + props.row.lastName}
          </Col>
          <Col xs={12}>
            Jobs:{" "}
            <span style={{ color: "green", fontWeight: "bold" }}>
              {props.row.amountOfAssignedJobs}
            </span>
          </Col>
          <Col xs={12}>
            Completed jobs:{" "}
            <span style={{ color: "red", fontWeight: "bold" }}>
              {props.row.amountOfPrevJobs}
            </span>
          </Col>
        </Row>
      </TableCell>
    );
  }
  if (props.column.name === "lastName") {
    return <TableCell></TableCell>;
  }
  if (props.column.name == "actions") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        <IconButton
          color="primary"
          aria-label="Edit"
          onClick={() => {
            editToggle();
            resetNames();
          }}
        >
          <EditTwoToneIcon />
        </IconButton>
        <Modal
          isOpen={editModal}
          size="lg"
          style={{ maxWidth: "1200px", width: "60%" }}
        >
          <Alert
            isOpen={openSaved}
            style={{
              marginTop: "10px",
              textAlign: "center",
              fontSize: "20px",
              width: "250px",
              marginLeft: "40%",
            }}
          >
            <NotificationsActiveIcon />
            {savedTitle}
          </Alert>
          <ModalHeader toggle={editToggle}>
            Edit {props.row.firstName + " " + props.row.lastName}
            {saveAlertMSG()}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <CardTitle>Edit Packer Details</CardTitle>
                    {editPacker()}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              disabled={disableSaveBtn}
              onClick={() => {
                saveChanges();
              }}
            >
              Save
            </Button>{" "}
            <Button
              color="danger"
              onClick={() => {
                resetAll();
                editToggle();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <IconButton
          color="primary"
          aria-label="View more"
          onClick={() => {
            getApiData();
            toggle();
          }}
        >
          <Info />
        </IconButton>
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="lg"
          style={{ maxWidth: "1200px", width: "60%" }}
        >
          <ModalHeader toggle={toggle}>
            Viewing {props.row.firstName + " " + props.row.lastName}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <CardTitle>Packer Details</CardTitle>
                    {colWithtwo(
                      props.row.firstName + " " + props.row.lastName,
                      props.row.amountOfAssignedJobs,
                      props.row.amountOfPrevJobs,
                      "Full Name",
                      "Jobs",
                      "Completed jobs"
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {Job()}
          </ModalBody>
          <ModalFooter>
            {" "}
            <Button
              color="danger"
              onClick={toggle}
              style={{ marginLeft: "90%" }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <IconButton
          color="secondary"
          aria-label="Delete"
          onClick={deleteToggle}
          disabled={props.row.boolOfAssignedJobs}
        >
          <DeleteForeverTwoToneIcon />
        </IconButton>
        <Modal isOpen={deleteModal} toggle={deleteToggle}>
          <Alert
            color="danger"
            isOpen={openSaved}
            style={{
              marginTop: "10px",
              textAlign: "center",
              fontSize: "20px",
              width: "240px",
              marginLeft: "23%",
            }}
          >
            <NotificationsActiveIcon />
            {savedTitle}
          </Alert>
          <ModalHeader toggle={deleteToggle}>
            Deactivating {props.row.firstName + " " + props.row.lastName}
          </ModalHeader>
          <ModalBody>
            {" "}
            Are you sure that you want to deactivate the selected person:
            <div style={{ fontWeight: "bold" }}>
              {props.row.firstName + " " + props.row.lastName}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                deleteCom();
              }}
            >
              Deactivate
            </Button>{" "}
            <Button color="warning" onClick={deleteToggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </TableCell>
    );
  }
  return <TableCell>No data</TableCell>;
};
export default JobPackerCell;
