import React, { useState, useEffect } from "react";
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import apiFacade from "../../auth/apiFacade";
import { PacmanLoader } from "react-spinners";
import CocktailMenuTable from "./CocktailMenuTable/CocktailMenuTable";
import CocktailMenuCell from "./CocktailMenuTable/CocktailMenuCell";
import CocktailsCell from "./CocktailMenuTable/CocktailsCell";
import * as MenuHelper from "./CocktailMenuTable/CocktailMenuHelperFunc";
import CocktailMenuHeaderCell from "./CocktailMenuTable/CocktailMenuHeaderCell";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import types from "../../redux/actionTypes";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";

var Style = {
  stuck: {
    position: "fixed",
    marginTop: "-4%",
    marginLeft: "40%",
    zIndex: "1000",
  },
  stuckLoading: {
    position: "fixed",
    marginTop: "20%",
    marginLeft: "15%",
    zIndex: "1000",
  },
};

export default function CocktailMenu() {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.CocktailsMenu);
  const { cocktails } = useSelector((state) => state.CocktailsMenu);
  const { cocktailmenus } = useSelector((state) => state.CocktailsMenu);
  const { selectedmenu } = useSelector((state) => state.CocktailsMenu);
  const { updateCocktails } = useSelector((state) => state.CocktailsMenu);
  const { updateMenu } = useSelector((state) => state.CocktailsMenu);
  const { addCocktail } = useSelector((state) => state.CocktailsMenu);
  const { products } = useSelector((state) => state.CocktailsMenu);
  const { openModal } = useSelector((state) => state.CocktailsMenu);
  const { openModalDelete } = useSelector((state) => state.CocktailsMenu);
  const { openModalAdd } = useSelector((state) => state.CocktailsMenu);
  const { getName } = useSelector((state) => state.CocktailsMenu);
  const { newOrUpdated } = useSelector((state) => state.CocktailsMenu);
  const { isLoading } = useSelector((state) => state.CocktailsMenu);
  const [sortIt, setSortIt] = useState("status");
  const [cocktailsLoaded, setCocktailsLoaded] = useState(true);
  const [loading, setloading] = useState(true);
  const [saveDisable, setSaveDisable] = useState(true);
  const [columnsLeft, setColumnsLeft] = useState([
    // { name: "status", title: "Status" },
    { name: "name", title: "Name" },
    { name: "actions", title: "Actions" },
  ]);
  const [columnsRight, setColumnsRight] = useState([
    { name: "name", title: "Name" },
    { name: "action", title: "Action" },
  ]);
  const [columnExtension, setColumnExtension] = useState([
    // { columnName: "status" },
    { columnName: "name" },
    { columnName: "action" },
  ]);

  useEffect(() => {
    let sortedMenus = [];
    apiFacade.getData("/Menus/getactivemenus").then((data) => {
      if (id === "0") {
        dispatch({
          type: types.CocktailsMenu.id,
          payload: data[0].id,
        });
        sortedMenus.push({
          id: data[0].id,
          name: data[0].name,
          hoverbutton: true,
        });
        data.forEach((element) => {
          const index = sortedMenus.map((e) => e.id).indexOf(element.id);
          if (index === -1) {
            sortedMenus.push({
              id: element.id,
              name: element.name,
              hoverbutton: false,
            });
          }
        });
        dispatch({
          type: types.CocktailsMenu.selectedmenu,
          payload: sortedMenus,
        });
      }
      // newOrUpdated if(false) = edit name.
      if (!newOrUpdated) {
        if (id !== "0" && selectedmenu.length > 0) {
          data.forEach((element) => {
            const index = selectedmenu.map((e) => e.id).indexOf(element.id);
            if (index !== -1) {
              if (selectedmenu[index].hoverbutton) {
                sortedMenus.push({
                  id: element.id,
                  name: element.name,
                  hoverbutton: true,
                });
              } else {
                sortedMenus.push({
                  id: element.id,
                  name: element.name,
                  hoverbutton: false,
                });
              }
            } else {
              sortedMenus.push({
                id: element.id,
                name: element.name,
                hoverbutton: false,
              });
            }
          });
          dispatch({
            type: types.CocktailsMenu.selectedmenu,
            payload: sortedMenus,
          });
        }
        // newOrUpdated if(true) = det er en ny menu.
      } else {
        if (id !== "0" && selectedmenu.length > 0) {
          data.forEach((element) => {
            const index = selectedmenu.map((e) => e.id).indexOf(element.id);
            if (index !== -1) {
              if (selectedmenu[index].hoverbutton) {
                sortedMenus.push({
                  id: element.id,
                  name: element.name,
                  hoverbutton: false,
                });
              } else {
                sortedMenus.push({
                  id: element.id,
                  name: element.name,
                  hoverbutton: false,
                });
              }
            } else {
              sortedMenus.push({
                id: element.id,
                name: element.name,
                hoverbutton: true,
              });
            }
          });
          dispatch({
            type: types.CocktailsMenu.newOrUpdated,
            payload: false,
          });
          dispatch({
            type: types.CocktailsMenu.selectedmenu,
            payload: sortedMenus,
          });
        }
      }
      dispatch({
        type: types.CocktailsMenu.cocktailmenus,
        payload: data,
      });
    });
  }, [updateMenu]);

  useEffect(() => {
    let mounted = true;
    apiFacade.getData("/MenuCocktails/products/" + id).then((data) => {
      dispatch({
        type: types.CocktailsMenu.products,
        payload: data,
      });
      if (mounted) {
        setloading(false);
      }
    });
    return () => {
      mounted = false;
    };
  }, [id, updateCocktails]);

  useEffect(() => {
    let mounted = true;
    apiFacade.getData("/MenuCocktails/" + id).then((cocktailsData) => {
      dispatch({
        type: types.CocktailsMenu.cocktails,
        payload: cocktailsData,
      });
      setCocktailsLoaded(false);
      if (mounted) {
        setloading(false);
      }
    });
    return () => {
      mounted = false;
    };
  }, [id, updateCocktails]);

  const disableAddProducts = () => {
    if (addCocktail !== null) {
      return (
        <form onSubmit={addProducts}>
          <Button color="success" type="submit">
            Add product
          </Button>{" "}
        </form>
      );
    } else {
      return (
        <form onSubmit={addProducts}>
          <Button disabled color="success" type="submit">
            Add product
          </Button>{" "}
        </form>
      );
    }
  };

  const cocktailTitle = () => {
    const index = selectedmenu.map((e) => e.hoverbutton).indexOf(true);
    return selectedmenu[index].name;
  };

  const addProducts = (event) => {
    event.preventDefault();
    let cocktailIndex = products
      .map((e) => e.id)
      .indexOf(addCocktail.productId);
    products.splice(cocktailIndex, 1);
    MenuHelper.ResetValue();
    apiFacade.postDataWorking("/MenuCocktails/", addCocktail).then((data) => {
      dispatch({
        type: types.CocktailsMenu.updateCocktails,
        payload: !updateCocktails,
      });
    });
  };

  const cocktailTable = () => {
    if (cocktailsLoaded === false) {
      return (
        <CocktailMenuTable
          rows={cocktails}
          columns={columnsRight}
          sortIt={sortIt}
          cell={CocktailsCell}
        />
      );
    }
  };

  const toggle = () => {
    dispatch({
      type: types.CocktailsMenu.openModal,
      payload: false,
    });
  };
  const toggleDelete = () => {
    dispatch({
      type: types.CocktailsMenu.openModalDelete,
      payload: false,
    });
  };
  const toggleAdd = () => {
    dispatch({
      type: types.CocktailsMenu.openModalAdd,
      payload: false,
    });
  };
  const openAddMenu = () => {
    dispatch({
      type: types.CocktailsMenu.openModalAdd,
      payload: true,
    });
  };

  const alert = () => {
    if (cocktails.length <= 1) {
      return (
        <Alert style={{ height: 50 }} color="warning">
          <span id="alertcocktails" style={{ color: "black" }}>
            A Cocktail Menu needs to have atleast one Cocktail in it!
          </span>
        </Alert>
      );
    }
  };

  const newCocktailMenuName = (event) => {
    if (event.target.value.length > 0) {
      setSaveDisable(false);
    } else {
      setSaveDisable(true);
    }
    dispatch({
      type: types.CocktailsMenu.getName,
      payload: event.target.value,
    });
  };

  const awaitDispatch = async () => {
    dispatch({
      type: types.CocktailsMenu.updateMenu,
      payload: !updateMenu,
    });
  };

  const resetId = async (id) => {
    dispatch({
      type: types.CocktailsMenu.id,
      payload: id,
    });
  };

  const newOrUpdated_ = async (bool) => {
    dispatch({
      type: types.CocktailsMenu.newOrUpdated,
      payload: bool,
    });
  };

  const isItLoading = async (bool) => {
    dispatch({
      type: types.CocktailsMenu.isLoading,
      payload: bool,
    });
  };

  const isMenuLoading = () => {
    if (isLoading) {
      return (
        <Box style={Style.stuckLoading} sx={{ display: "flex" }}>
          <Row>
            <Col xs="12">
              <h5 style={{ fontWeight: "bold" }}>Updating Podio</h5>
            </Col>
            <Col xs="12">
              <PacmanLoader sizeUnit={"px"} size={50} color={"#d16557"} />
            </Col>
          </Row>
        </Box>
      );
    }
  };

  const updateEditName = async (id, getName) => {
    await MenuHelper.editMenuName(id, getName);
    await newOrUpdated_(false);
    await awaitDispatch();
    await isItLoading(false);
  };

  const deleteMenu = async (id) => {
    await MenuHelper.deleteMenu(id);
    await resetId("0");
    await awaitDispatch();
    await isItLoading(false);
  };

  const addMenu = async (getName) => {
    let newMenu = await MenuHelper.addMenu(getName);
    await newOrUpdated_(true);
    await resetId(newMenu.id);
    await awaitDispatch();
    await isItLoading(false);
  };

  if (cocktailmenus.length > 0) {
    return (
      <div>
        <PanelHeader />
        <div className="content">
          <Row>
            <Col xs={12} md={6}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs={12} md={6}>
                      <CardTitle tag="h4">Cocktail Menus</CardTitle>
                    </Col>
                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={12} md={6}>{isMenuLoading(true)}</Col>
                        <Col xs={12} md={6}>
                          <Fab
                            disabled={isLoading}
                            style={{
                              backgroundColor: "#d16557",
                              color: "#FFFFFF",
                            }}
                            aria-label="add"
                            onClick={() => {
                              openAddMenu();
                            }}
                          >
                            <AddIcon style={{ fontSize: 40 }} />
                          </Fab>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <CocktailMenuTable
                        rows={cocktailmenus}
                        columns={columnsLeft}
                        sortIt={sortIt}
                        cell={CocktailMenuCell}
                        columnExtension={columnExtension}
                        headerCell={CocktailMenuHeaderCell}
                      />
                    </Col>
                  </Row>
                  {/* AddModal */}
                  <Modal isOpen={openModalAdd} toggle={toggleAdd}>
                    <ModalHeader toggle={toggleAdd}>Add new menu</ModalHeader>
                    <ModalBody>
                      <form>
                        <TextField
                          style={{ width: "100%" }}
                          onChange={newCocktailMenuName}
                          id="outlined-basic"
                          label="Enter name"
                          variant="outlined"                     
                        />
                      </form>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        disabled={saveDisable}
                        style={{ width: "35%" }}
                        color="success"
                        onClick={() => {
                          toggleAdd();
                          addMenu(getName);
                          isItLoading(true);
                        }}
                      >
                        Save
                      </Button>{" "}
                      <Button
                        style={{ width: "35%" }}
                        color="danger"
                        onClick={toggleAdd}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                  {/* EditModal */}
                  <Modal isOpen={openModal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>
                      Editing name of a cocktail menu
                    </ModalHeader>
                    <ModalBody>
                      <form>
                        <TextField
                          style={{ width: "100%" }}
                          onChange={newCocktailMenuName}
                          id="outlined-basic"
                          label={cocktailTitle()}
                          variant="outlined"
                        />
                      </form>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        disabled={saveDisable}
                        style={{ width: "35%" }}
                        color="success"
                        onClick={() => {
                          toggle();
                          updateEditName(id, getName);
                          isItLoading(true);
                        }}
                      >
                        Save
                      </Button>{" "}
                      <Button
                        style={{ width: "35%" }}
                        color="danger"
                        onClick={toggle}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                  {/* deleteModal */}
                  <Modal isOpen={openModalDelete} toggle={toggleDelete}>
                    <ModalHeader toggle={toggleDelete}>Delete</ModalHeader>
                    <ModalBody>
                      Are you sure you want to delete {cocktailTitle()}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        style={{ width: "35%" }}
                        color="danger"
                        onClick={() => {
                          toggleDelete();
                          deleteMenu(id);
                          isItLoading(true);
                        }}
                      >
                        Delete
                      </Button>{" "}
                      <Button
                        style={{ width: "35%" }}
                        color="warning"
                        onClick={toggleDelete}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <CardTitle tag="h4">{cocktailTitle()}</CardTitle>
                    </Col>
                    <Col xs="6">{alert()}</Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="12">{cocktailTable()}</Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col xs={12} md={8}>
                      {MenuHelper.ComboBox(id, products, isLoading)}
                    </Col>
                    <Col xs={12} md={4}>
                      {disableAddProducts()}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <PanelHeader />
        <div className="content">
          <div style={{ marginTop: 300, marginBottom: 400 }}>
            <div className="sweet-loading" style={{ marginLeft: 600 }}>
              <h5>Loading All Cocktail Menus</h5>
              <PacmanLoader sizeUnit={"px"} size={50} color={"#d16557"} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
