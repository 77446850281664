import React from "react";
import {
  IntegratedSorting,
  SortingState,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";
import GlassesCell from "./GlassesCell";

export default function GlassesTable(props) {
  const tableColumnExtensions = [
    { columnName: "id" },
    { columnName: "name" },
    { columnName: "size" },
    { columnName: "unit_id" },
    { columnName: "distributor_id" },
    { columnName: "category_id" },
    { columnName: "iconlink" },
    { columnName: "actions", align: "center" },
  ];
  // const [defaultColumnWidths, setDefaultColumnWidths] = useState([
  //   { columnName: "id", width: "auto" },
  //   { columnName: "name", width: "auto" },
  //   { columnName: "size", width: "auto" },
  //   { columnName: "unitName", width: "auto" },
  //   { columnName: "distributorName", width: "auto" },
  //   { columnName: "categoryName", width: "auto" },
  //   { columnName: "iconlink", width: 200 },
  //   { columnName: "actions", width: "100%" },
  // ]);

  if (props.myData.length > 0) {
    return (
      <Grid rows={props.myData} columns={props.dataColumn}>
        <SortingState
        /*  defaultSorting={[
						{ columnName: "category_Name", direction: "desc" }
					  ]} */
        />
        <IntegratedSorting />
        <DragDropProvider />
        <SearchState />
        <IntegratedFiltering />
        <Table
          columnExtensions={tableColumnExtensions}
          cellComponent={GlassesCell}
        />
        {/* <TableColumnResizing defaultColumnWidths={defaultColumnWidths} /> */}
        <TableHeaderRow showSortingControls={true} />
        <Toolbar />
        <SearchPanel />
      </Grid>
    );
  } else {
    return <div>Loading</div>;
  }
}