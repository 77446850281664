import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";

var Style = {
  stuck: {
    position: "fixed",
    marginTop: "3%",
    marginLeft: "8%",
    zIndex: "1000",
  },
  stuck2: {
    position: "fixed",
    marginTop: "0%",
    marginLeft: "0%",
    zIndex: "1000",
  },
  stuckLoading: {
    position: "fixed",
    marginTop: "20%",
    marginLeft: "15%",
    zIndex: "1000",
  },
};

function updateBookingMsg(
  updateBookingById,
  bookingId,
  updatebookingbydate,
  start,
  end,
  error,
  searchInput,
  errorStuck
) {
  if (updateBookingById) {
    return (
      <Alert style={Style.stuck} severity="success">
        <AlertTitle>Booking {bookingId} has been updated</AlertTitle>
      </Alert>
    );
  } else if (updatebookingbydate) {
    return (
      <Alert style={Style.stuck2} severity="success">
        <AlertTitle>
          All Bookings from {start} to {end} has been updated
        </AlertTitle>
      </Alert>
    );
  } else if (error === 2) {
    return (
      <Alert style={errorStuck} severity="error">
        <AlertTitle>Booking: {searchInput} doesn't exist</AlertTitle>
      </Alert>
    );
  } else {
    return <div></div>;
  }
}

export { updateBookingMsg };
