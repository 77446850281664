import React, { useState, useEffect } from "react";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import { AppBar, Typography } from "@material-ui/core/";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import BarArrangement from "./CalculationsFunc/BarArrangement";
import apiFacade from "../../auth/apiFacade";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import types from "../../redux/actionTypes";
import CombindJobtypePackage from "./CalculationsFunc/CombindJobtypePackage";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function Calculations() {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [calculationsTabValue, setCalculationsTabValue] = useState(0);
  const [samplingTabvalue, setSamplingTabvalue] = useState(0);

  let region = {
    regionId: 0,
    region: "",
    regionSelected: false,
    calculationType: 0,
  };

  useEffect(() => {
    apiFacade.getData("/Regions").then((data) => {
      dispatch({
        type: types.calculations.regions,
        payload: data,
      });
    });
  }, []);

  useEffect(() => {
    apiFacade.getData("/BookingTypes/getactivebookingtypeDtos").then((data) => {
      dispatch({
        type: types.calculations.jobtypes,
        payload: data,
      });
    });
  }, []);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeInnerTabCal = (event, newValue) => {
    setCalculationsTabValue(newValue);
  };
  const handleChangeInnerTabSamp = (event, newValue) => {
    setSamplingTabvalue(newValue);
  };
  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col>
            <Card className="card-chart">
              <CardHeader>
                <CardTitle style={{ marginBottom: "10px" }}>
                  Calculations
                </CardTitle>
                <Row>
                  <Col xs="12" /* md={{ size: 4, offset: 4 }} */>
                    <AppBar
                      position="static"
                      style={{
                        backgroundColor: "#d16557",
                        borderRadius: "5px",
                        //width: 350
                      }}
                    >
                      <Tabs
                        //variant="fullWidth"
                        value={value}
                        onChange={handleChangeTab}
                        scrollButtons="auto"
                        variant="scrollable"
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#ffffff",
                          },
                        }}
                      >
                        <Tab label="Cocktail Catering" {...a11yProps(0)} />
                        <Tab label="Cocktail Kursus" {...a11yProps(1)} />
                        <Tab label="En til En plus procent" {...a11yProps(2)} />
                        <Tab
                          style={{ minWidth: "25%" }}
                          label="Bar arrangement plus cocktail kursus"
                          {...a11yProps(3)}
                        />
                        <Tab
                          style={{ minWidth: "20%" }}
                          label="Combind jobtype with package"
                          {...a11yProps(4)}
                        />
                      </Tabs>
                    </AppBar>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TabPanel value={value} index={0}>
                  <BarArrangement />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Row>
                    <Col xs="6">
                      <Card>
                        <CardHeader>
                          <h6>Sjælland, Fyn & Udlandet</h6>
                        </CardHeader>
                        <CardBody></CardBody>
                      </Card>
                    </Col>

                    <Col xs="6">
                      <Card>
                        <CardHeader>
                          <h6>Jylland</h6>
                        </CardHeader>
                        <CardBody></CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Row>
                    <Col xs="6">
                      <Card>
                        <CardHeader>
                          <h6>Sjælland, Fyn & Udlandet</h6>
                        </CardHeader>
                        <CardBody></CardBody>
                      </Card>
                    </Col>

                    <Col xs="6">
                      <Card>
                        <CardHeader>
                          <h6>Jylland</h6>
                        </CardHeader>
                        <CardBody></CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Row>
                    <Col xs="6">
                      <Card>
                        <CardHeader>
                          <h6>Sjælland, Fyn & Udlandet</h6>
                        </CardHeader>
                        <CardBody></CardBody>
                      </Card>
                    </Col>

                    <Col xs="6">
                      <Card>
                        <CardHeader>
                          <h6>Jylland</h6>
                        </CardHeader>
                        <CardBody></CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <CombindJobtypePackage />
                </TabPanel>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <div p={3}>{children}</div>
    </Typography>
  );
}
