import React, { Component } from "react";
import PanelHeader from "../../components/PanelHeader/PanelHeader.js";
import Stats from "../../components/Stats/Stats.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import Moment from "react-moment";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import apiFacade from "../../auth/apiFacade";
import RefreshSharpIcon from "@material-ui/icons/RefreshSharp";
import AutorenewOutlinedIcon from "@material-ui/icons/AutorenewTwoTone";
import IconButton from "@material-ui/core/IconButton";
import { faHome, faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Print from "@material-ui/icons/Print";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
import "../../assets/css/checkboks.css";
import SamplingsTable from "./SamplingsTable/SamplingsTable";
import RangePicker from "react-range-picker";

var bookingStyle = {
  cellStyling: {
    padding: "0px 10px 0px 0px",
  },
  dropdownitemstyle: {
    background: "red",
  },
  textColor: {
    color: "#d16557",
  },
  cardchartMyFix: {
    position: "absolute",
    right: "220px",
    top: "10%",
  },
  cardchartMyFixEdit: {
    position: "absolute",
    right: "360px",
    top: "10px",
  },
  cardchartMyFixBack: {
    position: "fixed",
    bottom: "55px",
    right: "70px",
  },
  radioWhite: {
    border: "10px solid #90DDD0",
  },
  radioPink: {
    border: "10px solid #EF959D",
  },
  radioRed: {
    border: "10px solid #90DDD0",
  },
  stuck: {
    position: "fixed",
    bottom: "50px",
    backgroundColor: "#d16557",
  },
  dropdownmenustyle: {
    color: "#d16557",
    width: "100%",
    zIndex: "1001",
    right: "end",
  },
};

export class Samplings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onN: false,
      printing: false,
      sjælland: false,
      jylland: false,
      fyn: false,
      listOfId: [],
      selectedOption: null,
      samplings: [],
      menus: [],
      mytable: [],
      isLoading: true,
      start: Date().getDay,
      end: this.enddate(7),
      newdate: 7,
      pageSizes: [5, 10, 15],
      modalDemo: false,
      datePickerHandler: false,
      defaultExpandedGroups: ["Jylland", "Fyn", "Sjælland", "Udland"],
      newStartDate: this.startDate(0),
      newEndDate: this.enddate(7),
    };
  }

  componentDidMount() {
    let myArr = new Array();
    //f.eks på date --> api/samplings?start=2019-03-20&end=2019-03-23
    apiFacade
      .getData("/samplings")
      .then((data) => {
        // this.setState({ samplings: data });
        data.map((data) => {
          if (data.samplingDates.length <= 1) {
            myArr.push({
              Id: data.item_Id,
              sampling_Id: data.sampling_Id,
              outlet: data.outlet,
              //date: data.date,
              kampagne: data.kampagne,
              //typePopup: data.typePopup,
              bartenders: data.antalBartendere,
              samples: data.samples,
              region: data.region,
              date1Start: data.samplingDates[0].start,
              date1End: data.samplingDates[0].end,
              //isLoading: false,
            });
          }
          if (data.samplingDates.length > 1 && data.samplingDates.length <= 2) {
            myArr.push({
              Id: data.item_Id,
              sampling_Id: data.sampling_Id,
              outlet: data.outlet,
              //date: data.date,
              kampagne: data.kampagne,
              //typePopup: data.typePopup,
              bartenders: data.antalBartendere,
              samples: data.samples,
              region: data.region,
              date1Start: data.samplingDates[0].start,
              date1End: data.samplingDates[0].end,
              date2Start: data.samplingDates[1].start,
              date2End: data.samplingDates[1].end,
              //isLoading: false,
            });
          }
          if (data.samplingDates.length > 2 && data.samplingDates.length <= 3) {
            myArr.push({
              Id: data.item_Id,
              sampling_Id: data.sampling_Id,
              outlet: data.outlet,
              //date: data.date,
              kampagne: data.kampagne,
              //typePopup: data.typePopup,
              bartenders: data.antalBartendere,
              samples: data.samples,
              region: data.region,
              date1Start: data.samplingDates[0].start,
              date1End: data.samplingDates[0].end,
              date2Start: data.samplingDates[1].start,
              date2End: data.samplingDates[1].end,
              date3Start: data.samplingDates[2].start,
              date3End: data.samplingDates[2].end,

              //isLoading: false,
            });
          }
          if (data.samplingDates.length > 3) {
            myArr.push({
              Id: data.item_Id,
              sampling_Id: data.sampling_Id,
              outlet: data.outlet,
              //date: data.date,
              kampagne: data.kampagne,
              //typePopup: data.typePopup,
              bartenders: data.antalBartendere,
              samples: data.samples,
              region: data.region,
              date1Start: data.samplingDates[0].start,
              date1End: data.samplingDates[0].end,
              date2Start: data.samplingDates[1].start,
              date2End: data.samplingDates[1].end,
              date3Start: data.samplingDates[2].start,
              date3End: data.samplingDates[2].end,
              date4Start: data.samplingDates[3].start,
              date4End: data.samplingDates[3].end,
              //isLoading: false,
            });
          }
        });
        return myArr;
      })
      .then((myArr) => {
        this.setState({ mytable: myArr });
      });

    if (!this.state.mytable[1] === "undefined") {
    }
  }

  updateTable = (e) => {
    if (e.currentTarget.id === "30days") {
      // 30 days api call
      this.newBookingsbydate(this.startDate(0), 30);
      this.setState({ end: this.enddate(30) });
    }

    if (e.currentTarget.id === "7days") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(0), 7);
      this.state.end = this.enddate(7);
    }
    if (e.currentTarget.id === "tomorrow") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(-1), 1);
      this.state.start = this.startDate(-1);
      this.state.end = this.enddate(1);
    }
    if (e.currentTarget.id === "yesterday") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(1), -1);
      this.state.start = this.startDate(1);
      this.state.end = this.enddate(-1);
    }

    if (e.currentTarget.id === "today") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(0), 0);
      this.state.start = 0;
      this.state.start = this.startDate(0);
      this.state.end = 0;
      this.state.end = this.enddate(0);
    }
    if (e.currentTarget.id === "last7days") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(7), 0);
      this.state.start = this.startDate(7);
      this.state.end = 0;
      this.state.end = this.enddate(0);
    }
    if (e.currentTarget.id === "last30days") {
      // 7 days api call
      this.newBookingsbydate(this.startDate(30), 0);
      this.state.start = this.startDate(30);
      this.state.end = 0;
      this.state.end = this.enddate(0);
    }
  };
  startDate = (newday) => {
    var new_date = new Date();
    var day = String(new_date.getDate());
    var month = String(new_date.getMonth() + 1);
    var myNewdate = String(new_date.getFullYear() + "/" + month + "/" + day);
    var date = new Date(myNewdate);
    var next_date = new Date(date.setDate(date.getDate() - newday));
    var month_ = "" + (next_date.getMonth() + 1);
    var day_ = "" + next_date.getDate();
    var year_ = next_date.getFullYear();

    if (month_.length < 2) month_ = "0" + month_;
    if (day_.length < 2) day_ = "0" + day_;

    var myNewdate_ = [year_, month_, day_].join("/");
    return myNewdate_;
  };

  enddate = (newday) => {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var myNewdate = [year, month, day].join("-");

    var date = new Date(myNewdate);
    var next_date = new Date(date.setDate(date.getDate() + newday));
    var month_ = "" + (next_date.getMonth() + 1);
    var day_ = "" + next_date.getDate();
    var year_ = next_date.getFullYear();

    if (month_.length < 2) month_ = "0" + month_;
    if (day_.length < 2) day_ = "0" + day_;

    var myNewdate_ = [year_, month_, day_].join("/");
    return myNewdate_;
  };

  newBookingsbydate = (startdate, nday) => {
    var slutdate = this.enddate(nday);
    this.setState({ newStartDate: startdate, newEndDate: slutdate });

    //bookings?start=2019-03-30&end=2019-04-03
    this.state.mytable = [];
    this.state.menus = [];
    this.state.bookings = [];
    let myArr = new Array();
    apiFacade
      .getData("/samplings?start=" + startdate + "&end=" + this.enddate(nday))
      .then((data) => {
        data.map((data) => {
          if (data.samplingDates.length <= 1) {
            myArr.push({
              Id: data.item_Id,
              sampling_Id: data.sampling_Id,
              outlet: data.outlet,
              //date: data.date,
              kampagne: data.kampagne,
              //typePopup: data.typePopup,
              bartenders: data.antalBartendere,
              samples: data.samples,
              region: data.region,
              date1Start: data.samplingDates[0].start,
              date1End: data.samplingDates[0].end,
              //isLoading: false,
            });
          }
          if (data.samplingDates.length > 1 && data.samplingDates.length <= 2) {
            myArr.push({
              Id: data.item_Id,
              sampling_Id: data.sampling_Id,
              outlet: data.outlet,
              //date: data.date,
              kampagne: data.kampagne,
              //typePopup: data.typePopup,
              bartenders: data.antalBartendere,
              samples: data.samples,
              region: data.region,
              date1Start: data.samplingDates[0].start,
              date1End: data.samplingDates[0].end,
              date2Start: data.samplingDates[1].start,
              date2End: data.samplingDates[1].end,
              //isLoading: false,
            });
          }
          if (data.samplingDates.length > 2 && data.samplingDates.length <= 3) {
            myArr.push({
              Id: data.item_Id,
              sampling_Id: data.sampling_Id,
              outlet: data.outlet,
              //date: data.date,
              kampagne: data.kampagne,
              //typePopup: data.typePopup,
              bartenders: data.antalBartendere,
              samples: data.samples,
              region: data.region,
              date1Start: data.samplingDates[0].start,
              date1End: data.samplingDates[0].end,
              date2Start: data.samplingDates[1].start,
              date2End: data.samplingDates[1].end,
              date3Start: data.samplingDates[2].start,
              date3End: data.samplingDates[2].end,

              //isLoading: false,
            });
          }
          if (data.samplingDates.length > 3) {
            myArr.push({
              Id: data.item_Id,
              sampling_Id: data.sampling_Id,
              outlet: data.outlet,
              //date: data.date,
              kampagne: data.kampagne,
              //typePopup: data.typePopup,
              bartenders: data.antalBartendere,
              samples: data.samples,
              region: data.region,
              date1Start: data.samplingDates[0].start,
              date1End: data.samplingDates[0].end,
              date2Start: data.samplingDates[1].start,
              date2End: data.samplingDates[1].end,
              date3Start: data.samplingDates[2].start,
              date3End: data.samplingDates[2].end,
              date4Start: data.samplingDates[3].start,
              date4End: data.samplingDates[3].end,
              //isLoading: false,
            });
          }
        });
        return myArr;
      })
      .then((myArr) => {
        this.setState({ mytable: myArr });
      });
  };

  printOrBooking = (columnsSampling, grouping, row) => {
    if (this.state.printing) {
      this.state.listOfId = [];

      if (this.state.sjælland === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (this.state.mytable[i].region === "Sjælland") {
            this.state.listOfId.push(this.state.mytable[i].Id);
          }
        }
      }

      if (this.state.jylland === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (this.state.mytable[i].region === "Jylland") {
            this.state.listOfId.push(this.state.mytable[i].Id);
          }
        }
      }

      if (this.state.fyn === true) {
        for (let i = 0; i < this.state.mytable.length; i++) {
          if (this.state.mytable[i].region === "Fyn") {
            this.state.listOfId.push(this.state.mytable[i].Id);
          }
        }
      }

      if (this.state.listOfId) {
        this.props.history.push(`/samplings/printall`, {
          samplings: this.state.listOfId,
          type: this.state.selectedOption,
          allsamlings: this.state.mytable,
          sjælland: this.state.sjælland,
          fyn: this.state.fyn,
          jylland: this.state.jylland,
          selectedOption: this.state.selectedOption,
          startDate: this.state.newStartDate,
          endDate: this.state.newEndDate,
        });
      }
    } else {
      return (
        <SamplingsTable
          dataColumn={columnsSampling}
          myData={row}
          grouping={grouping}
          defaultExpandedGroups={this.state.defaultExpandedGroups}
        />
      );
    }
  };

  openDateRange = () => {
    this.setState({ datePickerHandler: true });
  };

  closeDateRange = () => {
    this.setState({ datePickerHandler: false });
  };

  customRange = () => {
    if (this.state.datePickerHandler) {
      return (
        <RangePicker
          onDateSelected={this.onDateChanges}
          onClose={this.onDateChanges}
        />
      );
    }
  };
  onDateChanges = (date, date2) => {
    if (date && date2) {
      var startMonth = date.getMonth() + 1;
      var endMonth = date2.getMonth() + 1;
      var start = null;
      start = date.getFullYear() + "-" + startMonth + "-" + date.getDate();
      var end = null;
      end = date2.getFullYear() + "-" + endMonth + "-" + date2.getDate();
    }
    if (start != null && end != null) {
      this.setState({ newStartDate: start, newEndDate: end });
      let myArr = new Array();
      apiFacade
        .getData("/samplings?start=" + start + "&end=" + end)
        .then((data) => {
          data.map((data) => {
            if (data.samplingDates.length <= 1) {
              myArr.push({
                Id: data.item_Id,
                sampling_Id: data.sampling_Id,
                outlet: data.outlet,
                //date: data.date,
                kampagne: data.kampagne,
                //typePopup: data.typePopup,
                bartenders: data.antalBartendere,
                samples: data.samples,
                region: data.region,
                date1Start: data.samplingDates[0].start,
                date1End: data.samplingDates[0].end,
                //isLoading: false,
              });
            }
            if (
              data.samplingDates.length > 1 &&
              data.samplingDates.length <= 2
            ) {
              myArr.push({
                Id: data.item_Id,
                sampling_Id: data.sampling_Id,
                outlet: data.outlet,
                //date: data.date,
                kampagne: data.kampagne,
                //typePopup: data.typePopup,
                bartenders: data.antalBartendere,
                samples: data.samples,
                region: data.region,
                date1Start: data.samplingDates[0].start,
                date1End: data.samplingDates[0].end,
                date2Start: data.samplingDates[1].start,
                date2End: data.samplingDates[1].end,
                //isLoading: false,
              });
            }
            if (
              data.samplingDates.length > 2 &&
              data.samplingDates.length <= 3
            ) {
              myArr.push({
                Id: data.item_Id,
                sampling_Id: data.sampling_Id,
                outlet: data.outlet,
                //date: data.date,
                kampagne: data.kampagne,
                //typePopup: data.typePopup,
                bartenders: data.antalBartendere,
                samples: data.samples,
                region: data.region,
                date1Start: data.samplingDates[0].start,
                date1End: data.samplingDates[0].end,
                date2Start: data.samplingDates[1].start,
                date2End: data.samplingDates[1].end,
                date3Start: data.samplingDates[2].start,
                date3End: data.samplingDates[2].end,

                //isLoading: false,
              });
            }
            if (data.samplingDates.length > 3) {
              myArr.push({
                Id: data.item_Id,
                sampling_Id: data.sampling_Id,
                outlet: data.outlet,
                //date: data.date,
                kampagne: data.kampagne,
                //typePopup: data.typePopup,
                bartenders: data.antalBartendere,
                samples: data.samples,
                region: data.region,
                date1Start: data.samplingDates[0].start,
                date1End: data.samplingDates[0].end,
                date2Start: data.samplingDates[1].start,
                date2End: data.samplingDates[1].end,
                date3Start: data.samplingDates[2].start,
                date3End: data.samplingDates[2].end,
                date4Start: data.samplingDates[3].start,
                date4End: data.samplingDates[3].end,
                //isLoading: false,
              });
            }
          });
          return myArr;
        })
        .then((myArr) => {
          this.setState({ mytable: myArr });
        });
      this.setState({ datePickerHandler: false });
    }
  };

  toggleModalDemo = () => {
    this.setState({
      modalDemo: !this.state.modalDemo,
    });
  };
  handleOptionChange = (changeEvent) => {
    this.setState({
      selectedOption: changeEvent.target.value,
    });
  };
  handleChangeFyn = (fyn) => {
    this.setState({ fyn: fyn.state.value });
  };
  handleChangeSjælland = (sjælland) => {
    this.setState({ sjælland: sjælland.state.value });
  };
  handleChangeJylland = (jylland) => {
    this.setState({ jylland: jylland.state.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // console.log(e);
    // console.log("You have selected:", this.state.selectedOption);
    // console.log("sjælland: " + this.state.sjælland);
    // console.log("jylland: " + this.state.jylland);
    // console.log("fyn: " + this.state.fyn);

    if (e) {
      this.setState({
        printing: true,
      });
    }
  };

  refreshAll = () => {
    for (let i = 0; i < this.state.mytable.length; i++) {
      this.state.listOfId.push(this.state.mytable[i].Id);
    }
    if (this.state.listOfId.length > 0) {
      this.state.listOfId.forEach((element) => {
        apiFacade.getData("/samplings/addorupdate/" + element);
      });

      setTimeout(function () {
        window.location.reload();
      }, 3000);
    }
  };

  viewnow = () => {
    if (this.state.selectedOption != null) {
      if (
        this.state.sjælland != false ||
        this.state.fyn != false ||
        this.state.jylland != false
      ) {
        return (
          <Button color="success" type="submit" onClick={this.toggleModalDemo}>
            View
          </Button>
        );
      }
    }

    if (
      this.state.sjælland == false ||
      this.state.fyn == false ||
      this.state.jylland == false ||
      this.state.selectedOption == null
    ) {
      return (
        <div style={{ fontSize: 14 }}>
          <p className="italic">
            {" "}
            Choose one or more region & printing type to view
          </p>
        </div>
      );
    }
  };

  render() {
    const grouping = [{ columnName: "region" }];
    const columnsSampling = [
      { name: "Id", title: "Item Id" },
      { name: "sampling_Id", title: "Sampling Id" },
      { name: "kampagne", title: "Kampagne" },
      { name: "outlet", title: "Outlet" },
      { name: "samples", title: "Samples" },
      //   { name: 'bartenders', title: 'Bartenders' },
      /* { name: 'barModules', title: 'BarModules' }, */
      { name: "region", title: "Region" },
      { name: "date1", title: "Dag 1" },
      { name: "date2", title: "Dag 2" },
      { name: "date3", title: "Dag 3" },
      { name: "date4", title: "Dag 4" },
      { name: "view", title: "View" },
    ];

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={4}>
              <Card className="card-chart" style={{ minHeight: "130px" }}>
                <CardHeader>
                  <CardTitle style={{ marginBottom: "20px" }} tag="h4">
                    Samplings & Pop-ups
                  </CardTitle>
                  {/* alle skal ikke kunne edit print menu */}
                </CardHeader>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card style={{ minHeight: "130px" }}>
                <CardBody>
                  <Button
                    style={{ width: "100%", backgroundColor: "#d16557" }}
                    size="sm"
                    onClick={this.toggleModalDemo}
                  >
                    <Print /> &nbsp; Print All
                  </Button>
                  <Modal
                    isOpen={this.state.modalDemo}
                    toggle={this.toggleModalDemo}
                  >
                    <form name="printall" onSubmit={this.handleSubmit}>
                      <ModalHeader
                        className="justify-content-center"
                        toggle={this.toggleModalDemo}
                      >
                        Print All Menu
                      </ModalHeader>
                      <ModalBody>
                        <Row>
                          <Col xs={12} md={4}>
                            <p>Regions</p>
                            <p className="category">Sjælland</p>
                            <Switch
                              onText={<i className="now-ui-icons ui-1_check" />}
                              onColor="custom"
                              offText={
                                <i className="now-ui-icons ui-1_simple-remove" />
                              }
                              checked={this.state.sjælland}
                              onChange={this.handleChangeSjælland}
                              defaultValue={this.state.sjælland}
                              id="1"
                            />
                            <p className="category">Jylland</p>
                            <Switch
                              onText={<i className="now-ui-icons ui-1_check" />}
                              onColor="custom"
                              offText={
                                <i className="now-ui-icons ui-1_simple-remove" />
                              }
                              checked={this.state.jylland}
                              onChange={this.handleChangeJylland}
                              defaultValue={this.state.jylland}
                              id="2"
                            />
                            <p className="category">Fyn</p>
                            <Switch
                              onText={<i className="now-ui-icons ui-1_check" />}
                              onColor="custom"
                              offText={
                                <i className="now-ui-icons ui-1_simple-remove" />
                              }
                              checked={this.state.fyn}
                              onChange={this.handleChangeFyn}
                              defaultValue={this.state.fyn}
                              id="3"
                            />
                          </Col>
                          <Col xs={12} md={8}>
                            <p>Printing type</p>
                            <FormGroup check className="form-check-radio">
                              <p className="category">
                                {" "}
                                only one type at the time
                              </p>
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="1"
                                  id="option1"
                                  name="radio"
                                  type="radio"
                                  checked={this.state.selectedOption === "1"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Packing List</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="2"
                                  id="2"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "2"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Front Pages</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="3"
                                  id="3"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "3"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Returlist</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="5"
                                  id="5"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "5"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Weekplan</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio">
                              <Label className="mycontainer">
                                <Input
                                  className="checkmark"
                                  defaultValue="6"
                                  id="6"
                                  name="exampleRadios"
                                  type="radio"
                                  checked={this.state.selectedOption === "6"}
                                  onChange={this.handleOptionChange}
                                />
                                <p className="category">Total</p>{" "}
                                <span className="checkmark" />
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="danger" onClick={this.toggleModalDemo}>
                          Close
                        </Button>
                        {this.viewnow()}
                      </ModalFooter>
                    </form>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card style={{ minHeight: "130px" }}>
                <CardBody>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      color="info"
                      style={{ width: "100%", backgroundColor: "#d16557" }}
                    >
                      <Moment format="YYYY/MM/DD">{this.state.start}</Moment>{" "}
                      &nbsp;
                      <Moment /* add={{ days: 7 }} */ format="YYYY/MM/DD">
                        {this.state.end}
                      </Moment>
                    </DropdownToggle>
                    <DropdownMenu style={bookingStyle.dropdownmenustyle}>
                      <DropdownItem onClick={this.updateTable} id="30days">
                        Next 30 Days
                      </DropdownItem>
                      <DropdownItem onClick={this.updateTable} id="7days">
                        Next 7 Days
                      </DropdownItem>
                      <DropdownItem onClick={this.updateTable} id="tomorrow">
                        Tomorrow
                      </DropdownItem>
                      <DropdownItem onClick={this.updateTable} id="today">
                        Today
                      </DropdownItem>
                      <DropdownItem onClick={this.updateTable} id="yesterday">
                        Yesterday
                      </DropdownItem>
                      <DropdownItem onClick={this.updateTable} id="last7days">
                        Last 7 days
                      </DropdownItem>
                      <DropdownItem onClick={this.updateTable} id="last30days">
                        Last 30 days
                      </DropdownItem>
                      <DropdownItem onClick={this.openDateRange}>
                        Custom Range
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {this.customRange()}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  {this.printOrBooking(
                    columnsSampling,
                    grouping,
                    this.state.mytable
                  )}
                </CardBody>
                <CardFooter>
                  <Stats>
                    {[
                      {
                        i: "now-ui-icons arrows-1_refresh-69",
                        t: "Just Updated",
                      },
                    ]}
                  </Stats>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md={{ size: 2, offset: 10 }}>
              <IconButton
                style={bookingStyle.stuck}
                aria-label="View more"
                onClick={this.refreshAll}
              >
                <AutorenewOutlinedIcon
                  fontSize="large"
                  style={{ color: "white" }}
                />
              </IconButton>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Samplings;
