import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import apiFacade from '../../../../auth/apiFacade';

const AddDrinkForm = props => {
    const initialFormState = { customName: null, beskrivelse: '', price: '0', glassId: '', cocktailOrNot: 1, bookingId: props.bookingId }
    const [newDrink, setNewDrink] = useState(initialFormState)
    const [glasses, setGlasses] = useState([])
    const [addDrink, setAddDrink] = useState(false)


    useEffect(
        () => {
            apiFacade.getData("/glasses").then((data) => {
                setGlasses(data.newGlassList)
            })
        },
        [props]
    )


    const handleInputChange = e => {
        setNewDrink({
            ...newDrink,
            [e.target.name]: e.target.value
        });
        setAddDrink(true)
    };

    const sumbitNow = () => {
        if (addDrink) {
            //apiFacade.postDataN("https://pakkemodelapi.azurewebsites.net/api/EditMenus" + newDrink);

            fetch("https://pakkemodelapi.azurewebsites.net/api/EditMenus", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("accessToken")
                },
                body: JSON.stringify(newDrink)
            });

            setNewDrink(initialFormState)
            setAddDrink(false)
            props.addToggle()
            setTimeout(function () {
                window.location.reload()
            }, 1000);

        }
    }

    const cancelInput = (event) => {
        props.addToggle()
    }

    return (
        <Form
            onSubmit={event => {
                event.preventDefault()
                sumbitNow()
            }}
        >
            <Label>Name</Label>
            <Input type="text" name="customName" onChange={handleInputChange} />
            <Label>Beskrivelse</Label>
            <Input type="textarea" style={{ height: 500 }} name="beskrivelse" onChange={handleInputChange} />
            <Label>Pris</Label>
            <Input type="number" name="price" onChange={handleInputChange} />
            <Label>Glass Type</Label>
            <Input type="select" name="glassId" id="exampleSelect" onChange={handleInputChange} >
                <option value={0}> None </option>
                {
                    glasses.map((row, i) =>
                        <option key={i} value={row.glassId}> {row.name} </option>
                    )
                }

            </Input>
            <Button color="info">Add Drink</Button>
            <Button color="warning" onClick={() => cancelInput()} className="Button muted-Button">
                Cancel
      </Button>
        </Form>
    )
}

export default AddDrinkForm