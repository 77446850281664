import types from "../actionTypes";

const initialState = {
  regions: ["Sjælland"],
  startDate: null,
  endDate: null,
  startDateOri: null,
  endDateOri: null,
  totalEvents: 0,
  bookings: [],
};

const weekplanReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.weekplan.regions:
      return {
        ...state,
        regions: action.payload,
      };
    case types.weekplan.startDate:
      return {
        ...state,
        startDate: action.payload,
      };
    case types.weekplan.endDate:
      return {
        ...state,
        endDate: action.payload,
      };
    case types.weekplan.startDateOri:
      return {
        ...state,
        startDateOri: action.payload,
      };
    case types.weekplan.endDateOri:
      return {
        ...state,
        endDateOri: action.payload,
      };
    case types.weekplan.totalEvents:
      return {
        ...state,
        totalEvents: action.payload,
      };
    case types.weekplan.bookings:
      return {
        ...state,
        bookings: action.payload,
      };
    case types.weekplan.deleteAllBookings:
      return {
        ...state,
        bookings: [],
      };
    default:
      return state;
  }
};

export default weekplanReducer;
