import types from "../actionTypes";

const initialState = {
    setData: false,
  };

  const returnWaresReducer = (state = initialState, action) => {
    switch (action.type) {
      case types.returnWares.setData:
        return {
          ...state,
          setData: action.payload,
        };
      default:
        return state;
    }
  };

  export default returnWaresReducer;