import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import apiFacade from '../../../../auth/apiFacade';

const EditCocktailForm = props => {
    const [cocktail, setCocktail] = useState(props.currentCocktail)
    const [glasses, setGlasses] = useState([])

    useEffect(
        () => {
            setCocktail(props.currentCocktail)
            apiFacade.getData("/glasses").then((data) => {
                setGlasses(data.newGlassList)
            })
        },
        [props]
    )

    // You can tell React to skip applying an effect if certain values haven’t changed between re-renders. [ props ]


    const handleInputChange = event => {
        const { name, value } = event.target
        setCocktail({ ...cocktail, [name]: value })
    }

    const cancelInput = (event) => {
        props.setEditing(false)
        props.onToggle()
    }

    return (
        <Form id="myForm"
            onSubmit={event => {
                event.preventDefault()

                props.updateUser(cocktail.id, cocktail)

            }}
        >
            <Label>Name</Label>
            <Input type="text" name="customName" value={cocktail.customName} onChange={handleInputChange} />
            <Label>Beskrivelse</Label>
            <Input type="textarea" style={{ height: 500 }} name="beskrivelse" value={cocktail.beskrivelse} onChange={handleInputChange} />
            <Label>Pris</Label>
            <Input type="number" name="price" value={cocktail.price} onChange={handleInputChange} />
            <Label>Glass Type</Label>
            <Input type="select" name="glassId" id="exampleSelect" onChange={handleInputChange} >
                <option value={cocktail.glassId}> {cocktail.glassType} </option>
                {
                    glasses.map((row, i) =>
                        <option key={i} value={row.glassId}> {row.name} </option>
                    )
                }

            </Input>
            <Button color="success">Update Cocktail</Button>
            <Button color="warning" onClick={() => cancelInput()} className="Button muted-Button">
                Cancel
      </Button>
        </Form>
    )
}

export default EditCocktailForm

