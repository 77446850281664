import { TableCell } from "@material-ui/core";
import React, { useState } from "react";
import DeleteForeverTwoTone from "@material-ui/icons/DeleteForeverTwoTone";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import { IconButton } from "@material-ui/core";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import types from "../../../redux/actionTypes";

const CocktailMenuCell = (props) => {
  const dispatch = useDispatch();
  const [hoverButton, setHoverButton] = useState(false);
  const { selectedmenu } = useSelector((state) => state.CocktailsMenu);
  const { isLoading } = useSelector((state) => state.CocktailsMenu);

  const EditMenuName = () => {
    dispatch({
      type: types.CocktailsMenu.openModal,
      payload: true,
    });
  };

  const DeleteMenu = () => {
    dispatch({
      type: types.CocktailsMenu.openModalDelete,
      payload: true,
    });
  };

  const changeColorButton = (id) => {
    let useSelectedMenu = [];
    const index = selectedmenu.map((e) => e.hoverbutton).indexOf(true);
    selectedmenu.forEach((element) => {
      if (element.id === selectedmenu[index].id && element.id !== id) {
        element.hoverbutton = false;
        useSelectedMenu.push(element);
      } else if (element.id === id) {
        element.hoverbutton = true;
        useSelectedMenu.push(element);
      } else {
        useSelectedMenu.push(element);
      }
    });
    dispatch({
      type: types.CocktailsMenu.emptySelectedmenu,
    });
    dispatch({
      type: types.CocktailsMenu.selectedmenu,
      payload: useSelectedMenu,
    });
  };

  const styles = {
    buttonStyle: {
      backgroundColor: "#FFFFFF",
      color: "black",
    },
  };
  const index = selectedmenu.map((e) => e.id).indexOf(props.row.id);
  if (index !== -1) {
    if (selectedmenu[index].hoverbutton === true) {
      styles.buttonStyle = {
        border: "1px solid black",
        backgroundColor: "#adddff",
        color: "black",
      };
    }

    if (hoverButton) {
      styles.buttonStyle = {
        border: "1px solid black",
        backgroundColor: "#d6eeff",
        color: "black",
      };
    }
  }

  if (props.column.name === "name") {
    return (
      <TableCell style={{ height: 100 }}>
        <Button
          disabled={isLoading}
          onClick={() => {
            dispatch({
              type: types.CocktailsMenu.id,
              payload: props.row.id,
            });
            changeColorButton(props.row.id);
          }}
          onMouseEnter={() => {
            setHoverButton(true);
          }}
          onMouseLeave={() => {
            setHoverButton(false);
          }}
          style={styles.buttonStyle}
        >
          {props.row.name}
        </Button>{" "}
      </TableCell>
    );
  }
  if (props.column.name === "actions") {
    return (
      <TableCell align="center">
        <IconButton
          disabled={isLoading}
          color="primary"
          onClick={() => {
            EditMenuName();
            dispatch({
              type: types.CocktailsMenu.id,
              payload: props.row.id,
            });
            changeColorButton(props.row.id);
          }}
        >
          <EditTwoToneIcon />
        </IconButton>{" "}
        <IconButton
          disabled={isLoading}
          color="secondary"
          onClick={() => {
            DeleteMenu();
            dispatch({
              type: types.CocktailsMenu.id,
              payload: props.row.id,
            });
            changeColorButton(props.row.id);
          }}
        >
          <DeleteForeverTwoTone />
        </IconButton>
      </TableCell>
    );
  }
  // if (props.column.name === "status") {
  //   return (
  //     <TableCell>
  //       <Checkbox
  //         disabled={!props.row.statusBoolean}
  //         checked={checked}
  //         onChange={handleChange}
  //         style={{ color: "#3f51b5" }}
  //         inputProps={{ "aria-label": "primary checkbox" }}
  //       />
  //     </TableCell>
  //   );
  // }
  return <TableCell>No data</TableCell>;
};
export default CocktailMenuCell;
