import types from "../actionTypes";

const initialState = {
  jwa: [],
  tempJwa: [],
  emptyJwa: [],
  emptyTempJwa: [],
  updateDate: "",
  dateBool: false,
  updateStatus: "",
  updatePacker: "",
  jobList: [],
  departments: [],
};

const jobManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.jobManagement.emptyJwa:
      return {
        ...initialState,
      };
    case types.jobManagement.emptyTempJwa:
      return {
        ...initialState,
      };
    case types.jobManagement.departments:
      return {
        ...state,
        departments: action.payload,
      };
    case types.jobManagement.jwa:
      return {
        ...state,
        jwa: action.payload,
      };
    case types.jobManagement.tempJwa:
      return {
        ...state,
        tempJwa: action.payload,
      };
    case types.jobManagement.dateBool:
      return {
        ...state,
        dateBool: !state.dateBool,
      };
    case types.jobManagement.updatePacker:
      const {
        packerId,
        packerJobId,
        packerAssignmentId,
        packerName,
        prevPacker,
      } = action.payload;
      let packerJob = state.jwa.find((j) => j.jobId === packerJobId);
      let jobIndex = state.jobList.findIndex((i) => i.jobId === packerJobId);
      let asIndex = state.jobList[jobIndex].assignment.findIndex(
        (a) => a.assignmentId === packerAssignmentId
      );
      if (packerJob) {
        let pakJobMan = packerJob.jobManagements.find(
          (m) => m.assignmentId === packerAssignmentId
        );
        if (pakJobMan) {
          if (pakJobMan.packingDate != null) {
            pakJobMan.status = true;
          }
          let pPacker = pakJobMan.jobPakkers.filter(
            (jp) => jp.pakker_Id === prevPacker.id
          );
          if (pPacker.length > 0) {
            let packer = pakJobMan.jobPakkers.filter(
              (jp) => jp.pakker_Id === packerId
            );
            if (packer.length <= 0) {
              if (pPacker.length <= 1) {
                pPacker[0].pakker_Id = packerId;
                pPacker[0].pakkerName = packerName;
                state.jobList[jobIndex].assignment[asIndex].packerBool = false;
                if (
                  !state.jobList[jobIndex].assignment[asIndex].dateTimeBool ||
                  pakJobMan.packingDate != null
                ) {
                  state.jobList[jobIndex].disabled = false;
                }
              } else {
                console.log(
                  "Error handling missing! - Samme person kan ikke tilføjes to gange"
                );
              }
            } else {
              if (packerId !== prevPacker.id) {
              } else {
                let remPacker = pakJobMan.jobPakkers.filter(
                  (jp) => jp.pakker_Id !== packerId
                );
                pakJobMan.jobPakkers = remPacker;
                if (remPacker.length === 0) {
                  state.jobList[jobIndex].assignment[asIndex].packerBool = true;
                  state.jobList[jobIndex].disabled = true;
                  pakJobMan.status = false;
                } else if (remPacker.length === 1) {
                  state.jobList[jobIndex].assignment[
                    asIndex
                  ].packerBool = false;
                  state.jobList[jobIndex].disabled = false;
                  pakJobMan.status = true;
                }
              }
            }
          } else {
            let packer = pakJobMan.jobPakkers.filter(
              (jp) => jp.pakker_Id === packerId
            );
            if (packer.length <= 0) {
              pakJobMan.jobPakkers.push({
                pakker_Id: packerId,
                pakkerName: packerName,
              });
              state.jobList[jobIndex].assignment[asIndex].packerBool = false;
              if (pakJobMan.packingDate != null) {
                state.jobList[jobIndex].disabled = false;
              }
            } else {
              console.log(
                "Error handling missing! - Samme person kan ikke tilføjes to gange"
              );
            }
          }
        }
      }
    case types.jobManagement.updateDate:
      const { date, jobId, assignmentId } = action.payload;
      if (jobId !== undefined) {
        let job = state.jwa.find((j) => j.jobId === jobId);
        let jobDateIndex = state.jobList.findIndex((i) => i.jobId === jobId);
        let asDateIndex = state.jobList[jobDateIndex].assignment.findIndex(
          (a) => a.assignmentId === assignmentId
        );
        if (job) {
          let jobMan = job.jobManagements.find(
            (m) => m.assignmentId === assignmentId
          );
          if (jobMan) {
            jobMan.packingDate = date;
            if (jobMan.jobPakkers.length > 0) {
              jobMan.status = true;
            }
            state.jobList[jobDateIndex].assignment[
              asDateIndex
            ].dateTimeBool = false;
            if (
              !state.jobList[jobDateIndex].assignment[asDateIndex].packerBool ||
              jobMan.jobPakkers.length > 0
            ) {
              state.jobList[jobDateIndex].disabled = false;
            }
          }
        }
      }
    case types.jobManagement.updateStatus:
      const { statusJobId, statusAssignmentId } = action.payload;
      if (statusJobId !== undefined) {
        let job_ = state.jwa.find((j) => j.jobId === statusJobId);
        let jobStatusIndex = state.jobList.findIndex(
          (i) => i.jobId === statusJobId
        );
        let asStatusIndex = state.jobList[jobStatusIndex].assignment.findIndex(
          (a) => a.assignmentId === statusAssignmentId
        );
        if (job_) {
          let jobMan_ = job_.jobManagements.find(
            (m) => m.assignmentId === statusAssignmentId
          );
          if (jobMan_) {
            jobMan_.status = !jobMan_.status;
            if (!jobMan_.status) {
              jobMan_.packingDate = "";
              jobMan_.jobPakkers = [];
              state.jobList[jobStatusIndex].assignment[
                asStatusIndex
              ].dateTimeBool = true;
              state.jobList[jobStatusIndex].assignment[
                asStatusIndex
              ].packerBool = true;
              state.jobList[jobStatusIndex].disabled = true;
            }
          }
        }
      }
    case types.jobManagement.jobList:
      const { jobListId, updated, newJob } = action.payload;
      if (updated !== undefined) {
        if (!updated) {
          if (state.jobList.length > 0) {
            let job = state.jobList.find((u) => u.jobId === jobListId);
            if (job === undefined) {
              state.jobList.push(newJob);
            }
          } else {
            state.jobList.push(newJob);
          }
        }
      }
    case types.jobManagement.resetJobList:
      const { resetJobId } = action.payload;
      if (resetJobId !== undefined) {
        let jobResetIndex = state.jobList.findIndex(
          (i) => i.jobId === resetJobId
        );
        state.jobList[jobResetIndex].disabled = true;
        state.jobList[jobResetIndex].assignment.forEach((element) => {
          element.dateTimeBool = true;
          element.packerBool = true;
        });
        let jwaResetIndex = state.jwa.findIndex((i) => i.jobId === resetJobId);
        state.jwa[jwaResetIndex].jobManagements.forEach((info) => {
          if (!info.status) {
            info.packingDate = null;
            info.jobPakkers = [];
          }
        });
      }
    default:
      return state;
  }
};

export default jobManagementReducer;
