export default {
  menuCocktailId: {
    Id: "menuCocktailId/Id",
    Name: "menuCocktailId/Name",
    products: "menuCocktailId/products",
    addedCocktails: "menuCocktailId/addedCocktails",
    deleteItem: "menuCocktailId/deleteItem",
    emptyCocktails: "menuCocktailId/emptyCocktails",
    emptyMenu: "menuCocktailId/emptyMenu",
    menuCocktails: "menuCocktailId/menuCocktails",
    emptyMenuCocktails: "menuCocktailId/emptyMenuCocktails",
    newMenu: "menuCocktailId/newMenu",
    updateNow: "menuCocktailId/update",
    Menu: "menuCocktailId/Menu",
    updateMenu: "menuCocktailId/updateMenu",
  },
  weekplan: {
    regions: "weekplan/regions",
    startDate: "weekplan/startDate",
    endDate: "weekplan/endDate",
    startDateOri: "weekplan/startDateOri",
    endDateOri: "weekplan/endDateOri",
    totalEvents: "weekplan/totalEvents",
    bookings: "weekplan/bookings",
    deleteAllBookings: "weekplan/deleteAllBookings",
  },
  CocktailsMenu: {
    id: "cocktailsMenu/id",
    cocktails: "cocktailsMenu/cocktails",
    cocktailmenus: "cocktailsMenu/cocktailmenus",
    selectedmenu: "cocktailsMenu/selectedmenu",
    emptySelectedmenu: "cocktailsMenu/emptySelectedmenu",
    updateCocktails: "cocktailsMenu/updateCocktails",
    addCocktail: "cocktailsMenu/addCocktail",
    products: "cocktailsMenu/products",
    openModal: "cocktailsMenu/openModal",
    openModalDelete: "cocktailsMenu/openModalDelete",
    openModalAdd: "cocktailsMenu/openModalAdd",
    newOrUpdated: "cocktailsMenu/newOrUpdated",
    getName: "cocktailsMenu/getName",
    updateMenu: "cocktailsMenu/updateMenu",
    isLoading: "cocktailsMenu/isLoading",
  },
  authentication: {
    login: "authentication/login",
    logout: "authentication/logout",
    setProfile: "authentication/setProfile",
  },
  packinglist: {
    getPackingList: "packinglist/getPackingList",
  },
  cart: {
    fetchCart: "cart/fetchCart",
    initCart: "cart/initCart",
    setCartContents: "cart/setCartContents",
    getCartContents: "cart/getCartContents",
  },
  header: {
    setShowNotification: "header/setShowNotification",
  },
  notifications: {
    setShowShoppingListNotification:
      "notifications/setShowShoppingListNotification",
    setShowRestartNotification: "notifications/setShowRestartNotification",
    setShowInnactivityNotification:
      "notifications/setShowInnactivityNotification",
  },
  productPage: {
    setisPIPVisible: "productPage/setisPIPVisible",
    setHideTransitionImage: "productPage/setHideTransitionImage",
  },
  session: {
    setPrivacyPolicyDocument: "session/setPrivacyPolicyDocument",
    setGuestToken: "session/setGuestToken",
    setOAuthToken: "session/setOAuthToken",
    setStoreFeatures: "session/setStoreFeatures",
    setIsKioskDevice: "session/setIsKioskDevice",
    setStoreDetails: "session/setStoreDetails",
    setHandoverUrl: "session/setHandoverUrl",
  },
  returnWares: {
    setData: "returnWares/setData",
  },
  settings: {
    setLanguageCode: "settings/setLanguageCode",
    setSettings: "settings/setSettings",
  },
  calculations: {
    regions: "calculations/regions",
    regionSelected: "calculations/regionSelected",
    updateRegionSelected: "calculations/updateRegionSelected",
    barArmCal1: "calculations/barArmCal1",
    barArmCal2: "calculations/barArmCal2",
    loadingRegionSelected: "calculations/loadingRegionSelected:",
    loadingBarArmCal1: "calculations/loadingBarArmCal1:",
    loadingBarArmCal2: "calculations/loadingBarArmCal2:",
    readOnly1: "calculations/readOnly1:",
    readOnly2: "calculations/readOnly2:",
    dateFromDb1: "calculations/dateFromDb1",
    dateFromDb2: "calculations/dateFromDb2",
    calFormBool1: "calculations/calFormBool1",
    calFormBool2: "calculations/calFormBool2",
    jobtypes: "calculations/jobtypes",
    jobtypesBool: "calculations/jobtypesBool",
  },
  booking: {
    updateBookingById: "booking/updateBookingById",
    updateBookingByDate: "booking/updateBookingByDate",
    bookingId: "booking/bookingId",
    redirectBoo: "booking/redirectBoo",
  },
  vckBooking: {
    updateBookingById: "vckBooking/updateBookingById",
    updateBookingByDate: "vckBooking/updateBookingByDate",
    bookingId: "vckBooking/bookingId",
  },
  pakker: {
    packerApi: "pakker/packerApi",
    packerBool: "pakker/packerBool", 
    errorArr: "pakker/errorArr",
    errorArrRemove: "pakker/errorArrRemove",
    errorBool: "pakker/errorBool",
    loginPacker: "pakker/loginPacker",
  },
  jobManagement: {
    jwa: "jobManagement/jwa",
    tempJwa: "jobManagement/tempJwa",
    emptyJwa: "jobManagement/emptyJwa",
    emptyTempJwa: "jobManagement/emptyTempJwa",
    updateDate: "jobManagement/updateDate",
    dateBool: "jobManagement/dateBool",
    updateStatus: "jobManagement/updateStatus",
    updatePacker: "jobManagement/updatePacker",
    jobList: "jobManagement/jobList",
    createJobList: "jobManagement/createJobList",
    resetJobList: "jobManagement/resetJobList",
    departments: "jobManagement/departments",
  },
  assignment: {
    assignmentBool: "assignment/assignmentBool",
    jobId: "assignment/jobId",
    assignmentId: "assignment/assignmentId",
    assignmentName: "assignment/assignmentName",
    statusChangeArr: "assignment/statusChangeArr",
    changeStatus: "assignment/changeStatus",
    statusBool: "assignment/statusBool",
    managementId: "assignment/managementId",
  },
  search: {
    setActive: "search/setActive",
  },
  error: {
    setNoServiceError: "error/setNoServiceError",
  },
};
