import React, { Component } from "react";
import PanelHeader from "../../components/PanelHeader/PanelHeader.js";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Alert,
} from "reactstrap";
import { ScreenSize } from "helper/ScreenSize";
import Stats from "../../components/Stats/Stats.jsx";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import apiFacade from "../../auth/apiFacade";
import ComponentTable from "./ComponentsTable/ComponentsTable";
import { PacmanLoader } from "react-spinners";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

var bookingStyle = {
  stuck: {
    position: "fixed",
    marginTop: "-4%",
    marginLeft: "80%",
    zIndex: "1000",
  },
  stuck2: {
    position: "fixed",
    marginTop: "-14%",
    marginLeft: "65%",
    zIndex: "1000",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

export default class Components_s extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSaved: false,
      components: [],
      unit: "",
      category: "",
      container: "",
      distributor: "",
      bay: "",
      alertVisible: false,
      distributors: [],
      units: [],
      allContainers: [],
      alertMsgs: [],
      component: {
        categoryId: 0,
        distributor_id: 0,
        name: "",
        size: "",
        unitId: 0,
        unitSuffix: "",
        bay: 99999,
        shelf: "",
        tradeVolume: "",
        minPerBartender: 0,
        container_id: 0,
      },
      categories: [],
      addModal: false,
      bayList: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      ],
      defaultExpandedGroups: [
        "Spiritus",
        "Juice & púre",
        "Sodavand, mixer",
        "Egenproduktion",
        "Friske produkter",
        "Sirup",
        "Specialvarer",
        "Glas",
        "Kursus speciel",
        "Isterninger",
        "Barudstyr",
        "Kolonial",
        "Mappen",
        "Plastikglas",
        "Vin",
        "PlastikglasWhiteLabel",
        "VCK prodiktion",
      ],
      componentColumn: [
        { name: "name", title: "Name" },
        { name: "tradeVolume", title: "Trade Volume" },
        { name: "unitSuffix", title: "Size" },
        { name: "unit_name", title: "Unit" },
        { name: "distributor_name", title: "Distributor" },
        { name: "category_Name", title: "Category" },
        { name: "bay", title: "Bay" },
        { name: "shelf", title: "Shelf" },
        { name: "actions", title: "Actions" },
      ],
      stuckStyle: bookingStyle.stuck,
    };
  }
  componentDidMount() {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      this.setState({
        componentColumn: [
          { name: "name", title: "Name" },
          { name: "category_Name", title: "Category" },
          { name: "actions", title: "Actions" },
        ],
      });
      this.setState({stuckStyle: bookingStyle.stuck2});
    } else if (screenSize === 2) {
      this.setState({
        componentColumn: [
          { name: "name", title: "Name" },
          { name: "unit_name", title: "Unit" },
          { name: "category_Name", title: "Category" },
          { name: "bay", title: "Bay" },
          { name: "shelf", title: "Shelf" },
          { name: "actions", title: "Actions" },
        ],
      });
    }

    apiFacade.getData("/components").then((data) => {
      this.setState({
        components: data,
        distributors: data[0].distributors,
        units: data[0].units,
        categories: data[0].categories,
        allContainers: data[0].containers,
      });
    });
  }
  addNewComponent = () => {
    this.state.alertMsgs = [];
    const {
      name,
      size,
      categoryId,
      distributor_id,
      unitId,
      unitSuffix,
      shelf,
      bay,
      container_id,
    } = this.state.component;

    if (!name.length) {
      this.state.alertMsgs.push("Name");
    }
    if (!size.length) {
      this.state.alertMsgs.push("Size");
    }
    if (!unitSuffix.length) {
      this.state.alertMsgs.push("Unit suffix (L, kg, stk etc.)");
    }
    if (unitId === 0) {
      this.state.alertMsgs.push("Units (Flasker, gr, kasser, rammer etc.)");
    }
    if (categoryId === 0) {
      this.state.alertMsgs.push("category (Spiritus, Sirup, Glas etc.)");
    }
    if (distributor_id === 0) {
      this.state.alertMsgs.push(
        "Distributor (Drinx, Bevco, Egenproduktion etc.)"
      );
    }
    if (bay === 99999) {
      this.state.alertMsgs.push("Reol nr (Choose 0 if u dont know where)");
    }
    if (!shelf.length) {
      this.state.alertMsgs.push("shelf (Write 0 if u dont know where)");
    }
    if (this.state.alertMsgs.length > 0) {
      this.setState({ alertVisible: true });
      window.setTimeout(() => {
        this.setState({ alertVisible: false });
      }, 12000);
    } else {
      this.setState({ alertVisible: false });
      apiFacade
        .postData2(
          "https://pakkemodelapi.azurewebsites.net/api/components/",
          this.state.component
        )
        .then((response) => {
          this.setState({ openSaved: true });
        })
        .catch((err) => console.error("Error", err));
      window.setTimeout(() => {
        this.toggle();
        window.location.reload();
      }, 1000);
    }
  };

  handleChangeUnit = (event) => {
    this.setState({ unit: event.target.value });
    this.setState({
      component: {
        ...this.state.component,
        unitId: event.target.value,
      },
    });
  };

  handleChangeReol = (event) => {
    this.setState({ bay: event.target.value });
    this.setState({
      component: {
        ...this.state.component,
        bay: event.target.value,
      },
    });
  };

  onDismissAlert = () => this.setState({ alertVisible: false });
  alertMsg = () => {
    const alerts = [];
    for (let index = 0; index < this.state.alertMsgs.length; index++) {
      alerts.push(
        <Alert
          severity="error"
          style={{ fontSize: "15px" }}
          isOpen={this.state.alertVisible}
          toggle={this.onDismissAlert}
          color="danger"
        >
          <div style={{ marginTop: "5px" }}>
            {" "}
            <p> {this.state.alertMsgs[index]} field is required</p>
          </div>
        </Alert>
      );
    }
    return alerts;
  };
  inputValueprintById = (id, where) => {
    let getValue = document.getElementById(id).value;

    if (where === "Name") {
      this.setState({
        component: {
          ...this.state.component,
          name: getValue,
        },
      });
    }
    if (where === "shelf") {
      this.setState({
        component: {
          ...this.state.component,
          shelf: getValue,
        },
      });
    }
    if (where === "MinPerBartender") {
      this.setState({
        component: {
          ...this.state.component,
          minPerBartender: getValue,
        },
      });
    }
    if (where === "TradeVolume") {
      this.setState({
        component: {
          ...this.state.component,
          tradeVolume: getValue,
        },
      });
    }
    if (where === "Size") {
      this.setState({
        component: {
          ...this.state.component,
          size: getValue,
        },
      });
    }
    if (where === "UnitSuffix") {
      this.setState({
        component: {
          ...this.state.component,
          unitSuffix: getValue,
        },
      });
    }
  };

  handleChangeCategory = (event) => {
    this.setState({ category: event.target.value });
    this.setState({
      component: {
        ...this.state.component,
        categoryId: event.target.value,
      },
    });
  };

  handleChangeContainer = (event) => {
    this.setState({ container: event.target.value });
    this.setState({
      component: {
        ...this.state.component,
        container_id: event.target.value,
      },
    });
  };

  handleChangeDistributors = (event) => {
    this.setState({ distributor: event.target.value });
    this.setState({
      component: {
        ...this.state.component,
        distributor_id: event.target.value,
      },
    });
  };
  toggle = () => {
    window.setTimeout(() => {
      this.setState({
        component: {
          categoryId: 0,
          distributor_id: 0,
          name: "",
          size: "",
          unitId: 0,
          unitSuffix: "",
          bay: 99999,
          shelf: "",
          tradeVolume: "",
          minPerBartender: 0,
        },
        unit: "",
        category: "",
        distributor: "",
        alertVisible: false,
        bay: "",
      });
    }, 7000);
    this.setState({ addModal: !this.state.addModal });
  };
  render() {
    const grouping = [{ columnName: "category_Name" }];

    if (this.state.components.length > 0) {
      return (
        <div>
          <PanelHeader size="sm" />

          <div className="content">
            <Row>
              <Col xs="auto" md={12}>
                <Card className="card-chart">
                  <CardHeader>
                    <CardTitle tag="h4">Components</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Modal
                      fade={false}
                      isOpen={this.state.addModal}
                      style={{ maxWidth: "1600px", width: "50%" }}
                    >
                      <Alert
                        isOpen={this.state.openSaved}
                        style={{
                          marginTop: "10px",

                          textAlign: "center",
                          fontSize: "20px",
                          width: "310px",
                          marginLeft: "33%",
                        }}
                      >
                        <NotificationsActiveIcon />
                        {"  Successfully Added"}
                      </Alert>
                      <ModalHeader>Add Component</ModalHeader>
                      <ModalBody>
                        <Card>
                          <CardTitle>
                            <Row>
                              <Col xs="4">Add Details *</Col>
                            </Row>
                          </CardTitle>
                          <CardBody>
                            <Row>
                              <Col xs="4">
                                <form
                                  className={useStyles.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <div>
                                    <TextField
                                      required
                                      id="name"
                                      label="Name"
                                      onChange={() => {
                                        this.inputValueprintById(
                                          "name",
                                          "Name"
                                        );
                                      }}
                                    />
                                  </div>
                                </form>
                              </Col>
                              <Col xs="4">
                                <form
                                  className={useStyles.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <div>
                                    <TextField
                                      required
                                      id="size"
                                      label="Size"
                                      onChange={() => {
                                        this.inputValueprintById(
                                          "size",
                                          "Size"
                                        );
                                      }}
                                    />
                                  </div>
                                </form>
                              </Col>
                              <Col xs="4">
                                <form
                                  className={useStyles.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <div>
                                    <TextField
                                      required
                                      id="unitsuffix"
                                      label="Unit suffix"
                                      onChange={() => {
                                        this.inputValueprintById(
                                          "unitsuffix",
                                          "UnitSuffix"
                                        );
                                      }}
                                    />
                                  </div>
                                </form>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="4">
                                <form
                                  className={useStyles.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <div>
                                    <TextField
                                      id="filled-se"
                                      select
                                      required
                                      style={{ width: "100%" }}
                                      label="Units"
                                      value={this.state.unit}
                                      onChange={this.handleChangeUnit}
                                      SelectProps={{
                                        native: false,
                                      }}
                                    >
                                      {this.state.units.map((uni) => (
                                        <MenuItem
                                          key={uni.id}
                                          id={uni.id}
                                          value={uni.id}
                                          name={uni.name}
                                        >
                                          {uni.name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </div>
                                </form>
                              </Col>
                              <Col xs="4">
                                <form
                                  className={useStyles.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <div>
                                    <TextField
                                      id="outlined-select-currency"
                                      select
                                      required
                                      style={{ width: "100%" }}
                                      label="Distributors"
                                      value={this.state.distributor}
                                      onChange={this.handleChangeDistributors}
                                      SelectProps={{
                                        native: false,
                                      }}
                                    >
                                      {this.state.distributors.map((option) => (
                                        <MenuItem
                                          key={option.id}
                                          value={option.id}
                                        >
                                          {option.name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </div>
                                </form>
                              </Col>
                              <Col xs="4">
                                <form
                                  className={useStyles.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <div>
                                    <TextField
                                      id="filled-select-currency-native"
                                      select
                                      required
                                      style={{
                                        width: "100%",
                                      }}
                                      label="Category"
                                      value={this.state.category}
                                      onChange={this.handleChangeCategory}
                                    >
                                      {this.state.categories.map((option) => (
                                        <MenuItem
                                          key={option.id}
                                          value={option.id}
                                        >
                                          {option.name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </div>
                                </form>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="4">
                                <form
                                  className={useStyles.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <div>
                                    <TextField
                                      id="filled-se"
                                      select
                                      required
                                      style={{ width: "100%" }}
                                      label="bay"
                                      value={this.state.bay}
                                      onChange={this.handleChangeReol}
                                      SelectProps={{
                                        native: false,
                                      }}
                                    >
                                      {this.state.bayList.map((uni) => (
                                        <MenuItem
                                          key={uni}
                                          id={uni}
                                          value={uni}
                                          name={uni}
                                        >
                                          {uni}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </div>
                                </form>
                              </Col>
                              <Col xs="4">
                                <form
                                  className={useStyles.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <div>
                                    <TextField
                                      id="shelf"
                                      label="shelf"
                                      onChange={() => {
                                        this.inputValueprintById(
                                          "shelf",
                                          "shelf"
                                        );
                                      }}
                                    />
                                  </div>
                                </form>
                              </Col>
                              <Col xs="4">
                                <form
                                  className={useStyles.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <div>
                                    <TextField
                                      id="filled-select-currency-native"
                                      select
                                      style={{
                                        width: "100%",
                                      }}
                                      label="Container"
                                      value={this.state.container}
                                      onChange={this.handleChangeContainer}
                                    >
                                      {this.state.allContainers.map(
                                        (option) => (
                                          <MenuItem
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.name +
                                              " " +
                                              option.size +
                                              " " +
                                              option.suffix}
                                          </MenuItem>
                                        )
                                      )}
                                    </TextField>
                                  </div>
                                </form>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="4">
                                <form
                                  className={useStyles.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <div>
                                    <TextField
                                      id="tradevolume"
                                      label="Trade Volume"
                                      onChange={() => {
                                        this.inputValueprintById(
                                          "tradevolume",
                                          "TradeVolume"
                                        );
                                      }}
                                    />
                                  </div>
                                </form>
                              </Col>
                              <Col xs="4">
                                <form
                                  className={useStyles.root}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <div>
                                    <TextField
                                      id="minperbartender"
                                      label="Minimal amount per bartender"
                                      onChange={() => {
                                        this.inputValueprintById(
                                          "minperbartender",
                                          "MinPerBartender"
                                        );
                                      }}
                                    />
                                  </div>
                                </form>
                              </Col>
                              <Col xs="12" style={{ marginTop: "10px" }}>
                                {this.alertMsg()}
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="success"
                          //style={{ backgroundColor: "green", color: "white" }}
                          size="sm"
                          onClick={this.addNewComponent}
                        >
                          Add
                        </Button>
                        <Button color="danger" size="sm" onClick={this.toggle}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <div style={this.state.stuckStyle}>
                      <Fab
                        style={{ backgroundColor: "#d16557", color: "#FFFFFF" }}
                        aria-label="add"
                        onClick={this.toggle}
                      >
                        <AddIcon style={{ fontSize: 40 }} />
                      </Fab>
                    </div>

                    {
                      <ComponentTable
                        dataColumn={this.state.componentColumn}
                        myData={this.state.components}
                        grouping={grouping}
                        defaultExpandedGroups={this.state.defaultExpandedGroups}
                        test={"test"}
                      />
                    }
                  </CardBody>
                  <CardFooter>
                    <Stats>
                      {[
                        {
                          i: "now-ui-icons arrows-1_refresh-69",
                          t: "Just Updated",
                        },
                      ]}
                    </Stats>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: 300, marginBottom: 400 }}>
          <div className="sweet-loading" style={{ marginLeft: 600 }}>
            <h5>Loading All Components</h5>
            <PacmanLoader sizeUnit={"px"} size={50} color={"#d16557"} />
          </div>
        </div>
      );
    }
  }
}
