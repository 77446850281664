import React, { useState } from "react";
import { TableCell } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import { Row, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import types from "../../../redux/actionTypes";
import apiFacade from "../../../auth/apiFacade.js";

export default function JobAssignmentCell(props) {
  const dispatch = useDispatch();
  const [changeIcon, setChangeIcon] = useState(props.row.jobAssignmentStatusId);
  const [returnValue, setReturnValue] = useState(props.row.return);
  const { statusChangeArr } = useSelector((state) => state.assignment);
  const { statusBool } = useSelector((state) => state.assignment);
  let assignment = JSON.parse(localStorage.getItem("assignment"));
  let user = JSON.parse(localStorage.getItem("user"));

  let statusCompId = statusChangeArr.find(
    (f) => f.componentId === props.row.componentId
  );

  const updateComponent = (componentId, JobAssignmentStatusId) => {
    let pakkerPackingList = {
      BookingId: assignment.jobId,
      ComponentId: componentId,
      JobAssignmentId: assignment.assignmentId,
      JobAssignmentStatusId: JobAssignmentStatusId,
      PakkerId: user.packer_Id,
      managementId: assignment.managementId,
    };
    apiFacade.putDataWithJson(
      "https://pakkemodelapi.azurewebsites.net/api/JobManagement/updatepackinglistreturn/",
      pakkerPackingList
    );
  };

  const updatereturnwares = (componentId, JobAssignmentStatusId, amount) => {
    let pakkerPackingList = {
      BookingId: assignment.jobId,
      ComponentId: componentId,
      JobAssignmentId: assignment.assignmentId,
      JobAssignmentStatusId: JobAssignmentStatusId,
      PakkerId: user.packer_Id,
      managementId: assignment.managementId,
      returnAmount: amount,
    };
    apiFacade.putDataWithJson(
      "https://pakkemodelapi.azurewebsites.net/api/JobManagement/updatereturnwares/",
      pakkerPackingList
    );
  };

  if (props.column.name === "name") {
    return <TableCell style={{ height: 100 }}>{props.row.name}</TableCell>;
  }
  if (props.column.name === "amount") {
    return <TableCell style={{ height: 100 }}>{props.row.amount}</TableCell>;
  }
  if (props.column.name === "unit") {
    return <TableCell style={{ height: 100 }}>{props.row.unit}</TableCell>;
  }

  if (props.column.name === "status") {
    if (assignment.assignmentId === 5) {
      if (changeIcon === 6) {
        return (
          <TableCell style={{ height: 100 }}>
            <Row>
              <Col>
                {" "}
                <TextField
                  type="number"
                  label="Amount"
                  id="standard-size-normal"
                  onClick={() => {
                    setReturnValue("");
                  }}
                  value={returnValue}
                  onChange={(e) => {
                    setReturnValue(e.target.value);
                  }}
                  onBlur={() => {
                    if (returnValue !== props.row.return && returnValue >= 0) {
                      updatereturnwares(props.row.componentId, 6, returnValue);
                    } else {
                      setReturnValue(props.row.return);
                    }
                  }}
                />
              </Col>
              <Col>
                <IconButton
                  onClick={() => {
                    setChangeIcon(2);
                    updatereturnwares(props.row.componentId, 2, returnValue);
                    dispatch({
                      type: types.assignment.changeStatus,
                      payload: {
                        componentId: props.row.componentId,
                        status: "In progress",
                      },
                    });
                    dispatch({
                      type: types.assignment.statusBool,
                      payload: !statusBool,
                    });
                  }}
                >
                  <span style={{ color: "green", fontSize: "80%" }}>
                    {statusCompId.status}
                  </span>
                </IconButton>
              </Col>
            </Row>
          </TableCell>
        );
      }

      if (changeIcon === 2) {
        return (
          <TableCell style={{ height: 100 }}>
            <Row>
              <Col xs="6">
                {" "}
                <TextField
                  type="number"
                  label="Amount"
                  id="standard-size-normal"
                  onClick={() => {
                    setReturnValue("");
                  }}
                  value={returnValue}
                  onChange={(e) => {
                    setReturnValue(e.target.value);
                  }}
                  onBlur={() => {
                    if (returnValue > 0) {
                      setChangeIcon(6);
                      updatereturnwares(props.row.componentId, 6, returnValue);
                      dispatch({
                        type: types.assignment.changeStatus,
                        payload: {
                          componentId: props.row.componentId,
                          status: "Done",
                        },
                      });
                      dispatch({
                        type: types.assignment.statusBool,
                        payload: !statusBool,
                      });
                    } else {
                      setReturnValue(props.row.return);
                    }
                  }}
                />
              </Col>
              <Col xs="6">
                <IconButton
                  onClick={() => {
                    setChangeIcon(6);
                    updatereturnwares(props.row.componentId, 6, returnValue);
                    dispatch({
                      type: types.assignment.changeStatus,
                      payload: {
                        componentId: props.row.componentId,
                        status: "Done",
                      },
                    });
                    dispatch({
                      type: types.assignment.statusBool,
                      payload: !statusBool,
                    });
                  }}
                >
                  <span style={{ color: "#BD7F6F", fontSize: "80%" }}>
                    {statusCompId.status}
                  </span>
                </IconButton>
              </Col>
            </Row>
          </TableCell>
        );
      }
    } else {
      if (changeIcon === 4) {
        return (
          <TableCell style={{ height: 100 }}>
            <IconButton
              onClick={() => {
                setChangeIcon(3);
                //iconChanger();
                updateComponent(props.row.componentId, 3);
                dispatch({
                  type: types.assignment.changeStatus,
                  payload: {
                    componentId: props.row.componentId,
                    status: "Missing stock",
                  },
                });
                dispatch({
                  type: types.assignment.statusBool,
                  payload: !statusBool,
                });
              }}
            >
              <span style={{ color: "green", fontSize: "80%" }}>
                {statusCompId.status}
              </span>
            </IconButton>
          </TableCell>
        );
      }
      if (changeIcon === 3) {
        return (
          <TableCell style={{ height: 100 }}>
            <IconButton
              onClick={() => {
                setChangeIcon(2);
                //iconChanger();
                updateComponent(props.row.componentId, 2);

                dispatch({
                  type: types.assignment.changeStatus,
                  payload: {
                    componentId: props.row.componentId,
                    status: "In progress",
                  },
                });
                dispatch({
                  type: types.assignment.statusBool,
                  payload: !statusBool,
                });
              }}
            >
              <span style={{ color: "red", fontSize: "80%" }}>
                {statusCompId.status}
              </span>
            </IconButton>
          </TableCell>
        );
      }
      if (changeIcon === 2) {
        return (
          <TableCell style={{ height: 100 }}>
            <IconButton
              onClick={() => {
                setChangeIcon(4);
                //iconChanger();
                updateComponent(props.row.componentId, 4);
                dispatch({
                  type: types.assignment.changeStatus,
                  payload: {
                    componentId: props.row.componentId,
                    status: "Packed",
                  },
                });
                dispatch({
                  type: types.assignment.statusBool,
                  payload: !statusBool,
                });
              }}
            >
              {/* <span style={{ color: "red", fontSize: "80%" }}>
              {statusCompId.status}
            </span> */}
              <span style={{ color: "#BD7F6F", fontSize: "80%" }}>
                {statusCompId.status}
              </span>
            </IconButton>
          </TableCell>
        );
      }
    }
  }
  return <TableCell>No data</TableCell>;
}
