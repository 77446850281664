import types from "../actionTypes";

const initialState = {
  updateBookingByDate: "",
  updateBookingById: false,
  bookingId: "",
  redirectBoo: true,
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.booking.updateBookingById:
      return {
        ...state,
        updateBookingById: action.payload,
      };
    case types.booking.updateBookingByDate:
      return {
        ...state,
        updateBookingByDate: action.payload,
      };
    case types.booking.bookingId:
      return {
        ...state,
        bookingId: action.payload,
      };
      case types.booking.redirectBoo:
      return {
        ...state,
        redirectBoo: action.payload,
      };
    default:
      return state;
  }
};

export default bookingReducer;
