function ScreenSize(w, h) {
  const { width } = getWindowDimensions(w, h);
    const mobile = 1;
    const tablet = 2;
    const desktop = 3;

  if (width < 670) {
    return mobile;
  } else if (width < 1025 && width > 669) {
    return tablet;
  } else if (width > 1024){
    return desktop;
  }
}

function getWindowDimensions(width, height) {
  const ratio = (width / height) * 100;
  return { width, height, ratio };
}

export { ScreenSize };
