import React, { Component } from "react";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import { Card, CardBody, CardHeader, Row, Col, CardTitle } from "reactstrap";
import { AppBar, Typography } from "@material-ui/core/";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Samplingtypes from "./SamplingFun/Samplingtypes.js";
class Sampling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }

  handleChangeTab = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle style={{ marginBottom: "10px" }}>
                    Sampling Settings
                  </CardTitle>
                  <AppBar
                    position="static"
                    style={{
                      backgroundColor: "#d16557",
                      borderRadius: "5px"
                      //width: 350
                    }}
                  >
                    <Tabs
                      value={this.state.value}
                      onChange={this.handleChangeTab}
                      variant="scrollable"
                      scrollButtons="auto"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#ffffff",
                        },
                      }}
                    >
                      <Tab label="Sampling types" {...a11yProps(0)} />
                    </Tabs>
                  </AppBar>
                </CardHeader>
                <CardBody>
                  <TabPanel value={this.state.value} index={0}>
                    <Samplingtypes />
                  </TabPanel>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default Sampling;

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <div p={3}>{children}</div>
    </Typography>
  );
}
