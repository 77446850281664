import React, { Component } from "react";
import PanelHeader from "../../components/PanelHeader/PanelHeader.js";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";
import { AppBar, Typography } from "@material-ui/core/";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import apiFacade from "../../auth/apiFacade";
import Paper from "@material-ui/core/Paper";
import parse from "html-react-parser";
import PackingTable from "./PackingTable";
import PrintMenu from "./PrintMenu/PrintMenu";
import BarLoader from "react-spinners/BarLoader";
var samplingStyle = {
  bold: {
    fontWeight: "bold"
  },
  paperShadow: {
    boxShadow: "1px 1px 5px 6px #ccc"
  },
  textColor: {
    color: "#3787C6"
  },
  stuck: {
    position: "fixed",
    bottom: "50px",
    marginLeft: "120px"
  }
};
class Sampling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      samplingInfo: null,
      outletWithoutAddress: "",
      dag1start: "",
      dag1end: "",
      dag2start: "",
      dag2end: "",
      dag3start: null,
      dag3end: null,
      dag4start: null,
      dag4end: null,
      packinglist: null
    };
  }

  componentDidMount() {
    apiFacade.getData("/samplings/" + this.props.match.params.id).then(data => {
      this.setState({ samplingInfo: data });
      if (this.state.samplingInfo != null) {
        apiFacade
          .getData("/samplings/getpackinglist/" + data.item_Id)
          .then(packingData => {
            this.setState({ packinglist: packingData });
          });
      }
    });
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { samplingInfo } = this.state;
    var outletNameArr = "";
    var dateLength = 0;

    var dag1start = "";
    var dag1end = "";
    var dag1date = "";

    var dag2start = "";
    var dag2end = "";
    var dag2date = "";

    var dag3start = "";
    var dag3end = "";
    var dag3date = "";

    var dag4start = "";
    var dag4end = "";
    var dag4date = "";
    const moment = require("moment");
    if (this.state.samplingInfo != null) {
      dateLength = samplingInfo.samplingDates.length;
      var info = this.state.samplingInfo.outlet;
     if (info != null) {
        outletNameArr = info.split(",");

      if (outletNameArr.length <= 2) {
        outletNameArr = outletNameArr[0].split("-");
      }
     }

      if (dateLength === 1) {
        dag1start = moment(
          `${samplingInfo.samplingDates[0].start}`,
          "YYYY/MM/DD HH:mm"
        );
        dag1end = moment(
          `${samplingInfo.samplingDates[0].end}`,
          "YYYY/MM/DD HH:mm"
        );
        dag1date =
          dag1start.format("DD/MM/YYYY HH:mm") +
          " - " +
          dag1end.format("HH:mm");
      }
      if (dateLength === 2) {
        dag1start = moment(
          `${samplingInfo.samplingDates[0].start}`,
          "YYYY/MM/DD HH:mm"
        );
        dag1end = moment(
          `${samplingInfo.samplingDates[0].end}`,
          "YYYY/MM/DD HH:mm"
        );
        dag1date =
          dag1start.format("DD/MM/YYYY HH:mm") +
          " - " +
          dag1end.format("HH:mm");
        dag2start = moment(
          `${samplingInfo.samplingDates[1].start}`,
          "YYYY/MM/DD HH:mm"
        );
        dag2end = moment(
          `${samplingInfo.samplingDates[1].end}`,
          "YYYY/MM/DD HH:mm"
        );
        dag2date =
          dag2start.format("DD/MM/YYYY HH:mm") +
          " - " +
          dag2end.format("HH:mm");
      }
      if (dateLength === 3) {
        dag1start = moment(
          `${samplingInfo.samplingDates[0].start}`,
          "YYYY/MM/DD HH:mm"
        );
        dag1end = moment(
          `${samplingInfo.samplingDates[0].end}`,
          "YYYY/MM/DD HH:mm"
        );
        dag1date =
          dag1start.format("DD/MM/YYYY HH:mm") +
          " - " +
          dag1end.format("HH:mm");
        dag2start = moment(
          `${samplingInfo.samplingDates[1].start}`,
          "YYYY/MM/DD HH:mm"
        );
        dag2end = moment(
          `${samplingInfo.samplingDates[1].end}`,
          "YYYY/MM/DD HH:mm"
        );
        dag2date =
          dag2start.format("DD/MM/YYYY HH:mm") +
          " - " +
          dag2end.format("HH:mm");
        dag3start = moment(
          `${samplingInfo.samplingDates[2].start}`,
          "YYYY/MM/DD HH:mm"
        );
        dag3end = moment(
          `${samplingInfo.samplingDates[2].end}`,
          "YYYY/MM/DD HH:mm"
        );
        dag3date =
          dag3start.format("DD/MM/YYYY HH:mm") +
          " - " +
          dag3end.format("HH:mm");
      }
      if (dateLength === 4) {
        dag1start = moment(
          `${samplingInfo.samplingDates[0].start}`,
          "YYYY/MM/DD HH:mm"
        );
        dag1end = moment(
          `${samplingInfo.samplingDates[0].end}`,
          "YYYY/MM/DD HH:mm"
        );
        dag1date =
          dag1start.format("DD/MM/YYYY HH:mm") +
          " - " +
          dag1end.format("HH:mm");
        dag2start = moment(
          `${samplingInfo.samplingDates[1].start}`,
          "YYYY/MM/DD HH:mm"
        );
        dag2end = moment(
          `${samplingInfo.samplingDates[1].end}`,
          "YYYY/MM/DD HH:mm"
        );
        dag2date =
          dag2start.format("DD/MM/YYYY HH:mm") +
          " - " +
          dag2end.format("HH:mm");
        dag3start = moment(
          `${samplingInfo.samplingDates[2].start}`,
          "YYYY/MM/DD HH:mm"
        );
        dag3end = moment(
          `${samplingInfo.samplingDates[2].end}`,
          "YYYY/MM/DD HH:mm"
        );
        dag3date =
          dag3start.format("DD/MM/YYYY HH:mm") +
          " - " +
          dag3end.format("HH:mm");
        dag4start = moment(
          `${samplingInfo.samplingDates[3].start}`,
          "YYYY/MM/DD HH:mm"
        );
        dag4end = moment(
          `${samplingInfo.samplingDates[3].end}`,
          "YYYY/MM/DD HH:mm"
        );
        dag4date =
          dag4start.format("DD/MM/YYYY HH:mm") +
          " - " +
          dag4end.format("HH:mm");
      }
    }

    if (this.state.samplingInfo != null && this.state.packinglist != null) {
      return (
        <div>
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col>
                <Card className="card-chart">
                  <CardHeader>
                    <CardTitle tag="h4">Sampling Info</CardTitle>
                    <PrintMenu
                      history={this.props.history}
                      match={this.props.match}
                    />
                  </CardHeader>
                  <CardBody>
                    <Paper style={samplingStyle.paperShadow}>
                      <Row style={{ marginLeft: "5px" }}>
                        <Col xs={5} style={{ marginTop: "10px" }}>
                          <div>
                            <span style={samplingStyle.bold}>
                              Sampling Id:{" "}
                            </span>
                            {samplingInfo.sampling_Id}
                          </div>
                          <div>
                            <span style={samplingStyle.bold}>Type popup:</span>{" "}
                            {samplingInfo.typePopup}
                          </div>
                          <div>
                            <span style={samplingStyle.bold}>Outlet: </span>{" "}
                            {outletNameArr[0]}
                          </div>
                          <div>
                            <span style={samplingStyle.bold}>Samples: </span>{" "}
                            {samplingInfo.samples}
                          </div>
                          <div>
                            <span style={samplingStyle.bold}>Bartenders: </span>{" "}
                            {samplingInfo.antalBartendere}
                          </div>
                        </Col>
                        <Col xs={7} style={{ marginTop: "10px" }}>
                          {" "}
                          <div>
                            <span style={samplingStyle.bold}>Kampagne: </span>{" "}
                            {samplingInfo.kampagne}
                          </div>
                          <div>
                            <span style={samplingStyle.bold}>Status: </span>{" "}
                            {samplingInfo.status}
                          </div>
                          <div>
                            <span style={samplingStyle.bold}>Adresse: </span>{" "}
                            {samplingInfo.adresse}
                          </div>
                          <div>
                            <span style={samplingStyle.bold}>
                              Kontakt person:{" "}
                            </span>{" "}
                            {samplingInfo.kontaktPersonOutlet}
                          </div>
                          <div>
                            <span style={samplingStyle.bold}>Region: </span>{" "}
                            {samplingInfo.region}
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "5px", marginLeft: "5px" }}>
                        <Col xs={2}>
                          <span style={samplingStyle.bold}>
                            Beskrivelse af event:{""}
                          </span>
                        </Col>
                        <Col xs={10}>
                          {parse(samplingInfo.beskrivelseAfEvent)}
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "10px", marginLeft: "5px" }}>
                        <Col xs={3}>
                          <span style={samplingStyle.bold}>Dag 1: </span>{" "}
                          {dag1date}
                        </Col>
                        <Col xs={3}>
                          <span style={samplingStyle.bold}>Dag 2: </span>{" "}
                          {dag2date}
                        </Col>
                        <Col xs={3}>
                          <span style={samplingStyle.bold}>Dag 3: </span>{" "}
                          {dag3date}
                        </Col>
                        <Col xs={3}>
                          <span style={samplingStyle.bold}>Dag 4: </span>
                          {dag4date}
                        </Col>
                      </Row>
                    </Paper>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col xs="12" /* md={{ size: 4, offset: 4 }} */>
                        <AppBar
                          position="static"
                          style={{
                            backgroundColor: "#36688D",
                            borderRadius: "5px"
                            //width: 350
                          }}
                        >
                          <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                          >
                            <Tab label="Packing List" {...a11yProps(0)} />
                            <Tab label="Products" {...a11yProps(1)} />
                          </Tabs>
                        </AppBar>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <TabPanel value={this.state.value} index={0}>
                      {
                        <PackingTable
                          id={samplingInfo.category_Id}
                          rows={this.state.packinglist}
                        />
                      }
                      {/*  <PackingTable categoryName={"Products"} newData={this.state.spiritus} colorId={this.state.spiritusColor} /> */}
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                      {/* <PackingTable categoryName={"Packing List"} newData={this.state.barudstyr} colorId={this.state.barudstyrColor} /> */}
                    </TabPanel>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md={{ size: 2, offset: 10 }}>
                <Button
                  color="danger"
                  round
                  style={samplingStyle.stuck}
                  onClick={() => {
                    this.props.history.push(`/samplings`);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </div>
          ;
        </div>
      );
    } else {
      return (
        <div>
          <div
            className="sweet-loading"
            style={{ marginLeft: 400, marginTop: 440 }}
          >
            <BarLoader
              height={15}
              width={300}
              sizeUnit={"px"}
              size={500}
              color={"#4C8FBF"}
            />
          </div>
        </div>
      );
    }
  }
}
export default Sampling;

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <div p={3}>{children}</div>
    </Typography>
  );
}
