import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Info from "@material-ui/icons/InfoSharp";
import { withStyles } from "@material-ui/core/styles";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";
import * as GlassesFuncs from "../Func/GlassesFunc";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: "none",
  },
});

const CustomTableCellStyles = {
  style1: {
    //color: "#3787C6",
    fontSize: 12,
    align: "left",
    paddingLeft: 25,
    // borderBottom: "3px solid #2c2c2c",
  },
  style2: {
    //color: "#3787C6",
    fontSize: 12,
    align: "left",
    paddingLeft: 0,
    //borderLeft: "3px solid #2c2c2c",
  },
};

const GlassesCell = (props) => {
  const [modal, setModal] = useState(false);
  const editToggle = () => setModal(!modal);

  if (props.column.name === "id") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>{props.row.id}</TableCell>
    );
  }

  if (props.column.name === "name") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.name}
      </TableCell>
    );
  }

  if (props.column.name === "size") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.size}
      </TableCell>
    );
  }

  if (props.column.name === "unitName") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.unitName}
      </TableCell>
    );
  }

  if (props.column.name === "distributorName") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.distributorName}
      </TableCell>
    );
  }

  if (props.column.name === "categoryName") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.categoryName}
      </TableCell>
    );
  }

  if (props.column.name === "iconlink") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        <img
          src={"https://pakkemodelapi.azurewebsites.net" + props.row.iconlink}
          width="25"
          height="45"
          alt="anImg"
        />
      </TableCell>
    );
  }

  if (props.column.name === "actions") {
    return (
      <React.Fragment>
        <TableCell style={CustomTableCellStyles.style2}>
          <IconButton
            color="primary"
            aria-label="Edit"
            // onClick={() => {
            // 	editToggle();
            // 	setAllComponentsValue();
            // }}
          >
            <EditTwoToneIcon />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="View"
            onClick={() => {
              editToggle();
            }}
          >
            <Info />
          </IconButton>
          <IconButton
            color="secondary"
            aria-label="Delete"
            //onClick={deleteToggle}
          >
            <DeleteForeverTwoToneIcon />
          </IconButton>
        </TableCell>
        {/* from here View modal*/}
        {GlassesFuncs.viewModel(
          modal,
          editToggle,
          props.row.name,
          props.row.iconlink,
          props.row.components,
          props.row
        )}
        {/* from here Edit modal*/}
      </React.Fragment>
    );
  }
  return <TableCell>No Data</TableCell>;
};

export default withStyles(styles)(GlassesCell);
