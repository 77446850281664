import React from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";

export default function PlasticGlassTable(props) {
  const columns = [
    { name: "name", title: "Name" },
    { name: "size", title: "Size" },
    { name: "unit_name", title: "Unit" },
    { name: "category_Name", title: "Category" },
    { name: "distributor_name", title: "Distributor" },
  ];
  const defaultColumnWidths = [
    { columnName: "name", width: 200 },
    { columnName: "size", width: 80 },
    { columnName: "unit_name", width: 100 },
    { columnName: "category_Name", width: 100 },
    { columnName: "distributor_name", width: 150 },
  ];

  if (props.rows.length > 0) {
    return (
      <div>
        <Grid rows={props.rows} columns={columns}>
          <Table />
          <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
          <TableHeaderRow />
        </Grid>
      </div>
    );
  } else {
    return <div>there may be no plastic glass</div>;
  }
}
