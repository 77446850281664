import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "reactstrap";
import apiFacade from "../../../auth/apiFacade";
import * as BarArmHelperFunc from "./BarArmHelperFunc";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import types from "../../../redux/actionTypes";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function BarArrangement() {
  const dispatch = useDispatch();
  const { loadingRegionSelected } = useSelector((state) => state.calculations);
  const { loadingBarArmCal1 } = useSelector((state) => state.calculations);
  const { loadingBarArmCal2 } = useSelector((state) => state.calculations);
  const { barArmCal1 } = useSelector((state) => state.calculations);
  const { barArmCal2 } = useSelector((state) => state.calculations);
  const { updateRegionSelected } = useSelector((state) => state.calculations);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogTypeTwo, setOpenDialogTypeTwo] = useState(false);
  const [dialogForJobtype, setDialogForJobtype] = useState(false);
  const { regionSelected } = useSelector((state) => state.calculations);
  const [handleCheckBox, setHandleCheckBox] = useState(regionSelected);
  const { jobtypes } = useSelector((state) => state.calculations);
  const [handleCheckBoxJobtype, setHandleCheckBoxJobtype] = useState(jobtypes);
  const { jobtypesBool } = useSelector((state) => state.calculations);
  const { readOnly1 } = useSelector((state) => state.calculations);
  const { readOnly2 } = useSelector((state) => state.calculations);
  const { dateFromDb1 } = useSelector((state) => state.calculations);
  const { dateFromDb2 } = useSelector((state) => state.calculations);
  const { calFormBool1 } = useSelector((state) => state.calculations);
  const { calFormBool2 } = useSelector((state) => state.calculations);
  // type1
  const [valuePerson, setValuePerson] = useState(barArmCal1.personer_procent);
  const [value, setValue] = useState(barArmCal1.km_procent);
  const [valueSpild, setValueSpild] = useState(barArmCal1.spild_procent);

  const [km, setKm] = useState(barArmCal1.km);
  const [person, setPerson] = useState(barArmCal1.personer);
  const [startcocktail, setStartcocktail] = useState(barArmCal1.startcocktail);
  const [ekstraCocktails, setEkstraCocktails] = useState(
    barArmCal1.ekstraCocktails
  );
  const [bartender, setBartender] = useState(barArmCal1.bartender);
  const [skaleringfactor, setSkaleringfactor] = useState(
    barArmCal1.skaleringfactor
  );
  // type2
  const [typeTwoValuePerson, setTypeTwoValuePerson] = useState(
    barArmCal2.personer_procent
  );
  const [typeTwoValue, setTypeTwoValue] = useState(barArmCal2.km_procent);
  const [typeTwoValueSpild, setTypeTwoValueSpild] = useState(
    barArmCal2.spild_procent
  );
  const [kmTypeTwo, setKmTypeTwo] = useState(barArmCal2.km);
  const [personTypeTwo, setPersonTypeTwo] = useState(barArmCal2.personer);
  const [startcocktailTypeTwo, setStartcocktailTypeTwo] = useState(
    barArmCal2.startcocktail
  );
  const [ekstraCocktailsTypeTwo, setEkstraCocktailsTypeTwo] = useState(
    barArmCal2.ekstraCocktails
  );
  const [bartenderTypeTwo, setBartenderTypeTwo] = useState(
    barArmCal2.bartender
  );
  const [skaleringfactorTypeTwo, setSkaleringfactorTypeTwo] = useState(
    barArmCal2.skaleringfactor
  );

  useEffect(() => {
    apiFacade.getData("/BookingTypes/getactivebookingtypeDtos").then((data) => {
      dispatch({
        type: types.calculations.jobtypes,
        payload: data,
      });
      setHandleCheckBoxJobtype(data);
    });
  }, [jobtypesBool]);

  useEffect(() => {
    apiFacade.getData("/CalculationBarArrangements/1").then((data) => {
      dispatch({
        type: types.calculations.barArmCal1,
        payload: data,
      });
      dispatch({
        type: types.calculations.loadingBarArmCal1,
        payload: false,
      });
      setValuePerson(data.personer_procent);
      setValue(data.km_procent);
      setValueSpild(data.spild_procent);
      setKm(data.km);
      setPerson(data.personer);
      setStartcocktail(data.startcocktail);
      setEkstraCocktails(data.ekstraCocktails);
      setBartender(data.bartender);
      setSkaleringfactor(data.skaleringfactor);

      if (calFormBool1) {
        document.getElementById("startcocktail").value = data.startcocktail;
        document.getElementById("km").value = data.km;
        document.getElementById("person").value = data.personer;
        document.getElementById("ekstraCocktails").value = data.ekstraCocktails;
        document.getElementById("bartender").value = data.bartender;
        document.getElementById("skaleringfactor").value = data.skaleringfactor;

        dispatch({
          type: types.calculations.calFormBool1,
          payload: false,
        });
      }
    });
  }, [dateFromDb1]);

  useEffect(() => {
    apiFacade.getData("/CalculationBarArrangements/2").then((data) => {
      dispatch({
        type: types.calculations.barArmCal2,
        payload: data,
      });
      dispatch({
        type: types.calculations.loadingBarArmCal2,
        payload: false,
      });
      setTypeTwoValuePerson(data.personer_procent);
      setTypeTwoValue(data.km_procent);
      setTypeTwoValueSpild(data.spild_procent);
      setKmTypeTwo(data.km);
      setPersonTypeTwo(data.personer);
      setStartcocktailTypeTwo(data.startcocktail);
      setEkstraCocktailsTypeTwo(data.ekstraCocktails);
      setBartenderTypeTwo(data.bartender);
      setSkaleringfactorTypeTwo(data.skaleringfactor);

      if (calFormBool2) {
        document.getElementById("startcocktailTypeTwo").value =
          data.startcocktail;
        document.getElementById("kmTypeTwo").value = data.km;
        document.getElementById("personTypeTwo").value = data.personer;
        document.getElementById("ekstraCocktailsTypeTwo").value =
          data.ekstraCocktails;
        document.getElementById("bartenderTypeTwo").value = data.bartender;
        document.getElementById("skaleringfactorTypeTwo").value =
          data.skaleringfactor;

        dispatch({
          type: types.calculations.calFormBool2,
          payload: false,
        });
      }
    });
  }, [dateFromDb2]);

  useEffect(() => {
    apiFacade.getData("/Regions/selectedregion").then((data) => {
      dispatch({
        type: types.calculations.regionSelected,
        payload: data,
      });
      dispatch({
        type: types.calculations.loadingRegionSelected,
        payload: false,
      });
      setHandleCheckBox(data);
    });
  }, [updateRegionSelected]);

  const handleChange = (event) => {
    handleCheckBox.forEach((element) => {
      if (element.region === event.target.name) {
        element.regionSelected = event.target.checked;
        if (event.target.checked) {
          element.calculationType = 1;
        } else {
          element.calculationType = 0;
        }
      }
    });

    setHandleCheckBox(handleCheckBox);
    dispatch({
      type: types.calculations.regionSelected,
      payload: handleCheckBox,
    });
  };

  const handleChangeTypeTwo = (event) => {
    handleCheckBox.forEach((element) => {
      if (element.region === event.target.name) {
        element.regionSelected = event.target.checked;
        if (event.target.checked) {
          element.calculationType = 2;
        } else {
          element.calculationType = 0;
        }
      }
    });
    setHandleCheckBox(handleCheckBox);
    dispatch({
      type: types.calculations.regionSelected,
      payload: handleCheckBox,
    });
  };

  const region = (calculation_type) => {
    const regionCheckBoxes = handleCheckBox.map((data) => {
      let disabled = false;

      if (
        data.calculationType !== calculation_type &&
        data.calculationType !== 0
      ) {
        disabled = true;
      }
      if (calculation_type === 1) {
        return (
          <FormControlLabel
            key={data}
            control={
              <Checkbox
                checked={data.regionSelected}
                disabled={disabled}
                onChange={handleChange}
                name={data.region}
                style={{color: "#d16557"}}
              />
            }
            label={data.region}
          />
        );
      } else if (calculation_type === 2) {
        return (
          <FormControlLabel
            key={data}
            control={
              <Checkbox
                key={data.regionId}
                checked={data.regionSelected}
                disabled={disabled}
                onChange={handleChangeTypeTwo}
                name={data.region}
                style={{color: "#d16557"}}
              />
            }
            label={data.region}
          />
        );
      }
    });
    return regionCheckBoxes;
  };

  const handleKmChange = (event) => {
    if (parseFloat(event.target.value) <= 0) {
      setTimeout(() => {
        setValue(0);
        dispatch({
          type: types.calculations.barArmCal1,
          payload: {
            ...barArmCal1,
            km_procent: 0,
            km: 0,
          },
        });
      }, 500);
    }
    dispatch({
      type: types.calculations.barArmCal1,
      payload: {
        ...barArmCal1,
        km: parseFloat(event.target.value),
      },
    });
  };

  const handleKmChangeTypeTwo = (event) => {
    if (parseFloat(event.target.value) <= 0) {
      setTimeout(() => {
        setTypeTwoValue(0);
        dispatch({
          type: types.calculations.barArmCal2,
          payload: {
            ...barArmCal2,
            km_procent: 0,
            km: 0,
          },
        });
      }, 500);
    }
    dispatch({
      type: types.calculations.barArmCal2,
      payload: {
        ...barArmCal2,
        km: parseFloat(event.target.value),
      },
    });
  };

  const handlePersonChange = (event) => {
    if (parseFloat(event.target.value) <= 0) {
      setTimeout(() => {
        setValuePerson(0);
        dispatch({
          type: types.calculations.barArmCal1,
          payload: {
            ...barArmCal1,
            personer_procent: 0,
            personer: 0,
          },
        });
      }, 500);
    }
    dispatch({
      type: types.calculations.barArmCal1,
      payload: {
        ...barArmCal1,
        personer: parseFloat(event.target.value),
      },
    });
  };

  const handlePersonChangeTypeTwo = (event) => {
    if (parseFloat(event.target.value) <= 0) {
      setTimeout(() => {
        setTypeTwoValuePerson(0);
        dispatch({
          type: types.calculations.barArmCal2,
          payload: {
            ...barArmCal2,
            personer_procent: 0,
            personer: 0,
          },
        });
      }, 500);
    }
    dispatch({
      type: types.calculations.barArmCal2,
      payload: {
        ...barArmCal2,
        personer: parseFloat(event.target.value),
      },
    });
  };

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    dispatch({
      type: types.calculations.barArmCal1,
      payload: { ...barArmCal1, km_procent: newValue },
    });
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
    dispatch({
      type: types.calculations.barArmCal1,
      payload: {
        ...barArmCal1,
        km_procent: parseFloat(event.target.value),
      },
    });
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 30) {
      setValue(30);
    }
  };

  const handleSliderChangePerson = (event, newValue) => {
    setValuePerson(newValue);
    dispatch({
      type: types.calculations.barArmCal1,
      payload: { ...barArmCal1, personer_procent: newValue },
    });
  };

  const handleInputChangePerson = (event) => {
    setValuePerson(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlurPerson = () => {
    if (valuePerson < 0) {
      setValuePerson(0);
    } else if (valuePerson > 30) {
      setValuePerson(30);
    }
  };

  const handleSliderChangeSpild = (event, newValue) => {
    setValueSpild(newValue);
    dispatch({
      type: types.calculations.barArmCal1,
      payload: {
        ...barArmCal1,
        spild_procent: parseFloat(newValue),
      },
    });
  };

  const handleInputChangeSpild = (event) => {
    setValueSpild(event.target.value === "" ? "" : Number(event.target.value));
    dispatch({
      type: types.calculations.barArmCal1,
      payload: {
        ...barArmCal1,
        spild_procent: parseFloat(event.target.value),
      },
    });
  };

  const handleBlurSpild = () => {
    if (valueSpild < 0) {
      setValueSpild(0);
    } else if (valueSpild > 5) {
      setValueSpild(5);
    }
  };

  const handleSliderChangeTypeTwo = (event, newValue) => {
    setTypeTwoValue(newValue);
    dispatch({
      type: types.calculations.barArmCal2,
      payload: {
        ...barArmCal2,
        km_procent: newValue,
      },
    });
  };

  const handleInputChangeTypeTwo = (event) => {
    setTypeTwoValue(
      event.target.value === "" ? "" : Number(event.target.value)
    );
    dispatch({
      type: types.calculations.barArmCal2,
      payload: {
        ...barArmCal2,
        km_procent: parseFloat(event.target.value),
      },
    });
  };

  const handleBlurTypeTwo = () => {
    if (typeTwoValue < 0) {
      setTypeTwoValue(0);
    } else if (typeTwoValue > 15) {
      setTypeTwoValue(15);
    }
  };

  const handleSliderChangeTypeTwoPerson = (event, newValue) => {
    setTypeTwoValuePerson(newValue);
    dispatch({
      type: types.calculations.barArmCal2,
      payload: { ...barArmCal2, personer_procent: newValue },
    });
  };

  const handleInputChangeTypeTwoPerson = (event) => {
    setTypeTwoValuePerson(
      event.target.value === "" ? "" : Number(event.target.value)
    );
    dispatch({
      type: types.calculations.barArmCal2,
      payload: {
        ...barArmCal2,
        personer_procent: parseFloat(event.target.value),
      },
    });
  };

  const handleBlurTypeTwoPerson = () => {
    if (typeTwoValuePerson < 0) {
      setTypeTwoValuePerson(0);
    } else if (typeTwoValuePerson > 15) {
      setTypeTwoValuePerson(15);
    }
  };

  const handleSliderChangeTypeTwoSpild = (event, newValue) => {
    setTypeTwoValueSpild(newValue);
    dispatch({
      type: types.calculations.barArmCal2,
      payload: {
        ...barArmCal2,
        spild_procent: newValue,
      },
    });
  };

  const handleInputChangeTypeTwoSpild = (event) => {
    setTypeTwoValueSpild(
      event.target.value === "" ? "" : Number(event.target.value)
    );
    dispatch({
      type: types.calculations.barArmCal2,
      payload: {
        ...barArmCal2,
        spild_procent: parseFloat(event.target.value),
      },
    });
  };

  const handleBlurTypeTwoSpild = () => {
    if (typeTwoValueSpild < 0) {
      setTypeTwoValueSpild(0);
    } else if (typeTwoValueSpild > 5) {
      setTypeTwoValueSpild(5);
    }
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    dispatch({
      type: types.calculations.updateRegionSelected,
      payload: !updateRegionSelected,
    });
    setOpenDialog(false);
  };

  const handleCloseAndSave = () => {
    apiFacade
      .postDataWorking("/Regions/updateregionselected", regionSelected)
      .then((data) => {});
    setOpenDialog(false);
  };

  const handleClickOpenTypeTwo = () => {
    setOpenDialogTypeTwo(true);
  };

  const handleCloseTypeTwo = () => {
    dispatch({
      type: types.calculations.updateRegionSelected,
      payload: !updateRegionSelected,
    });
    setOpenDialogTypeTwo(false);
  };

  const handleCloseAndSaveTypeTwo = () => {
    apiFacade
      .postDataWorking("/Regions/updateregionselected", regionSelected)
      .then((data) => {});
    setOpenDialogTypeTwo(false);
  };

  const handleReadOnly = (type, changeTo) => {
    if (type === 1) {
      dispatch({
        type: types.calculations.readOnly1,
        payload: changeTo,
      });
    } else if (type === 2) {
      dispatch({
        type: types.calculations.readOnly2,
        payload: changeTo,
      });
    }
  };

  const handleChangeForJobtype = (event) => {
    handleCheckBoxJobtype.forEach((element) => {
      if (element.name === event.target.name) {
        element.bookingTypeSelected = event.target.checked;
        if (event.target.checked) {
          element.calculationId = 1;
        } else {
          element.calculationId = 0;
        }
      }
    });

    setHandleCheckBoxJobtype(handleCheckBoxJobtype);
    dispatch({
      type: types.calculations.jobtypes,
      payload: handleCheckBoxJobtype,
    });
  };

  const checkboxJobtypes = () => {
    const jobtypeCheckBoxes = handleCheckBoxJobtype.map((data) => {
      let disabled = false;

      if (data.calculationId !== 1 && data.calculationId !== 0) {
        disabled = true;
      }
      return (
        <Col xs="4" key={data}>
          <FormControlLabel
            control={
              <Checkbox
                key={data}
                checked={data.bookingTypeSelected}
                disabled={disabled}
                onChange={handleChangeForJobtype}
                name={data.name}
                style={{color: "#d16557"}}
              />
            }
            label={data.name}
          />
        </Col>
      );
    });
    return jobtypeCheckBoxes;
  };

  const handleOpenForJobtype = () => {
    setDialogForJobtype(true);
  };

  const handleCloseForJobtypes = () => {
    setDialogForJobtype(false);
    dispatch({
      type: types.calculations.jobtypesBool,
      payload: !jobtypesBool,
    });
  };

  const handleCloseAndSaveForJobtype = () => {
    apiFacade
      .postDataWorking("/BookingTypes/updatebookingtypeselected", jobtypes)
      .then((data) => {});
    setDialogForJobtype(false);
  };

  function cardNames() {
    let listOfNames = [];

    if (jobtypes.length > 0) {
      const names = jobtypes.map((data) => {
        if (data.calculationId === 1) {
          listOfNames.push(data.name);
        }
      });
    }

    return (
      <div style={{ fontSize: 18, fontWeight: 600 }}>
        {listOfNames.join(", ")}
      </div>
    );
  }

  if (
    loadingBarArmCal1 !== true &&
    loadingBarArmCal2 !== true &&
    loadingRegionSelected !== true
  ) {
    return (
      <div>
        <Row>
          <Col xs={7} md={9}>{cardNames()}</Col>
          <Col
            xs={5} md={3}
            style={{
              textAlign: "right",
              marginBottom: "15px",
            }}
          >
            <Button
              variant="outlined"
              style={{ backgroundColor: "#d1d1d1", color: "#d16557"}}
              onClick={handleOpenForJobtype}
            >
              Add jobtype
            </Button>
            <Dialog
              maxWidth={"md"}
              open={dialogForJobtype}
              onClose={handleCloseForJobtypes}
            >
              <DialogTitle style={{ textAlign: "center" }}>
                Select jobtype
              </DialogTitle>
              <DialogContent>
                <Row>{checkboxJobtypes()}</Row>
              </DialogContent>
              <DialogActions>
                <Button
                  style={{
                    position: "absolute",
                    left: "1%",
                    bottom: "2%",
                    color: "#4CAF50",
                  }}
                  onClick={handleCloseAndSaveForJobtype}
                >
                  Save
                </Button>
                <Button onClick={handleCloseForJobtypes} color="secondary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Col>
          <Col xs={12} md={6}>
            {BarArmHelperFunc.typeOne(
              value,
              valuePerson,
              handleSliderChange,
              handleInputChange,
              handleBlur,
              handleSliderChangePerson,
              handleInputChangePerson,
              handleBlurPerson,
              valueSpild,
              handleSliderChangeSpild,
              handleInputChangeSpild,
              handleBlurSpild,
              handleClickOpen,
              handleClose,
              openDialog,
              region,
              handleCloseAndSave,
              barArmCal1,
              regionSelected,
              readOnly1,
              handleReadOnly,
              handleKmChange,
              handlePersonChange,
              dateFromDb1,
              dispatch,
              km,
              person,
              startcocktail,
              ekstraCocktails,
              bartender,
              skaleringfactor
            )}
          </Col>
          <Col xs={12} md={6}>
            {BarArmHelperFunc.typeTwo(
              typeTwoValue,
              typeTwoValuePerson,
              handleSliderChangeTypeTwo,
              handleInputChangeTypeTwo,
              handleBlurTypeTwo,
              handleSliderChangeTypeTwoPerson,
              handleInputChangeTypeTwoPerson,
              handleBlurTypeTwoPerson,
              typeTwoValueSpild,
              handleSliderChangeTypeTwoSpild,
              handleInputChangeTypeTwoSpild,
              handleBlurTypeTwoSpild,
              handleClickOpenTypeTwo,
              handleCloseTypeTwo,
              openDialogTypeTwo,
              region,
              handleCloseAndSaveTypeTwo,
              barArmCal2,
              regionSelected,
              readOnly2,
              handleReadOnly,
              handleKmChangeTypeTwo,
              handlePersonChangeTypeTwo,
              dateFromDb2,
              dispatch,
              kmTypeTwo,
              personTypeTwo,
              startcocktailTypeTwo,
              ekstraCocktailsTypeTwo,
              bartenderTypeTwo,
              skaleringfactorTypeTwo
            )}
          </Col>
        </Row>
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
}
