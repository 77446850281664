import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import Moment from "react-moment";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: "none",
  },
});

const CustomTableCellStyles = {
  style1: {
    //color: "#3787C6",
    fontSize: 12,
    align: "left",
    paddingLeft: 25,
  },
  style2: {
    //color: "#3787C6",
    fontSize: 12,
    align: "left",
    paddingLeft: 0,
  },
};

const tableCell = (props) => {
  if (props.column.name === "id") {
    if (
      props.row.type === "Den virtuelle ginrejse" ||
      props.row.type === "Virtuelt cocktailkursus" ||
      props.row.type === "Den virtuelle ølsmagning"
    ) {
      return (
        <TableCell style={CustomTableCellStyles.style1}>
          <a
            href={
              "https://podio.com/fridaygroup/intranet/apps/virtuelle-jobs/items/" +
              props.row.id
            }
          >
            {props.row.id}
          </a>
        </TableCell>
      );
    } else {
      return (
        <TableCell style={CustomTableCellStyles.style1}>
          <a
            href={
              "https://podio.com/fridaygroup/intranet/apps/bookinger/items/" +
              props.row.id
            }
          >
            {props.row.id}
          </a>
        </TableCell>
      );
    }
  }

  if (props.column.name === "type") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.type}
      </TableCell>
    );
  }

  if (props.column.name === "date") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        <Moment format="YYYY/MM/DD HH:mm">{props.row.date}</Moment>
      </TableCell>
    );
  }

  if (props.column.name === "errorMsg") {
    return (
      <TableCell style={CustomTableCellStyles.style2}>
        {props.row.errorMsg}
      </TableCell>
    );
  }

  return <TableCell>No Data</TableCell>;
};

export default withStyles(styles)(tableCell);
