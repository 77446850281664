import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { NavLink } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Info from "@material-ui/icons/InfoSharp";
import { withStyles } from "@material-ui/core/styles";
import Moment from "react-moment";

const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: "none"
  }
});

const CustomTableCellStyles = {
  style1: {
    //color: "#3787C6",
    fontSize: 12,
    align: "left",
    paddingLeft: 10
  }
};

const SamplingCell = props => {
  const { classes } = props;
  if (props.column.name === "Id") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        <a
          href={
            "https://podio.com/fridaygroup/intranet/apps/samplings-pop-ups/items/" +
            props.row.Id
          }
        >
          {props.row.Id}
        </a>
      </TableCell>
    );
  }
  if (props.column.name === "date") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        <Moment format="YYYY/MM/DD HH:mm">{props.row.date}</Moment>
      </TableCell>
    );
  }
  if (props.column.name === "outlet") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        {props.row.outlet}
      </TableCell>
    );
  }

  if (props.column.name === "kampagne") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        {props.row.kampagne}
      </TableCell>
    );
  }
  if (props.column.name === "sampling_Id") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        {props.row.sampling_Id}
      </TableCell>
    );
  }
  if (props.row.samples) {
    if (props.column.name === "samples") {
      return (
        <TableCell style={CustomTableCellStyles.style1}>
          {props.row.samples}
        </TableCell>
      );
    }
  } else {
    if (props.column.name === "samples") {
      return (
        <TableCell style={CustomTableCellStyles.style1}>
          <a
            href={
              "https://podio.com/fridaygroup/intranet/apps/samplings-pop-ups/items/" +
              props.row.Id
            }
          >
            <div style={{ color: "red" }}>Ikke angivet i podio</div>
          </a>
        </TableCell>
      );
    }
  }
  if (props.column.name === "date1") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        <Moment format="YYYY/MM/DD HH:mm">{props.row.date1Start}</Moment>
      </TableCell>
    );
  }
  if (props.row.date2Start) {
    if (props.column.name === "date2") {
      return (
        <TableCell style={CustomTableCellStyles.style1}>
          <Moment format="YYYY/MM/DD HH:mm">{props.row.date2Start}</Moment>
        </TableCell>
      );
    }
  } else {
    if (props.column.name === "date2") {
      return (
        <TableCell style={CustomTableCellStyles.style1}>
          <div></div>
        </TableCell>
      );
    }
  }
  if (props.row.date3Start) {
    if (props.column.name === "date3") {
      return (
        <TableCell style={CustomTableCellStyles.style1}>
          <Moment format="YYYY/MM/DD HH:mm">{props.row.date3Start}</Moment>
        </TableCell>
      );
    }
  } else {
    if (props.column.name === "date3") {
      return (
        <TableCell style={CustomTableCellStyles.style1}>
          <div></div>
        </TableCell>
      );
    }
  }
  if (props.row.date4Start) {
    if (props.column.name === "date4") {
      return (
        <TableCell style={CustomTableCellStyles.style1}>
          <Moment format="YYYY/MM/DD HH:mm">{props.row.date4Start}</Moment>
        </TableCell>
      );
    }
  } else {
    if (props.column.name === "date4") {
      return (
        <TableCell style={CustomTableCellStyles.style1}>
          <div></div>
        </TableCell>
      );
    }
  }
  /* if (props.column.name === "bartenders") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        {props.row.bartenders}
      </TableCell>
    );
  } */
  if (props.column.name === "view") {
    return (
      <TableCell style={CustomTableCellStyles.style1}>
        <NavLink to={"/samplings/" + props.row.Id}>
          <IconButton
            className={classes.button}
            color="primary"
            aria-label="View more"
          >
            <Info />
          </IconButton>
        </NavLink>
      </TableCell>
    );
  }
  return <TableCell>No Data</TableCell>;
};

export default withStyles(styles)(SamplingCell);
