import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import hexToRgb from "pretty-easy-hex-to-rgb";
import BarLoader from "react-spinners/BarLoader";
import { ScreenSize } from "helper/ScreenSize";

// under arbejde, virker ikke lige nu da vi skal  adskille jylland fra og printe anderledes ud

const PackingvckFunc = (props) => {
  const [pdfWidth, setPdfWidth] = useState(700);
  const [pdfHeight, setPdfHeight] = useState(900);

  useEffect(() => {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      setPdfWidth(300);
      setPdfHeight(350);
    } else if (screenSize === 2) {
      setPdfWidth(675);
      setPdfHeight(800);
    }
  });

  if (!props.isLoading) {
    let contentIframe = null;
    const {
      headRows,
      bodyRows,
      bookingInfo,
      filteredData,
      bookingNr,
      region,
      headRowsFynJylland,
      headRowsProduct,
      headRowsvck,
      myAllData,
    } = props;
    const moment = require("moment");
    var doc = new jsPDF("p", "pt", "a4", true);
    bookingInfo.forEach((element, i) => {
      var pagenr = doc.internal.getCurrentPageInfo();
      let bartender = 1;
      if (element.bartender >= 2) {
        bartender = element.bartender / 2;
      }
      var date = moment(`${element.date}`, "YYYY/MM/DD");
      var startTime = moment(`${element.date}`);
      var endTime = moment(`${element.dateEnd}`);
      doc.setFontSize(18);
      doc.setFont("times");
      doc.setFontType("italic");
      doc.text("Job ID " + element.bookingId, 14, 40);
      if (element.barcode != null && element.barcode != "") {
        doc.addImage(
          "data:image/png;base64," + element.barcode,
          "png",
          14,
          55,
          100,
          40
        );
      }
      doc.text("Date: " + `${date.format("DD/MM/YYYY")}`, 250, 30);
      doc.text(
        `Start: ${startTime.format("HH:mm  ")}End: ${endTime.format("HH:mm")}`,
        250,
        50
      );
      doc.text("Pax Total: " + element.paxtotal, 454, 30);
      doc.text("Bartenders: " + bartender, 454, 50);
      doc.text("Packs: " + element.packs, 454, 70);
      doc.text("Alcohol Free: " + element.alcoholFree, 454, 90);


      //table for total
      doc.autoTable({
        columns: headRowsvck,
        body: filteredData[i],
        startY: 100,
        //startY: doc.lastAutoTable.finalY + 95,
        rowPageBreak: "auto",
        margin: { horizontal: 10 },
        styles: { overflow: "linebreak" },
        bodyStyles: { valign: "top" },
        columnStyles: { email: { cellWidth: "wrap" } },
        // columnStyles: { 0: { cellWidth: 1200 } },

        styles: {
          lineColor: [44, 62, 80],
          lineWidth: 1,
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
          //fillColor: hexToRgb(myColor)
        },
        columnStyles: {
          name: { cellWidth: 150 },
          amount: { cellWidth: 100 },
          prkasse: { cellWidth: 50 },
          containerName: { cellWidth: 100 },
          unitcl: { cellWidth: 50 },
          totalamountcl: { cellWidth: 100 },
        },
        headstyles: { fillColor: hexToRgb("#36688d") }, // Red  textColor: hexToRgb("#000000")
        //pageBreak: 'avoid',
      });
      // ny side
      doc.setFontSize(12);
      doc.setFontType("bold");
      doc.text("Yderligere info: ", 16, 725);
      doc.setFontSize(12);
      doc.setFontType("italic");
      var splitTitle = doc.splitTextToSize(element.additionalInfo, 450);
      doc.text(125, 750, splitTitle);
      doc.addPage();
      //total list end
      // packlist start
      let nextOne = 0;
      myAllData[i].vCKtotals.forEach((elementItem, i) => {
        if (i === 0) {
          doc.setFontSize(18);
          doc.setFont("times");
          doc.setFontType("italic");

          nextOne = nextOne + 65;

          doc.text(elementItem.productName, 14, 30);
          doc.autoTable({
            columns: headRowsProduct,
            body: elementItem.vCKItemtotals,
            //startY: doc.lastAutoTable.finalY + 45,
            startY: 40,
            rowPageBreak: "auto",
            margin: { horizontal: 10 },
            styles: { overflow: "linebreak" },
            bodyStyles: { valign: "top" },
            // columnStyles: {
            //   email: { cellWidth: "wrap" },
            //   amount:{ cellWidth: 1000}
            // },
            // columnStyles: { 0: { cellWidth: 1200 ,} },

            styles: {
              lineColor: [44, 62, 80],
              lineWidth: 1,
              cellWidth: "wrap",
              rowPageBreak: "auto",
              halign: "justify",
              //fillColor: hexToRgb(myColor)
            },
            columnStyles: {
              name: { cellWidth: 150 },
              oriAmount: { cellWidth: 100 },
              oriUnit: { cellWidth: 100 },
            },
            headStyles: { fillColor: hexToRgb(elementItem.productColor) }, // Red  textColor: hexToRgb("#000000")
            //pageBreak: 'avoid',
          });
        }
      });

      // loop rest of the vcktotals
      myAllData[i].vCKtotals.forEach((elementItem, i) => {
        if (i > 0) {
          doc.setFontSize(18);
          doc.setFont("times");
          doc.setFontType("italic");

          nextOne = nextOne + 65;

          doc.text(elementItem.productName, 14, doc.lastAutoTable.finalY + 40);
          doc.autoTable({
            columns: headRowsProduct,
            body: elementItem.vCKItemtotals,
            startY: doc.lastAutoTable.finalY + 45,
            rowPageBreak: "auto",
            margin: { horizontal: 10 },
            styles: { overflow: "linebreak" },
            bodyStyles: { valign: "top" },
            // columnStyles: {
            //   email: { cellWidth: "wrap" },
            //   amount:{ cellWidth: 1000}
            // },
            // columnStyles: { 0: { cellWidth: 1200 ,} },

            styles: {
              lineColor: [44, 62, 80],
              lineWidth: 1,
              cellWidth: "wrap",
              rowPageBreak: "auto",
              halign: "justify",
              //fillColor: hexToRgb(myColor)
            },
            columnStyles: {
              name: { cellWidth: 150 },
              oriAmount: { cellWidth: 100 },
              oriUnit: { cellWidth: 100 },
            },
            headstyles: { fillColor: hexToRgb(elementItem.productColor) }, // Red  textColor: hexToRgb("#000000")
            pageBreak: "avoid",
          });
        }
      });
      // additionalInfo

      if (i < bookingInfo.length - 1) {
        doc.addPage();
      }
    });

    const uri = doc.output("datauristring");
    return <iframe frameBorder="0" width={pdfWidth} height={pdfHeight} src={uri} />;
  } else {
    return (
      <div>
        <div className="sweet-loading" style={{ marginLeft: 300 }}>
          <BarLoader
            height={5}
            width={200}
            sizeUnit={"px"}
            size={500}
            color={"#4C8FBF"}
          />
        </div>
      </div>
    );
  }
};

export default PackingvckFunc;
