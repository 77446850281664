import React from "react";
import Settings from "../Settings/Settings";
import { Switch, Route } from "react-router-dom";
import Calculations from "./Calculations.js";
import Sampling from "./Sampling.js";

export default function Settingsview() {
  return (
    <div>
      <React.Fragment>
        <Switch>
          <Route
            exact
            path="/settings"
            render={(props) => <Settings {...props} />}
          />
          <Route
            exact
            path="/settings/calculations"
            render={(props) => <Calculations {...props} />}
          />
          <Route
            exact
            path="/settings/sampling"
            render={(props) => <Sampling {...props} />}
          />
        </Switch>
      </React.Fragment>
    </div>
  );
}
