import apiFacade from "../../../auth/apiFacade";

class Services {
  getData = () => {
    const data = apiFacade.getData("/bookingerrors").then((data) => {
      return data;
    });
    return data;
  };
  getErrorColumn() {
    const componentColumn = [
      { name: "id", title: "Id" },
      { name: "type", title: "Type" },
      { name: "date", title: "Date" },
      { name: "errorMsg", title: "Error description" },
    ];

    return componentColumn;
  }
}
const services_ = new Services();
export default services_;
