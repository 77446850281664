import React, { useState, useEffect } from "react";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import {
  FaCalculator,
  FaClipboardList,
  FaClipboard,
  FaWrench,
} from "react-icons/fa";
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import { ScreenSize } from "helper/ScreenSize";

const SettingsStyle = {
  bagCal: {
    boxShadow: "5px 5px 10px 2px #777",
  },
};

export default function Settings(props) {
  const [iconOffset, setIconOffSet] = useState(4);
  const [Textsize, setTextSize] = useState(8);
  const [textOffset, setTextOffSet] = useState(3);

  useEffect(() => {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      setIconOffSet(2);
      setTextSize(10);
      setTextOffSet(2);
    } else if (screenSize === 2) {
      setIconOffSet(2);
      setTextSize(10);
      setTextOffSet(2);
    }
  }, []);

  const chooseSetting = (settingId) => {
    if (settingId === 1) {
      props.history.push(`/Settings/Calculations`);
    }
    if (settingId === 3) {
      props.history.push(`/Settings/Sampling`);
    }
  };

  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle>Settings</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs={6} md={3}>
                <Card style={SettingsStyle.bagCal} className="card-chart">
                  <CardBody>
                    <Row>
                      <Col sm="12" md={{ offset: iconOffset }}>
                        <IconButton
                          color="secondary"
                          aria-label="View more"
                          onClick={() => {
                            chooseSetting(1);
                          }}
                        >
                          <FaCalculator
                            size={"50px"}
                            color={"#d16557"}
                            //style={{ marginLeft: "35%" }}
                          />
                        </IconButton>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md={{ size: Textsize, offset: textOffset }}>
                        <p> Calculation Settings </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={6} md={3}>
                <Card style={SettingsStyle.bagCal}>
                  <CardBody>
                    <Row>
                      <Col sm="12" md={{ offset: iconOffset }}>
                        <IconButton
                          color="#d16557"
                          aria-label="View more"
                          //onClick={refreshAll}
                        >
                          <FaClipboardList
                            size={"50px"}
                            color={"#d16557"}
                            //style={{ marginLeft: "35%" }}
                          />
                        </IconButton>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md={{ size: Textsize, offset: textOffset }}>
                        <p> Packing list Settings </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={6} md={3}>
                <Card style={SettingsStyle.bagCal}>
                  <CardBody>
                    <Row>
                      <Col sm="12" md={{ offset: iconOffset }}>
                        <IconButton
                          color="secondary"
                          aria-label="View more"
                          //onClick={refreshAll}
                          onClick={() => {
                            chooseSetting(3);
                          }}
                        >
                          <FaClipboard
                            size={"50px"}
                            color={"#d16557"}
                            //style={{ marginLeft: "35%" }}
                          />
                        </IconButton>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md={{ size: Textsize, offset: textOffset }}>
                        <p> Sampling Settings</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={6} md={3}>
                <Card style={SettingsStyle.bagCal}>
                  <CardBody>
                    <Row>
                      <Col sm="12" md={{ offset: iconOffset }}>
                        <IconButton
                          color="secondary"
                          aria-label="View more"
                          //onClick={refreshAll}
                        >
                          <FaWrench
                            size={"50px"}
                            color={"#d16557"}
                            //style={{ marginLeft: "35%" }}
                          />
                        </IconButton>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md={{ size: Textsize, offset: textOffset }}>
                        <p> General Settings </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
