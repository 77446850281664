import types from "../actionTypes";

const initialState = {
  regions: [],
  regionSelected: [],
  updateRegionSelected: false,
  barArmCal1: [],
  barArmCal2: [],
  loadingRegionSelected: true,
  loadingBarArmCal1: true,
  loadingBarArmCal2: true,
  readOnly1: true,
  readOnly2: true,
  dateFromDb1: true,
  dateFromDb2: true,
  calFormBool1: false,
  calFormBool2: false,
  jobtypes: [],
  jobtypesBool: false,
};

const calculationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.calculations.jobtypesBool:
      return {
        ...state,
        jobtypesBool: action.payload,
      };
    case types.calculations.jobtypes:
      return {
        ...state,
        jobtypes: action.payload,
      };
    case types.calculations.calFormBool1:
      return {
        ...state,
        calFormBool1: action.payload,
      };
    case types.calculations.calFormBool2:
      return {
        ...state,
        calFormBool2: action.payload,
      };
    case types.calculations.dateFromDb1:
      return {
        ...state,
        dateFromDb1: action.payload,
      };
    case types.calculations.dateFromDb2:
      return {
        ...state,
        dateFromDb2: action.payload,
      };
    case types.calculations.readOnly1:
      return {
        ...state,
        readOnly1: action.payload,
      };
    case types.calculations.readOnly2:
      return {
        ...state,
        readOnly2: action.payload,
      };
    case types.calculations.regions:
      return {
        ...state,
        regions: action.payload,
      };
    case types.calculations.regionSelected:
      return {
        ...state,
        regionSelected: action.payload,
      };
    case types.calculations.updateRegionSelected:
      return {
        ...state,
        updateRegionSelected: action.payload,
      };
    case types.calculations.barArmCal1:
      return {
        ...state,
        barArmCal1: action.payload,
      };
    case types.calculations.barArmCal2:
      return {
        ...state,
        barArmCal2: action.payload,
      };
    case types.calculations.loadingRegionSelected:
      return {
        ...state,
        loadingRegionSelected: action.payload,
      };
    case types.calculations.loadingBarArmCal1:
      return {
        ...state,
        loadingBarArmCal1: action.payload,
      };
    case types.calculations.loadingBarArmCal2:
      return {
        ...state,
        loadingBarArmCal2: action.payload,
      };
    default:
      return state;
  }
};

export default calculationsReducer;
