import React from "react";
import apiFacade from "../../../auth/apiFacade";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Alert,
} from "reactstrap";
import TextField from "@material-ui/core/TextField";

function getAllcontainers() {
  const data = apiFacade.getData("/containers").then((data_) => {
    return data_;
  });
  return data;
}

function getContainer() {
  return "You got a container!";
}

function getContainerColumn() {
  const componentColumn = [
    { name: "id", title: "Id" },
    { name: "name", title: "Name" },
    { name: "size", title: "Size" },
    { name: "suffix", title: "Suffix" },
    { name: "actions", title: "Actions" },
  ];
  return componentColumn;
}

function viewModel(modal, editToggle, name, container) {
  return (
    <Modal
      isOpen={modal}
      toggle={editToggle}
      size="lg"
      style={{ maxWidth: "800px", width: "80%" }}
    >
      <ModalHeader toggle={editToggle}>{name}</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Row>
              <Col xs="9">
                <h6>View Container</h6>
                <Row>
                  <Col xs="4">
                    <TextField
                      id="standard-read-only-input"
                      label="Name:"
                      value={container.name}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Col>
                  <Col xs="4">
                    <TextField
                      id="standard-read-only-input"
                      label="Size:"
                      value={container.size}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Col>
                  <Col xs="4">
                    <TextField
                      id="standard-read-only-input"
                      label="Suffix:"
                      value={container.suffix}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        {/* <Button
              color="secondary"
              style={{ marginLeft: "85%" }}
              onClick={editToggle}
            >
              Close
            </Button> */}
      </ModalFooter>
    </Modal>
  );
}

function deleteModel(modal, deleteToggle, name, container) {
  let openSaved = false;
  let savedTitle = "";
  const deleteCom = () => {
    savedTitle = "  Deleted";
    apiFacade.deleteData(
      "https://pakkemodelapi.azurewebsites.net/api/containers/" + container.id
    );
    openSaved = true;
    window.setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={deleteToggle}
      size="lg"
      style={{ maxWidth: "600px", width: "60%" }}
    >
      <ModalHeader toggle={deleteToggle}>{name}</ModalHeader>
      <Alert
        color="danger"
        isOpen={openSaved}
        style={{
          marginTop: "10px",

          textAlign: "center",
          fontSize: "20px",
          width: "240px",
          marginLeft: "23%",
        }}
      >
        <NotificationsActiveIcon />
        {savedTitle}
      </Alert>
      <ModalHeader toggle={deleteToggle}>Deleting {container.name}</ModalHeader>
      <ModalBody>
        {" "}
        Are you sure that you want to delete the selected item:
        <div style={{ fontWeight: "bold" }}>{container.name}</div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          onClick={() => {
            deleteCom();
          }}
        >
          Delete
        </Button>{" "}
        <Button color="danger" onClick={deleteToggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function editModel(modal, editToggle, name, container) {
  let openSaved = false;
  let savedTitle = "";
  let containerValues = {
    id: container.id,
    name: container.name,
    size: container.size,
    suffix: container.suffix,
  };

  const inputValueprintById = (id, where) => {
    let getValue = document.getElementById(id).value;
    if (getValue != "" || where === "Name") {
      if (where === "Name") {
        containerValues = { ...containerValues, name: getValue };
      }
      if (where === "Size") {
        containerValues = { ...containerValues, size: getValue };
      }
      if (where === "Suffix") {
        containerValues = { ...containerValues, suffix: getValue };
      }
    }
  };

  const changeOpenSaved = () => {
    openSaved = !openSaved;
  };

  const saveChanges = () => {
    savedTitle = "  Saved";
    apiFacade
      .putData(
        "https://pakkemodelapi.azurewebsites.net/api/containers",
        containerValues
      )
      .then((response) => {
        openSaved = true;
      })
      .catch((err) => console.error("Error", err));
    window.setTimeout(() => {
      changeOpenSaved();
      window.location.reload();
    }, 2000);
  };
  return (
    <Modal
      isOpen={modal}
      toggle={editToggle}
      size="lg"
      style={{ maxWidth: "800px", width: "80%" }}
    >
      <Alert
        isOpen={openSaved}
        style={{
          marginTop: "10px",
          textAlign: "center",
          fontSize: "20px",
          width: "250px",
          marginLeft: "40%",
        }}
      >
        <NotificationsActiveIcon />
        {savedTitle}
      </Alert>
      <ModalHeader toggle={editToggle}>{name}</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <Row>
              <Col xs="9">
                <h6>Edit Container</h6>
                <Row>
                  <Col xs="4">
                    <form noValidate autoComplete="off">
                      <TextField
                        id="nName"
                        label={"Name"}
                        defaultValue={container.name}
                        onBlur={() => {
                          inputValueprintById("nName", "Name");
                        }}
                      />
                    </form>
                  </Col>
                  <Col xs="4">
                    <form noValidate autoComplete="off">
                      <TextField
                        id="sSize"
                        label={"Size"}
                        defaultValue={container.size}
                        onBlur={() => {
                          inputValueprintById("sSize", "Size");
                        }}
                      />
                    </form>
                  </Col>
                  <Col xs="4">
                    <form noValidate autoComplete="off">
                      <TextField
                        id="sSuffix"
                        label={"Suffix"}
                        defaultValue={container.suffix}
                        onBlur={() => {
                          inputValueprintById("sSuffix", "Suffix");
                        }}
                      />
                    </form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          onClick={() => {
            saveChanges();
          }}
        >
          Save
        </Button>{" "}
        <Button color="danger" onClick={editToggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export {
  getAllcontainers,
  getContainer,
  getContainerColumn,
  viewModel,
  deleteModel,
  editModel,
};
