import React, { useState, useEffect } from "react";
import BarLoader from "react-spinners/BarLoader";
import { useSelector } from "react-redux";
import types from "../../../../redux/actionTypes";
import { CSVLink, CSVDownload } from "react-csv";

const headRows = [
  { label: "Id", key: "id" },
  { label: "Name", key: "componentName" },
  { label: "Amount", key: "amount" },
  { label: "Unit", key: "unit" },
  { label: "Category", key: "category" },
  { label: "Return", key: "return" },
];

const CsvGen = ({ refreshApi, jobid }) => {
  const { getPackingList } = useSelector((state) => state.packinglist);
  let myData = [];
  const [loaded, setLoaded] = useState(false);
  let arrToString = jobid.toString();

  useEffect(() => {
    if (refreshApi) {
      if (getPackingList.length > 0) {
        setLoaded(true);
      }
    }
  }, [getPackingList]);

  if (loaded) {
    return (
      <div>
        <CSVLink data={getPackingList} filename={arrToString} headers={headRows}>
          Download
        </CSVLink>
      </div>
    );
  } else {
    return <div>isLoading</div>;
  }
};

export default CsvGen;
