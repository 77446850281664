import types from "../actionTypes";

const initialState = {
  packerApi: [],
  packerBool: false,
  errorArr: [{ id: 0, msg: "test" }],
  errorBool: false,
  loginPacker: {
    packer_Id: 0,
    firstName: "",
    lastName: "",
    userName: "",
  },
};

const pakkerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.pakker.loginPacker:
      return {
        ...state,
        loginPacker: action.payload,
      };
    case types.pakker.packerApi:
      return {
        ...state,
        packerApi: action.payload,
      };
    case types.pakker.packerBool:
      return {
        ...state,
        packerBool: action.payload,
      };
    case types.pakker.errorBool:
      return {
        ...state,
        errorBool: action.payload,
      };
    case types.pakker.errorArrRemove:
      let filteredMsg = state.errorArr.filter((e) => e.id !== action.payload);
      state.errorArr = filteredMsg;
    case types.pakker.errorArr:
      if (action.payload.msg !== undefined) {
        let checkErrorArr = state.errorArr.findIndex(
          (e) => e.id === action.payload.id
        );
        if (checkErrorArr === -1) {
          state.errorArr.push(action.payload);
        } else {
          state.errorArr[checkErrorArr].msg = action.payload.msg;
        }
      }
    default:
      return state;
  }
};

export default pakkerReducer;
