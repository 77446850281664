import React, { useState } from "react";
import MaterialTable from "material-table";
import apiFacade from "../../../auth/apiFacade";
import { TablePagination } from "@material-ui/core";
import { Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";
import Paper from "@material-ui/core/Paper";
import { SketchPicker } from "react-color";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";

function getModalStyle() {
  const top = 40;
  const left = 40;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const PackingTable = (props) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [state, setState] = useState({
    columns: [
      { title: "Name", field: "name", editable: "never", width: 50 },
      { title: "Zone", field: "zone" },
      {
        title: "Bay",
        field: "bay",
        lookup: {
          0: "0",
          1: "1",
          2: "2",
          3: "3",
          4: "4",
          5: "5",
          6: "6",
          7: "7",
          8: "8",
          90: "90",
          91: "91",
          92: "92",
          93: "93",
          94: "94",
          95: "95",
          96: "96",
          97: "97",
          98: "98",
          99: "99",
        },
      },
      { title: "Shelf", field: "shelf" },
      { title: "Position", field: "position" },
    ],
    data: props.newData,
  });
  const [open, setOpen] = React.useState(false);
  const [bgColor, setBgColor] = useState(props.colorId);

  const handleChangeComplete = (color) => {
    setBgColor(color.hex);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    apiFacade
      .getData("/ComponentCategories/" + props.newData[0].categoryId)
      .then((data) => {
        setBgColor(data.colorId);
      });

    setOpen(false);
  };
  const handleSave = () => {
    const newColor = { id: props.newData[0].categoryId, colorId: bgColor };

    apiFacade
      .putData(
        "https://pakkemodelapi.azurewebsites.net/api/ComponentCategories/updatecolor",
        newColor
      )
      .then((response) => {
      })
      .catch((error) => console.error("Error:", error));
    setOpen(false);
  };

  return (
    <div>
      <Row>
        <Col xs="auto" md={9}>
          <MaterialTable
            title={props.categoryName}
            columns={state.columns}
            data={state.data}
            options={{
              paging: false,
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    const data = [...state.data];
                    data[data.indexOf(oldData)] = newData;
                    setState({ ...state, data });
                  }, 600);
                  //gem data her
                  // /NewSortings/putnewsortingbyid/
                  apiFacade
                    .putData(
                      "https://pakkemodelapi.azurewebsites.net/api/NewSortings/putnewsortingbyid/",
                      newData
                    )
                    .then((response) => {
                    })
                    .catch((error) => console.error("Error:", error));
                }),
            }}
          />
        </Col>
        <Col xs="auto" md={3}>
          <div>
            <Paper>
              <Row>
                <Col xs={12}>
                  <div
                    style={{
                      backgroundColor: bgColor,
                      width: "auto",
                      height: "60px",
                      borderRadius: "5px",
                    }}
                  ></div>
                </Col>
              </Row>
              <Row>
                <Col sm={{ size: "auto", offset: 2 }}>
                  <Button color="info" onClick={handleOpen}>
                    Change category color
                  </Button>
                </Col>

                <Modal open={open} onClose={handleCancel}>
                  <div style={modalStyle} className={classes.paper}>
                    <SketchPicker
                      color={bgColor}
                      onChangeComplete={handleChangeComplete}
                    />
                    <Row>
                      <Col xs="7">
                        <Button color="danger" onClick={handleCancel}>
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <Button color="success" onClick={handleSave}>
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Modal>
              </Row>
            </Paper>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PackingTable;
