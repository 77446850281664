import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  CardFooter,
} from "reactstrap";
import PanelHeader from "../../components/PanelHeader/PanelHeader";
import apiFacade from "../../auth/apiFacade.js";
import AssignmentsTable from "./AssignmentsTable/AssignmentsTable.js";
import AssignmentsCell from "./AssignmentsTable/AssignmentsCell.js";
import JobAssignmentTable from "./JobAssignmentTable/JobAssignmentTable.js";
import JobAssignmentCell from "./JobAssignmentTable/JobAssignmentCell.js";
import { useSelector, useDispatch } from "react-redux";
import types from "../../redux/actionTypes";

export default function Assignments(props) {
  const dispatch = useDispatch();
  const { assignmentId } = useSelector((state) => state.assignment);
  const { assignmentName } = useSelector((state) => state.assignment);
  const { assignmentBool } = useSelector((state) => state.assignment);
  const { managementId } = useSelector((state) => state.assignment);
  const { jobId } = useSelector((state) => state.assignment);
  const { statusChangeArr } = useSelector((state) => state.assignment);
  const { statusBool } = useSelector((state) => state.assignment);
  const [saveAs, setSaveAs] = useState("In progress");
  const [assignmentStatusId, setAssignmentStatusId] = useState(2);
  const [statusColor, setStatusColor] = useState("#BD7F6F");
  let user = JSON.parse(localStorage.getItem("user"));
  let LSAssignment = JSON.parse(localStorage.getItem("assignment"));
  const [assignment, setAssignment] = useState({
    managementId: null,
    assignmentId: null,
    assignmentName: null,
    jobId: null,
  });

  let job = JSON.parse(localStorage.getItem("job"));
  const [useBool, setUseBool] = useState(false);
  const [myTable, setMyTable] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [ifPackingList, setIfPackingList] = useState(false);
  const [columns] = useState([
    { name: "jobId", title: "Job" },
    { name: "assignmentName", title: "Assignment" },
    { name: "packingDate", title: "Date" },
    { name: "startJob", title: "Start job" },
  ]);
  const [assignmentsColumns] = useState([
    { name: "name", title: "Name" },
    { name: "amount", title: "Amount" },
    { name: "unit", title: "Unit" },
    { name: "category", title: "Category" },
    { name: "status", title: "Status" },
  ]);

  useEffect(() => {
    if (statusChangeArr !== undefined) {
      let misStock = statusChangeArr.find((f) => f.status === "Missing stock");
      let progress = statusChangeArr.find((f) => f.status === "In progress");
      let packingDone = statusChangeArr.find((f) => f.status === "Packed");
      let done = statusChangeArr.find((f) => f.status === "Done");
      if (
        progress !== undefined &&
        misStock === undefined &&
        done === undefined &&
        packingDone === undefined
      ) {
        setSaveAs("In progress");
        setStatusColor("#BD7F6F");
        setAssignmentStatusId(2);
        if (job !== null) {
          let job = {
            status: "In progress",
            color: "#BD7F6F",
            statusId: "2",
          };
          localStorage.setItem("job", JSON.stringify(job));
        }
      } else if (misStock !== undefined && progress === undefined) {
        setSaveAs("Missing stock");
        setStatusColor("red");
        setAssignmentStatusId(3);
        let job = {
          status: "Missing stock",
          color: "red",
          statusId: "3",
        };
        localStorage.setItem("job", JSON.stringify(job));
      } else if (
        progress === undefined &&
        misStock === undefined &&
        packingDone !== undefined
      ) {
        setSaveAs("Packed");
        setStatusColor("green");
        setAssignmentStatusId(4);
        let job = {
          status: "Packed",
          color: "green",
          statusId: "4",
        };
        localStorage.setItem("job", JSON.stringify(job));
      } else if (progress === undefined && done !== undefined) {
        setSaveAs("Done");
        setStatusColor("green");
        setAssignmentStatusId(4);
        let job = {
          status: "Done",
          color: "green",
          statusId: "4",
        };
        localStorage.setItem("job", JSON.stringify(job));
      }
    }
  }, [statusBool]);

  useEffect(() => {
    apiFacade
      .getData("/jobmanagement/getpakkerjobs/" + user.packer_Id)
      .then((data) => {
        if (data !== undefined) {
          setMyTable(data.futureJobManagements);
          data.futureJobManagements.forEach((element) => {
            if (element.assignmentStatusId === 5) {
              setAssignment({
                managementId: element.managementId,
                assignmentId: element.assignmentId,
                assignmentName: element.assignmentName,
                jobId: element.jobId,
              });
              setIfPackingList(true);
              apiFacade
                .getData(
                  "/PackingListReturns/" +
                    element.jobId +
                    "/" +
                    element.assignmentId
                )
                .then((data) => {
                  setJobList(data.packingListReturnDto);
                  let statusArr = [];
                  data.packingListReturnDto.forEach((element) => {
                    statusArr.push({
                      componentId: element.componentId,
                      status: element.jobAssignmentStatusName,
                    });
                  });
                  dispatch({
                    type: types.assignment.statusChangeArr,
                    payload: statusArr,
                  });
                  dispatch({
                    type: types.assignment.managementId,
                    payload: element.managementId,
                  });
                });
            }
          });
        }
      });
  }, [useBool]);

  useEffect(() => {
    if (job !== null) {
      setSaveAs(job.status);
      setStatusColor(job.color);
      setAssignmentStatusId(job.statusId);
    }

    if (jobId !== null && assignmentId !== null) {
      apiFacade
        .getData("/PackingListReturns/" + jobId + "/" + assignmentId)
        .then((data) => {
          setJobList(data.packingListReturnDto);
          setIfPackingList(true);
          let statusArr = [];
          data.packingListReturnDto.forEach((element) => {
            statusArr.push({
              componentId: element.componentId,
              status: element.jobAssignmentStatusName,
            });
          });
          dispatch({
            type: types.assignment.statusChangeArr,
            payload: statusArr,
          });
        });
      apiFacade
        .getData(
          "/PackingListReturns/getpackinglistreturnstatus/" +
            assignment.managementId +
            "/" +
            user.packer_Id
        )
        .then((data) => {
          setSaveAs(data.assignmentStatus);
          setStatusColor(data.assignmentStatusColor);
          setAssignmentStatusId(data.assignmentStatus_Id);
          let job = {
            status: data.assignmentStatus,
            color: data.assignmentStatusColor,
            statusId: data.assignmentStatus_Id,
          };
          localStorage.setItem("job", JSON.stringify(job));
        });
    }
  }, [assignmentBool]);

  const putAssignmentStatus = (id) => {
    var jobPakker = {
      pakker_Id: user.packer_Id,
      management_Id: managementId,
      assignmentStatus_Id: id,
    };
    apiFacade.putDataWithJson(
      "https://pakkemodelapi.azurewebsites.net/api/JobManagement/updateassignmentstatusid",
      jobPakker
    );
    if (id !== 5) {
      setIfPackingList(false);
    }
    if (id === 4 && assignment.assignmentId === 5) {
      apiFacade.getData("/PriceOnPakkelistes/" + assignment.jobId);
    }
    if (id === 4) {
      setUseBool(!useBool);
    }
  };

  if (LSAssignment !== null && assignment.assignmentId === null) {
    setAssignment(LSAssignment);
  }

  if (!ifPackingList) {
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader align="center" tag="h4">
                  Your assignments {user.firstName}
                </CardHeader>
                <CardBody>
                  <AssignmentsTable
                    rows={myTable}
                    columns={columns}
                    cell={AssignmentsCell}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  } else if (assignment.assignmentId !== null) {
    localStorage.setItem("assignment", JSON.stringify(assignment));
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader align="center" tag="h4">
                  Job:{" "}
                  <span style={{ fontWeight: "bold" }}>{assignment.jobId}</span>{" "}
                  - Assignment:
                  <span style={{ fontWeight: "bold" }}>
                    {" " + assignment.assignmentName}
                  </span>
                </CardHeader>
                <CardBody>
                  <JobAssignmentTable
                    rows={jobList}
                    columns={assignmentsColumns}
                    cell={JobAssignmentCell}
                  />
                </CardBody>
                <CardFooter style={{ textAlign: "center" }}>
                  <Button
                    size="lg"
                    style={{ width: "50%", backgroundColor: statusColor }}
                    onClick={() => {
                      document
                        .querySelector(".navbar-brand")
                        .scrollIntoView(true);
                      putAssignmentStatus(assignmentStatusId);
                    }}
                  >
                    Save as {saveAs}
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
}
