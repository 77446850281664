import React from "react";
import { TableCell } from "@material-ui/core";
import Moment from "react-moment";

export default function JobPackerComponentCell(props) {
  if (props.column.name === "jobId") {
    return <TableCell>{props.row.jobId}</TableCell>;
  }
  if (props.column.name === "assignmentName") {
    return <TableCell>{props.row.assignmentName}</TableCell>;
  }
  if (props.column.name === "packingDate") {
    return (
      <TableCell>
        <div>
          <Moment date={props.row.packingDate} format={"dddd"} />
        </div>
        <Moment date={props.row.packingDate} format={"MMM Do"} />
      </TableCell>
    );
  }
  if (props.column.name === "assignmentStatus") {
    if(props.row.assignmentStatus === "Packed" || props.row.assignmentStatus === "Done"){
      return <TableCell style={{color: "green"}}>{props.row.assignmentStatus}</TableCell>;
    } else if(props.row.assignmentStatus === "Missing stock") {
      return <TableCell style={{color: "red"}}>{props.row.assignmentStatus}</TableCell>;
    } else {
      return <TableCell>{props.row.assignmentStatus}</TableCell>;
    }
  }
  return <TableCell>No data</TableCell>;
}
