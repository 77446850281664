import React, { useEffect, useState } from "react";
import apiFacade from "../../../../auth/apiFacade";
import BarLoader from "react-spinners/BarLoader";
import jsPDF from "jspdf";

function Weekplan({
  listOfId,
  refreshApi,
  loading,
  navne,
  startDate,
  endDate
}) {
  const [myListOfIds, setMyListOfIds] = useState([]);
  const [myData, setMyData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [newStartDate, setStartDate] = useState(startDate);
  const [newEndDate, setEndDate] = useState(endDate);
  const [lengthOfData, setLengthOfData] = useState(0);

  useEffect(() => {
    setMyListOfIds(listOfId);
    if (refreshApi) {
      apiFacade
        .getData("/samplings/getsamplings/" + listOfId.join())
        .then(data => {
          setMyData(data);
          setLengthOfData(data.length);
          //setMyListOfIds(data)
        })
        .finally(() => setLoaded(true));
    }
  }, [refreshApi]);

  if (myData.length > 0) {
    var doc = new jsPDF("l", "pt", "a4");
    const moment = require("moment");
    var startDateFormat = moment(`${newStartDate}`);
    var endDateFormat = moment(`${newEndDate}`);
    var daysBetween = endDateFormat.diff(startDateFormat, "days");
    // hvor mange sider frem dvs 4 dage par side,
    // find ud af dage imellem start og slut dernæste / 4
    var antalSiderFrem = 0;
    var checkIfItsdouble = Number.isInteger(daysBetween / 4);
    if (checkIfItsdouble) {
      antalSiderFrem = daysBetween / 4;
    } else {
      antalSiderFrem = Math.trunc(daysBetween / 4 + 1);
    }
    // hvor mange sider "ned" dvs 4 sampling par side,
    let pageNr = antalSiderFrem;
    // ID
    doc.setFontSize(25);
    doc.setFontType("bold");
    doc.text(
      "Periode: " + ` ${newStartDate}` + " - " + `${newEndDate}`,
      doc.internal.pageSize.width / 2 - 200,
      30,
      null,
      null,
      "center"
    );

    for (let i = 0; i < antalSiderFrem; i++) {
      var b = i + 1;

      doc.line(0, 35, 840, 35);
      doc.setDrawColor(0);
      doc.setFillColor(220, 220, 220);
      doc.rect(10, 45, 205, 600, "F");
      doc.setDrawColor(0);
      doc.setFillColor(250, 240, 230);
      doc.rect(215, 45, 205, 600, "F");
      doc.setDrawColor(0);
      doc.setFillColor(220, 220, 220);
      doc.rect(420, 45, 205, 600, "F");
      doc.setDrawColor(0);
      doc.setFillColor(250, 240, 230);
      doc.rect(625, 45, 205, 600, "F");
      doc.setLineWidth(1);
      doc.line(10, 95, 830, 95);
      let startHeightLinen = 120;
      var startLinen = 15;
      // first round
      if (b === 1) {
        if (daysBetween > 4) {
          doc.setFontSize(15);
          doc.text("1", 10, 25);
          for (let p = 0; p < 4; p++) {
            var dayName = moment(startDateFormat, "YYYY-MM-DD HH:mm:ss")
              .add(p, "days")
              .format("dddd");
            var date = moment(startDateFormat, "YYYY-MM-DD HH:mm:ss")
              .add(p, "days")
              .format("DD/MM/YYYY");

            doc.setFontSize(15);
            doc.text(dayName + " " + date, startLinen, 80);
            var colorCheck = true;
            myData.map((d, j) => {
              if (j <= 5) {
                d.samplingDates.map((s, i) => {
                  var compareDates = moment(
                    s.start,
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("DD/MM/YYYY");
                  var startTime = moment(s.start, "YYYY-MM-DD HH:mm:ss").format(
                    "HH:mm "
                  );
                  var endTime = moment(s.end, "YYYY-MM-DD HH:mm:ss").format(
                    "HH:mm"
                  );
                  if (d.outlet != "") {
                    var info = d.outlet;
                    var forretningNameArr = info.split(",");

                    if (forretningNameArr.length <= 2) {
                      forretningNameArr = forretningNameArr[0].split("-");
                    }
                  }
                  if (colorCheck) {
                    doc.setFillColor(252, 136, 78);
                  }
                  if (!colorCheck) {
                    doc.setFillColor(127, 146, 255);
                  }
                  if (date === compareDates) {
                    doc.setDrawColor(0);

                    doc.rect(
                      startLinen - 5,
                      startHeightLinen - 24,
                      205,
                      60,
                      "F"
                    );

                    if (i === 0) {
                      doc.text(
                        "Sampling ID: " + `${d.sampling_Id}`,
                        startLinen,
                        startHeightLinen - 5
                      );
                      doc.setFontSize(12);
                      doc.text(
                        "Forretning: " + `${forretningNameArr[0]}`,
                        startLinen,
                        startHeightLinen + 27
                      );
                    }

                    doc.setFontSize(15);
                    doc.text(
                      "Tid: " + `${startTime}` + " - " + `${endTime}`,
                      startLinen,
                      startHeightLinen + 12
                    );
                  }
                });
              }

              startHeightLinen += 80;
              if (colorCheck) {
                colorCheck = false;
              } else {
                colorCheck = true;
              }
            });

            startLinen += 205;
            startHeightLinen = 120;
          }
        }

        if (daysBetween < 4) {
          for (let p = 0; p < daysBetween + 1; p++) {
            var dayName = moment(startDateFormat, "YYYY-MM-DD HH:mm:ss")
              .add(p, "days")
              .format("dddd");
            var date = moment(startDateFormat, "YYYY-MM-DD HH:mm:ss")
              .add(p, "days")
              .format("DD/MM/YYYY");
            doc.text("1", 10, 20);
            doc.setFontSize(15);
            doc.text(dayName + " " + date, startLinen, 80);
            var colorCheck = true;
            myData.map((d, j) => {
              if (j <= 5) {
                d.samplingDates.map((s, i) => {
                  var compareDates = moment(
                    s.start,
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("DD/MM/YYYY");
                  var startTime = moment(s.start, "YYYY-MM-DD HH:mm:ss").format(
                    "HH:mm "
                  );
                  var endTime = moment(s.end, "YYYY-MM-DD HH:mm:ss").format(
                    "HH:mm"
                  );
                  if (d.outlet != "") {
                    var info = d.outlet;
                    var forretningNameArr = info.split(",");

                    if (forretningNameArr.length <= 2) {
                      forretningNameArr = forretningNameArr[0].split("-");
                    }
                  }
                  if (colorCheck) {
                    doc.setFillColor(252, 136, 78);
                  }
                  if (!colorCheck) {
                    doc.setFillColor(127, 146, 255);
                  }
                  if (date === compareDates) {
                    doc.setDrawColor(0);

                    doc.rect(
                      startLinen - 5,
                      startHeightLinen - 24,
                      205,
                      60,
                      "F"
                    );

                    if (i === 0) {
                      doc.text(
                        "Sampling ID: " + `${d.sampling_Id}`,
                        startLinen,
                        startHeightLinen - 5
                      );
                      doc.setFontSize(12);
                      doc.text(
                        "Forretning: " + `${forretningNameArr[0]}`,
                        startLinen,
                        startHeightLinen + 27
                      );
                    }

                    doc.setFontSize(15);
                    doc.text(
                      "Tid: " + `${startTime}` + " - " + `${endTime}`,
                      startLinen,
                      startHeightLinen + 12
                    );
                  }
                });
              }

              startHeightLinen += 80;
              if (colorCheck) {
                colorCheck = false;
              } else {
                colorCheck = true;
              }
            });

            startLinen += 205;
            startHeightLinen = 120;
          }
        }
      }

      // 2 second runde
      if (b === 2) {
        if (daysBetween > 8) {
          for (let p = 4; p < 8; p++) {
            var dayName = moment(startDateFormat, "YYYY-MM-DD HH:mm:ss")
              .add(p, "days")
              .format("dddd");
            var date = moment(startDateFormat, "YYYY-MM-DD HH:mm:ss")
              .add(p, "days")
              .format("DD/MM/YYYY");

            doc.setFontSize(15);
            doc.text(dayName + " " + date, startLinen, 80);
            var colorCheck = true;
            myData.map((d, j) => {
              if (j <= 5) {
                doc.text("2", 10, 20);
                d.samplingDates.map((s, i) => {
                  var compareDates = moment(
                    s.start,
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("DD/MM/YYYY");
                  var startTime = moment(s.start, "YYYY-MM-DD HH:mm:ss").format(
                    "HH:mm "
                  );
                  var endTime = moment(s.end, "YYYY-MM-DD HH:mm:ss").format(
                    "HH:mm"
                  );
                  if (d.outlet != "") {
                    var info = d.outlet;
                    var forretningNameArr = info.split(",");

                    if (forretningNameArr.length <= 2) {
                      forretningNameArr = forretningNameArr[0].split("-");
                    }
                  }
                  if (colorCheck) {
                    doc.setFillColor(252, 136, 78);
                  }
                  if (!colorCheck) {
                    doc.setFillColor(127, 146, 255);
                  }
                  if (date === compareDates) {
                    doc.setDrawColor(0);

                    doc.rect(
                      startLinen - 5,
                      startHeightLinen - 24,
                      205,
                      60,
                      "F"
                    );

                    if (i === 0) {
                      doc.text(
                        "Sampling ID: " + `${d.sampling_Id}`,
                        startLinen,
                        startHeightLinen - 5
                      );
                      doc.setFontSize(12);
                      doc.text(
                        "Forretning: " + `${forretningNameArr[0]}`,
                        startLinen,
                        startHeightLinen + 27
                      );
                    }

                    doc.setFontSize(15);
                    doc.text(
                      "Tid: " + `${startTime}` + " - " + `${endTime}`,
                      startLinen,
                      startHeightLinen + 12
                    );
                  }
                });
              }

              startHeightLinen += 80;
              if (colorCheck) {
                colorCheck = false;
              } else {
                colorCheck = true;
              }
            });

            startLinen += 205;
            startHeightLinen = 120;
          }
        }

        if (daysBetween > 4 && daysBetween <= 8) {
          doc.text("2", 10, 20);
          for (let p = 4; p < daysBetween + 1; p++) {
            var dayName = moment(startDateFormat, "YYYY-MM-DD HH:mm:ss")
              .add(p, "days")
              .format("dddd");
            var date = moment(startDateFormat, "YYYY-MM-DD HH:mm:ss")
              .add(p, "days")
              .format("DD/MM/YYYY");

            doc.setFontSize(15);
            doc.text(dayName + " " + date, startLinen, 80);
            var colorCheck = true;
            myData.map((d, j) => {
              if (j <= 5) {
                // doc.text("2", 10, 20);
                d.samplingDates.map((s, i) => {
                  var compareDates = moment(
                    s.start,
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("DD/MM/YYYY");
                  var startTime = moment(s.start, "YYYY-MM-DD HH:mm:ss").format(
                    "HH:mm "
                  );
                  var endTime = moment(s.end, "YYYY-MM-DD HH:mm:ss").format(
                    "HH:mm"
                  );
                  if (d.outlet != "") {
                    var info = d.outlet;
                    var forretningNameArr = info.split(",");

                    if (forretningNameArr.length <= 2) {
                      forretningNameArr = forretningNameArr[0].split("-");
                    }
                  }
                  if (colorCheck) {
                    doc.setFillColor(252, 136, 78);
                  }
                  if (!colorCheck) {
                    doc.setFillColor(127, 146, 255);
                  }
                  if (date === compareDates) {
                    doc.setDrawColor(0);

                    doc.rect(
                      startLinen - 5,
                      startHeightLinen - 24,
                      205,
                      60,
                      "F"
                    );

                    if (i === 0) {
                      doc.text(
                        "Sampling ID: " + `${d.sampling_Id}`,
                        startLinen,
                        startHeightLinen - 5
                      );
                      doc.setFontSize(12);
                      doc.text(
                        "Forretning: " + `${forretningNameArr[0]}`,
                        startLinen,
                        startHeightLinen + 27
                      );
                    }

                    doc.setFontSize(15);
                    doc.text(
                      "Tid: " + `${startTime}` + " - " + `${endTime}`,
                      startLinen,
                      startHeightLinen + 12
                    );
                    doc.setFontSize(12);
                  }
                });
              }

              startHeightLinen += 80;
              if (colorCheck) {
                colorCheck = false;
              } else {
                colorCheck = true;
              }
            });

            startLinen += 205;
            startHeightLinen = 120;
          }
        }
      }

      if (i < pageNr) {
        doc.addPage();
      }
      if (i == pageNr && lengthOfData > 6) {
        doc.addPage();
      }
    }

    //-------------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------------
    var newLength = Math.ceil(lengthOfData / 6);
    var myDataSixLess = myData.slice(6, myData.length);
    var addOneMorePage = 6;
    for (let w = 0; w < newLength; w++) {

      for (let i = 0; i < antalSiderFrem; i++) {
        var b = i + 1;

        doc.setDrawColor(0);
        doc.setFillColor(220, 220, 220);
        doc.rect(10, 0, 205, 600, "F");
        doc.setDrawColor(0);
        doc.setFillColor(250, 240, 230);
        doc.rect(215, 0, 205, 600, "F");
        doc.setDrawColor(0);
        doc.setFillColor(220, 220, 220);
        doc.rect(420, 0, 205, 600, "F");
        doc.setDrawColor(0);
        doc.setFillColor(250, 240, 230);
        doc.rect(625, 0, 205, 600, "F");

        let startHeightLinen = 120;
        var startLinen = 15;
        // first round
        if (b === 1) {
          if (daysBetween > 4) {
            for (let p = 0; p < 4; p++) {
              var date = moment(startDateFormat, "YYYY-MM-DD HH:mm:ss")
                .add(p, "days")
                .format("DD/MM/YYYY");

              doc.setFontSize(15);

              var colorCheck = true;
              doc.text("1", 10, 20);
              myDataSixLess.map((d, j) => {
                if (j <= 5) {
                  d.samplingDates.map((s, i) => {
                    var compareDates = moment(
                      s.start,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("DD/MM/YYYY");
                    var startTime = moment(
                      s.start,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("HH:mm ");
                    var endTime = moment(s.end, "YYYY-MM-DD HH:mm:ss").format(
                      "HH:mm"
                    );
                    if (d.outlet != "") {
                      var info = d.outlet;
                      var forretningNameArr = info.split(",");

                      if (forretningNameArr.length <= 2) {
                        forretningNameArr = forretningNameArr[0].split("-");
                      }
                    }
                    if (colorCheck) {
                      doc.setFillColor(252, 136, 78);
                    }
                    if (!colorCheck) {
                      doc.setFillColor(127, 146, 255);
                    }
                    if (date === compareDates) {
                      doc.setDrawColor(0);

                      doc.rect(
                        startLinen - 5,
                        startHeightLinen - 24,
                        205,
                        60,
                        "F"
                      );

                      if (i === 0) {
                        doc.text(
                          "Sampling ID: " + `${d.sampling_Id}`,
                          startLinen,
                          startHeightLinen - 5
                        );
                        doc.setFontSize(12);
                        doc.text(
                          "Forretning: " + `${forretningNameArr[0]}`,
                          startLinen,
                          startHeightLinen + 27
                        );
                      }

                      doc.setFontSize(15);
                      doc.text(
                        "Tid: " + `${startTime}` + " - " + `${endTime}`,
                        startLinen,
                        startHeightLinen + 12
                      );
                    }
                  });
                }

                startHeightLinen += 80;
                if (colorCheck) {
                  colorCheck = false;
                } else {
                  colorCheck = true;
                }
              });

              startLinen += 205;
              startHeightLinen = 120;
            }
          }

          if (daysBetween < 4) {
            for (let p = 0; p < daysBetween + 1; p++) {
              var date = moment(startDateFormat, "YYYY-MM-DD HH:mm:ss")
                .add(p, "days")
                .format("DD/MM/YYYY");

              doc.setFontSize(15);
              var colorCheck = true;
              doc.text("1", 10, 20);
              myDataSixLess.map((d, j) => {
                if (j <= 5) {
                  d.samplingDates.map((s, i) => {
                    var compareDates = moment(
                      s.start,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("DD/MM/YYYY");
                    var startTime = moment(
                      s.start,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("HH:mm ");
                    var endTime = moment(s.end, "YYYY-MM-DD HH:mm:ss").format(
                      "HH:mm"
                    );

                    if (d.outlet != "") {
                      var info = d.outlet;
                      var forretningNameArr = info.split(",");

                      if (forretningNameArr.length <= 2) {
                        forretningNameArr = forretningNameArr[0].split("-");
                      }
                    }
                    if (colorCheck) {
                      doc.setFillColor(252, 136, 78);
                    }
                    if (!colorCheck) {
                      doc.setFillColor(127, 146, 255);
                    }
                    if (date === compareDates) {
                      doc.setDrawColor(0);

                      doc.rect(
                        startLinen - 5,
                        startHeightLinen - 24,
                        205,
                        60,
                        "F"
                      );

                      if (i === 0) {
                        doc.text(
                          "Sampling ID: " + `${d.sampling_Id}`,
                          startLinen,
                          startHeightLinen - 5
                        );
                        doc.setFontSize(12);
                        doc.text(
                          "Forretning: " + `${forretningNameArr[0]}`,
                          startLinen,
                          startHeightLinen + 27
                        );
                      }

                      doc.setFontSize(15);
                      doc.text(
                        "Tid: " + `${startTime}` + " - " + `${endTime}`,
                        startLinen,
                        startHeightLinen + 12
                      );
                    }
                  });
                }

                startHeightLinen += 80;
                if (colorCheck) {
                  colorCheck = false;
                } else {
                  colorCheck = true;
                }
              });

              startLinen += 205;
              startHeightLinen = 120;
            }
          }
        }

        // 2 second round
        if (b === 2) {
          if (daysBetween > 8) {
            for (let p = 4; p < 8; p++) {
              var date = moment(startDateFormat, "YYYY-MM-DD HH:mm:ss")
                .add(p, "days")
                .format("DD/MM/YYYY");
              doc.setFontSize(15);
              var colorCheck = true;
              doc.text("2", 10, 20);
              myDataSixLess.map((d, j) => {
                if (j <= 5) {
                  d.samplingDates.map((s, i) => {
                    var compareDates = moment(
                      s.start,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("DD/MM/YYYY");
                    var startTime = moment(
                      s.start,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("HH:mm ");
                    var endTime = moment(s.end, "YYYY-MM-DD HH:mm:ss").format(
                      "HH:mm"
                    );

                    if (d.outlet != "") {
                      var info = d.outlet;
                      var forretningNameArr = info.split(",");

                      if (forretningNameArr.length <= 2) {
                        forretningNameArr = forretningNameArr[0].split("-");
                      }
                    }
                    if (colorCheck) {
                      doc.setFillColor(252, 136, 78);
                    }
                    if (!colorCheck) {
                      doc.setFillColor(127, 146, 255);
                    }
                    if (date === compareDates) {
                      doc.setDrawColor(0);

                      doc.rect(
                        startLinen - 5,
                        startHeightLinen - 24,
                        205,
                        60,
                        "F"
                      );

                      if (i === 0) {
                        doc.text(
                          "Sampling ID: " + `${d.sampling_Id}`,
                          startLinen,
                          startHeightLinen - 5
                        );
                        doc.setFontSize(12);
                        doc.text(
                          "Forretning: " + `${forretningNameArr[0]}`,
                          startLinen,
                          startHeightLinen + 27
                        );
                      }

                      doc.setFontSize(15);
                      doc.text(
                        "Tid: " + `${startTime}` + " - " + `${endTime}`,
                        startLinen,
                        startHeightLinen + 12
                      );
                    }
                  });
                }

                startHeightLinen += 80;
                if (colorCheck) {
                  colorCheck = false;
                } else {
                  colorCheck = true;
                }
              });

              startLinen += 205;
              startHeightLinen = 120;
            }
          }

          if (daysBetween > 4 && daysBetween <= 8) {
            for (let p = 4; p < daysBetween + 1; p++) {
              var date = moment(startDateFormat, "YYYY-MM-DD HH:mm:ss")
                .add(p, "days")
                .format("DD/MM/YYYY");
              doc.setFontSize(15);
              var colorCheck = true;
              doc.text("2", 10, 20);
              myDataSixLess.map((d, j) => {
                if (j <= 5) {
                  d.samplingDates.map((s, i) => {
                    var compareDates = moment(
                      s.start,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("DD/MM/YYYY");
                    var startTime = moment(
                      s.start,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("HH:mm ");
                    var endTime = moment(s.end, "YYYY-MM-DD HH:mm:ss").format(
                      "HH:mm"
                    );

                    if (d.outlet != "") {
                      var info = d.outlet;
                      var forretningNameArr = info.split(",");

                      if (forretningNameArr.length <= 2) {
                        forretningNameArr = forretningNameArr[0].split("-");
                      }
                    }
                    if (colorCheck) {
                      doc.setFillColor(252, 136, 78);
                    }
                    if (!colorCheck) {
                      doc.setFillColor(127, 146, 255);
                    }
                    if (date === compareDates) {
                      doc.setDrawColor(0);

                      doc.rect(
                        startLinen - 5,
                        startHeightLinen - 24,
                        205,
                        60,
                        "F"
                      );

                      if (i === 0) {
                        doc.text(
                          "Sampling ID: " + `${d.sampling_Id}`,
                          startLinen,
                          startHeightLinen - 5
                        );
                        doc.setFontSize(12);
                        doc.text(
                          "Forretning: " + `${forretningNameArr[0]}`,
                          startLinen,
                          startHeightLinen + 27
                        );
                      }

                      doc.setFontSize(15);
                      doc.text(
                        "Tid: " + `${startTime}` + " - " + `${endTime}`,
                        startLinen,
                        startHeightLinen + 12
                      );
                    }
                  });
                }

                startHeightLinen += 80;
                if (colorCheck) {
                  colorCheck = false;
                } else {
                  colorCheck = true;
                }
              });

              startLinen += 205;
              startHeightLinen = 120;
            }
          }
        }

        //slå tomme sider fra. maker den den første if ud eller den næste for side 2
        if (i < pageNr) {
          doc.addPage();
        }
        if (i == pageNr && lengthOfData > addOneMorePage) {
          addOneMorePage += 6;
          doc.addPage();
        }
      }

      myDataSixLess = myDataSixLess.slice(6, myDataSixLess.length);
    }
    //-------------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------------

    var pageCount = doc.internal.getNumberOfPages();
    doc.deletePage(pageCount);
    doc.deletePage(pageCount - 1);
    const uri = doc.output("datauristring");
    return <iframe frameBorder="0" width={"900"} height={"700"} src={uri} />;
  }

  if (refreshApi) {
    return (
      <div>
        <div className="sweet-loading" style={{ marginLeft: 300 }}>
          <BarLoader
            height={5}
            width={200}
            sizeUnit={"px"}
            size={500}
            color={"#4C8FBF"}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ marginLeft: 450 }}>
          <h5>Press View</h5>
        </div>
      </div>
    );
  }
}
export default Weekplan;
