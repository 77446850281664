import React, { useState } from "react";
import {
  IntegratedSorting,
  SortingState,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";
import tableCell from "./BookingErrorTableCell";

export default function BookingErrorTable(props) {
  const [tableColumnExtensions, setTableColumnExtensions] = useState([
    //company name
    { columnName: "id" },
    { columnName: "type" },
    { columnName: "msg" },
    { columnName: "date" },
  ]);

  if (props.myData.length > 0) {
    return (  
      <Grid rows={props.myData} columns={props.dataColumn}>
        <SortingState />
        <IntegratedSorting />
        <DragDropProvider />
        <SearchState />
        <IntegratedFiltering />
        <Table
          columnExtensions={tableColumnExtensions}
          cellComponent={tableCell}
        />

        <TableHeaderRow showSortingControls={true} />

        <Toolbar />
        <SearchPanel />
      </Grid>
    );
  } else {
    return <div>No Data</div>;
  }
}
