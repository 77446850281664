import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";

var Style = {
  stuck2: {
    position: "fixed",
    marginTop: "0%",
    marginLeft: "0%",
    zIndex: "1000",
  },
};

function updateJobManagementMsg(jobListOfIds, error, searchInput, errorStuck) {
  if (error === 2) {
    return (
      <Alert style={errorStuck} severity="error">
        <AlertTitle>Job: {searchInput} doesn't exist</AlertTitle>
      </Alert>
    );
  } else if (error === 1) {
    return (
      <Alert style={errorStuck} severity="error">
        <AlertTitle>No jobs the next 7 days</AlertTitle>
      </Alert>
    );
  } else if (error === 4) {
    return (
      <Alert style={errorStuck} severity="error">
        <AlertTitle>No data was received from the server</AlertTitle>
      </Alert>
    );
  } else if (jobListOfIds.length > 0) {
    return (
      <Alert style={Style.stuck2} severity="success">
        <AlertTitle>Jobs {jobListOfIds} has been updated</AlertTitle>
      </Alert>
    );
  } else {
    return <div></div>;
  }
}

export { updateJobManagementMsg };
