import React from "react";
import {
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";

export default function JobPackerTable(props) {
  if (props.rows != undefined) {
    return (
      <React.Fragment>
        <Grid
          rows={props.rows}
          columns={props.columns}
          getRowId={props.getRowId}
        >
          <SearchState />
          <IntegratedFiltering />
          <Table
            columnExtensions={props.tableColumnExtensions}
            cellComponent={props.JobPackerCell}
          />
          <TableHeaderRow
            columnExtensions={props.headerExtension}
          />
          <Toolbar />
          <SearchPanel />
        </Grid>
      </React.Fragment>
    );
  } else {
    return <div></div>;
  }
}
