import React, { useEffect, useState } from "react";
import apiFacade from "../../../../auth/apiFacade";
import BarLoader from "react-spinners/BarLoader";
import jsPDF from "jspdf";
import hexToRgb from "pretty-easy-hex-to-rgb";
import { ScreenSize } from "helper/ScreenSize";

// function sortNumber(a, b) {
//   return a - b;
// }

var headRows = [
  { header: "Item", dataKey: "name" },
  { header: "Amount", dataKey: "amount" },
  { header: "Unit", dataKey: "unit" },
  { header: "Reol", dataKey: "reol" },
  { header: "Hylde", dataKey: "hylde" },
];

var bookingInfoHeadRow = [
  { header: "Title", dataKey: "title" },
  { header: "Info", dataKey: "value" },
];

const Returliste = ({
  listOfId,
  refreshApi,
  loading,
  navne,
  componentDtos,
  sortingList,
}) => {
  const [myListOfIds, setMyListOfIds] = useState([]);
  const [myData, setMyData] = useState([]);
  const [pdfWidth, setPdfWidth] = useState(700);
  const [pdfHeight, setPdfHeight] = useState(900);
  //const [loaded, setLoaded] = useState(false);
  //const [myComponentDtos, setMyComponentDtos] = useState([]);
  //const [mySortingList, setmySortingList] = useState([]);

  useEffect(() => {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1) {
      setPdfWidth(300);
      setPdfHeight(350);
    } else if (screenSize === 2) {
      setPdfWidth(675);
      setPdfHeight(800);
    }

    setMyListOfIds(listOfId);
    //setMyComponentDtos(componentDtos);
    //setmySortingList(sortingList);
    if (refreshApi) {
      apiFacade
        .getData("/bookings/getlistofpacking/" + listOfId.join())
        .then((data) => {
          setMyData(data);
        })
        .finally();
    }
  }, [refreshApi]);

  let pageCount = 0;

  let newCount = 0;

  if (myData.length > 0) {
    let pageNr = myData.length - 1;
    var doc = new jsPDF("p", "pt", "a4", true);
    var changeId = 0;

    myData.map((data, i) => {
      let newBookingInfo = [];

      newBookingInfo.push({
        title: "Company name",
        value: data.bookingInfo[0].companyName,
      });
      newBookingInfo.push({
        title: "Menu",
        value: data.bookingInfo[0].bookinginfo.menutype,
      });

      newBookingInfo.push({
        title: "Date",
        value: data.bookingInfo[0].date,
      });
      newBookingInfo.push({
        title: "Type",
        value: data.bookingInfo[0].type,
      });
      newBookingInfo.push({
        title: "Bartenders",
        value: data.bookingInfo[0].bartenders,
      });
      newBookingInfo.push({
        title: "Bar Modules",
        value: data.bookingInfo[0].barModules,
      });
      newBookingInfo.push({
        title: "Duration",
        value: data.bookingInfo[0].duration,
      });
      newBookingInfo.push({
        title: "Region",
        value: data.bookingInfo[0].region,
      });
      newBookingInfo.push({
        title: "Persons",
        value: data.bookingInfo[0].persons,
      });
      if (data.bookingInfo[0].barbacks === 0) {
        newBookingInfo.push({
          title: "Barbacks",
          value: "None",
        });
      } else {
        newBookingInfo.push({
          title: "Barbacks",
          value: data.bookingInfo[0].barbacks,
        });
      }

      if (data.bookingInfo[0].waiters === 0) {
        newBookingInfo.push({
          title: "Waiters",
          value: "None",
        });
      } else {
        newBookingInfo.push({
          title: "Waiters",
          value: data.bookingInfo[0].waiters,
        });
      }

      if (data.bookingInfo[0].industrialDishwasher === false) {
        newBookingInfo.push({
          title: "IndustrialDishwasher",
          value: "None",
        });
      } else {
        newBookingInfo.push({
          title: "IndustrialDishwasher",
          value: data.bookingInfo[0].industrialDishwasher,
        });
      }

      // doc.setFontSize(18)
      //doc.text("Booking Id: " + `${bookingNr}`, 420, 30)
      // doc.setFontSize(16);
      //  doc.text("Packing List", 14, 30);
      doc.setFontSize(14);
      doc.setFont("times");
      doc.setFontType("italic");
      // doc.text(`${data.bookingId}`, 500, 20)
      doc.autoTable({
        columns: bookingInfoHeadRow,
        body: newBookingInfo,
        theme: "grid",

        styles: {
          lineColor: [44, 62, 80],
          lineWidth: 1,
        },

        columnStyles: { title: { cellWidth: 150 } },
        headStyles: { fillColor: hexToRgb("#af7c7c") }, // Red
      });

      const componentDtos = [];
      const componentWithPid = [];

      const listOfComponents = data.packinglist.map((id) => {
        return {
          componentId: id.component.id,
        };
      });

      sortingList.map((sorting) => {
        sorting.componentDtos.map((dtos) => {
          componentDtos.push(dtos);
        });
      });

      listOfComponents.map((plist) => {
        componentDtos.map((d) => {
          if (d.componentId === plist.componentId) {
            componentWithPid.push({
              componentId: d.componentId,
              pladsId: d.pladsId,
              colorId: d.colorId,
              categoryPlads: d.categoryPlads,
              reol: d.reol_nr,
              hylde: d.hylde,
            });
          }
        });
      });

      let myNewData = [];
      if (data.packinglist.length > 0) {
        var carlsbergAmount = null;
        var unibrewAmount = null;
        myNewData = data.packinglist.map((packing, i) => {
          var pladsID = null;
          var colorId = null;
          var categoryPlads = null;
          var reol_nr = null;
          var hylde = null;
          var amount = packing.amount;

          if (packing.component.id === 126) {
            unibrewAmount = Math.round((packing.amount * 75) / 50);
          }
          if (packing.component.id === 185) {
            carlsbergAmount = Math.round((packing.amount * 75) / 50);
          }

          componentWithPid.map((s) => {
            if (s.componentId === packing.component.id) {
              pladsID = s.pladsId;
              colorId = s.colorId;
              categoryPlads = s.categoryPlads;
              reol_nr = s.reol;
              hylde = s.hylde;
            }
          });
          if (packing.component.id === 233) {
            amount = unibrewAmount;
          }
          if (packing.component.id === 147) {
            amount = carlsbergAmount;
          }
          return {
            componentId: packing.component.id,
            name: packing.component.name,
            amount: amount,
            category: packing.component.category.id,
            pladsId: pladsID,
            unit: packing.unit,
            colorId: colorId,
            categoryPlads: categoryPlads,
            reol: reol_nr,
            hylde: hylde,
          };
        });

        if (myNewData[0].reol !== null) {
          var reolUsorting = [];
          var reolSorting = [];

          var hyldeUsorting = [];
          var hyldeSorting = [];

          myNewData.map((data, i) => {
            reolUsorting.push(data.reol);
            hyldeUsorting.push(data.hylde);
          });

          if (reolUsorting.length > 0) {
            //først sorter fra duplicates
            reolSorting = reolUsorting.filter(
              (el, i, a) => i === a.indexOf(el)
            );
            hyldeSorting = hyldeUsorting.filter(
              (el, i, a) => i === a.indexOf(el)
            );
            // dernæste sorter fra 0 til højst
            reolSorting.sort((a, b) => a - b);
            hyldeSorting.sort();

            if (reolSorting.length > 0) {
              reolSorting.forEach((reolNr) => {
                hyldeSorting.map((hylde) => {
                  //var pagenr = doc.internal.getCurrentPageInfo();
                  var reolText = "Reol";
                  var hyldeText = reolNr + ".";
                  var fuldText = "";
                  if (hylde != null) {
                    fuldText =
                      reolText + " " + hyldeText + " " + hylde.toUpperCase();
                  }
                  var comp = [];

                  // kun category id 8 og 12 som er glas og barudstyr
                  myNewData.map((data) => {
                    if (
                      (data.reol === reolNr &&
                        data.hylde === hylde &&
                        data.category === 8) ||
                      (data.reol === reolNr &&
                        data.hylde === hylde &&
                        data.category === 12)
                    ) {
                      comp.push(data);
                    }
                  });

                  if (reolNr > 89) {
                    reolText = "";
                    hyldeText = "";
                    fuldText = hylde;
                  }

                  // sorter fra en gangs -->
                  // Fadølskrus(Carlsberg) id = 147
                  // Fadølskrus(Carlsberg) id = 233
                  // Plastikglas(CK) - 50 stk id = 63
                  // Plastikglas(Whitelabel) id = 194
                  // Shotglas id = 109
                  // Vinglas(Plastik) id = 235
                  // Sugerør - Korte(15cm) Husk elastik rundt om! id = 65
                  // Sugerør - Lange(20 cm) Husk elastik rundt om! id = 66

                  if (comp.length > 0) {
                    var lists = comp.filter((x) => {
                      return (
                        x.componentId !== 63 &&
                        x.componentId !== 65 &&
                        x.componentId !== 66 &&
                        x.componentId !== 109 &&
                        x.componentId !== 147 &&
                        x.componentId !== 194 &&
                        x.componentId !== 233 &&
                        x.componentId !== 235
                      );
                    });
                    //removeEmptyObject
                    if (lists.length > 0) {
                      //doc.setFontSize(16);
                      doc.setFontSize(18);
                      doc.setFont("times");
                      doc.setFontType("italic");
                      doc.text(fuldText, 14, doc.lastAutoTable.finalY + 40);

                      doc.autoTable({
                        columns: headRows,
                        body: lists,
                        startY: doc.lastAutoTable.finalY + 45,
                        rowPageBreak: "auto",
                        margin: { horizontal: 10 },
                        styles: { overflow: "linebreak" },
                        bodyStyles: { valign: "top" },

                        styles: {
                          lineColor: [44, 62, 80],
                          lineWidth: 1,
                          cellWidth: "wrap",
                          rowPageBreak: "auto",
                          halign: "justify",
                        },
                        columnStyles: {
                          name: { cellWidth: 200 },
                          email: { cellWidth: "wrap" },
                        },
                        headStyles: { fillColor: hexToRgb("#36688d") }, // Red  textColor: hexToRgb("#000000")
                        //pageBreak: 'avoid',
                      });
                      doc.setFontSize(18);
                      doc.setFont("times");
                      doc.setFontType("italic");
                      doc.text("Returliste", 14, 30);
                      doc.text(420, 30, "Booking id: " + `${data.bookingId}`);
                    }
                  }
                });
              });
            }
          }
        }
      }
      if (pageCount !== doc.internal.getNumberOfPages()) {
        newCount = doc.internal.getNumberOfPages() - pageCount;
        pageCount = pageCount + newCount;
      }

      newBookingInfo = [];

      if (i < pageNr) {
        doc.addPage();
        changeId++;
        doc.setFontSize(18);
        doc.setFont("times");
        doc.setFontType("italic");
        // doc.text("Returliste", 14, 30);
        doc.text(420, 30, `Booking id: ` + `${myListOfIds[changeId]}`);
      }
    });

    const uri = doc.output("datauristring");
    return (
      <iframe
        title="myFrame"
        frameBorder="0"
        width={pdfWidth}
        height={pdfHeight}
        src={uri}
      />
    );
  }
  if (refreshApi) {
    return (
      <div>
        <div className="sweet-loading" style={{ marginLeft: 300 }}>
          <BarLoader
            height={5}
            width={200}
            sizeUnit={"px"}
            size={500}
            color={"#4C8FBF"}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ marginLeft: 450 }}>
          <h5>Press View</h5>
        </div>
      </div>
    );
  }
};
export default Returliste;
