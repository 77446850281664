import React from "react";
import { Switch, Route } from "react-router-dom";
import Wares from "./Wares"

export default function WaresView() {
  return (
    <div>
      <React.Fragment>
        <Switch>
          <Route
            exact
            path="/digitalpackinglist"
            render={(props) => <Wares {...props} />}
          />
        </Switch>
      </React.Fragment>
    </div>
  );
}