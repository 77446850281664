import React, { useState, useEffect } from "react";
import apiFacade from "../../../../auth/apiFacade";
import BarLoader from "react-spinners/BarLoader";
import jsPDF from "jspdf";
import hexToRgb from "pretty-easy-hex-to-rgb";

var headRows = [
  { title: "Item", dataKey: "name" },
  { title: "Amount", dataKey: "amount" },
  { title: "Unit", dataKey: "unit" },
  { title: "Reol", dataKey: "reol" },
  { title: "Hylde", dataKey: "hylde" },
];

var samplingInfoHeadRow = [
  { title: "Title", dataKey: "title" },
  { title: "Info", dataKey: "value" },
];

const ReturlistAll = ({
  listOfId,
  refreshApi,
  loading,
  navne,
  componentDtos,
  sortingList,
  listOfsamplings,
}) => {
  // const [myListOfIds, setMyListOfIds] = useState([]);
  const [myData, setMyData] = useState([]);
  //const [loaded, setLoaded] = useState(false);
  // const [myComponentDtos, setMyComponentDtos] = useState([]);
  // const [mySortingList, setmySortingList] = useState([]);

  useEffect(() => {
    // setMyListOfIds(listOfId);
    // setMyComponentDtos(componentDtos);
    // setmySortingList(sortingList);
    if (refreshApi) {
      apiFacade
        .getData("/samplings/getlistofpacking/" + listOfId.join())
        .then((data) => {
          setMyData(data.allsamplings);
          //setMyListOfIds(data)
        })
        .finally();
    }
  }, [refreshApi]);

  let pageCount = 0;
  let newCount = 0;
  const moment = require("moment");
  if (myData.length > 0) {

    let pageNr = myData.length - 1;
    var doc = new jsPDF("p", "pt", "a4", true);
    var changeId = 0;
    myData.map((myNewData, i) => {
      let newSamplingInfo = [];
      var reolUsorting = [];
      var reolSorting = [];

      var hyldeUsorting = [];
      var hyldeSorting = [];

      newSamplingInfo.push({
        title: "Sampling Id",
        value: listOfsamplings[i].sampling_Id,
      });

      newSamplingInfo.push({
        title: "Outlet",
        value: listOfsamplings[i].outlet,
      });

      newSamplingInfo.push({
        title: "Samples",
        value: listOfsamplings[i].samples,
      });
      newSamplingInfo.push({
        title: "Kampagne",
        value: listOfsamplings[i].kampagne,
      });
      if (listOfsamplings[i].date1Start != null) {
        newSamplingInfo.push({
          title: "Tid - Dag 1 ",
          value:
            moment(
              `${listOfsamplings[i].date1Start}`,
              "YYYY/MM/DD HH:mm"
            ).format("HH:mm") +
            " - " +
            moment(`${listOfsamplings[i].date1End}`, "YYYY/MM/DD HH:mm").format(
              "HH:mm"
            ),
        });
      }

      if (listOfsamplings[i].date2Start != null) {
        newSamplingInfo.push({
          title: "Tid - Dag 2 ",
          value:
            moment(
              `${listOfsamplings[i].date2Start}`,
              "YYYY/MM/DD HH:mm"
            ).format("HH:mm") +
            " - " +
            moment(`${listOfsamplings[i].date2End}`, "YYYY/MM/DD HH:mm").format(
              "HH:mm"
            ),
        });
      }

      if (listOfsamplings[i].date3Start != null) {
        newSamplingInfo.push({
          title: "Tid - Dag 3 ",
          value:
            moment(
              `${listOfsamplings[i].date3Start}`,
              "YYYY/MM/DD HH:mm"
            ).format("HH:mm") +
            " - " +
            moment(`${listOfsamplings[i].date3End}`, "YYYY/MM/DD HH:mm").format(
              "HH:mm"
            ),
        });
      }
      if (listOfsamplings[i].date4Start != null) {
        newSamplingInfo.push({
          title: "Tid - Dag 4 ",
          value:
            moment(
              `${listOfsamplings[i].date4Start}`,
              "YYYY/MM/DD HH:mm"
            ).format("HH:mm") +
            " - " +
            moment(`${listOfsamplings[i].date4End}`, "YYYY/MM/DD HH:mm").format(
              "HH:mm"
            ),
        });
      }

      doc.setFontSize(14);
      doc.setFont("times");
      doc.setFontType("italic");
      // doc.text(`${data.bookingId}`, 500, 20)
      doc.autoTable({
        columns: samplingInfoHeadRow,
        body: newSamplingInfo,
        //startY: doc.lastAutoTable.finalY - 39,
        // margin: { horizontal: 40 },
        //styles: { overflow: 'linebreak' },
        //bodyStyles: { valign: 'top' },

        //theme: "striped",

        styles: {
          lineColor: [44, 62, 80],
          lineWidth: 1,
          // cellWidth: 'auto'
        },
        columnStyles: { title: { cellWidth: 150 } },
        headStyles: { fillColor: hexToRgb("#af7c7c") }, // Red
      });

      myNewData.samplings.map((data, i) => {
        reolUsorting.push(data.reol);
        hyldeUsorting.push(data.hylde);
      });

      if (reolUsorting.length > 0) {
        //først sorter fra duplicates
        reolSorting = reolUsorting.filter((el, i, a) => i === a.indexOf(el));
        hyldeSorting = hyldeUsorting.filter((el, i, a) => i === a.indexOf(el));
        // dernæste sorter fra 0 til højst
        reolSorting.sort((a, b) => a - b);
        hyldeSorting.sort();

        if (reolSorting.length > 0) {

          reolSorting.forEach((reolNr) => {
            hyldeSorting.map((hylde) => {
              var reolText = "Reol";
              var hyldeText = reolNr + ".";
              var fuldText = "";
              if (hylde != null) {
                fuldText =
                  reolText + " " + hyldeText + " " + hylde.toUpperCase();
              }
              var comp = [];

              myNewData.samplings.map((data) => {
                if (
                  (data.reol === reolNr &&
                    data.hylde === hylde &&
                    data.category_id === 8) ||
                  (data.reol === reolNr &&
                    data.hylde === hylde &&
                    data.category_id === 12)
                ) {
                  // sorter fra en gangs -->
                  // Fadølskrus(Carlsberg) id = 147
                  // Fadølskrus(Carlsberg) id = 233
                  // Plastikglas(CK) - 50 stk id = 63
                  // Plastikglas(Whitelabel) id = 194
                  // Shotglas id = 109
                  // Vinglas(Plastik) id = 235
                  // Sugerør - Korte(15cm) Husk elastik rundt om! id = 65
                  // Sugerør - Lange(20 cm) Husk elastik rundt om! id = 66
                  if (
                    data.component_Id !== 64 ||
                    (data.component_Id !== 65 && data.component_Id !== 66)
                  ) {
                    comp.push(data);
                  }
                }
              });

              if (reolNr > 89) {
                reolText = "";
                hyldeText = "";
                fuldText = hylde;
              }

              if (comp.length > 0) {
                var lists = comp.filter((x) => {
                  return (
                    x.component_Id !== 63 &&
                    x.component_Id !== 65 &&
                    x.component_Id !== 66 &&
                    x.component_Id !== 109 &&
                    x.component_Id !== 147 &&
                    x.component_Id !== 194 &&
                    x.component_Id !== 233 &&
                    x.component_Id !== 235
                  );
                });

                if (lists.length > 0) {
                  //doc.setFontSize(16);
                  doc.setFontSize(18);
                  doc.setFont("times");
                  doc.setFontType("italic");
                  doc.text(fuldText, 14, doc.lastAutoTable.finalY + 40);

                  doc.autoTable({
                    columns: headRows,
                    body: lists,
                    startY: doc.lastAutoTable.finalY + 45,
                    rowPageBreak: "auto",
                    margin: { horizontal: 10 },
                    styles: { overflow: "linebreak" },
                    bodyStyles: { valign: "top" },
                    columnStyles: { email: { cellWidth: "wrap" } },
                    // columnStyles: { 0: { cellWidth: 1200 } },

                    styles: {
                      lineColor: [44, 62, 80],
                      lineWidth: 1,
                      cellWidth: "wrap",
                      rowPageBreak: "auto",
                      halign: "justify",
                      //fillColor: hexToRgb(myColor)
                    },
                    columnStyles: {
                      name: { cellWidth: 200 },
                      // unit: { cellWidth: 100 },
                      // amount: { cellWidth: 50 },
                      // reol: { cellWidth: 40 },
                      // hylde: { cellWidth: 140 }
                    },
                    headStyles: { fillColor: hexToRgb("#36688d") }, // Red  textColor: hexToRgb("#000000")
                    //pageBreak: 'avoid',
                  });
                  doc.setFontSize(18);
                  doc.setFont("times");
                  doc.setFontType("italic");
                  doc.text("Returliste", 14, 30);
                  doc.text(
                    420,
                    30,
                    `Sampling id: ` + `${myNewData.sampling_id}`
                  );
                }
              }
            });
          });
        }
      }

      if (pageCount !== doc.internal.getNumberOfPages()) {
        newCount = doc.internal.getNumberOfPages() - pageCount;
        pageCount = pageCount + newCount;
      }
      newSamplingInfo = [];

      if (i < pageNr) {
        doc.addPage();
        changeId++;
        /*         doc.setFontSize(18);
                doc.setFont("times");
                doc.setFontType("italic");
                doc.text("Packing List", 14, 30);
                doc.text(420, 30, "Booking id: " + `${myListOfIds[changeId]}`); */
      }
    });
    const uri = doc.output("datauristring");
    return (
      <iframe
        title="Myframe"
        frameBorder="0"
        width={"900"}
        height={"700"}
        src={uri}
      />
    );
  }
  if (refreshApi) {
    return (
      <div>
        <div className="sweet-loading" style={{ marginLeft: 300 }}>
          <BarLoader
            height={5}
            width={200}
            sizeUnit={"px"}
            size={500}
            color={"#4C8FBF"}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ marginLeft: 450 }}>
          <h5>Press View</h5>
        </div>
      </div>
    );
  }
};

export default ReturlistAll;
