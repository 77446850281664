import React, { Component } from "react";
import PackingvckFunc from "./PackingvckFunc";
import apiFacade from "../../../auth/apiFacade";
import {
  Row,
  Button,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import { ScreenSize } from "helper/ScreenSize";

var packingStyle = {
  textColor: {
    color: "#3787C6",
  },
  backbtn: {
    position: "absolute",
    top: "10px",
  },
  printMenu: {
    position: "absolute",
    top: "18%",
    left: "100px",
  },
  fixedElement: {
    position: "fixed",
    width: 250,
    height: 80,
  },
};

class Packinglistvck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: "",
      packingList: [],
      packingListTotal: [],
      bookingInfo: [],
      menus: [],
      rawProducts: [],
      products: [],
      Details: [],
      sortingList: [],
      componentDtos: [],
      componentWithPid: [],
      isLoading: true,
      vin: [],
      pladsId: [],
      dtos: [],
      jobType: [],
      colSize1: 2,
      colSize2: 8,
    };
  }
  componentDidMount() {
    let screenSize = ScreenSize(window.innerWidth, window.innerHeight);

    if (screenSize === 1 || screenSize === 2) {
     this.setState({ colSize1: 12});
     this.setState({ colSize2: 12});
    }

    this.getdata(this.props.match.params.id);
  }
  stringToHTML(str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, "text/html");
    return doc.body;
  }

  getdata(id) {
    apiFacade
      .getData("/packinglists/packinglistVCK/" + id)
      .then((data) => {
        this.setState({ packingList: data });
        data.forEach((element1) => {
          const sortedData = [];
          let totalContainerQty = 0;

          element1.bookingInfo.forEach((getbookinginfo) => {
            let pax = getbookinginfo.persons;
            let bartenders = getbookinginfo.bartenders;
            let packs = 1 + pax;
            let additionalInfo_ = "";
            // if (getbookinginfo.additionalInfo != null) additionalInfo_ = getbookinginfo.additionalInfo
            if (getbookinginfo.additionalInfo != null) {
              const Yderligere_step1 = this.stringToHTML(
                getbookinginfo.additionalInfo
              );

              additionalInfo_ = Yderligere_step1.innerText;
            }
            if (bartenders > 1) {
              packs = pax + bartenders / 2;
            }

            this.state.bookingInfo.push({
              bookingId: getbookinginfo.id,
              bartender: getbookinginfo.bartenders,
              paxtotal: getbookinginfo.persons,
              packs: packs,
              alcoholFree: getbookinginfo.alkoholfrieVCK,
              date: getbookinginfo.date,
              dateEnd: getbookinginfo.dateEnd,
              additionalInfo: additionalInfo_,
              barcode: getbookinginfo.barcode,
            });
          });
          // slut bookinginfo

          element1.vCKtotals.forEach((element) => {
            element.vCKItemtotals.forEach((comp) => {
              let objIndex = sortedData.findIndex(
                (obj) => obj.componentId === comp.componentId
              );
              if (objIndex !== -1) {
                sortedData[objIndex].amount =
                  sortedData[objIndex].amount + comp.amount;
                sortedData[objIndex].totalamountcl =
                  sortedData[objIndex].totalamountcl + comp.totalamountcl;
                sortedData[objIndex].containerQty =
                  sortedData[objIndex].containerQty + comp.containerQty;
                sortedData[objIndex].prkasse =
                  sortedData[objIndex].prkasse + comp.prkasse;
                if (comp.containerName === "PET") {
                  totalContainerQty = totalContainerQty + comp.containerQty;
                }
              } else {
                sortedData.push(comp);
                if (comp.containerName === "PET") {
                  totalContainerQty = totalContainerQty + comp.containerQty;
                }
              }
            });
            //slut med enhver item i en booking
          });
          if (sortedData.length > 0) {
            let packs = null;
            packs = sortedData.find(function (post, index) {
              if (post.componentId === 451) {
                return post.amount;
              }
            });
            if (packs != null) {
              sortedData.push({
                componentId: 9999,
                amount: totalContainerQty,
                prkasse: totalContainerQty / packs.amount,
                componentName: "Total Container",
                unit: "PET flasker",
                containerQty: "",
              });
            }
          }
          this.state.packingListTotal.push(sortedData);
          //slut element1 array
        });
        //slut newdata2 array

        if (this.state.packingList.length > 0) {
        }

        //setMyListOfIds(data)
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    const { packingList, colSize2, colSize1 } = this.state;
    var headRows = [
      { title: "Item", dataKey: "name" },
      { title: "Unit", dataKey: "unit" },
      { title: "Amount", dataKey: "amount" },

      { title: "Reol", dataKey: "reol" },
      { title: "Hylde", dataKey: "hylde" },
    ];
    var headRowsFynJylland = [
      { title: "Item", dataKey: "name" },
      { title: "Unit", dataKey: "unit" },
      { title: "Amount", dataKey: "amount" },
    ];

    var headRowsvck = [
      { title: "Component Name", dataKey: "componentName" },
      { title: "Amount", dataKey: "amount" },
      { title: "Pr.Kasse", dataKey: "prkasse" },
      { title: "Container", dataKey: "containerName" },
      { title: "cl", dataKey: "unitcl" },
      { title: "Total Amount cl", dataKey: "totalamountcl" },
    ];

    var headRowsProduct = [
      { title: "Component Name", dataKey: "componentName" },
      { title: "Amount", dataKey: "oriAmount" },
      { title: "Unit", dataKey: "oriUnit" },
    ];

    if (packingList.length > 0) {
      return (
        <div>
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col xs={12} md={colSize2} className="mr-auto">
                <Card className="card-chart ">
                  <CardHeader>
                    <CardTitle>Print VCK</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <PackingvckFunc
                      headRowsFynJylland={headRowsFynJylland}
                      region={this.state.region}
                      bookingNr={this.props.match.params.id}
                      isLoading={this.state.isLoading}
                      headRows={headRows}
                      myAllData={packingList}
                      headRowsvck={headRowsvck}
                      headRowsProduct={headRowsProduct}
                      bookingInfo={this.state.bookingInfo}
                      filteredData={this.state.packingListTotal}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} md={colSize1}>
                <Card>
                  <CardBody>
                    <Button
                      color="danger"
                      onClick={() => {
                        this.props.history.push(
                          `/bookingoverview/vbookings/${this.props.match.params.id}`
                        );
                      }}
                    >
                      Back
                    </Button>
                    <UncontrolledDropdown style={packingStyle.printMenu}>
                      <DropdownToggle
                        style={{ backgroundColor: "#d16557" }}
                        caret
                        color="info"
                      >
                        Print
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          style={{ color: "#d16557" }}
                          onClick={() => {
                            this.props.history.push(
                              `/bookingoverview/bookings/${this.props.match.params.id}/packinglistvck`
                            );
                          }}
                        >
                          Packing List VCK
                        </DropdownItem>
                        <DropdownItem
                          style={{ color: "#d16557" }}
                          onClick={() => {
                            this.props.history.push(
                              `/bookingoverview/bookings/${this.props.match.params.id}/frontpage`
                            );
                          }}
                        >
                          Front page
                        </DropdownItem>
                        {/* <DropdownItem
                          onClick={() => {
                            this.props.history.push(
                              `/bookingoverview/bookings/${this.props.match.params.id}/specsheet`
                            );
                          }}
                        >
                          Specsheet
                        </DropdownItem>*/}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      return <div>loading</div>;
    }
  }
}

export default Packinglistvck;
