import React, { useState, useEffect } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import DeleteForeverTwoTone from "@material-ui/icons/DeleteForeverTwoTone";
import { IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import types from "../../../redux/actionTypes";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";

var JobAssignmentStyle = {
  dropdownmenustyle: {
    backgroundColor: "#dbdbdb",
    color: "#d16557",
    width: "100%",
    zIndex: "1001",
    right: "end",
  },
};

export default function JobAssignment(props) {
  const dispatch = useDispatch();
  const { dateBool } = useSelector((state) => state.jobManagement);
  const [myBool, setMyBool] = useState(false);

  const handleTime = (timeDate, timeJobId, timeAssignmentId) => {
    dispatch({
      type: types.jobManagement.updateDate,
      payload: {
        date: timeDate,
        jobId: timeJobId,
        assignmentId: timeAssignmentId,
      },
    });
    dispatch({
      type: types.jobManagement.dateBool,
      payload: {
        dateBool: !dateBool,
      },
    });
  };

  useEffect(() => {
    setMyBool(!myBool);
  }, [dateBool]);

  const handleChange = (jobId, assignmentId) => {
    dispatch({
      type: types.jobManagement.updateStatus,
      payload: { statusJobId: jobId, statusAssignmentId: assignmentId },
    });
    dispatch({
      type: types.jobManagement.dateBool,
      payload: {
        dateBool: !dateBool,
      },
    });
  };

  const dateTime = (date, assignmentid, jobId, assignmentStatusId) => {
    if (date !== null) {
      let disableDrop = false;
      // jobPakkers[index].assignmentStatusId - Hvis det er blevet pakket eller er i gang = disable.
      // if (assignmentStatusId === 2 || assignmentStatusId === 4) {
      //   disableDrop = true;
      // }
      return (
        <span>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
              label="Date & time"
              value={date}
              ampm={false}
              disablePast={true}
              disabled={disableDrop}
              onChange={(newDate) => {
                handleTime(newDate, jobId, assignmentid);
              }}
              inputFormat="dd/MM/yyyy HH:mm"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </span>
      );
    } else {
      return (
        <span>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
              label="Date & time"
              value={date}
              ampm={false}
              disablePast={true}
              onChange={(newDate) => {
                handleTime(newDate, jobId, assignmentid);
              }}
              inputFormat="dd/MM/yyyy HH:mm"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </span>
      );
    }
  };

  const dropDownItem = (jobId, assignmentId, pakker) => {
    let item = props.packers.map((packer) => {
      let packerName = packer.firstName + " " + packer.lastName;
      return (
        <DropdownItem
          style={{ color: "#d16557" }}
          onClick={() => {
            dispatch({
              type: types.jobManagement.dateBool,
              payload: {
                dateBool: !dateBool,
              },
            });
            dispatch({
              type: types.jobManagement.updatePacker,
              payload: {
                packerId: packer.id,
                packerJobId: jobId,
                packerAssignmentId: assignmentId,
                packerName: packerName,
                prevPacker: pakker,
              },
            });
          }}
          id="packer"
          key={packer.id}
        >
          {packerName}
        </DropdownItem>
      );
    });
    return item;
  };

  const showPackers = (pakker, jobId, assignmentId, assignmentStatusId, jobPakkers) => {
    let packerName = "";
    let disableDrop = true;
    if(jobPakkers !== undefined){
      disableDrop = jobPakkers.isitFutureJob;
    }
    // jobPakkers[index].assignmentStatusId - Hvis det er blevet pakket eller er i gang = disable.
    // if (assignmentStatusId === 2 || assignmentStatusId === 4) {
    //   disableDrop = true;
    // }
    if (pakker) {
      packerName = pakker.firstName + " " + pakker.lastName;
    }
    return (
      <div>
        <UncontrolledDropdown>
          <DropdownToggle
            caret
            style={{ width: "100%", backgroundColor: "#d16557" }}
            // disabled={!disableDrop}
          >
            {packerName}
          </DropdownToggle>
          <DropdownMenu style={JobAssignmentStyle.dropdownmenustyle}>
            {dropDownItem(jobId, assignmentId, pakker)}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  };

  const formGroupMaker = () => {
    if (!props.loadingFg) {
      var mapJobMan;
      mapJobMan = props.jobManagements.map((ele) => {
        let packer1 = false;
        let packer2 = false;
        let disableDrop = false;
        // jobPakkers[index].assignmentStatusId - Hvis det er blevet pakket eller er i gang = disable.
        // if (ele.assignmentStatusId === 2 || ele.assignmentStatusId === 4) {
        //   disableDrop = true;
        // }
        if (ele.jobPakkers[0] != undefined) {
          packer1 = props.packers.find(
            (p) => p.id === ele.jobPakkers[0].pakker_Id
          );
        }
        if (ele.jobPakkers[1] != undefined) {
          packer2 = props.packers.find(
            (p) => p.id === ele.jobPakkers[1].pakker_Id
          );
        }
        return (
          <div key={ele.assignmentId + ele.jobId}>
            <Row>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12} md={3}>
                    <IconButton
                      color="secondary"
                      id={ele.jobId.toString()}
                      disabled={!ele.status}
                      onClick={() => {
                        handleChange(ele.jobId, ele.assignmentId);
                        props.saveInfo(ele.jobId);
                      }}
                    >
                      <DeleteForeverTwoTone />
                    </IconButton>
                  </Col>
                  <Col xs={12} md={9} style={{ marginTop: "15px" }}>
                    {ele.assignmentName}
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                {showPackers(
                  packer1,
                  ele.jobId,
                  ele.assignmentId,
                  ele.assignmentStatusId,
                  ele.jobPakkers[0]
                )}
              </Col>
              <Col xs={12} md={6}>
                {dateTime(
                  ele.packingDate,
                  ele.assignmentId,
                  ele.jobId,
                  ele.assignmentStatusId
                )}
              </Col>
              <Col xs={12} md={6}>
                {showPackers(
                  packer2,
                  ele.jobId,
                  ele.assignmentId,
                  ele.assignmentStatusId,
                  ele.jobPakkers[1]
                )}
              </Col>
            </Row>
            <hr style={{ border: "0.5px dashed lightgrey", width: "100%" }} />
          </div>
        );
      });
      return mapJobMan;
    }
  };

  return <FormGroup>{formGroupMaker()}</FormGroup>;
}
