import types from "../actionTypes";

const initialState = {
  Id: "0",
  Name: "",
  products: [],
  addedCocktails: {},
  menuCocktails: [],
  updateNow: false,
  updateMenu: false,
};

const menuCocktailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.menuCocktailId.Id:
      return {
        ...state,
        Id: action.payload,
      };
    case types.menuCocktailId.Name:
      return {
        ...state,
        Name: action.payload,
      };
    case types.menuCocktailId.Menu:
      return {
        ...state,
        Menu: action.payload,
      };
    case types.menuCocktailId.products:
      return {
        ...state,
        products: action.payload,
      };
    case types.menuCocktailId.addedCocktails:
      return {
        ...state,
        addedCocktails: [...state.addedCocktails, { ...action.payload }],
      };
    case types.menuCocktailId.emptyCocktails:
      return {
        ...state,
        addedCocktails: [],
      };
    case types.menuCocktailId.emptyMenu:
      return {
        ...state,
        updateMenu: !state.updateMenu,
      };
    case types.menuCocktailId.deleteItem:
      return {
        ...state,
        addedCocktails: state.addedCocktails.filter(
          (item) => item.id !== action.payload
        ),
      };
    case types.menuCocktailId.menuCocktails:
      return {
        ...state,
        menuCocktails: action.payload,
      };
    case types.menuCocktailId.emptyMenuCocktails:
      return {
        ...state,
        menuCocktails: [],
      };
    case types.menuCocktailId.newMenu:
      return {
        ...state,
        newMenu: action.payload,
      };
    case types.menuCocktailId.updateNow:
      return {
        ...state,
        updateNow: action.payload,
      };
    default:
      return state;
  }
};

export default menuCocktailReducer;
