import { combineReducers } from "redux";
import auth from "./authReducer";
import menuCocktailReducer from "./menuCocktailReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import weekplanReducer from "./weekplanReducer";
import cocktailsMenuReducer from "./cocktailsMenuReducer";
import calculationsReducer from "./calculationsReducer";
import bookingReducer from "./bookingReducer";
import vckBookingReducer from "./vckBookingReducer";
import packinglistReducer from "./packinglistReducer";
import returnWaresReducer from "./returnWaresReducer";
import jobManagementReducer from "./jobManagementReducer";
import pakkerReducer from "./pakkerReducer";
import assignmentReducer from "./assignmentReducer";

const persistConfig = {
  key: "root",
  storage,
  debug: true,
};

const rootReducer = combineReducers({
  auth,
  MenuCocktail: menuCocktailReducer,
  weekplan: weekplanReducer,
  CocktailsMenu: cocktailsMenuReducer,
  calculations: calculationsReducer,
  booking: bookingReducer,
  vckBooking: vckBookingReducer,
  packinglist: packinglistReducer,
  returnWares: returnWaresReducer,
  jobManagement: jobManagementReducer,
  pakker: pakkerReducer,
  assignment: assignmentReducer,
});

export default persistReducer(persistConfig, rootReducer);
