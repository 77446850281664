import React from "react";
import jsPDF from "jspdf";

const FuncComponent = props => {
  const { columns, rows, isLoading } = props;
  if (!isLoading) {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF("p", "pt");
    doc.setFontSize(20);
    doc.setTextColor(40);
    doc.setFontStyle("normal");
    doc.text("YafasfaOLO", 20, 30);
    doc.text("YOLO2", 20, 60);
    doc.autoTable({
      columns: columns,
      body: rows,
      startY: 70,
      margin: { horizontal: 10 },
      styles: { overflow: "linebreak" },
      bodyStyles: { valign: "top" },
      columnStyles: { email: { cellWidth: "wrap" } },
      theme: "striped"
    });

    doc.autoTable({
      columns: columns,
      body: rows,
      startY: (doc.lastAutoTable.finalY) + 70,
      margin: { horizontal: 10 },
      styles: { overflow: "linebreak" },
      bodyStyles: { valign: "top" },
      columnStyles: { email: { cellWidth: "wrap" } },
      theme: "striped"
    });
    const uri = doc.output("datauristring");
    return (
      <iframe
        title="autotable"
        frameBorder="0"
        width="700"
        height="1000"
        src={uri}
      />
    );
  }
  return <div>loading</div>;
};

export default FuncComponent;
