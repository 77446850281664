import React, { useState } from "react";
import MaterialTable from "material-table";
import apiFacade from "../../../auth/apiFacade";
import { TablePagination } from "@material-ui/core";
import { Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";
import Paper from "@material-ui/core/Paper";
import { SketchPicker } from "react-color";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";

function getModalStyle() {
  const top = 40;
  const left = 40;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));
const SamplingsTable = props => {
  const [state, setState] = useState({
    columns: [
      { title: "Name.", field: "name" },
      { title: "Product Id.", field: "product_id" }
    ],
    data: props.myData
  });

  return (
    <div>
      <Row>
        <Col xs="auto" md={12}>
          <MaterialTable
            title={"Samplingstype"}
            columns={state.columns}
            data={state.data}
            options={{
              paging: false
            }}
            editable={{
              onRowAdd: newData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    setState(prevState => {
                      const data = [...prevState.data];
                      data.push(newData);
                      return { ...prevState, data };
                    });
                  }, 600);
                  apiFacade
                    .postData2(
                      "https://pakkemodelapi.azurewebsites.net/api/samplingcategories",
                      newData
                    )
                    .then(response => {
                    })
                    .catch(error => console.error("Error:", error));
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    setState(prevState => {
                      const data = [...prevState.data];
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data };
                    });
                  }, 600);
                  apiFacade.deleteData(
                    "https://pakkemodelapi.azurewebsites.net/api/samplingcategories/" +
                      oldData.id
                  );
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    const data = [...state.data];
                    data[data.indexOf(oldData)] = newData;
                    setState({ ...state, data });
                  }, 600);
                  //gem data her
                  // /NewSortings/putnewsortingbyid/
                  apiFacade
                    .putData(
                      "https://pakkemodelapi.azurewebsites.net/api/samplingcategories",
                      newData
                    )
                    .then(response => {
                    })
                    .catch(error => console.error("Error:", error));
                })
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SamplingsTable;
